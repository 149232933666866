import React, { useState, useCallback } from 'react'
import { Pagination, Table, Button, Modal, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { renderMobileColumn } from "utils/renderMobileColumn";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
	Field,
	formValueSelector,
	reduxForm,
} from "redux-form";
import { getMasterList, masterSelectors, ImportExcelFile, ExportExcelFile} from 'slices/masterSlice'
import useMasterLogic from 'customHooks/useMasterLogic';
import { ReduxFormFileField } from 'utils/ReduxFormFileField';
import { TfiReload } from "react-icons/tfi";
import { renderActions } from 'utils/renderActions';
import CustomerGroupDetails from './CustomerGroupDetails';
import { useHotkeys } from 'react-hotkeys-hook'

const masterName = "customergroup"
const CustomerGroup = () => {

	const formSelector = formValueSelector(masterName);
	const dispatch = useDispatch()
	const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
	const importing = useSelector(masterSelectors?.getIsImportingExcel);
	const exporting = useSelector(masterSelectors?.getIsExportingExcel);
	const list = useSelector(masterSelectors?.getCustomerGroupMasterList);
	const { pageSize, totalElements, currentPage } = useSelector(
		masterSelectors?.getCustomerGroupPagination
	);

	const getListData = useCallback(
		(pageNum = 1, itemsPerPage = 10) => {
			dispatch(
				getMasterList({
					masterName,
					query: {
						page: pageNum,
						pageSize: itemsPerPage
					},
				})
			);
		},
		[dispatch]
	);

	const { handlePageChange } = useMasterLogic(getListData, masterName);

    const [data, setData] = useState({})
	const [type, setType] = useState("update")

	const handleSetData = (value) =>{
		setType("update")
		let temp = {...value};
		let ids = [];
		value?.customer?.map((item)=> ids.push({label:item?.partyName, value:item?.id}))
        temp.customerId = ids;
		setData(temp)
	}

	const handleDuplicate = (value)=>{
		setType("new")
		let temp = {...value};
		let ids = [];
		value?.customer?.map((item)=> ids.push({label:item?.partyName, value:item?.id}))
        temp.customerId = ids;
		setData(temp)
	}

	const handleImport = () =>{
		dispatch(ImportExcelFile({
			masterName:'customergroup/export/excel',
			name:'customer-group'
		}))
	}
	const [show, setShow] = useState(false)
	const excelFile = useSelector(state => formSelector(state, "item_excel"));
	const handleExport = () =>{
		setShow(false);
		const form = new FormData();
		form.append("file", excelFile);
		dispatch(
			ExportExcelFile({
				masterName:"customergroup/upload/import",
				formData: form,
				configType: "multipart/form-data",
			})
		);
	}


    const columns = [
		{
			title: "Customer Group",
			render: data => {
				const primaryText = data?.groupName || "-";
				const secondaryText = `${data?.customer || "-"}`;
				return renderMobileColumn(primaryText, secondaryText);
			},
			responsive: ["xs"],
		},
		{
			title: "Group Name",
			dataIndex: "groupName",
			key: "groupName",
			responsive: ["sm"],
			render: (value, data) => <p onDoubleClick={()=>handleSetData(data)} className='cursor-pointer'>{value || "-"}</p>,
		},
		{
			title: "Actions",
			key: "Actions",
			dataIndex: "action",
			width: "20%",
			render: (_, data, index) => {
				const items = [
          {
						label: "Duplicate",
						onClick: () => handleDuplicate(data),
					}
				];

				return renderActions(items);
			},
		},
	];

	const nestedColumn = [
        {
            title: "Name",
			dataIndex: "partyName",
			key: "partyName",
            width: "auto"
        },
        {
            title: "Email",
			dataIndex: "emailId",
			key: "emailId",
            width: "auto"
        },
        {
            title: "Telephone No.",
			dataIndex: "telephone",
			key: "telephone",
            width: "auto"
        },
        {
            title: "Mobile No.",
			dataIndex: "mobile",
			key: "mobile",
            width: "auto"
        },
        {
            title: "City",
			dataIndex: ["cityMaster", "cityName"],
			key: "cityName",
            width: "auto"
        },
        {
            title: "State",
			dataIndex: ["cityMaster", "state"],
			key: "state",
            width: "auto"
        },
    ]

	useHotkeys('alt+n', () => window.open('/customer-group/add', '_blank'))

  return (
    <>
    <Helmet>
	<title>Customer Group</title>
	</Helmet>
    <div className='flex justify-between items-center mb-2'>
        <h1 className='text-2xl'>Customer Group List</h1>
		<div className='flex gap-5'>
			<Tooltip title="Reload">
		<Button
			type='default' size='small'
			loading={loading}
			disabled={loading}
			onClick={()=>getListData(1, 10)}
		>
			<TfiReload/>
		</Button>
		</Tooltip>
		
		<Button
			type='default' size='small'
			loading={exporting}
			disabled={exporting}
			onClick={()=>setShow(true)}
		>
			Import Excel
		</Button>
		<Button
			onClick={handleImport}
			loading={importing}
			disabled={exporting}
			type='default' size='small'
		>
			Export Excel
		</Button>
		<Link to="/customer-group/add" target='_blank'>
        <Button type='primary' size='small' className='w-[80px]'>Add New</Button>
		</Link>
		</div>
		
    </div>
    <Table
    dataSource={list}
    columns={columns}
    rowKey={item => item?.id}
	expandable={{
        expandedRowRender: (record) => (
          <>
          <p className='mb-0 font-bold text-primaryBase'>Customers :</p>
          <Table
          dataSource={record?.customer}
          columns={nestedColumn}
          pagination={false}
          bordered
          />
          </>
        ),
    }}
    pagination={false}
    loading={loading}
    scroll={{y:400}}
    bordered
	style={{minHeight:"360px"}}
    />
	{list?.length ? (
		<div className='flex justify-end'>
		<Pagination
			pageSize={pageSize}
			total={totalElements}
			current={currentPage}
			onChange={handlePageChange}
		/>
		</div>
	) : null}
	<div className='mb-20'></div>
    <CustomerGroupDetails data={data} setData={setData} type={type} setType={setType}/>
	<Modal
		title="Upload Excel File"
		open={show}
		onOk={handleExport}
		onCancel={()=>setShow(false)}
	>
		<Field
			name="item_excel"
			component={ReduxFormFileField}
			className="mt-1"
			placeholder="Item Image"
			itemname="excel file"
		/>
		<p className="mt-2 text-lg font-bold">{excelFile?.name}</p>
	</Modal>
    </>
  )
}

export default reduxForm({
	form: masterName,
	enableReinitialize: true,
})(CustomerGroup);
