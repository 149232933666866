import { Pagination, Table } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import TableOptions from "components/TableOptions";

import StatusSegments from "components/StatusSegments";
import { masterStatuses } from "components/category/Categories";
import useMasterLogic from "customHooks/useMasterLogic";
import useSearchFilter from "customHooks/useSearchFilter";
import { Helmet } from "react-helmet";
import { getMasterList, masterSelectors } from "slices/masterSlice";
import { renderActions } from "utils/renderActions";
import { renderMobileColumn } from "utils/renderMobileColumn";

export const masterName = "subcategory";

const Subcategories = () => {
	const { status } = useParams();

	const dispatch = useDispatch();
	const subCategoryList = useSelector(
		masterSelectors?.getSubCategoryMasterList
	);

	const { pageSize, totalElements, currentPage } = useSelector(
		masterSelectors?.getSubCategoryPagination
	);

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);

	const { getColumnSearchProps, tableFilters } = useSearchFilter();

	const getSubCategoryList = useCallback(
		(pageNum = 1, itemsPerPage = 10) => {
			dispatch(
				getMasterList({
					masterName,
					query: {
						page: pageNum,
						pageSize: itemsPerPage,
						is_active: status,
						...tableFilters,
					},
				})
			);
		},
		[status, tableFilters, dispatch]
	);

	const { handleEditMaster } = useMasterLogic(getSubCategoryList, masterName);
	const { handlePageChange } = useMasterLogic(getSubCategoryList, masterName);

	const columns = [
		{
			title: "Subcategory",
			render: data => {
				const primaryText = data?.name || "-";
				const secondaryText = `${data?.category_id?.[0]?.name || "-"}, ${
					data?.hsn_code || "-"
				}, ${data?.comment || "-"}`;
				return renderMobileColumn(primaryText, secondaryText);
			},
			responsive: ["xs"],
			...getColumnSearchProps("Subcategory", "subcategory_name"),
		},
		{
			title: "Subcategory Name",
			dataIndex: "name",
			key: "name",
			responsive: ["sm"],
			render: value => value || "-",
			...getColumnSearchProps("Subcategory", "subcategory_name"),
		},
		{
			title: "Category Name",
			dataIndex: "category_id",
			key: "category_id",
			responsive: ["sm"],
			render: value => value?.[0]?.name || "-",
			...getColumnSearchProps("Category", "category_name"),
		},
		{
			title: "HSN Code",
			dataIndex: "hsn_code",
			key: "hsn_code",
			responsive: ["sm"],
			render: value => value || "-",
			...getColumnSearchProps("HSN Code", "hsn_code"),
		},
		{
			title: "Comment",
			dataIndex: "comment",
			key: "comment",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Actions",
			key: "Actions",
			dataIndex: "action",
			width: "20%",
			render: (_, data, index) => {
				const items = [
					{
						label: "Image Upload",
						onClick: () => handleEditMaster(data?.id),
					},
				];

				return renderActions(items);
			},
		},
	];

	return (
		<>
			<Helmet>
				<title>Sub-Categories</title>
			</Helmet>
			<TableOptions masterName={masterName} showAdd={false} />
			<StatusSegments masterName={masterName} options={masterStatuses} />
			<Table
				dataSource={subCategoryList}
				columns={columns}
				rowKey={subCategoryList => subCategoryList?.id}
				pagination={false}
				loading={isFetchingMasterList}
				bordered
			/>
			<div className="flex justify-center mt-5">
				{subCategoryList?.length ? (
					<Pagination
						pageSize={pageSize}
						total={totalElements}
						current={currentPage}
						onChange={handlePageChange}
					/>
				) : null}
			</div>
		</>
	);
};

export default Subcategories;
