const TableOptions = ({ masterName, handleAddMaster, showAdd = true }) => {
	const addMaster = () => {
		handleAddMaster();
	};

	const displayName = masterName?.replace(/-/g, " ");

	return (
		<div className="flex flex-row justify-between p-3">
			{masterName && (
				<h3 className="text-2xl font-bold self-center capitalize">
					{displayName} List
				</h3>
			)}
			<div className="grow"></div>
			<>
				{showAdd && (
					<button
						className="bg-primaryBase hover:bg-primaryDark text-white ml-2 font-bold self-center p-2 rounded"
						type="button"
						onClick={addMaster}
					>
						Add <span className="capitalize">{displayName}</span>
					</button>
				)}
			</>
		</div>
	);
};

export default TableOptions;
