import React,{useEffect, useState} from 'react'
import { Button, Select, Tooltip } from 'antd'
import { Form, Field } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { addMasterList, getMasterDropDownList, getMasterList, masterSelectors, } from 'slices/masterSlice'
import { Helmet } from 'react-helmet';
import TextareaFieldV2 from 'utils/v2/TextareFieldV2';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { useHotkeys } from 'react-hotkeys-hook'
import SubDetailForm from './SubDetailForm';
import TopForm from './TopForm';

const masterName = "sale"
const partyNameDropdownMaster = "customer_dropdown"
const subCategoryDropdownMaster = "subcategory_dropdown"
const mainItemDropdownMaster = "item"
const colorDropdownMaster = "color_dropdown"

const AddSaleOrderForm = () => {

  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const partyDropdown = useSelector(masterSelectors?.getCustomerDropdown);
  const subCategoryDropdown = useSelector(masterSelectors?.getSubCategoryDropdown);
  const mainItemDropdown = useSelector(masterSelectors?.getMainItemDropdown);
  const colorDropdown = useSelector(masterSelectors?.getColorDropdown);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);

  useEffect(()=>{
    dispatch(getMasterDropDownList({masterName:subCategoryDropdownMaster}))
    dispatch(getMasterDropDownList({masterName:partyNameDropdownMaster}))
    dispatch(getMasterDropDownList({masterName:mainItemDropdownMaster}))
    dispatch(getMasterDropDownList({masterName:colorDropdownMaster}))

  },[])

    const onSubmit = async (values, form) => {
        if(values?.saleOrderSubForm === undefined || values?.saleOrderSubForm?.length === 0){
            return alert("Please add sub-form details")
        }


       console.log('form Data', values)
        let formData = values;

      let res = await dispatch(
          addMasterList({
            masterName,
            formData:formData
          })
        );
        if(res?.type === "master/add/fulfilled"){
          form.restart();
        }
      }

      const required = value => (value ? undefined : 'Required')

      useHotkeys('alt+l', () => window.location.reload())
    useHotkeys('alt+s', (event) => {
      event.preventDefault();
      document.getElementById('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    });


  return (
    <>
    <Helmet>
	<title>Sale Order | Add</title>
	</Helmet>
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators
      }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} id='form'>
    <>
    <h1 className='text-2xl mb-5'>Add Sale Order</h1>
    <TopForm form={form} loadingDropdown={loadingDropdown} partyDropdown={partyDropdown}/>

    <div className='w-full my-4'>
    <FieldArray name="saleOrderSubForm">
          {({ fields }) => (
            <div>
                <div className='flex justify-between'>
                    <p className='font-bold'>Sub Details : </p>
                <Button
                type="primary"
                htmlType='button'
                className='bg-primaryBase px-5'
                size='small'
                onClick={() => fields.push({})}
              >
                Add Details
              </Button>
                </div>
              {fields.map((name, index) => (
                <div key={name}>
                <div className='flex gap-5 mb-3 items-end'>

          <div>
          <label>Main Item</label><br></br>
          <Field name={`${name}.mainitemId`} validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"192px", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="Main item"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:mainItemDropdownMaster, query:{name:e}}))}
					options={mainItemDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div>
          <label>Sub-Category</label><br></br>
          <Field name={`${name}.subcategoryId`} validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"192px", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="sub-category"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:subCategoryDropdownMaster, query:{name:e}}))}
					options={subCategoryDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div>
          <label>Color</label><br></br>
          <Field name={`${name}.colorId`} validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"192px", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="color"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:colorDropdownMaster, query:{name:e}}))}
					options={colorDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>
        <div>
        <Field name={`${name}.remarks`}>
          {({ input, meta }) => (
          <TextareaFieldV2 input={input} meta={meta} label="Remarks" style='w-[300px]'/>
          )}
      </Field>
        </div>
                  
          <Button type="primary" htmlType='button' size='small' className='bg-redBase mt-5' onClick={() => fields.remove(index)}>
            Remove
          </Button>
                </div>
                <SubDetailForm form={form} name={name}/>
                </div>
      ))}
              
            </div>
          )}
        </FieldArray>
    </div>

<div className='flex gap-5 justify-end mt-10'>
<Button type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
onClick={form.reset}
>Clear</Button>
<Button type="primary" size="small" className='bg-redBase w-[80px]' htmlType='submit'
loading={loading}
>Add</Button>

</div>
    </>
    </form>
      )}/>
    </>
  )
}

export default AddSaleOrderForm