import { Button, Input } from "antd";
import { useRef, useState } from "react";

import { MdSearch } from "react-icons/md";

const useSearchFilter = () => {
	const searchInput = useRef(null);
	const [tableFilters, setTableFilters] = useState({});

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setTableFilters({ ...tableFilters, [dataIndex]: selectedKeys[0] });
	};

	const handleReset = (clearFilters, close) => {
		clearFilters();
		close();
		setTableFilters({});
	};

	const getColumnSearchProps = (label, dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
			close,
		}) => {
			return (
				<div className="p-2" onKeyDown={e => e.stopPropagation()}>
					<Input
						ref={searchInput}
						placeholder={`Search ${label}`}
						value={selectedKeys}
						onChange={e =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
						className="mb-2 block rounded"
					/>
					<div className="flex">
						<Button
							type="primary"
							onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
							size="small"
							className="w-11/12 bg-primaryBase text-white me-2"
						>
							Search
						</Button>
						<Button
							onClick={() => clearFilters && handleReset(clearFilters, close)}
							size="small"
							className="w-11/12"
						>
							Reset
						</Button>
					</div>
				</div>
			);
		},
		filteredValue: tableFilters[dataIndex] || "",
		filterIcon: filtered => (
			<MdSearch className={`text-xl ${filtered} ? "bg-primaryBase" : ""`} />
		),
		onFilterDropdownOpenChange: visible => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
	});

	return { getColumnSearchProps, tableFilters };
};

export default useSearchFilter;
