import { Checkbox } from "antd";
import { components as reactSelectComponent } from "react-select";

const OptionComponent = props => {
	return (
		<reactSelectComponent.Option {...props}>
			<div className="flex">
				{props.value === "*" &&
				!props?.selectProps?.isAllSelected.current &&
				props?.selectProps?.filteredSelectedOptions?.length > 0 ? (
					<Checkbox key={props.value} className="mr-3" />
				) : (
					<Checkbox
						key={props.value}
						className="mr-3"
						checked={
							props.isSelected || props?.selectProps?.isAllSelected.current
						}
					/>
				)}
				<span className="mx-2 whitespace-nowrap">{props.label}</span>
			</div>
		</reactSelectComponent.Option>
	);
};

export default OptionComponent;
