import { Table } from "antd";

const SaleOrderTable = ({
	data,
	loading,
	handleClick,
	mainitemsaleFilters,
	partyFilters,
	subCategoryFilters,
	readyStatusFilters,
}) => {
	const columns = [
		{
			title: "Sub Category",
			dataIndex: "subcategory",
			key: "subcategory",
			responsive: ["sm"],
			//className: "custom-font-size",
			render: (value, record) => (
				<p
					style={{ cursor: "pointer" }}
					onDoubleClick={() => handleClick(record)}
				>
					{value || "-"}
				</p>
			),
			filters: subCategoryFilters,
			onFilter: (value, record) => {
				return record.subcategory.startsWith(value);
			},

			filterSearch: true,
		},

		{
			title: "Main Item",
			dataIndex: "mainitem",
			key: "mainitem",
			responsive: ["sm"],
			width: "120px",
			//className: "custom-font-size",
			render: (value, record) => (
				<p
					style={{ cursor: "pointer" }}
					onDoubleClick={() => handleClick(record)}
				>
					{value || "-"}
				</p>
			),
			filters: mainitemsaleFilters,
			onFilter: (value, record) => {
				return record.mainitem.startsWith(value);
			},
			sorter: (a, b) => a.mainitem.localeCompare(b.mainitem),
			filterSearch: true,
		},
		{
			title: "Party ",
			dataIndex: "party",
			key: "party",
			responsive: ["sm"],
			//className: "custom-font-size ",
			render: (value, record) => (
				<p
					style={{ cursor: "pointer" }}
					onDoubleClick={() => handleClick(record)}
				>
					{value || "-"}
				</p>
			),
			filters: partyFilters,
			onFilter: (value, record) => {
				return record.party.startsWith(value);
			},
			sorter: (a, b) => a.party.localeCompare(b.party),
			filterSearch: true,
		},

		{
			title: "34",
			dataIndex: "size34",
			key: "size34",
			responsive: ["sm"],
			width: "60px",
			//className: "custom-font-size ",
			render: value => value || "0",
			sorter: (a, b) => a.size34 - b.size34,
		},
		{
			title: "36",
			dataIndex: "size36",
			key: "size36",
			responsive: ["sm"],
			width: "60px",
			//className: "custom-font-size ",
			render: value => value || "0",
			sorter: (a, b) => a.size36 - b.size36,
		},
		{
			title: "38",
			dataIndex: "size38",
			key: "size38",
			responsive: ["sm"],
			width: "60px",
			//className: "custom-font-size ",
			render: value => value || "0",
			sorter: (a, b) => a.size38 - b.size38,
		},
		{
			title: "40",
			dataIndex: "size40",
			key: "size40",
			responsive: ["sm"],
			width: "60px",
			//className: "custom-font-size ",
			render: value => value || "0",
			sorter: (a, b) => a.size40 - b.size40,
		},
		{
			title: "42",
			dataIndex: "size42",
			key: "size42",
			responsive: ["sm"],
			width: "60px",
			//className: "custom-font-size ",
			render: value => value || "0",
			sorter: (a, b) => a.size42 - b.size42,
		},
		{
			title: "44",
			dataIndex: "size44",
			key: "size44",
			responsive: ["sm"],
			width: "60px",
			//className: "custom-font-size ",
			render: value => value || "0",
			sorter: (a, b) => a.size44 - b.size44,
		},
		{
			title: "46",
			dataIndex: "size46",
			key: "size46",
			responsive: ["sm"],
			width: "60px",
			//className: "custom-font-size",
			render: value => value || "0",
			sorter: (a, b) => a.size46 - b.size46,
		},
		{
			title: "48",
			dataIndex: "size48",
			key: "size48",
			responsive: ["sm"],
			width: "60px",
			//	className: "custom-font-size ",
			render: value => value || "0",
			sorter: (a, b) => a.size48 - b.size48,
		},

		{
			title: "QTY",
			dataIndex: "quantity",
			key: "quantity",
			responsive: ["sm"],
			width: "60px",
			//className: "custom-font-size",
			render: value => value || "-",
			sorter: (a, b) => {
				const totalA =
					a.size34 +
					a.size36 +
					a.size38 +
					a.size40 +
					a.size42 +
					a.size44 +
					a.size46 +
					a.size48;
				const totalB =
					b.size34 +
					b.size36 +
					b.size38 +
					b.size40 +
					b.size42 +
					b.size44 +
					b.size46 +
					b.size48;
				return totalA - totalB;
			},
		},
		{
			title: "Instruction",
			dataIndex: "instruction",
			key: "instruction",
			responsive: ["sm"],
			//className: "custom-font-size",
			render: value => value || "-",
			// filters: instructionFilters,
			// onFilter: (value, record) => {
			// 	return record.instruction.startsWith(value);
			// },

			// filterSearch: true,
		},
		{
			title: "Order Date ",
			dataIndex: "orderDate",
			key: "orderDate",
			responsive: ["sm"],
			width: "140px",
			//className: "custom-font-size",
			render: (value, record) => (
				<p onDoubleClick={() => handleClick(record)}>{value || "-"}</p>
			),
		},
		{
			title: "Ready Status",
			dataIndex: "ReadyStatus",
			key: "ReadyStatus",
			responsive: ["sm"],
			//className: "custom-font-size",
			render: value => value || "-",
			filters: readyStatusFilters,
			onFilter: (value, record) => {
				return record.ReadyStatus.startsWith(value);
			},

			filterSearch: true,
		},
	];
	return (
		<>
			<h6 className="text-xl my-3">Sales Order Details Table</h6>
			<Table
				dataSource={data}
				columns={columns}
				rowKey={data => data?.id}
				pagination={false}
				loading={loading}
				bordered
				size="medium"
				scroll={{
					y: 220,
				}}
			/>
		</>
	);
};

export default SaleOrderTable;
