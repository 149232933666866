import React, {useEffect} from 'react'
import { Field } from "react-final-form";
import InputFieldV2 from 'utils/v2/InputFieldV2'

const SubDetailForm = ({form, name}) => {
    const required = value => (value ? undefined : 'Required')

    useEffect(() => {
		const size85 = Number(form.getFieldState(`${name}.size85`)?.value) || 0;
		const size90 = Number(form.getFieldState(`${name}.size90`)?.value) || 0;
		const size95 = Number(form.getFieldState(`${name}.size95`)?.value) || 0;
		const size100 = Number(form.getFieldState(`${name}.size100`)?.value) || 0;
		const size105 = Number(form.getFieldState(`${name}.size105`)?.value) || 0;
		const size110 = Number(form.getFieldState(`${name}.size110`)?.value) || 0;
		const size115 = Number(form.getFieldState(`${name}.size115`)?.value) || 0;
		const size120 = Number(form.getFieldState(`${name}.size120`)?.value) || 0;
        const total = size85 + size90 + size95 + size100 + size105 + size110 + size115 + size120;
		form.change(`${name}.totalPcs`, total);
	}, [form]);

  return (
    <div className='flex gap-5 mb-3 items-end'>
        <Field name={`${name}.size85`} validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="size 85" style='w-[50px]'/>
            )}
        </Field>
        <Field name={`${name}.size90`} validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="size 90" style='w-[50px]'/>
            )}
        </Field>
        <Field name={`${name}.size95`} validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="size 95" style='w-[50px]'/>
            )}
        </Field>
        <Field name={`${name}.size100`} validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="size 100" style='w-[50px]'/>
            )}
        </Field>
        <Field name={`${name}.size105`} validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="size 105" style='w-[50px]'/>
            )}
        </Field>
        <Field name={`${name}.size110`} validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="size 110" style='w-[50px]'/>
            )}
        </Field>
        <Field name={`${name}.size115`} validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="size 115" style='w-[50px]'/>
            )}
        </Field>
        <Field name={`${name}.size120`} validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="size 120" style='w-[50px]'/>
            )}
        </Field>
        <Field name={`${name}.totalPcs`} validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="total" style='w-[120px]' disabled={true}/>
            )}
        </Field>

        </div>
  )
}

export default SubDetailForm