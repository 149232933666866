import React from 'react'
import { Button, Select, Tooltip } from 'antd'
import { Form, Field } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { addMasterList, getMasterList, masterSelectors,getMasterDropDownList } from 'slices/masterSlice'
import { Helmet } from 'react-helmet';
import TextareaFieldV2 from 'utils/v2/TextareFieldV2';
import { useState } from 'react';
import { useEffect } from 'react';

const masterName = "mainitem"
const hsnDropdownMaster = "hsn_dropdown"
const categoryDropdownMaster = "categorydropdown"
const subCategoryDropdownMaster = "subcategory_dropdown"
const AddMainItem = () => {

  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);
  const hsnDropdown = useSelector(masterSelectors?.getHsnDropdown);
  const categoryDropdown = useSelector(masterSelectors?.getCategoryDropdown);
  const subCategoryDropdown = useSelector(masterSelectors?.getSubCategoryDropdown);
  const [item_images, setImage] = useState(null)


  useEffect(()=>{
    dispatch(getMasterDropDownList({masterName:categoryDropdownMaster}))
    dispatch(getMasterDropDownList({masterName:subCategoryDropdownMaster}))
    dispatch(getMasterDropDownList({masterName:hsnDropdownMaster}))
  },[])

    const onSubmit = async (values, form) => {
      values.comment = values?.comment || null;
      const formData = new FormData();
      if(item_images !== null || item_images !== undefined){
        formData.append('item_images', item_images)
      }
      formData.append("mainitem", new Blob([JSON.stringify({...values})], 
      {type:"application/json"}))
      
      let res = await dispatch(
          addMasterList({
            masterName,
            formData,
            configType: "multipart/form-data",
          })
        );
        if(res?.type === "master/add/fulfilled"){
          form.restart();
          dispatch(
            getMasterList({
              masterName
            })
          )
        }
      }

      const required = value => (value ? undefined : 'Required')

  return (
    <>
    <Helmet>
	<title>Main Item | Add</title>
	</Helmet>
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
    <>
    <h1 className='text-2xl mb-5'>Add Main Item</h1>
    <div className='flex gap-5'>
        <div className=''>
            <Field name="name" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Name"/>
            )}
          </Field>
        </div>
        <div>
          <label>Category</label>
          <Field name="category_name" validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"100%", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="Category"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:categoryDropdownMaster, query:{name:e}}))}
					options={categoryDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div>
          <label>Sub-category</label>
          <Field name="sub_category_name" validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"100%", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="Sub-category"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:subCategoryDropdownMaster, query:{name:e}}))}
					options={subCategoryDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div className=''>
          <label>HSN Code</label>
          <Field name="hsnId" validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"100%", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
          className={`${meta.error && meta.touched && "border border-redBase"}`}
					showSearch
          loading={loadingDropdown}
					placeholder="HSN code"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:hsnDropdownMaster, query:{name:e}}))}
					options={hsnDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div className=''>
            <Field name="itm_no" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Item No."/>
            )}
          </Field>
        </div>

        <div className=''>
            <Field name="wsp" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="WSP"/>
            )}
          </Field>
        </div>

        <div className=''>
            <Field name="mrp" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="MRP"/>
            )}
          </Field>
        </div>

        <div>
          <label>Image</label><br></br>
          <input type='file' className='' accept='image/*' onChange={(e)=>setImage(e.target.files[0])}/>
        </div>
        
    </div>
    <div className='flex gap-5 my-3'>
    <div className='w-full'>
        <Field name="comment">
            {({ input, meta }) => (
              <TextareaFieldV2 input={input} meta={meta} label="Comment" style='w-1/2'/>
            )}
          </Field>
        </div>
    </div>

<div className='flex gap-5 justify-end mt-10'>
<Button type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
onClick={form.reset}
>Clear</Button>
<Button type="primary" size="small" className='bg-redBase w-[80px]' htmlType='submit'
loading={loading}
disabled={loading}
>Add</Button>

</div>
    </>
    </form>
      )}/>
    </>
  )
}

export default AddMainItem