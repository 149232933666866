import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { generatePdf, masterSelectors } from "slices/masterSlice";
import PdfGeneratorForm from "./PdfGeneratorForm";

const PdfGeneratorWrapper = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const collection_list = useSelector(masterSelectors?.getCollectionList);

	const handleSubmit = (formData, index) => {
		// let minSize = 0;
		// if (formData?.sizeMin === undefined) {
		// 	minSize = 0;
		// } else {
		// 	minSize = formData?.sizeMin.value;
		// }
		const formValues = {
			category: formData?.category?.map(cat => cat?.value) || null,
			subCategory: formData?.subCategory?.map(subc => subc?.value) || null,
			mainItem: formData?.mainItem?.map(item => item?.value) || [],
			colors: formData?.colors?.map(color => color?.value) || [],
			season: formData?.season?.map(s => s?.value) || [],
			collection: formData?.collection?.map(col => col?.value) || [],
			specification: formData?.specification?.map(spe => spe?.value) || [],
			status: formData?.status?.map(st => st?.value) || [],
			lots: formData?.lots?.map(lt => lt?.value) || [],
			generatedColors:
				formData?.generatedColors?.map(color => color?.value) || [],
			preview: formData?.preview?.map(pr => pr?.value) || [],
			main_item_statuses:
				formData?.main_item_statuses?.map(mis => mis?.value) || [],
			chalanStatus: formData?.chalanStatus?.value || null,
			color_per_page: formData?.color_per_page?.value || null,
			setMin: formData?.setMin || 0,
			setMax: formData?.setMax || 0,
			qtyMin: formData?.qtyMin || 0,
			qtyMax: formData?.qtyMax || 0,
			mrpMin: formData?.mrpMin || 0,
			mrpMax: formData?.mrpMax || 0,
			sizeMin: formData?.sizeMin?.value || 0,
			noOfDays:
				formData?.date_type?.value === "days" ? formData?.noOfDays : null,
			date: formData?.date_type?.value === "date" ? formData?.date : null,
			orderList: collection_list?.map(c => {
				return {
					collectionName: c,
					order: formData?.order || 0,
				};
			}),
		};

		dispatch(
			generatePdf({
				formData: formValues,
			})
		);
	};

	const handleClose = () => {
		navigate(-1);
	};

	return (
		<PdfGeneratorForm
			title="PDF Generator"
			onSubmit={handleSubmit}
			onCancel={handleClose}
		/>
	);
};

export default PdfGeneratorWrapper;
