import React, { useState, useCallback, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import SaleOrderFilter from "./SaleOrderFilter";

const SaleOrderSheet = () => {
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "",
      field: "row",
    },
    { headerName: "", field: "row2", width: 150 },
  ]);

  const [rowData, setRowData] = useState([]);

  const [showTable, setShowTable] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const defaultCofDef = useMemo(() => {
    return {
      editable: true,
    };
  });

  const ref = useRef();

  const handleAddSubCategory = useCallback((newRows) => {
    setRowData((prevRows) => [...prevRows, ...newRows]);
  }, []);

  const handleAddCustomer = useCallback((newCustomers) => {
    const newCustomerColumns = newCustomers.map((customer) => ({
      headerName: customer.label,
      children: [
        { headerName: "34", field: `${customer.label}34`, width: 70 },

        { headerName: "36", field: `${customer.label}36`, width: 70 },
        { headerName: "38", field: `${customer.label}38`, width: 70 },
        { headerName: "40", field: `${customer.label}40`, width: 70 },
        { headerName: "42", field: `${customer.label}42`, width: 70 },
        { headerName: "44", field: `${customer.label}44`, width: 70 },
        { headerName: "46", field: `${customer.label}46`, width: 70 },
        { headerName: "48", field: `${customer.label}48`, width: 70 },
        { headerName: "Total", field: `${customer.label}Total`, width: 100 },
      ],
    }));

    setColumnDefs((prevCols) => [...prevCols, ...newCustomerColumns]);

    setRowData((prevRows) =>
      prevRows.map((row) => {
        const newFields = {};
        newCustomers.forEach((customer) => {
          newFields[`${customer.label}34`] = 0;
          newFields[`${customer.label}36`] = 0;
          newFields[`${customer.label}38`] = 0;
          newFields[`${customer.label}40`] = 0;
          newFields[`${customer.label}42`] = 0;
          newFields[`${customer.label}44`] = 0;
          newFields[`${customer.label}46`] = 0;
          newFields[`${customer.label}48`] = 0;
          newFields[`${customer.label}Total`] = 0;
        });
        return { ...row, ...newFields };
      })
    );
  }, []);

  function cellValueChanged(e) {
    console.log("value changed: ", e);
    const columnToEdit = e.colDef.field;
    const editingColRowId = e.rowIndex;
    const newValue = e.newValue;
    console.log("editing row : ", editingColRowId);

    if (editingColRowId === 0) {
      const ratio = parseFloat(newValue) || 0;

      const distributedValue = Math.ceil(totalItems * ratio);
      const remainder = distributedValue % (totalItems || 1);
      const baseValue = Math.floor(distributedValue / (totalItems || 1));

      // Distribute the calculated values
      let remainderToDistribute = remainder;

      ref.current.api.forEachNodeAfterFilterAndSort((rowNode, index) => {
        if (index > 0 && rowNode.data.row !== "Ratio") {
          let valueToSet = baseValue;
          if (remainderToDistribute > 0) {
            valueToSet += 1;
            remainderToDistribute--;
          }

          const updatedRowNode = {
            ...rowNode.data,
            [columnToEdit]: valueToSet,
          };
          rowNode.setData(updatedRowNode);
        } else if (index > 0 && rowNode.data.row === "Ratio") {
          console.log("Encountered another Ratio row, stopping distribution.");
          return false;
        }
      });
    }
  }

  return (
    <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
      <SaleOrderFilter
        setColumnDefs={setColumnDefs}
        columnDefs={columnDefs}
        onAddSubCategory={handleAddSubCategory}
        onAddCustomer={handleAddCustomer}
        setRowData={setRowData}
        rowData={rowData}
        setShowTable={setShowTable}
        setTotalItems={setTotalItems}
      />

      {showTable && (
        <AgGridReact
          ref={ref}
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultCofDef}
          onCellValueChanged={cellValueChanged}
        />
      )}
    </div>
  );
};

export default SaleOrderSheet;
