import { Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import { userLogin, userSelectors } from "slices/userSlice";
import LoginForm from "./LoginForm";

const LoginPage = () => {
	const dispatch = useDispatch();

	const user = useSelector(userSelectors?.getUserDetails);

	const handleSubmit = formData => {
		dispatch(userLogin(formData));
	};

	if (user?.isLoggedIn && user?.token) {
		return <Navigate to="/dashboard" />;
	}

	return (
		<div className="bg-slate-100 h-screen">
			<div
				className="flex justify-center items-center"
				style={{
					height: "60vh",
				}}
			>
				<Card className="w-72">
					<div className="p-3">
						<img src="/images/ethos.svg" alt="Ethos" className="h-14 w-full" />
						<Helmet>
							<title>Login</title>
						</Helmet>
						<LoginForm
							onSubmit={handleSubmit}
							disabled={user?.isUserLoggingIn}
						/>
					</div>
				</Card>
			</div>
		</div>
	);
};

export default LoginPage;
