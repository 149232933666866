import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

function Inward({ inward, inwardTotal }) {
  const rowData = inward;

  const totals = inwardTotal;

  const colDefs = [
    { field: "mainItem", width: 100 },
    { field: "color", width: 100 },
    { field: "size34", headerName: "34", width: 80 },
    { field: "size36", headerName: "36", width: 80 },
    { field: "size38", headerName: "38", width: 80 },
    { field: "size40", headerName: "40", width: 80 },
    { field: "size42", headerName: "42", width: 80 },
    { field: "size44", headerName: "44", width: 80 },
    { field: "size46", headerName: "46", width: 80 },
    { field: "size48", headerName: "48", width: 80 },
    { field: "total", width: 100 },
    { field: "Narration", width: 100 },
    { field: "EntryNo", width: 150 },
    { field: "ReadyStockDate", headerName: "Date", width: 100 },
    { field: "comment", width: 100 },
  ];

  const pinnedBottomRow = [
    {
      size34: totals?.totalSize34 || 0,
      size36: totals?.totalSize36 || 0,
      size38: totals?.totalSize38 || 0,
      size40: totals?.totalSize40 || 0,
      size42: totals?.totalSize42 || 0,
      size44: totals?.totalSize44 || 0,
      size46: totals?.totalSize46 || 0,
      size48: totals?.totalSize48 || 0,
      total: totals?.total || 0,
      mainItem: "Total",
      color: "-",
    },
  ];
  return (
    <div className="ag-theme-quartz" style={{ height: 500 }}>
      <h1 className="text-xl mb-2 font-semibold">Inward Details Table</h1>
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        pinnedBottomRowData={pinnedBottomRow}
      />
    </div>
  );
}

export default Inward;
