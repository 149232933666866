import { Table } from "antd";
import { useState } from "react";

const StockTable = ({
	data,
	loading,
	renderMobileColumn,
	mainItemFilters,
	colorFilters,
	katkutFilters,
	filterValue,
	setFilterValue,
	tempColorFilter,
	setTempColorFilter,
	tempKatkutFilter,
	setTempKatkutFilter,
}) => {
	const handleFilterChange = (selectedKeys, confirm) => {
		confirm();
		setFilterValue(selectedKeys[0]);
	};
	const [colorFilterValue, setColorFilterValue] = useState([]);

	const [katkutFilterValue, setKatkutFilterValue] = useState([]);

	const columns = [
		{
			title: "Ready Stock",

			render: data => {
				const primaryText = `${data?.mainitem || "-"}-${data?.color || "-"}`;
				const secondaryText = `${data?.size85 || "0"}-${data?.size90 || "0"}-${
					data?.size95 || "0"
				}-${data?.size100 || "0"}-${data?.size105 || "0"}-${
					data?.size110 || "0"
				}-${data?.size115 || "0"}- ${data?.size120 || "0"}`;
				return renderMobileColumn(primaryText, secondaryText);
			},
			responsive: ["xs"],
		},
		{
			title: "Main Item",
			dataIndex: "mainitem",
			key: "mainitem",
			responsive: ["sm"],
			width: "80px",
			render: value => value || "-",
			filters: mainItemFilters,
			// filterDropdown: ({
			// 	setSelectedKeys,
			// 	selectedKeys,
			// 	confirm,
			// 	clearFilters,
			// }) => (
			// 	<div style={{ padding: 8 }}>
			// 		<Space direction="vertical">
			// 			<Checkbox.Group
			// 				options={mainItemFilters.map(item => ({
			// 					label: item.text,
			// 					value: item.value,
			// 				}))}
			// 				checked={selectedKeys}
			// 				onChange={values =>
			// 					setSelectedKeys(values.length > 0 ? values : [])
			// 				}
			// 			/>

			// 			<Space>
			// 				<button
			// 					type="button"
			// 					onClick={() => {
			// 						confirm();
			// 						setFilterValue(
			// 							selectedKeys.length > 0 ? selectedKeys[0] : ""
			// 						);
			// 					}}
			// 				>
			// 					OK
			// 				</button>
			// 				<button
			// 					type="button"
			// 					onClick={() => {
			// 						clearFilters();
			// 						setFilterValue(null);
			// 					}}
			// 				>
			// 					Reset
			// 				</button>
			// 			</Space>
			// 		</Space>
			// 	</div>
			// ),
			filterSearch: true,
			filteredValue: filterValue,
			//filteredValue: filterValue ? [filterValue] : null,
			onFilter: (value, record) => {
				return record?.mainitem?.startsWith(value);
			},
			onFilterDropdownOpenChange: visible => {
				if (!visible) {
					setFilterValue([]);
				}
			},
		},
		{
			title: "Color ",
			dataIndex: "color",
			key: "color",
			responsive: ["sm"],
			width: "80px",
			render: value => value || "-",
			filters: tempColorFilter?.length ? tempColorFilter : colorFilters,
			filteredValue: colorFilterValue,
			onFilter: (value, record) => {
				return record.color.startsWith(value);
			},
			filterSearch: true,
		},
		{
			title: `85`,
			dataIndex: "size85",
			key: "size85",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size85 - b.size85,
		},
		{
			title: `90`,
			dataIndex: "size90",
			key: "size90",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size90 - b.size90,
		},
		{
			title: `95 `,
			dataIndex: "size95",
			key: "size95",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size95 - b.size95,
		},
		{
			title: `100 `,
			dataIndex: "size100",
			key: "size100",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size100 - b.size100,
		},
		{
			title: `105 `,
			dataIndex: "size105",
			key: "size105",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size105 - b.size105,
		},
		{
			title: `110 `,
			dataIndex: "size110",
			key: "size110",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size110 - b.size110,
		},
		{
			title: `115 `,
			dataIndex: "size115",
			key: "size115",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size115 - b.size115,
		},
		{
			title: `120`,
			dataIndex: "size120",
			key: "size120",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size120 - b.size120,
		},
		{
			title: `QTY`,
			dataIndex: "quantity",
			key: "quantity",
			responsive: ["sm"],
			render: (text, data, index) => {
				const total =
					data?.size85 +
					data?.size90 +
					data?.size95 +
					data?.size100 +
					data?.size105 +
					data?.size110 +
					data?.size115 +
					data?.size120;
				return <p>{total}</p>;
			},
			sorter: (a, b) => {
				const totalA =
					a.size85 +
					a.size90 +
					a.size95 +
					a.size100 +
					a.size105 +
					a.size110 +
					a.size115 +
					a.size120;
				const totalB =
					b.size85 +
					b.size90 +
					b.size95 +
					b.size100 +
					b.size105 +
					b.size110 +
					b.size115 +
					b.size120;
				return totalA - totalB;
			},
		},
		{
			title: "Katkut ",
			dataIndex: "Katkut",
			key: "Katkut",
			responsive: ["sm"],
			width: "80px",
			render: value => value || "-",
			filters: tempKatkutFilter?.length ? tempKatkutFilter : katkutFilters,
			filteredValue: katkutFilterValue,
			onFilter: (value, record) => {
				return record.Katkut.startsWith(value);
			},
			filterSearch: true,
		},
	];

	const onChange = (x, y, z, e) => {
		if (e?.currentDataSource) {
			setTempColorFilter(
				e?.currentDataSource?.map(item => {
					return { text: item?.color, value: item?.color };
				})
			);
		}

		if (e?.currentDataSource) {
			setTempKatkutFilter(
				e?.currentDataSource?.map(item => {
					return { text: item?.Katkut, value: item?.Katkut };
				})
			);
		}
		if (y.mainitem !== null && y.mainitem.length) {
			setFilterValue(y.mainitem);
		} else {
			setFilterValue([]);
		}
		if (y.color !== null && y.color.length) {
			setColorFilterValue(y.color);
		} else {
			setColorFilterValue([]);
		}
		if (y.Katkut !== null && y.Katkut.length) {
			setKatkutFilterValue(y.Katkut);
		} else {
			setKatkutFilterValue([]);
		}
	};

	return (
		<>
			<div className="mr-2">
				<h6 className="text-xl mb-3">Stock Details Table</h6>
				<Table
					style={{ width: "700px" }}
					dataSource={data}
					columns={columns}
					rowKey={data => data?.id}
					pagination={false}
					loading={loading}
					bordered
					size="small"
					scroll={{
						y: 350,
					}}
					rowClassName={(record, index) => {
						return record.backgroundColor ? "selected-row" : "";
					}}
					onChange={onChange}
					// onFilterDropdownVisibleChange={visible => {
					// 	if (!visible) {
					// 		setFilterValue(null);
					// 	}
					// }}
				/>
			</div>
		</>
	);
};

export default StockTable;
