import React, {useCallback} from 'react'
import { Table, Pagination, Checkbox } from 'antd'
import DispatchPagination from './DispatchPagination'
import useMasterLogic from 'customHooks/useMasterLogic';
import { getOutwardEntryDetails } from 'slices/masterSlice';
import { useDispatch } from 'react-redux';

const DispatchStock = ({dispatchColumns, dispatchStockList, isFetchingMasterList, selectAllDispatch, handleSelectAllDispatchChange, expandedDispatchRowRender}) => {
  
    const dispatch = useDispatch()

    const getDispatchStockList = useCallback((pageNum = 1, itemsPerPage = 50) => {
		const val = {
			startDate: "",
			endDate: "",
			mainItem: [],
			color: [],
			dispatchno: [],
			party: [],
		};
		dispatch(
			getOutwardEntryDetails({
				masterName:"DispatchStock",
				formdata: val,
				query: {
					page: pageNum,
					pageSize: itemsPerPage
				},
			})
		);
	}, [dispatch]);
  
    const { handlePageChange } = useMasterLogic(getDispatchStockList, "DispatchStock", 50);

    return (
    <>
				<Table
					columns={dispatchColumns}
					expandable={{
						expandedRowRender: expandedDispatchRowRender,
						rowExpandable: record => !!record.id,
					}}
					rowKey={dispatchStockList => dispatchStockList?.id}
					dataSource={dispatchStockList}
					bordered
					loading={isFetchingMasterList}
					pagination={false}
					title={() => (
						<Checkbox
							checked={selectAllDispatch}
							onChange={handleSelectAllDispatchChange}
						>
							Select All
						</Checkbox>
					)}
					scroll={{ y: 600 }}
					size="small"
				/>
				{dispatchStockList?.length ?
				<DispatchPagination handlePageChange2={handlePageChange}/>
				: null}
				</>
  )
}

export default DispatchStock