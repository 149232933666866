import axios from "axios";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiConfig from "slices/apiConfig";

export const userSelectors = {
	getUserDetails: state => state.userDetails,
};

const initialState = {
	isUserLoggingIn: false,
	isLoggedIn: false,
	role: null,
	token: null,
	accessRights: {},
	isUserLoggingOut: false,
};

export const userLogin = createAsyncThunk(
	"user/login",
	async (body, { getState, rejectWithValue }) => {
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.post(
				`${apiConfig?.baseURL}/login`,
				body,
				config
			);

			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const userLogout = createAsyncThunk(
	"user/logout",
	async (_, { getState, rejectWithValue, dispatch }) => {
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(`${apiConfig?.baseURL}/signout`, config);

			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		clearUserDetails: state => {
			state.role = null;
			state.token = null;
			state.isLoggedIn = false;
			state.isUserLoggingOut = false;
			state.userId = null;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(userLogin.pending, state => {
				state.isUserLoggingIn = true;
			})
			.addCase(userLogin.fulfilled, (state, { payload }) => {
				state.isUserLoggingIn = false;
				state.token = payload?.token;
				state["accessRights"] = payload?.accessRights;
				state.isLoggedIn = true;
			})
			.addCase(userLogin.rejected, state => {
				state.isUserLoggingIn = false;
			})

			.addCase(userLogout.pending, state => {
				state.isUserLoggingOut = true;
			})
			.addCase(userLogout.fulfilled, (state, { payload }) => {
				state.role = null;
				state.token = null;
				state.isLoggedIn = false;
				state.isUserLoggingOut = false;
				state.userId = null;
			})
			.addCase(userLogout.rejected, state => {
				state.isUserLoggingOut = false;
			});
	},
});

export default userSlice.reducer;
export const { clearUserDetails } = userSlice.actions;
