import { useDispatch, useSelector } from "react-redux";

import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	editMasterList,
	getMasterDetails,
	masterSelectors,
	resetMasterDetails,
} from "slices/masterSlice";
import { masterName } from "./MainItems";
import MainItemsForm from "./MainItemsForm";

const MainItemsWrapper = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { mode, id } = useParams();

	const [loading, setLoading] = useState(id ? true : false);

	const isViewOnly = mode === "view";
	const mainItemDetails = useSelector(masterSelectors?.getMainItemDetails);
	const isFetchingMasterDetails = useSelector(
		masterSelectors?.getIsFetchingMasterDetails
	);

	useEffect(() => {
		if (id) {
			dispatch(getMasterDetails({ masterName, id }));
			setLoading(false);
		}
		return () => dispatch(resetMasterDetails(masterName));
	}, [dispatch, id]);

	const handleSubmit = formData => {
		const form = new FormData();
		form.append("id", formData?.id);

		form.append(
			"mainitem",
			new Blob(
				[
					JSON.stringify({
						seasonId: formData?.season?.value || null,
						collectionId: formData?.collection?.value || null,
						mrp: formData?.mrp || null,
						type_of_item: formData?.type_of_item || null,
						raw_material_colors: formData?.raw_material_colors?.map(color => ({
							color_id: color?.color_id,
							color_name: color?.color_name,
							file_name: color?.item_images?.name || color?.file_name || null,
						})),
					}),
				],
				{
					type: "application/json",
				}
			)
		);
		formData?.raw_material_colors
			?.filter(color => color.item_images)
			.map(({ item_images }) => {
				return form.append("item_images", item_images);
			});
		form.append("main_images", formData?.main_images);
		dispatch(
			editMasterList({
				masterName,
				formData: form,
				navigate,
				configType: "multipart/form-data",
			})
		);
	};
	const handleClose = () => {
		navigate(`/${masterName}`);
	};

	const initialValues = {
		id: mainItemDetails?.[0]?.id || null,
		itm_no: mainItemDetails?.[0]?.itm_no || null,
		name: mainItemDetails?.[0]?.name || null,
		category: mainItemDetails?.[0]?.category?.[0]?.name || null,
		subcategory: mainItemDetails?.[0]?.subcategory?.[0]?.name || null,
		hsn_code: mainItemDetails?.[0]?.hsn_code || null,
		comment: mainItemDetails?.[0]?.comment || null,
		season: mainItemDetails?.[0]?.season || null,
		collection: mainItemDetails?.[0]?.collection || null,
		mrp: mainItemDetails?.[0]?.mrp || null,
		raw_material_colors: mainItemDetails?.[0]?.raw_material_colors || [],
		type_of_item: mainItemDetails?.[0]?.type_of_item || null,
	};

	if (loading || isFetchingMasterDetails)
		return (
			<Spin
				className="h-100 d-flex justify-content-center align-items-center"
				size="large"
			/>
		);

	const title = isViewOnly ? `View Main-Item` : `Edit Main-Item`;

	return (
		<MainItemsForm
			title={title}
			initialValues={initialValues}
			onSubmit={handleSubmit}
			onCancel={handleClose}
			isViewOnly={isViewOnly}
		/>
	);
};

export default MainItemsWrapper;
