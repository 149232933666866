import { Button } from "antd";
import { useState } from "react";
import { Field } from "redux-form";
import {
	ReduxFormSelectField,
	ReduxFormSelectField2,
} from "utils/ReduxFormSelectField";

const MainFilter = ({
	subCategoryOptions,
	partyNameOptions,
	mainItemsOptions,
	isFetchingDropdownList,
	handleSubmit,
	subCategory,
	partyName,
}) => {
	const [show, setShow] = useState(false);

	return (
		<>
			{show ? (
				<>
					<div className="flex justify-between items-center">
						<h6 className="text-2xl font-bold"> Outward Form</h6>
						<Button
							className="text-redBase border-redBase"
							onClick={() => setShow(false)}
						>
							Hide Filter
						</Button>
					</div>
					<div className="md:flex block md:flex-wrap">
						<div className="md:mr-10 mb-5">
							<Field
								component={ReduxFormSelectField}
								label="Sub-Category"
								name="subCategory"
								options={subCategoryOptions}
								placeholder="Select Subcategories"
								isLoading={isFetchingDropdownList}
								isMulti
								disabled={partyName?.length}
							/>
						</div>
						<div className="md:mr-10 mb-5">
							<Field
								component={ReduxFormSelectField2}
								label="Main Item"
								name="mainItems"
								options={mainItemsOptions}
								placeholder="Select Main Items"
								isLoading={isFetchingDropdownList}
								isMulti
								disabled={partyName?.length}
							/>
						</div>
						<div className="md:mr-10 mb-5">
							<Field
								component={ReduxFormSelectField}
								label="Party"
								name="party"
								placeholder="Enter party"
								options={partyNameOptions}
								isLoading={isFetchingDropdownList}
								isMulti
								disabled={subCategory?.length}
							/>
						</div>
						<div className="md:mr-10 mb-5">
							<Button
								className="mt-7 text-primaryBase border-primaryDark font-semibold"
								onClick={handleSubmit}
							>
								Get Data
							</Button>
						</div>
					</div>
				</>
			) : (
				<div className="flex justify-between items-center">
					<h6 className="text-2xl font-bold"> Outward Form</h6>
					<Button
						className="text-primaryBase border-primaryDark"
						onClick={() => setShow(true)}
					>
						Show Filter
					</Button>
				</div>
			)}
		</>
	);
};

export default MainFilter;
