import { useDispatch, useSelector } from "react-redux";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import { masterSelectors } from "slices/masterSlice";

import { Image } from "antd";
import { Helmet } from "react-helmet";
import { MdDelete } from "react-icons/md";
import { ReduxFormFileField } from "utils/ReduxFormFileField";
import { ReduxFormTextField } from "utils/ReduxFormTextField";
import { renderModalButtons } from "utils/renderModalButtons";

const formName = "subcategory";
const formSelector = formValueSelector(formName);

const SubcategoriesForms = ({ title, onCancel, handleSubmit, isViewOnly }) => {
	const dispatch = useDispatch();
	const isUpdatingMaster = useSelector(
		masterSelectors?.getIsUpdatingMasterList
	);

	const subcategoryColours = useSelector(state =>
		formSelector(state, "item_images")
	);

	const removeSubcategoryImage = () => {
		dispatch(change(formName, "item_images", null));
	};

	const imageUrl =
		subcategoryColours instanceof File
			? URL.createObjectURL(subcategoryColours)
			: subcategoryColours;

	return (
		<>
			<Helmet>
				<title>Subcategory Form</title>
			</Helmet>
			<form onSubmit={handleSubmit}>
				<h6 className="text-2xl font-bold md:mb-5 mb-10">{title}</h6>
				<div className="md:flex block md:flex-wrap">
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormTextField}
							label="Subcategory Name"
							name="name"
							disabled
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormTextField}
							label="Category"
							name="category"
							disabled
						/>
					</div>

					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormTextField}
							label="HSN Code"
							name="hsn_code"
							disabled
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormTextField}
							label="Comment"
							name="comment"
							disabled
						/>
					</div>
				</div>
				<div className="flex flex-wrap flex-col mb-5 w-80 max-w-[95%] rounded shadow-xl mr-10">
					{imageUrl ? (
						<>
							<Image
								src={imageUrl}
								alt="colour img"
								className="!object-contain !h-56"
								preview={{ toolbarRender: () => null }}
							/>
						</>
					) : (
						<Field
							name="item_images"
							component={ReduxFormFileField}
							className="mt-1"
							placeholder="Item Image"
							disabled={isViewOnly}
							itemname="image"
						/>
					)}
					<div className="px-6 py-4 flex justify-between border-t border-[#d1d5db]">
						<div className="font-bold text-xl self-center">Cover Image</div>
						{imageUrl && (
							<button
								className="bg-redBase text-white font-semibold rounded py-2 px-4"
								type="button"
								onClick={() => removeSubcategoryImage()}
							>
								<MdDelete className="text-2xl" />
							</button>
						)}
					</div>
				</div>
				{renderModalButtons(onCancel, isViewOnly, isUpdatingMaster)}
			</form>
		</>
	);
};

export default reduxForm({
	form: formName,
})(SubcategoriesForms);
