import { Button, Table, message } from "antd";
import TableOptions from "components/TableOptions";
import useMasterLogic from "customHooks/useMasterLogic";
import useSearchFilter from "customHooks/useSearchFilter";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
	generateFabricReport,
	getLadtSyncDate,
	masterSelectors,
} from "slices/masterSlice";
import { renderMobileColumn } from "utils/renderMobileColumn";

const masterName = "fabric";

const Reports = () => {
	const dispatch = useDispatch();

	const mainFabricList = useSelector(masterSelectors?.getFabricReportList);

	const fabricLastSyncDate = useSelector(masterSelectors?.getLastSyncDate);
	const { pageSize, totalElements, currentPage } = useSelector(
		masterSelectors?.getFabricReportPagination
	);

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);
	const { getColumnSearchProps, tableFilters } = useSearchFilter();

	const [fabricList, setFabricList] = useState(
		useSelector(masterSelectors?.getFabricReportList)
	);

	useEffect(() => {
		setFabricList(mainFabricList);
	}, [mainFabricList]);

	// let totalmeter = useRef(0);
	// let pendingmeter = useRef(0);
	// let quantity = useRef(0);

	// console.log(totalmeter, pendingmeter, quantity);
	var totalmeter = 0;
	var pendingmeter = 0;
	var quantity = 0;

	fabricList?.forEach(se => {
		totalmeter += +se?.total_meter || 0;
		pendingmeter += +se?.pending_meter || 0;
		quantity += +se?.quantity || 0;
	});

	const getFabricData = useCallback(() => {
		dispatch(
			generateFabricReport({
				masterName,
				query: {
					...tableFilters,
				},
			})
		);
	}, [tableFilters, dispatch]);

	useEffect(() => {
		dispatch(getLadtSyncDate({ type: masterName }));
	}, [dispatch]);

	useMasterLogic(getFabricData, masterName);

	const [filters, setFilters] = useState({}); // State to hold filters

	const handleFilterChange = (column, filtersValue) => {
		setFilters({
			...filters,
			[column]: filtersValue,
		});
		setExportArray(filteredArray);
	};

	useEffect(() => {
		//console.log("filters:", Object.values(filters));
		let obj = Object.values(filters);

		let tempData = mainFabricList;

		let supplier = obj?.[0]?.["supplier"] || [];
		let main_item = obj?.[0]?.["main_item"] || [];
		let color_name = obj?.[0]?.["color_name"] || [];
		let sub_category_name = obj?.[0]?.["sub_category_name"] || [];
		//console.log(supplier, main_item, color_name, sub_category_name);

		if (supplier?.length)
			tempData = tempData.filter(item => supplier.includes(item?.supplier));
		if (main_item?.length)
			tempData = tempData.filter(item => main_item.includes(item?.main_item));
		if (color_name?.length)
			tempData = tempData.filter(item => color_name.includes(item?.color_name));
		if (sub_category_name?.length)
			tempData = tempData.filter(item =>
				sub_category_name.includes(item?.sub_category_name)
			);

		//	console.log("fd", tempData);
		setFabricList(tempData);
	}, [filters]);

	const subcategories = Array.from(
		new Set(fabricList.map(item => item.sub_category_name))
	);

	const subCategoryFilters = subcategories.map(subcategory => ({
		text: subcategory,
		value: subcategory,
	}));

	const mainItems = Array.from(new Set(fabricList.map(item => item.main_item)));

	const mainItemFilters = mainItems.map(mainItems => ({
		text: mainItems,
		value: mainItems,
	}));

	const colors = Array.from(new Set(fabricList.map(item => item.color_name)));

	const colorsFilters = colors.map(colors => ({
		text: colors,
		value: colors,
	}));

	const suppliers = Array.from(new Set(fabricList.map(item => item.supplier)));

	const supplierFilters = suppliers.map(suppliers => ({
		text: suppliers,
		value: suppliers,
	}));

	let filteredArray = useMemo(() => []);

	const [exportArray, setExportArray] = useState([]);

	useEffect(() => {
		// Simulating data fetching delay with setTimeout
		setTimeout(() => {
			// Fetch data or perform any necessary actions
			setExportArray(fabricList);
		}, 2000); // Simulated 2 seconds delay for data fetching
	}, [fabricList]);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const headers = [
		{ label: "Supplier", key: "supplier" },
		{ label: "Sub Category", key: "sub_category_name" },
		{ label: "Main Item", key: "main_item" },
		{ label: "Color", key: "color_name" },
		{ label: "Total Meter", key: "total_meter" },
		{ label: "Pending Meter", key: "pending_meter" },
		{ label: "Quantity", key: "quantity" },
	];

	const columns = useMemo(
		() => [
			{
				title: "Fabric",
				render: data => {
					const primaryText = `${data?.main_item || "-"}-${
						data?.color_name || "-"
					}-${data?.sub_category_name || "-"}`;
					const secondaryText = data?.supplier || "-";
					const ternaryText = `${data?.total_meter || "-"}- ${
						data?.pending_meter || "-"
					}-${data?.quantity || "-"}`;
					return renderMobileColumn(primaryText, secondaryText, ternaryText);
				},
				responsive: ["xs"],
				...getColumnSearchProps("Search", "universal_search"),
			},
			{
				title: "Supplier",
				dataIndex: "supplier",
				key: "supplier",
				responsive: ["sm"],
				render: value => value || "-",
				filters: supplierFilters,
				onFilter: (value, record) => {
					if (record.supplier.startsWith(value)) {
						filteredArray.push(record);
					}
					return record.supplier.startsWith(value);
				},
				filterSearch: true,
			},
			{
				title: "Main Item",
				dataIndex: "main_item",
				key: "main_item",
				responsive: ["sm"],
				render: value => value || "-",
				filters: mainItemFilters,
				onFilter: (value, record) => {
					if (record.main_item.startsWith(value)) {
						filteredArray.push(record);
					}
					return record.main_item.startsWith(value);
				},
				filterSearch: true,
			},
			{
				title: "Color Name",
				dataIndex: "color_name",
				key: "color_name",
				responsive: ["sm"],
				render: value => value || "-",
				filters: colorsFilters,
				onFilter: (value, record) => {
					if (record.color_name.startsWith(value)) {
						filteredArray.push(record);
					}
					return record.color_name.startsWith(value);
				},
				filterSearch: true,
			},
			{
				title: "Sub Category",
				dataIndex: "sub_category_name",
				key: "sub_category_name",
				responsive: ["sm"],
				render: value => value || "-",
				filters: subCategoryFilters,
				onFilter: (value, record) => {
					if (record.sub_category_name.startsWith(value)) {
						filteredArray.push(record);
					}
					return record.sub_category_name.startsWith(value);
				},
				filterSearch: true,
			},
			{
				title: `Total Meter   -   ${totalmeter}`,
				dataIndex: "total_meter",
				key: "total_meter",
				responsive: ["sm"],
				render: value => value || "-",
			},
			{
				title: `pending Meter  -   ${pendingmeter}`,
				dataIndex: "pending_meter",
				key: "pending_meter",
				responsive: ["sm"],
				render: value => value || "-",
			},
			{
				title: `Quantity  - ${quantity.toFixed(4)}`,
				dataIndex: "quantity",
				key: "quantity",
				responsive: ["sm"],
				render: value => value || "-",
			},
		],
		[
			getColumnSearchProps,
			colorsFilters,
			subCategoryFilters,
			supplierFilters,
			mainItemFilters,
			totalmeter,
			pendingmeter,
			quantity,
			filteredArray,
		]
	);

	return (
		<>
			<Helmet>
				<title>Fabric Report</title>
			</Helmet>
			<h1 className=" font-bold text-xl flex justify-end ">
				{fabricLastSyncDate}
			</h1>
			<TableOptions masterName={masterName} showAdd={false} />
			<div style={{ float: "right", marginBottom: "10px" }}>
				<Button onClick={handlePrint} type="primary" danger>
					{" "}
					Export to PDF{" "}
				</Button>
				<CSVLink
					filename={"Fabric Report.csv"}
					data={exportArray}
					headers={headers}
					style={{ marginLeft: "10px" }}
					onClick={() => {
						message.success("The file is downloading");
					}}
				>
					<Button type="primary" danger>
						Export to CSV
					</Button>
				</CSVLink>
			</div>
			<div ref={componentRef} style={{ width: "100%" }}>
				<Table
					dataSource={fabricList}
					columns={columns}
					rowKey={fabricList => fabricList?.id}
					pagination={false}
					loading={isFetchingMasterList}
					bordered
					onChange={handleFilterChange}
				/>
			</div>
		</>
	);
};

export default Reports;
