import axios from "axios";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiConfig from "slices/apiConfig";
import stringifyQueryParams from "utils/stringifyQueryParams";

const initialState = {
	isFetchingMasterList: false,
	isUpdatingMasterList: false,
	isFetchingMasterDetails: false,
	isUpdatingMasterDetails: false,
	isFetchingDropdownList: false,
	isSyncingMasters: false,
	isSyncingChalan: false,
	isSyncingFabric: false,
	isSyncingReady: false,
	isSyncingSet: false,
	isExportingReadyStock: false,
	isLastSyncDate: false,
	isUpdatingReadyStockEntryList: false,
	// category
	categoryMasterList: [],
	categoryDropdownList: [],
	categoryPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},

	// subCategory
	subcategoryMasterList: [],
	subcategoryDetails: {},
	subcategoryDropdownList: [],
	subcategoryPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},

	// seasons
	seasonMasterList: [],
	seasonDropdownList: [],
	seasonDetails: {},
	seasonPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},

	// collection
	collectionMasterList: [],
	collectionDropdownList: [],
	collectionDetails: {},
	collectionPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},

	// main-item
	mainitemMasterList: [],
	mainitemPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	mainitemDropdownList: [],
	mainitemDetails: {},
	raw_material_colorsDropdownList: [],
	generatedmainitemMasterList: [],
	generatedmainitemDropdownList: [],
	generatedmainitemDetails: {},
	generatedraw_material_colorsDropdownList: [],

	lot_setDropdownList: [],

	fabricMasterList: [],
	fabricPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},

	imageUploadMasterList: [],
	imageUploadPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},

	chalanMasterList: [],
	chalanPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},

	readyStockMasterList: [],
	readyStockPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},

	katkutMasterList: [],
	katkutPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	saleOrderMasterList: [],
	saleOrderPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	outwardList: [],
	readyStockList: [],
	readyStockEntryMasterList: [],
	readyStockEntryDetails: {},
	readyStockWorkingList: [],
	outwardStockList: [],
	outwardStockPagination: {
		pageSize: 50,
		totalElements: 50,
		currentPage: 1,
	},
	outwardStockDetails: {},
	lastSyncDate: [],
	collectionList: [],
	colorsDropdownList: [],
	filteredColorDropdownList: [],
	partyNameDropdownList: [],
	lotNoDropdownList: [],
	DispatchStockList: [],
	DispatchStockPagination: {
		PageSize: 50,
		TotalElements: 50,
		CurrentPage: 1,
	},
	ledgerList: [],
	isGeneratingPdf: false,
	isExportExcel: false,
	isExportChalan: false,
	isFetchingSaleOrderExcel: false,
	saleOrderExcel: [],
	//
	chargesMasterList: [],
	chargesPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	hsnMasterList: [],
	hsnPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	cityMasterList: [],
	cityPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	customergroupMasterList: [],
	customergroupPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	agentMasterList: [],
	agentPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	transportMasterList: [],
	transportPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	customerMasterList: [],
	customerPagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	userroleMasterList: [],
	userrolePagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},
	saleMasterList: [],
	salePagination: {
		pageSize: 10,
		totalElements: 10,
		currentPage: 1,
	},

	exportingExcel: false,
	importingExcel: false,
	importedExcel: [],
	isFetchingDropdown: false,
	hsn_dropdown: [],
	categorydropdown: [],
	city_dropdown: [],
	customer_dropdown: [],
	dispatch_dropdown: [],
	outward_dropdown: [],
	item:[],//mainitem
	partyName_dropdown: [],
	color_dropdown: [],
	subcategory_dropdown: [],
	mainitem_subcategory: [],
	agent_dropdown: [],
	transport_dropdown: [],
	role_dropdown: [],

	isFetchingLogs: false,
	CityLogs: [],
	HSNLogs: [],
	CustomerGroupLogs: [], 
	ChargesLogs: [],
	AgentLogs: [],
	CustomerLogs: [],
	CategoryLogs: [],
	SubCategoryLogs: [],
	TransportLogs: [],
	addingUserRole: false,
	loadingDispatchPrint: false,
	dispatchPrintData: [],
	
};

export const masterSelectors = {
	getIsUpdatingMasterList: state => state.masterDetails?.isUpdatingMasterList,
	getIsFetchingMasterList: state => state.masterDetails?.isFetchingMasterList,
	getIsFetchingMasterDetails: state =>
		state.masterDetails?.isFetchingMasterDetails,
	getIsFetchingDropdownList: state =>
		state.masterDetails?.isFetchingDropdownList,
	getIsSyncingMasters: state => state.masterDetails?.isSyncingMasters,
	getIsSyncingChalan: state => state.masterDetails?.isSyncingChalan,
	getIsSyncingFabric: state => state.masterDetails?.isSyncingFabric,
	getIsSyncingReady: state => state.masterDetails?.isSyncingReady,
	getIsSyncingSet: state => state.masterDetails?.isSyncingSet,
	getIsUpdatingReadyStockEntryList: state =>
		state.masterDetails?.isUpdatingReadyStockEntryList,
	//category
	getCategoryMasterList: state => state.masterDetails?.categoryMasterList,
	getCategoriesDropdownList: state => state.masterDetails?.categoryDropdownList,
	getCategoryPagination: state => state.masterDetails?.categoryPagination,
	//sub category
	getSubCategoryMasterList: state => state.masterDetails?.subcategoryMasterList,
	getSubCategoriesDropdownList: state =>
		state.masterDetails?.subcategoryDropdownList,
	getSubCategoryPagination: state => state.masterDetails?.subcategoryPagination,
	getSubcategoryDetails: state => state.masterDetails?.subcategoryDetails,
	//seasons
	getSeasonsMasterList: state => state.masterDetails?.seasonMasterList,
	getSeasonsDropdownList: state => state.masterDetails?.seasonDropdownList,
	getSeasonsPagination: state => state.masterDetails?.seasonPagination,
	getSeasonDetails: state => state.masterDetails?.seasonDetails,
	//collections
	getCollectionsMasterList: state => state.masterDetails?.collectionMasterList,
	getCollectionsDropdownList: state =>
		state.masterDetails?.collectionDropdownList,
	getCollectionsPagination: state => state.masterDetails?.collectionPagination,
	getCollectionDetails: state => state.masterDetails?.collectionDetails,
	//main-items
	getMainItemsMasterList: state => state.masterDetails?.mainitemMasterList,
	getMainItemPagination: state => state.masterDetails?.mainitemPagination,
	getMainItemsDropdownList: state => state.masterDetails?.mainitemDropdownList,
	getMainItemDetails: state => state.masterDetails?.mainitemDetails,
	getRawMaterialColorsDropdownList: state =>
		state.masterDetails?.raw_material_colorsDropdownList,
	getGeneratedMainItemsMasterList: state =>
		state.masterDetails?.generatedmainitemMasterList,
	getGeneratedMainItemsDropdownList: state =>
		state.masterDetails?.generatedmainitemDropdownList,
	getGeneratedMainItemDetails: state =>
		state.masterDetails?.generatedmainitemDetails,
	getGeneratedRawMaterialColorsDropdownList: state =>
		state.masterDetails?.generatedraw_material_colorsDropdownList,
	getIsGeneratingPdf: state => state.masterDetails?.isGeneratingPdf,
	getIsExportExcel: state => state.masterDetails?.isExportExcel,
	getIsExportReady: state => state.masterDetails?.isExportingReadyStock,
	getIsExportChalan: state => state.masterDetails?.isExportChalan,
	getFabricReportList: state => state.masterDetails?.fabricMasterList,
	getFabricReportPagination: state => state.masterDetails?.fabricPagination,
	getImageUploadReportList: state => state.masterDetails?.imageUploadMasterList,
	getImageUploadReportPagination: state =>
		state.masterDetails?.imageUploadPagination,
	getchalanReportList: state => state.masterDetails?.chalanMasterList,
	getChalanReportPagination: state => state.masterDetails?.chalanPagination,
	getReadyStockReportList: state => state.masterDetails?.readyStockMasterList,
	getReadyStockReportPagination: state =>
		state.masterDetails?.readyStockPagination,
	getKatkutMasterList: state => state.masterDetails?.katkutMasterList,
	getKatkutPagination: state => state.masterDetails?.katkutPagination,
	getSalesOrderReportList: state => state.masterDetails?.saleOrderMasterList,
	getSalesOrderReportPagination: state =>
		state.masterDetails?.saleOrderPagination,
	getReadyStockList: state => state.masterDetails?.readyStockList,
	getReadyStockEntryList: state =>
		state.masterDetails?.readyStockEntryMasterList,
	getReadyStockEntryDetails: state =>
		state.masterDetails?.readyStockEntryDetails,
	getLastSyncDate: state => state.masterDetails?.lastSyncDate,
	getLotSetDropdownList: state => state.masterDetails?.lot_setDropdownList,
	getCollectionList: state => state.masterDetails?.collectionList,
	getOutwardList: state => state.masterDetails?.outwardList,
	getColorsDropdownList: state => state.masterDetails?.colorsDropdownList,
	getFilteredColorDropdownList: state =>
		state.masterDetails?.filteredColorDropdownList, //**** */
	getFilteredColorList: state => state.masterDetails?.colorsDropdownList,
	getReadyStockWorkingList: state => state.masterDetails?.readyStockWorkingList,
	getOutwardStockEntryList: state => state.masterDetails?.outwardStockList,
	getOutwardStockEntryPagination: state => state.masterDetails?.outwardStockPagination,
	getOutwardStockDetails: state => state.masterDetails?.outwardStockDetails,
	getPartyNameDropdownList: state => state.masterDetails?.partyNameDropdownList,
	getLotNoDropdownList: state => state.masterDetails?.lotNoDropdownList,
	getDispatchStockList: state => state.masterDetails?.DispatchStockList,
	getDispatchStockPagination: state => state.masterDetails?.DispatchStockPagination,
	getLedgerList: state => state.masterDetails?.ledgerList,
	//sale order excel
	getSaleOrderExcel: state => state.masterDetails?.saleOrderExcel,
	getIsFetchingSaleOrderExcel: state => state.masterDetails?.isFetchingSaleOrderExcel,
	//masters v2
	getChargesMasterList: state => state.masterDetails?.chargesMasterList,
	getChargesPagination: state => state.masterDetails?.chargesPagination,
	getHsnMasterList: state => state.masterDetails?.hsnMasterList,
	getHsnPagination: state => state.masterDetails?.hsnPagination,
	getCityMasterList: state => state.masterDetails?.cityMasterList,
	getCityPagination: state => state.masterDetails?.cityPagination,
	getCustomerGroupMasterList: state => state.masterDetails?.customergroupMasterList,
	getCustomerGroupPagination: state => state.masterDetails?.customergroupPagination,
	getAgentMasterList: state => state.masterDetails?.agentMasterList,
	getAgentPagination: state => state.masterDetails?.agentPagination,
	getTransportMasterList: state => state.masterDetails?.transportMasterList,
	getTransportPagination: state => state.masterDetails?.transportPagination,
	getCustomerMasterList: state => state.masterDetails?.customerMasterList,
	getCustomerPagination: state => state.masterDetails?.customerPagination,
	getRoleMasterList: state => state.masterDetails?.userroleMasterList,
	getRolePagination: state => state.masterDetails?.userrolePagination,
	getSaleMasterList: state => state.masterDetails?.saleMasterList,
	getSalePagination: state => state.masterDetails?.salePagination,

	getImportedExcel: state => state.masterDetails?.importedExcel,
	getIsImportingExcel: state => state.masterDetails?.importingExcel,
	getIsExportingExcel: state => state.masterDetails?.exportingExcel,
	getIsFetchingDropdown: state => state.masterDetails?.isFetchingDropdown,
	getCityLogs: state => state.masterDetails?.CityLogs,
	getHsnLogs: state => state.masterDetails?.HSNLogs,
	getChargesLogs: state => state.masterDetails?.ChargesLogs,
	getCustomerGroupLogs: state => state.masterDetails?.CustomerGroupLogs,
	getAgentLogs: state => state.masterDetails?.AgentLogs,
	getCategoryLogs: state => state.masterDetails?.CategoryLogs,
	getSubCategoryLogs: state => state.masterDetails?.SubCategoryLogs,
	getTransportLogs: state => state.masterDetails?.TransportLogs,
	getIsFetchingLogs: state => state?.masterDetails?.isFetchingLogs,
	getCustomerLogs: state => state?.masterDetails?.CategoryLogs,
	getHsnDropdown: state => state.masterDetails?.hsn_dropdown, 
	getCategoryDropdown: state => state.masterDetails?.categorydropdown,
	getCityDropdown: state => state.masterDetails?.city_dropdown,
	getCustomerDropdown: state => state.masterDetails?.customer_dropdown,
	getDispatchDropdown: state => state.masterDetails?.dispatch_dropdown,
	getOutwardDropdown: state => state.masterDetails?.outward_dropdown,
	getMainItemDropdown: state => state.masterDetails?.item,
	getPartyNameDropdown: state => state.masterDetails?.partyName_dropdown,
	getColorDropdown: state => state.masterDetails?.color_dropdown,
	getSubCategoryDropdown: state => state.masterDetails?.subcategory_dropdown,
	getMainItemSubCategoryDropdown: state => state.masterDetails?.mainitem_subcategory,
	getAgentDropdown: state => state.masterDetails?.agent_dropdown,
	getTransportDropdown: state => state.masterDetails?.transport_dropdown,
	getRoleDropdown: state => state.masterDetails?.role_dropdown,
	getAddingUserRoleLoading: state => state.masterDetails?.addingUserRole,
	getDispatchPrintLoading: state => state.masterDetails?.loadingDispatchPrint,
	getDispatchPrintData: state => state.masterDetails?.dispatchPrintData,
	
};

export const getMasterList = createAsyncThunk(
	"master/list",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, query = {} } = body;
		try {
			const queryString = stringifyQueryParams(query);

			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/${masterName}?${queryString}`,
				config
			);

			return {
				data,
				masterName,
				query,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getMasterDropDownList = createAsyncThunk(
	"master/dropdown/list",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, query = {}, asString } = body;
		try {
			const queryString = stringifyQueryParams(query);

			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/${masterName}?${ asString ? asString : queryString}`,
				config
			);
			return {
				data,
				masterName,
				query,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getMasterLogsList = createAsyncThunk(
	"master/logs/list",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, id } = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/logs?name=${masterName}&id=${id}`,
				config
			);
			return {
				data,
				masterName,
				id,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getMasterDetails = createAsyncThunk(
	"master/details",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, id } = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/${masterName}?id=${id}`,
				config
			);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const addMasterList = createAsyncThunk(
	"master/add",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/${masterName}/`,
				formData,
				config
			);

			if (navigate) navigate(`/${masterName}`);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const editMasterList = createAsyncThunk(
	"master/edit",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
			id,
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};
			const { data } = await axios.put(
				`${apiConfig?.baseURL}/${masterName}/${
					id || formData?.id || formData.get("id")
				}`,
				formData,
				config
			);

			if (navigate) navigate(`/${masterName}`);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const editMasterListMain = createAsyncThunk(
	"master/editmain",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};
			const { data } = await axios.put(
				`${apiConfig?.baseURL}/${masterName}/update/${
					formData?.id || formData.get("id")
				}`,
				formData,
				config
			);

			if (navigate) navigate(`/${masterName}`);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const allMasterSync = createAsyncThunk(
	"master/sync",
	async (_, { getState, rejectWithValue }) => {
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/all_masters`,
				config
			);

			return {
				data,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const lotSetSync = createAsyncThunk(
	"master/lotSetSync",
	async (_, { getState, rejectWithValue }) => {
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(`${apiConfig?.baseURL}/lot_set`, config);

			return {
				data,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
export const chalanSync = createAsyncThunk(
	"master/chalanSync",
	async (_, { getState, rejectWithValue }) => {
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(`${apiConfig?.baseURL}/chalan`, config);

			return {
				data,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
export const fabricSync = createAsyncThunk(
	"master/fabricSync",
	async (_, { getState, rejectWithValue }) => {
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(`${apiConfig?.baseURL}/fabric`, config);

			return {
				data,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const readyStockSync = createAsyncThunk(
	"master/readyStockSync",
	async (_, { getState, rejectWithValue }) => {
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/ready-stock`,
				config
			);

			return {
				data,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getDropdownList = createAsyncThunk(
	"master/dropdown",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, query = {} } = body;
		try {
			const queryString = stringifyQueryParams(query);

			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/${masterName}_dropdown?${queryString}`,
				config
			);

			return {
				data,
				masterName,
				query,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
//
export const getFilteredColorList = createAsyncThunk(
	"master/dropdown/color",
	async (value, { getState, rejectWithValue }) => {
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/${value?.masterName}?itemName=${value?.mainItem}`,
				config
			);

			return {
				data,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const generatePdf = createAsyncThunk(
	"master/pdf",
	async (body, { getState, rejectWithValue }) => {
		const { formData, configType = "application/json" } = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/pdf`,
				formData,
				config
			);

			if (data?.message) window.open(data?.message);
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const generateFabricReport = createAsyncThunk(
	"master/reports",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, query = {} } = body;
		try {
			const queryString = stringifyQueryParams(query);

			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/report/${masterName}?${queryString}`,
				config
			);

			return {
				data,
				masterName,
				query,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// export const generateSalesOrderReport = createAsyncThunk(
// 	"master/salesOrderReports",
// 	async (body, { getState, rejectWithValue }) => {
// 		const { masterName, query = {} } = body;
// 		try {
// 			const queryString = stringifyQueryParams(query);

// 			const config = {
// 				headers: {
// 					...apiConfig?.getHeaders(getState),
// 				},
// 			};
// 			const { data } = await axios.get(
// 				`${apiConfig?.baseURL}/${masterName}?${queryString}`,
// 				config
// 			);

// 			return {
// 				data,
// 				masterName,
// 				query,
// 			};
// 		} catch (error) {
// 			return rejectWithValue(error);
// 		}
// 	}
// );
export const exportExcel = createAsyncThunk(
	"master/excel",
	async (_, { getState, rejectWithValue }) => {
		try {
			const date = new Date();
			const fileName = `image-uploaded_${date.getDate()}-${
				date.getMonth() + 1
			}-${date.getFullYear()}.xlsx`; //month + 1 because date returns months from 0 - 11
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
				responseType: "blob",
			};
			await axios
				.get(`${apiConfig?.baseURL}/image-uploaded/export/excel`, config)
				.then(response => {
					let url = window.URL.createObjectURL(new Blob([response.data]));
					let a = document.createElement("a");
					a.href = url;
					a.download = fileName;
					a.click();
				});
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const exportChalan = createAsyncThunk(
	"master/chalan",
	async (_, { getState, rejectWithValue }) => {
		try {
			const date = new Date();
			const fileName = `Chalan_${date.getDate()}-${
				date.getMonth() + 1
			}-${date.getFullYear()}.xlsx`; //month + 1 because date returns months from 0 - 11
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
				responseType: "blob",
			};
			await axios
				.get(`${apiConfig?.baseURL}/chalan/export/excel`, config)
				.then(response => {
					let url = window.URL.createObjectURL(new Blob([response.data]));
					let a = document.createElement("a");
					a.href = url;
					a.download = fileName;
					a.click();
				});
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const exportReadyStock = createAsyncThunk(
	"master/ready-stock",
	async (_, { getState, rejectWithValue }) => {
		try {
			const date = new Date();
			const fileName = `Ready-Stock_${date.getDate()}-${
				date.getMonth() + 1
			}-${date.getFullYear()}.xlsx`; //month + 1 because date returns months from 0 - 11
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
				responseType: "blob",
			};
			await axios
				.get(`${apiConfig?.baseURL}/ready-stock/export/excel`, config)
				.then(response => {
					let url = window.URL.createObjectURL(new Blob([response.data]));
					let a = document.createElement("a");
					a.href = url;
					a.download = fileName;
					a.click();
				});
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const uploadExcel = createAsyncThunk(
	"master/excelfile",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/saleOrder/upload/import/`,
				formData,
				config
			);

			if (navigate) navigate(`/${masterName}`);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const uploadNewItemExcel = createAsyncThunk(
	"master/excelNewItemfile",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/mainitem/upload/import/`,
				formData,
				config
			);

			if (navigate) navigate(`/${masterName}`);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
export const getReadyStockFormDetails = createAsyncThunk(
	"master/readyStockDetails",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, formData } = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/${masterName}/form`,
				formData,
				config
			);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const addReadyStockEntry = createAsyncThunk(
	"master/addReadyStockEntry",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/${masterName}/`,
				formData,
				config
			);

			// if (navigate) navigate("/outwardStockEntry");
			if (navigate) navigate("/working/outward/outwardStockEntry/");

			// if (navigate) navigate("/components/working/ready-stock-entry/");

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getLadtSyncDate = createAsyncThunk(
	"master/syncDate",
	async (body, { getState, rejectWithValue }) => {
		const { type } = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/lastSync?list_type=${type}`,
				config
			);

			return {
				data,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
export const getOutwardEntryDetails = createAsyncThunk(
	"master/outwardEntryDetails",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, formdata, query = {} }= body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};

			const queryString = stringifyQueryParams(query);
			const { data } = await axios.post(
				`${apiConfig?.baseURL}/${masterName}/get?${queryString}`,
				formdata,
				config
			);

			return {
				data,
				masterName,
				query
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getOutwardDetails = createAsyncThunk(
	"master/OutwardDetails",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, formData } = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/${masterName}/details`,
				{ mainItem: formData?.mainItem, partyName: formData?.partyName },

				config
			);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getReadyStockWorkingReport = createAsyncThunk(
	"master/readyStockWorkingReport",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, query = {} } = body;
		try {
			const queryString = stringifyQueryParams(query);

			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/readyStock/${masterName}?${queryString}`,
				config
			);

			return {
				data,
				masterName,
				query,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const editReadyStock = createAsyncThunk(
	"master/editReadyStock",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};
			const { data } = await axios.put(
				`${apiConfig?.baseURL}/${masterName}/${
					formData?.id || formData.get("id")
				}`,
				formData,
				config
			);

			//navigate(`/working/${masterName}/`);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const dispatchOutwardStockEntry = createAsyncThunk(
	"master/dispatchOutwardStockEntry",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/DispatchStock`,
				formData,
				config
			);

			//if (navigate) navigate("/outwardStockEntry");

			//if (navigate) navigate("/components/working/ready-stock-entry/");

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const dispatchStockEntry = createAsyncThunk(
	"master/dispatchStockEntry",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/DispatchStock/Dispatch/Packing`,
				formData,
				config
			);

			//if (navigate) navigate("/outwardStockEntry");

			//if (navigate) navigate("/components/working/ready-stock-entry/");

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
export const dispatchStockRevert = createAsyncThunk(
	"master/dispatchStockRevert",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/DispatchStock/revert`,
				formData,
				config
			);

			//if (navigate) navigate("/outwardStockEntry");

			//if (navigate) navigate("/components/working/ready-stock-entry/");

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
export const getLedger = createAsyncThunk(
	"master/getLedger",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, formdata } = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/${masterName}`,
				formdata,
				config
			);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const editOutwardStockEntry = createAsyncThunk(
	"master/editOutwardStockEntry",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			navigate,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};
			const { data } = await axios.put(
				`${apiConfig?.baseURL}/${masterName}/${
					formData?.id || formData.get("id")
				}`,
				formData,
				config
			);

			if (navigate) navigate("/working/outward/outwardStockEntry/");

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getSaleOrderExcelList = createAsyncThunk(
	"master/saleOrderExcelList",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, query = {} } = body;
		try {
			const queryString = stringifyQueryParams(query);

			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
				responseType: "blob",
			};
			const { data } = await axios.get(
				`${apiConfig?.baseURL}/${masterName}?${queryString}`,
				config
			);

			if(data !== undefined){
				const date = new Date();
			const fileName = `sale-order_${date.getDate()}-${
				date.getMonth() + 1
			}-${date.getFullYear()}.xlsx`;
					let url = window.URL.createObjectURL(new Blob([data]));
					let a = document.createElement("a");
					a.href = url;
					a.download = fileName;
					a.click();
			}
			return {
				data,
				masterName,
				query,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const ledgerExcelList = createAsyncThunk(
	"master/ledgerExcelList",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, formData } = body;
		try {

			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
				responseType: "blob",
			};
			const { data } = await axios.post(
				`${apiConfig?.baseURL}/${masterName}`,
				formData,
				config
			);

			if(data !== undefined){
				const date = new Date();
			const fileName = `ledger_${date.getDate()}-${
				date.getMonth() + 1
			}-${date.getFullYear()}.xlsx`;
					let url = window.URL.createObjectURL(new Blob([data]));
					let a = document.createElement("a");
					a.href = url;
					a.download = fileName;
					a.click();
			}
			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const ImportExcelFile = createAsyncThunk(
	"master/importExcelFile",
	async (body, { getState, rejectWithValue }) => {
		const { masterName, name } = body;
		try {
			const date = new Date();
			const fileName = `${name}_${date.getDate()}-${
				date.getMonth() + 1
			}-${date.getFullYear()}.xlsx`; //month + 1 because date returns months from 0 - 11
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
				},
				responseType: "blob",
			};
			await axios
				.get(`${apiConfig?.baseURL}/${masterName}`, config)
				.then(response => {
					let url = window.URL.createObjectURL(new Blob([response.data]));
					let a = document.createElement("a");
					a.href = url;
					a.download = fileName;
					a.click();
				});
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const ExportExcelFile = createAsyncThunk(
	"master/exportExcelFile",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
				responseType: "blob",
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/${masterName}`,
				formData,
				config
			);

			const date = new Date();
			const fileName = `${masterName}_${date.getDate()}-${
				date.getMonth() + 1
			}-${date.getFullYear()}.xlsx`;

			let url = window.URL.createObjectURL(new Blob([data]));
			let a = document.createElement("a");
			a.href = url;
			a.download = fileName;
			a.click();

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const AddUserRole = createAsyncThunk(
	"master/addUserRole",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/${masterName}`,
				formData,
				config
			);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const GetDispatchPrintData = createAsyncThunk(
	"master/dispatchPrint",
	async (body, { getState, rejectWithValue }) => {
		const {
			masterName,
			formData,
			configType = "application/json",
		} = body;
		try {
			const config = {
				headers: {
					...apiConfig?.getHeaders(getState),
					"Content-Type": configType,
				},
			};

			const { data } = await axios.post(
				`${apiConfig?.baseURL}/${masterName}`,
				formData,
				config
			);

			return {
				data,
				masterName,
			};
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

const masterSlice = createSlice({
	name: "master",
	initialState,
	reducers: {
		resetMasterDetails: (state, action) => {
			state[`${action?.payload}Details`] = {};
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getMasterList.pending, state => {
				state.isFetchingMasterList = true;
			})
			.addCase(getMasterList.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;
				state.isFetchingMasterList = false;
				state[`${masterName}MasterList`] = data?.data;
				state[`${masterName}Pagination`] = data?.pagination;
				state["collectionList"] = data;
			})
			.addCase(getMasterList.rejected, state => {
				state.isFetchingMasterList = false;
			})
			.addCase(getMasterDropDownList.pending, state => {
				state.isFetchingDropdown = true;
			})
			.addCase(getMasterDropDownList.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;
				state.isFetchingDropdown = false;
				state[`${masterName}`] = data;
			})
			.addCase(getMasterDropDownList.rejected, state => {
				state.isFetchingDropdown = false;
			})
			.addCase(getMasterLogsList.pending, state => {
				state.isFetchingLogs = true;
			})
			.addCase(getMasterLogsList.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;
				state.isFetchingLogs = false;
				state[`${masterName}Logs`] = data;
			})
			.addCase(getMasterLogsList.rejected, state => {
				state.isFetchingLogs = false;
			})

			.addCase(getMasterDetails.pending, state => {
				state.isFetchingMasterDetails = true;
			})
			.addCase(getMasterDetails.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;
				state.isFetchingMasterDetails = false;
				state[`${masterName}Details`] = data;
			})
			.addCase(getMasterDetails.rejected, state => {
				state.isFetchingMasterDetails = false;
			})

			.addCase(addMasterList.pending, state => {
				state.isUpdatingMasterList = true;
			})
			.addCase(addMasterList.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
			})
			.addCase(addMasterList.rejected, state => {
				state.isUpdatingMasterList = false;
			})
			.addCase(uploadExcel.pending, state => {
				state.isUpdatingMasterList = true;
			})
			.addCase(uploadExcel.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
			})
			.addCase(uploadExcel.rejected, state => {
				state.isUpdatingMasterList = false;
			})
			.addCase(uploadNewItemExcel.pending, state => {
				state.isUpdatingMasterList = true;
			})
			.addCase(uploadNewItemExcel.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
			})
			.addCase(uploadNewItemExcel.rejected, state => {
				state.isUpdatingMasterList = false;
			})
			.addCase(editMasterList.pending, state => {
				state.isUpdatingMasterList = true;
			})
			.addCase(editMasterList.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
				state.isUpdatingReadyStockEntryList = false;
			})
			.addCase(editMasterList.rejected, state => {
				state.isUpdatingReadyStockEntryList = true;
				state.isUpdatingMasterList = false;
			})
			.addCase(editMasterListMain.pending, state => {
				state.isUpdatingMasterList = true;
			})
			.addCase(editMasterListMain.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
			})
			.addCase(editMasterListMain.rejected, state => {
				state.isUpdatingMasterList = false;
			})

			.addCase(editReadyStock.pending, state => {
				state.isUpdatingMasterList = true;
			})
			.addCase(editReadyStock.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
			})
			.addCase(editReadyStock.rejected, state => {
				state.isUpdatingMasterList = false;
			})
			.addCase(allMasterSync.pending, state => {
				state.isSyncingMasters = true;
			})
			.addCase(allMasterSync.fulfilled, (state, { payload }) => {
				state.isSyncingMasters = false;
			})
			.addCase(allMasterSync.rejected, state => {
				state.isSyncingMasters = false;
			})

			.addCase(chalanSync.pending, state => {
				state.isSyncingChalan = true;
			})
			.addCase(chalanSync.fulfilled, (state, { payload }) => {
				state.isSyncingChalan = false;
			})
			.addCase(chalanSync.rejected, state => {
				state.isSyncingChalan = false;
			})

			.addCase(fabricSync.pending, state => {
				state.isSyncingFabric = true;
			})
			.addCase(fabricSync.fulfilled, (state, { payload }) => {
				state.isSyncingFabric = false;
			})
			.addCase(fabricSync.rejected, state => {
				state.isSyncingFabric = false;
				state.isFetchingMasterList = false;
			})

			.addCase(lotSetSync.pending, state => {
				state.isSyncingSet = true;
			})
			.addCase(lotSetSync.fulfilled, (state, { payload }) => {
				state.isSyncingSet = false;
			})
			.addCase(lotSetSync.rejected, state => {
				state.isSyncingSet = false;
			})

			.addCase(readyStockSync.pending, state => {
				state.isSyncingReady = true;
			})
			.addCase(readyStockSync.fulfilled, (state, { payload }) => {
				state.isSyncingReady = false;
			})
			.addCase(readyStockSync.rejected, state => {
				state.isSyncingReady = false;
			})

			.addCase(getDropdownList.pending, state => {
				state.isFetchingDropdownList = true;
			})
			.addCase(getDropdownList.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;

				state.isFetchingDropdownList = false;
				state[`${masterName}DropdownList`] = data;
			})
			.addCase(getDropdownList.rejected, state => {
				state.isFetchingDropdownList = false;
			})
			.addCase(getFilteredColorList.pending, state => {
				state.isFetchingDropdownList = true;
			})
			.addCase(getFilteredColorList.fulfilled, (state, { payload }) => {
				const { data } = payload;

				state.isFetchingDropdownList = false;
				state[`filteredColorDropdownList`] = data;
			})
			.addCase(getFilteredColorList.rejected, state => {
				state.isFetchingDropdownList = false;
			})

			.addCase(generateFabricReport.pending, state => {
				state.isFetchingMasterList = true;
			})
			.addCase(generateFabricReport.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;
				state.isFetchingMasterList = false;
				state[`${masterName}MasterList`] = data?.data;
				state[`${masterName}Pagination`] = data?.pagination;
			})
			.addCase(generateFabricReport.rejected, state => {
				state.isFetchingMasterList = false;
			})

			// .addCase(generateSalesOrderReport.pending, state => {
			// 	state.isFetchingMasterList = true;
			// })
			// .addCase(generateSalesOrderReport.fulfilled, (state, { payload }) => {
			// 	const { data, masterName } = payload;
			// 	state.isFetchingMasterList = false;
			// 	state[`${masterName}MasterList`] = data?.data;
			// 	state[`${masterName}Pagination`] = data?.pagination;
			// })
			// .addCase(generateSalesOrderReport.rejected, state => {
			// 	state.isFetchingMasterList = false;
			// })

			.addCase(generatePdf.pending, state => {
				state.isGeneratingPdf = true;
			})
			.addCase(generatePdf.fulfilled, state => {
				state.isGeneratingPdf = false;
			})
			.addCase(generatePdf.rejected, state => {
				state.isGeneratingPdf = false;
			})

			.addCase(exportExcel.pending, state => {
				state.isExportExcel = true;
			})
			.addCase(exportExcel.fulfilled, state => {
				state.isExportExcel = false;
			})
			.addCase(exportExcel.rejected, state => {
				state.isExportExcel = false;
			})

			.addCase(exportReadyStock.pending, state => {
				state.isExportingReadyStock = true;
			})
			.addCase(exportReadyStock.fulfilled, state => {
				state.isExportingReadyStock = false;
			})
			.addCase(exportReadyStock.rejected, state => {
				state.isExportingReadyStock = false;
			})

			.addCase(exportChalan.pending, state => {
				state.isExportChalan = true;
			})
			.addCase(exportChalan.fulfilled, state => {
				state.isExportChalan = false;
			})
			.addCase(exportChalan.rejected, state => {
				state.isExportChalan = false;
			})

			.addCase(getReadyStockFormDetails.pending, state => {
				state.isFetchingMasterList = true;
			})
			.addCase(getReadyStockFormDetails.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;
				state.isFetchingMasterList = false;
				state[`${masterName}List`] = data?.data;
			})
			.addCase(getReadyStockFormDetails.rejected, state => {
				state.isFetchingMasterList = false;
			})

			.addCase(addReadyStockEntry.pending, state => {
				state.isUpdatingMasterList = true;
				//state.isUpdatingReadyStockEntryList = true;
			})
			.addCase(addReadyStockEntry.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
				state.isUpdatingReadyStockEntryList = false;
			})
			.addCase(addReadyStockEntry.rejected, state => {
				state.isUpdatingReadyStockEntryList = true;
				state.isUpdatingMasterList = false;
			})

			.addCase(getLadtSyncDate.pending, state => {
				state.isLastSyncDate = true;
			})
			.addCase(getLadtSyncDate.fulfilled, (state, { payload }) => {
				const { data } = payload;
				state.isLastSyncDate = false;
				state["lastSyncDate"] = data?.message;
			})
			.addCase(getLadtSyncDate.rejected, state => {
				state.isLastSyncDate = false;
			})
			.addCase(getOutwardEntryDetails.pending, state => {
				state.isFetchingMasterList = true;
			})
			.addCase(getOutwardEntryDetails.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;
				state.isFetchingMasterList = false;
				state[`${masterName}List`] = data?.data;
				state[`${masterName}Pagination`] = data?.pagination;
			})
			.addCase(getOutwardEntryDetails.rejected, state => {
				state.isFetchingMasterList = false;
			})

			.addCase(getOutwardDetails.pending, state => {
				state.isFetchingMasterList = true;
			})
			.addCase(getOutwardDetails.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;
				state.isFetchingMasterList = false;
				state[`${masterName}List`] = data?.data;
			})
			.addCase(getOutwardDetails.rejected, state => {
				state.isFetchingMasterList = false;
			})

			.addCase(getReadyStockWorkingReport.pending, state => {
				state.isFetchingMasterList = true;
			})
			.addCase(getReadyStockWorkingReport.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;
				state.isFetchingMasterList = false;
				state["readyStockWorkingList"] = data?.data;
			})
			.addCase(getReadyStockWorkingReport.rejected, state => {
				state.isFetchingMasterList = false;
			})

			.addCase(dispatchOutwardStockEntry.pending, state => {
				state.isUpdatingMasterList = true;
			})
			.addCase(dispatchOutwardStockEntry.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
			})
			.addCase(dispatchOutwardStockEntry.rejected, state => {
				state.isUpdatingMasterList = false;
			})
			.addCase(dispatchStockEntry.pending, state => {
				state.isUpdatingMasterList = true;
			})
			.addCase(dispatchStockEntry.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
			})
			.addCase(dispatchStockEntry.rejected, state => {
				state.isUpdatingMasterList = false;
			})
			.addCase(dispatchStockRevert.pending, state => {
				state.isUpdatingMasterList = true;
			})
			.addCase(dispatchStockRevert.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
			})
			.addCase(dispatchStockRevert.rejected, state => {
				state.isUpdatingMasterList = false;
			})
			.addCase(getLedger.pending, state => {
				state.isFetchingMasterList = true;
			})
			.addCase(getLedger.fulfilled, (state, { payload }) => {
				const { data, masterName } = payload;
				state.isFetchingMasterList = false;
				state[`${masterName}List`] = data?.data;
			})
			.addCase(getLedger.rejected, state => {
				state.isFetchingMasterList = false;
			})
			.addCase(editOutwardStockEntry.pending, state => {
				state.isUpdatingMasterList = true;
			})
			.addCase(editOutwardStockEntry.fulfilled, (state, { payload }) => {
				state.isUpdatingMasterList = false;
				state.isUpdatingReadyStockEntryList = false;
			})
			.addCase(editOutwardStockEntry.rejected, state => {
				state.isUpdatingReadyStockEntryList = true;
				state.isUpdatingMasterList = false;
			}).addCase(getSaleOrderExcelList.pending, state => {
				state.isFetchingSaleOrderExcel = true;
			})
			.addCase(getSaleOrderExcelList.fulfilled, (state, { payload }) => {
				const { data } = payload;
				state.isFetchingSaleOrderExcel = false;
				state[`saleOrderExcel`] = data;
			})
			.addCase(getSaleOrderExcelList.rejected, state => {
				state.isFetchingSaleOrderExcel = false;
			}).addCase(ImportExcelFile.pending, state => {
				state.importingExcel = true;
			})
			.addCase(ImportExcelFile.fulfilled, (state) => {
				state.importingExcel = false;
			})
			.addCase(ImportExcelFile.rejected, state => {
				state.importingExcel = false;
			}).addCase(ExportExcelFile.pending, state => {
				state.importingExcel = true;
			})
			.addCase(ExportExcelFile.fulfilled, (state) => {
				state.importingExcel = false;
			})
			.addCase(ExportExcelFile.rejected, state => {
				state.importingExcel = false;
			})
			.addCase(AddUserRole.pending, state => {
				state.addingUserRole = true;
			})
			.addCase(AddUserRole.fulfilled, (state) => {
				state.addingUserRole = false;
			})
			.addCase(AddUserRole.rejected, state => {
				state.addingUserRole = false;
			})
			.addCase(GetDispatchPrintData.pending, state => {
				state.loadingDispatchPrint = true;
			})
			.addCase(GetDispatchPrintData.fulfilled, (state, { payload }) => {
				const {data} = payload;
				state.loadingDispatchPrint = false;
				state.dispatchPrintData = data;
			})
			.addCase(GetDispatchPrintData.rejected, state => {
				state.loadingDispatchPrint = false;
			});
	},
});

export default masterSlice.reducer;
export const { clearUserDetails, resetMasterDetails } = masterSlice.actions;
