import React, { useState, useCallback } from 'react'
import { Pagination, Table, Button, Modal, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { renderMobileColumn } from "utils/renderMobileColumn";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
	Field,
	formValueSelector,
	reduxForm,
} from "redux-form";
import { getMasterList, masterSelectors, ImportExcelFile, ExportExcelFile} from 'slices/masterSlice'
import useMasterLogic from 'customHooks/useMasterLogic';
import { ReduxFormFileField } from 'utils/ReduxFormFileField';
import { TfiReload } from "react-icons/tfi";
import { renderActions } from 'utils/renderActions';
import { useHotkeys } from 'react-hotkeys-hook'
import EditSaleOrderForm from './EditSaleOrderForm';

const masterName = "sale"
const NewSaleOrderReport = () => {

	const formSelector = formValueSelector(masterName);
	const dispatch = useDispatch()
	const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
	const importing = useSelector(masterSelectors?.getIsImportingExcel);
	const exporting = useSelector(masterSelectors?.getIsExportingExcel);
	const list = useSelector(masterSelectors?.getSaleMasterList);
	const { pageSize, totalElements, currentPage } = useSelector(
		masterSelectors?.getSalePagination
	);

	const getListData = useCallback(
		(pageNum = 1, itemsPerPage = 10) => {
			dispatch(
				getMasterList({
					masterName,
					query: {
						page: pageNum,
						pageSize: itemsPerPage
					},
				})
			);
		},
		[dispatch]
	);

	const { handlePageChange } = useMasterLogic(getListData, masterName);

    const [data, setData] = useState({})
	const [type, setType] = useState("update")
	const [showEdit, setShowEdit] = useState(false)

	const handleSetData = (value) =>{
		setType("update")
		let temp = {...value};
        temp.cityId = value?.city?.id;
		
		setData(temp)
	}

	const handleDuplicate = (value)=>{
		setType("new")
		let temp = {...value};
		temp.partyId = value?.party?.id
        temp.cityId = value?.city?.id;
		temp.saleOrderSubForm = temp.saleOrderSubForm.map((item) => ({
			...item,
			colorId: item?.color?.color_id,
			mainitemId: item?.mainitem?.id,
			subcategoryId: item?.mainitem?.sub_category_id?.id,
		}));
		setData(temp)
		setShowEdit(true)
	}

	const handleImport = () =>{
		dispatch(ImportExcelFile({
			masterName:'sale/export/excel',
			name:'sale'
		}))
	}
	const [show, setShow] = useState(false)
	const excelFile = useSelector(state => formSelector(state, "item_excel"));
	const handleExport = () =>{
		setShow(false);
		const form = new FormData();
		form.append("file", excelFile);
		dispatch(
			ExportExcelFile({
				masterName:"sale/upload/import",
				formData: form,
				configType: "multipart/form-data",
			})
		);
	}


    const columns = [
		{
			title: "Sales",
			render: data => {
				const primaryText = data?.orderNo || "-";
				const secondaryText = `${data?.orderDate || "-"}`;
				return renderMobileColumn(primaryText, secondaryText);
			},
			responsive: ["xs"],
		},
		{
			title: "Order No.",
			dataIndex: "orderNo",
			key: "orderNo",
			responsive: ["sm"],
			//render: (value, data) => <p onDoubleClick={()=>handleSetData(data)} className='cursor-pointer'>{value || "-"}</p>,
		},
		{
			title: "Order Date",
			dataIndex: "orderDate",
			key: "orderDate",
			responsive: ["sm"],
			//render: (value, data) => <p onDoubleClick={()=>handleSetData(data)} className='cursor-pointer'>{value || "-"}</p>,
		},
		{
			title: "Party",
			dataIndex: ["party", "partyName",],
			key: "partyName",
			responsive: ["sm"],
			//render: (value, data) => <p onDoubleClick={()=>handleSetData(data)} className='cursor-pointer'>{value || "-"}</p>,
		},
		{
			title: "City",
			dataIndex: ["party", "cityMaster", "cityName"],
			key: "city",
			responsive: ["sm"],
			//render: (value, data) => <p onDoubleClick={()=>handleSetData(data)} className='cursor-pointer'>{value || "-"}</p>,
		},
		{
			title: "State",
			dataIndex: ["party", "state"],
			key: "state",
			responsive: ["sm"],
			//render: (value, data) => <p onDoubleClick={()=>handleSetData(data)} className='cursor-pointer'>{value || "-"}</p>,
		},
		{
			title: "Country",
			dataIndex: ["party", "country"],
			key: "country",
			responsive: ["sm"],
			//render: (value, data) => <p onDoubleClick={()=>handleSetData(data)} className='cursor-pointer'>{value || "-"}</p>,
		},
		{
			title: "Email",
			dataIndex: ["party", "emailId"],
			key: "emailId",
			responsive: ["sm"],
			//render: (value, data) => <p onDoubleClick={()=>handleSetData(data)} className='cursor-pointer'>{value || "-"}</p>,
		},
		{
			title: "Total Pcs",
			dataIndex: "totalPcs",
			key: "totalPcs",
			responsive: ["sm"],
			//render: (value, data) => <p onDoubleClick={()=>handleSetData(data)} className='cursor-pointer'>{value || "-"}</p>,
		},
		{
			title: "Remarks",
			dataIndex: "remarks",
			key: "remarks",
			responsive: ["sm"],
			//render: (value, data) => <p onDoubleClick={()=>handleSetData(data)} className='cursor-pointer'>{value || "-"}</p>,
		},
		{
			title: "Actions",
			key: "Actions",
			dataIndex: "action",
			width: "auto",
			render: (_, data, index) => {
				const items = [
          {
						label: "Edit",
						onClick: () => handleDuplicate(data),
					}
				];

				return renderActions(items);
			},
		},
	];

    const nestedColumn = [
        {
            title: "Main Item",
			dataIndex: ["mainitem", "name"],
			key: "mainitem",
            width: "auto"
        },
        {
            title: "Sub-Category",
			dataIndex: ["subcategory", "name"],
			key: "subcategory",
            width: "auto"
        },
        {
            title: "Color",
			dataIndex: ["color", "color_name"],
			key: "color",
            width: "auto"
        },
        {
            title: "Size 85",
			dataIndex: "size85",
			key: "size85",
            width: "auto"
        },
        {
            title: "Size 90",
			dataIndex: "size90",
			key: "size90",
            width: "auto"
        },
        {
            title: "Size 95",
			dataIndex: "size95",
			key: "size95",
            width: "auto"
        },
        {
            title: "Size 100",
			dataIndex: "size100",
			key: "size100",
            width: "auto"
        },
        {
            title: "Size 105",
			dataIndex: "size105",
			key: "size105",
            width: "auto"
        },
        {
            title: "Size 110",
			dataIndex: "size110",
			key: "size110",
            width: "auto"
        },
        {
            title: "Size 115",
			dataIndex: "size115",
			key: "size115",
            width: "auto"
        },
        {
            title: "Size 120",
			dataIndex: "size120",
			key: "size120",
            width: "auto"
        },
        {
            title: "Remarks",
			dataIndex: "remarks",
			key: "remarks",
            width: "auto"
        }
    ]

	useHotkeys('alt+n', () => window.open('/transport/add', '_blank'))

  return (
    <>
    <Helmet>
	<title>Sales</title>
	</Helmet>
    <div className='flex justify-between items-center mb-2'>
        <h1 className='text-2xl'>Sales List</h1>
		<div className='flex gap-5'>
			<Tooltip title="Reload">
		<Button
			type='default' size='small'
			loading={loading}
			disabled={loading}
			onClick={()=>getListData(1, 10)}
		>
			<TfiReload/>
		</Button>
		</Tooltip>
		
		{/* <Button
			type='default' size='small'
			loading={exporting}
			disabled={exporting}
			onClick={()=>setShow(true)}
		>
			Import Excel
		</Button> */}
		{/* <Button
			onClick={handleImport}
			loading={importing}
			disabled={exporting}
			type='default' size='small'
		>
			Export Excel
		</Button> */}
		<Link to="/working/sales-order/add" target='_blank'>
        <Button type='primary' size='small' className='w-[80px]'>Add New</Button>
		</Link>
		</div>
		
    </div>
    <Table
    dataSource={list}
    columns={columns}
    rowKey={item => item?.id}
    expandable={{
        expandedRowRender: (record) => (
          <>
          <p className='mb-0 font-bold text-primaryBase'>Sub Details:</p>
          <Table
          dataSource={record?.saleOrderSubForm}
          columns={nestedColumn}
          pagination={false}
          bordered
          />
          </>
        ),
    }}
    pagination={false}
    loading={loading}
    scroll={{y:400}}
    bordered
	style={{minHeight:"360px"}}
    />
	{list?.length ? (
		<div className='flex justify-end'>
		<Pagination
			pageSize={pageSize}
			total={totalElements}
			current={currentPage}
			onChange={handlePageChange}
		/>
		</div>
	) : null}
	<div className='mb-20'></div>

	<Modal
		title="Upload Excel File"
		open={show}
		onOk={handleExport}
		onCancel={()=>setShow(false)}
	>
		<Field
			name="item_excel"
			component={ReduxFormFileField}
			className="mt-1"
			placeholder="Item Image"
			itemname="excel file"
		/>
		<p className="mt-2 text-lg font-bold">{excelFile?.name}</p>
	</Modal>
	<EditSaleOrderForm show={showEdit} setShow={setShowEdit} data={data}/>
    </>
  )
}

export default reduxForm({
	form: masterName,
	enableReinitialize: true,
})(NewSaleOrderReport);
