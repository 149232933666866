import React, {useState} from 'react'
import { Button } from 'antd'
import { useDispatch, useSelector } from "react-redux";
import { AddUserRole, masterSelectors } from 'slices/masterSlice';
import { Helmet } from 'react-helmet';
import MasterRoleForm from './forms/MasterRoleForm';
import ReportRoleForm from './forms/ReportRoleForm';
import WorkingForm from './forms/WorkingForm';
import PdfRoleForm from './forms/PdfRoleForm';

const masterName = "pageacess"
const UserRoles = () => {

  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getAddingUserRoleLoading);

    const [roleName, setRoleName] = useState('')

    const [masterRole, setMasterRole] = useState({
      categories: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      subCategories: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      seasons: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      collections: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      }, 
      mainItems: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      charges: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      }, 
      hsnCode: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      cities: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      }, 
      customerGroup: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      agent: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      }, 
      transport: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      }
    })

    const [reportRole, setReportRole] = useState({
      imageUploadReport: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      fabricReport: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      chalanReport: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      openingStockReport: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      }, 
      katkutReport: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      }
    })

    const [pdfRole, setPdfRole] = useState({
      pdfGenerator: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
    })

    const [workingRole, setWorkingRole] = useState({
      inwardStockEntry: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      salesOrderReport: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      closingStockReport: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      },
      outwardStockEntry: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      }, 
      ledger: {
        main:false,
        createAccess:false,
        deleteAccess:false,
        viewAccess:false,
        editAccess:false
      }
    })

    const [error, setError] = useState("")

    function mapPermissions(obj) {
      const mappedPermissions = {};
      for (const key in obj) {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
              mappedPermissions[key] = mapPermissions(obj[key]);
          } else {
              mappedPermissions[key] = obj[key] ? "1" : "0";
          }
      }
      return mappedPermissions;
  }

    const handleSubmit = async() =>{
      if(roleName === ""){
        return setError("Role Name is Required")
      }else{
        setError("")
      }
      let temp = {...masterRole, ...reportRole, ...pdfRole, ...workingRole}
      let postData = {}
      // for (const key in temp) {
      //   if (temp.hasOwnProperty(key)) {
      //     postData[key] = temp[key] ? "1" : "0";
      //   }
      // }
      postData = mapPermissions(temp);
      postData.role = roleName;
      const result = {
        role: postData.role,
        categories: postData?.categories.main,
        subcategories: postData?.subCategories.main,
        seasons: postData?.seasons.main,
        collections: postData?.collections.main,
        mainItems: postData?.mainItems.main,
        charges: postData?.charges.main,
        hsncode: postData?.hsnCode.main,
        cities: postData?.cities.main,
        customerGroup: postData?.customerGroup.main,
        agent: postData?.agent.main,
        transport: postData?.transport.main,
        imageUploadReport: postData?.imageUploadReport.main,
        fabricReport: postData?.fabricReport.main,
        chalanReport: postData?.chalanReport.main,
        openingStockReport: postData?.openingStockReport.main,
        katkutReport: postData?.katkutReport.main,
        pdfGenerator: postData?.pdfGenerator.main,
        inwardStockEntry: postData?.inwardStockEntry.main,
        salesOrderReport: postData?.salesOrderReport.main,
        closingStockReport: postData?.closingStockReport.main,
        outwardStockEntry: postData?.outwardStockEntry.main,
        ledger: postData?.ledger.main,
        specificRole: Object.keys(postData)?.map((item)=>{
          return {
            deleteAccess: postData[item]?.main !== "1" ? "0" : postData[item].deleteAccess,
            createAccess: postData[item]?.main !== "1" ? "0" : postData[item].createAccess,
            viewAccess: postData[item]?.main !== "1" ? "0" : postData[item].viewAccess,
            editAccess: postData[item]?.main !== "1" ? "0" : postData[item].editAccess,
            moduleName: item
          }
        })
      };

      let res = await dispatch(AddUserRole({masterName, formData:result}))
      if(res?.type === "master/addUserRole/fulfilled"){
       setTimeout(()=>{
        window.location.reload()
       },1000)
      }

    }

  return (
    <>
    <Helmet>
	  <title>User Roles</title>
	  </Helmet>
    <div className='flex justify-between items-center'>
    <h1 className='text-xl'>Add New User Role</h1>
    <Button type='primary' className='bg-primaryBase'
    disabled={loading}
    loading={loading}
    onClick={handleSubmit}
    >Add</Button>
    </div>
    <div className='mt-4'>
    <label>Role Name</label><br></br>
    <input type='text' value={roleName} onChange={(e)=>setRoleName(e.target.value)} className='c-input border'/>
    {error ? <p className='text-redBase'>{error}</p> : null}
    </div>
    <MasterRoleForm masterRole={masterRole} setMasterRole={setMasterRole}/>
    <ReportRoleForm reportRole={reportRole} setReportRole={setReportRole}/>
    <PdfRoleForm pdfRole={pdfRole} setPdfRole={setPdfRole}/>
    <WorkingForm workingRole={workingRole} setWorkingRole={setWorkingRole}/>
    </>
  )
}

export default UserRoles