import { Button } from "antd";

export const renderModalButtons = (onCancel, disabled, isLoading = false) => {
	return (
		<div className="md:mt-5 mt-10">
			<Button
				className="rounded-lg bg-secondaryBase hover:bg-secondaryDark text-white font-semibold"
				type="button"
				onClick={onCancel}
			>
				Cancel
			</Button>
			<Button
				className="rounded-lg bg-primaryBase hover:bg-primaryDark text-white font-semibold ml-3"
				disabled={disabled}
				htmlType="submit"
				loading={isLoading}
			>
				Submit
			</Button>
		</div>
	);
};
