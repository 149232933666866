import React, {useEffect, useState} from 'react'
import { Button, Select, Tooltip } from 'antd'
import { Form, Field  } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { getMasterList, masterSelectors, editMasterList, addMasterList, getMasterDropDownList, getMasterLogsList } from 'slices/masterSlice'
import TextareaFieldV2 from 'utils/v2/TextareFieldV2';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import Activity from '../common/Activity';
import { useHotkeys } from 'react-hotkeys-hook'

const masterName = "agent"
const cityDropdownMaster = "city_dropdown"
const AgentDetails = ({data, setData, type, setType}) => {

  const [isEnabled, setIsEnabled] = useState(true)
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const cityDropdown = useSelector(masterSelectors?.getCityDropdown);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);

  const logs = useSelector(masterSelectors?.getAgentLogs);

  useEffect(()=>{
    if(data?.id){
      dispatch(getMasterLogsList({masterName:"Agent", id: data?.id}))
      setIsEnabled(true)
    }
  },[data])

  useEffect(()=>{
    dispatch(getMasterDropDownList({masterName:cityDropdownMaster}))
  },[])

  const onSubmit = async values => {
    if(values?.agentContactPerson === undefined || values?.agentContactPerson?.length === 0){
        return alert("Please add Agent Contact Persons.")
    }
    let res = null;
    if(type === 'new'){
      res = await dispatch(
        addMasterList({
          masterName,
          formData:values,
        })
      );
    }else{
      res = await dispatch(
        editMasterList({
          masterName,
          formData:values,
        })
      );
    }
      if(res?.type === "master/edit/fulfilled" || res?.type === "master/add/fulfilled"){
        setData({})
        dispatch(getMasterList({
          masterName
        }))
      }
    }

    const handleClear = () =>{
      setData({})
      setType("update")
    }

      const required = value => (value ? undefined : 'Required')
      useHotkeys('alt+e', () => setIsEnabled(false))
    useHotkeys('alt+l', () => handleClear())
    useHotkeys('alt+s', (event) => {
      event.preventDefault();
      document.getElementById('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    });

  return (
    <>
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators
      }}
      initialValues={data}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id='form'>
    <>
   <div className='flex justify-between items-center mt-5'>

   <p className='font-bold mb-3'>Record Details:</p>
   <div className='flex gap-4'>
   <Button
   disabled={isEnabled}
   type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
   onClick={handleClear}
   >{type === "new" ? "Cancel" : "Clear"}</Button>
   
   <Button
   disabled={isEnabled || loading}
   type="primary" size="small" className='bg-redBase' htmlType='submit'
   loading={loading}
   >{type === "new" ? "Add As New" : "Update"}</Button>
   
   </div>
   </div>

   <div className='flex gap-5'>
        <div className=''>
            <Field name="agentName" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
              disabled={isEnabled}
              input={input} meta={meta} type='text' label="Name"/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="telephone" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
              disabled={isEnabled}
              input={input} meta={meta} type='number' label="Telephone No."/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="emailId" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
              disabled={isEnabled}
              input={input} meta={meta} type='text' label="Email"/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="gstin" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
              disabled={isEnabled}
              input={input} meta={meta} type='text' label="GSTIN"/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="pan" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
              disabled={isEnabled}
              input={input} meta={meta} type='text' label="PAN"/>
            )}
          </Field>
        </div>
        <div className=''>
        <Field name="mobile" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
              disabled={isEnabled}
              input={input} meta={meta} type='number' label="Mobile No."/>
            )}
          </Field>
        </div>
    </div>
    <div className='flex gap-5 mt-4'>
    <div className=''>
        <Field name="addressLine1" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
              disabled={isEnabled}
              input={input} meta={meta} type='text' label="Address" style='w-[385px]'/>
            )}
          </Field>
        </div> 

        <div>
          <label>City</label><br></br>
          <Field name="cityId" validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
        disabled={isEnabled}
          style={{width:"192px", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="City"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:cityDropdownMaster, query:{name:e}}))}
					options={cityDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div className=''>
        <Field name="country" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
              disabled={isEnabled}
              input={input} meta={meta} type='text' label="Country"/>
            )}
          </Field>
        </div> 
        <div className=''>
        <Field name="pincode" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
              disabled={isEnabled}
              input={input} meta={meta} type='number' label="Pincode"/>
            )}
          </Field>
        </div> 

    </div>
    <div className='flex my-4'>
    <div className='w-full'>
        <Field name="notes">
            {({ input, meta }) => (
              <TextareaFieldV2
              disabled={isEnabled}
              input={input} meta={meta} label="Notes" style='w-[805px]'/>
            )}
          </Field>
        </div>
    </div>
    <div className='w-full my-4'>
    <FieldArray name="agentContactPerson">
          {({ fields }) => (
            <div>
                <div className='flex justify-between'>
                    <p className='font-bold'>Contact Persons : </p>
                <Button
disabled={isEnabled}

                type="primary"
                htmlType='button'
                className='bg-primaryBase px-5'
                size='small'
                onClick={() => fields.push({})}
              >
                Add Contact Person
              </Button>
                </div>
              {fields.map((name, index) => (
                <div key={name} className='flex gap-5 mb-3 items-center'>
                  <div>
                  <Field name={`${name}.name`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2
                    disabled={isEnabled}
                    input={input} meta={meta} type='text' label="Name"/>
                    )}
                </Field>
                  </div>
                  <div>
                  <Field name={`${name}.designation`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2
                    disabled={isEnabled}
                    input={input} meta={meta} type='text' label="Designation"/>
                    )}
                </Field>
                  </div>
                  <div>
                  <Field name={`${name}.department`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2
                    disabled={isEnabled}
                    input={input} meta={meta} type='text' label="Department"/>
                    )}
                </Field>
                  </div>
                  <div>
                  <Field name={`${name}.mobileNo`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2
                    disabled={isEnabled}
                    input={input} meta={meta} type='number' label="Mobile No."/>
                    )}
                </Field>
                  </div>
                  <div>
                  <Field name={`${name}.emailId`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2
                    disabled={isEnabled}
                    input={input} meta={meta} type='text' label="Email"/>
                    )}
                </Field>
                  </div>
                  <Button
                  disabled={isEnabled}
                  type="primary" htmlType='button' size='small' className='bg-redBase mt-5' onClick={() => fields.remove(index)}>
                    Remove
                  </Button>
                </div>
              ))}
              
            </div>
          )}
        </FieldArray>
    </div>
   
    </>
    </form>
      )}/>
      {logs?.length ?
      <Activity logs={logs}/>
    :null  
    }
    </>
  )
}

export default AgentDetails