import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { root as rootSaga } from "./sagas";

import { configureStore } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import masterReducer from "slices/masterSlice";
import userReducer from "slices/userSlice";
import { notificationsManager } from "./middlewares/notifications.middleware";

const persistConfig = {
	key: "root",
	storage,
};

export const sagaMiddleware = createSagaMiddleware();

const persistedUserReducer = persistReducer(persistConfig, userReducer);

const middlewares = [thunk, sagaMiddleware, notificationsManager];

const store = configureStore({
	reducer: {
		userDetails: persistedUserReducer,
		masterDetails: masterReducer,
		form: formReducer,
	},
	middleware: middlewares,
	devTools: true,
});

let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
export { persistor, store };
