import React from 'react'
import { Steps } from 'antd';


const Activity = ({logs}) => {
  return (
    <div className='mt-5'>
        <p className='text-lg font-bold mb-4'>Activity</p>
        <Steps
      progressDot
      current={1}
      direction="vertical"
      items={logs?.map((item)=> {return{title: item?.logs, description: item?.date}})}
    />
    </div>
  )
}

export default Activity