import { Modal } from "antd";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { GetDispatchPrintData, masterSelectors } from "slices/masterSlice";
import { useEffect } from "react";

const OutwardPrintV2 = ({
	modalPrint,
	revertSelections,
	setModalPrint,
	setDispatchRowSelections,
	setExpandedDispatchRowSelections,
	setRevertSelections,
	setStatusDispatch,
	setStatusPacking,
    selectedId,
}) => {

    const dispatch = useDispatch();

	const isFetching = useSelector(
		masterSelectors?.getDispatchPrintLoading
	);
	const printData = useSelector(
		masterSelectors?.getDispatchPrintData
	);

    console.log('selected id', selectedId)
    console.log('pdt', printData)

    useEffect(()=>{
        if(selectedId?.length){
            dispatch(GetDispatchPrintData({masterName:"DispatchStock/print", formData:{dispatchOverviewId:selectedId} }))
        }
    },[selectedId])

    // console.log('revert selection', revertSelections)
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1); // Initialize total pages based on your data
	const [confirmLoading, setConfirmLoading] = useState(false);
	// console.log(revertSelections);


	const groupedPartyData = Object.values(revertSelections).reduce(
		(acc, item) => {
			if (!acc[item.partyName]) {
				acc[item.partyName] = [];
			}
			acc[item.partyName].push(item);
			return acc;
		},
		{}
	);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onBeforePrint: () => {
			setCurrentPage(currentPage);
		},
	});
	const handleSubmit = () => {
		const totalPagesCount = Object.keys(groupedPartyData).length;
		handlePrint();

		setConfirmLoading(true);
		setTimeout(() => {
			setConfirmLoading(false);
			setModalPrint(false);
			setExpandedDispatchRowSelections({});
			setDispatchRowSelections({});
			setStatusDispatch([]);
			setStatusPacking([]);
			setRevertSelections({});
			setTotalPages(totalPagesCount);
		}, 500);
	};

	console.log('total pages', totalPages)
	const calculateTotals = data => {
		return data.reduce(
			(acc, item) => {
				acc.size85 += item.size34 || 0;
				acc.size90 += item.size36 || 0;
				acc.size95 += item.size38 || 0;
				acc.size100 += item.size40 || 0;
				acc.size105 += item.size42 || 0;
				acc.size110 += item.size44 || 0;
				acc.size115 += item.size46 || 0;
				acc.size120 += item.size48 || 0;
				acc.total += item.total || 0;
				return acc;
			},
			{
				size85: 0,
				size90: 0,
				size95: 0,
				size100: 0,
				size105: 0,
				size110: 0,
				size115: 0,
				size120: 0,
				total: 0,
			}
		);
	};
const calculateSubTotals = (data, item, index) =>{
		let temp = data?.filter((x)=> x?.mainItem === item?.mainItem)
		let tt = calculateTotals(temp)
	return <>
<tr className="table1" key={index}>
	<td className="table1 text-center">
		{item.outward_date || ""}
	</td>
	<td className="table1 text-center">
		{item.outward_no || ""}
	</td>
	<td className="table1 text-center">
		{`${item.mainItem}-${item.color}` || ""}
	</td>
	<td className="table1 text-center">{item.size34 || 0}</td>
	<td className="table1 text-center">{item.size36 || 0}</td>
	<td className="table1 text-center">{item.size38 || 0}</td>
	<td className="table1 text-center">{item.size40 || 0}</td>
	<td className="table1 text-center">{item.size42 || 0}</td>
	<td className="table1 text-center">{item.size44 || 0}</td>
	<td className="table1 text-center">{item.size46 || 0}</td>
	<td className="table1 text-center">{item.size48 || 0}</td>
	<td className="table1 text-center">{item.total || 0}</td>
</tr>
	<tr className="table1" key={index}>
	<td colSpan="3" className="table1 font-bold text-center">
		Total
	</td>
	<td className="table1 text-center font-bold">{tt.size85 || 0}</td>
	<td className="table1 text-center font-bold">{tt.size90 || 0}</td>
	<td className="table1 text-center font-bold">{tt.size95 || 0}</td>
	<td className="table1 text-center font-bold">{tt.size100 || 0}</td>
	<td className="table1 text-center font-bold">{tt.size105 || 0}</td>
	<td className="table1 text-center font-bold">{tt.size110 || 0}</td>
	<td className="table1 text-center font-bold">{tt.size115 || 0}</td>
	<td className="table1 text-center font-bold">{tt.size120 || 0}</td>
	<td className="table1 text-center font-bold">{tt.total || 0}</td>
</tr>
</>
	}
	return (
		<Modal
			title="Print"
			open={modalPrint}
			onOk={handleSubmit}
			data={revertSelections}
			onCancel={() => setModalPrint(false)}
			width={1500}
			confirmLoading={confirmLoading}
		>
			<p>Outward Dispatch Print</p>
			<hr></hr>
			<div className="p-5" ref={componentRef}>
				{Object.keys(groupedPartyData).map((partyName, index) => {
					const partyData = groupedPartyData[partyName];
					const totals = calculateTotals(partyData);
					return (
						<div key={partyName} className="mb-4 p-5">
							<table className="table1">
								<thead className="table1">
								<tr className="table1">
									<th colSpan="" style={{ width: "220px", paddingLeft:"20px" }}>{partyName}</th>
									<th colSpan="" style={{ width: "220px" }}>Dispatch Date: {partyData[index]?.dispatch_date}</th>
									<th colSpan="" style={{ width: "220px" }}>Dispatch No: {partyData[index]?.dispatch_no}</th>
									<th colSpan=""><div className="">
							<h3 style={{width:"80px"}} className="text-center">Comment: </h3>
							<input
								type="text"
								name="comment"
								id="comment"
								class="w-full border-0 border-b-2 text-center py-1.5  text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							style={{width:"300px"}}
							/>
							</div></th>
								</tr>
									<tr className="table1">
										<th
											className="table1 text-center"
											style={{ width: "100px" }}
										>
											OUTWARD DATE
										</th>
										<th
											className="table1 text-center"
											style={{ width: "180px" }}
										>
											OUTWARD NO.
										</th>
										<th
											className="table1 text-center"
											style={{ width: "180px" }}
										>
											COLOR
										</th>
										<th
											className="table1 text-center"
											// style={{ width: "20px" }}
										>
											85
										</th>
										<th
											className="table1 text-center"
											// style={{ width: "20px" }}
										>
											90
										</th>
										<th
											className="table1 text-center"
											// style={{ width: "20px" }}
										>
											95
										</th>
										<th
											className="table1 text-center"
											// style={{ width: "25px" }}
										>
											100
										</th>
										<th
											className="table1 text-center"
											// style={{ width: "25px" }}
										>
											105
										</th>
										<th
											className="table1 text-center"
											// style={{ width: "25px" }}
										>
											110
										</th>
										<th
											className="table1 text-center"
											// style={{ width: "25px" }}
										>
											115
										</th>
										<th
											className="table1 text-center"
											// style={{ width: "25px" }}
										>
											120
										</th>
										<th
											className="table1 text-center"
											// style={{ width: "42px" }}
										>
											TOTAL
										</th>
									</tr>
								</thead>
								<tbody>
									{partyData?.sort((a, b) => {
										if (a?.mainItem < b?.mainItem) {
											return -1;
										}
										if (a?.mainItem > b?.mainItem) {
											return 1;
										}
										return 0;
										})?.map((item, index) => (
										item?.mainItem !== partyData?.[index + 1]?.mainItem
											?
											calculateSubTotals(partyData, item, index)
											
											:
											<tr className="table1" key={index}>
											<td className="table1 text-center">
												{item.outward_date || ""}
											</td>
											<td className="table1 text-center"
											style={{width:"180px"}}
											>
												{item.outward_no || ""}
											</td>
											<td className="table1 text-center">
												{`${item.mainItem}-${item.color}` || ""}
											</td>
											<td className="table1 text-center">{item.size34 || 0}</td>
											<td className="table1 text-center">{item.size36 || 0}</td>
											<td className="table1 text-center">{item.size38 || 0}</td>
											<td className="table1 text-center">{item.size40 || 0}</td>
											<td className="table1 text-center">{item.size42 || 0}</td>
											<td className="table1 text-center">{item.size44 || 0}</td>
											<td className="table1 text-center">{item.size46 || 0}</td>
											<td className="table1 text-center">{item.size48 || 0}</td>
											<td className="table1 text-center">{item.total || 0}</td>
										</tr>
									))}
									<tr>
										<td colSpan="3" style={{border:'1px solid black'}}>{" "}</td>
									</tr>
									<tr>
										<td colSpan="3" style={{ fontWeight: "bold", textAlign:"center" }}>
											Final Total
										</td>
										<td className="table1 text-center">{totals.size85}</td>
										<td className="table1 text-center">{totals.size90}</td>
										<td className="table1 text-center">{totals.size95}</td>
										<td className="table1 text-center">{totals.size100}</td>
										<td className="table1 text-center">{totals.size105}</td>
										<td className="table1 text-center">{totals.size110}</td>
										<td className="table1 text-center">{totals.size115}</td>
										<td className="table1 text-center">{totals.size120}</td>
										<td className="table1 text-center">{totals.total}</td>
									</tr>
									{/* <tr>
										<td colSpan="3" style={{ fontWeight: "bold" }}></td>
										<td className="table1 text-center"></td>
										<td className="table1 text-center"></td>
										<td className="table1 text-center"></td>
										<td className="table1 text-center"></td>

										<td className="table1 text-center">
											{totals.size85 +
												totals.size90 +
												totals.size95 +
												totals.size100 +
												totals.size105}
										</td>
										<td className="table1 text-center">{totals.size110}</td>
										<td className="table1 text-center">{totals.size115}</td>
										<td className="table1 text-center">{totals.size120}</td>
										<td className="table1 text-center">{totals.total}</td>
									</tr> */}
								</tbody>
							</table>
						</div>
					);
				})}

			{/* <div style={{ position: 'absolute', bottom: 5, right: 5}}>
				Page {currentPage} of {totalPages}
			</div> */}
			<div style={{position: 'absolute', top: 0, right: 5, height:`${totalPages *768}px`}}>
				{Array.from({length:totalPages}).map((item, index)=>(
					<div style={{height:"790px"}} key={index}>
					<p className="rounded-md p-1" style={{background:'lightgrey'}}>Page {index + 1} of {totalPages}</p>
					</div>
				))}
			</div>
			</div>
			
		</Modal>
	);
};

export default OutwardPrintV2;
