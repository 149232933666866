import { Pagination, Table } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import TableOptions from "components/TableOptions";

import useMasterLogic from "customHooks/useMasterLogic";
import useSearchFilter from "customHooks/useSearchFilter";
import { Helmet } from "react-helmet";
import { getFormMeta, getFormValues } from "redux-form";
import { getMasterList, masterSelectors } from "slices/masterSlice";
import { renderActions } from "utils/renderActions";
import { renderMobileColumn } from "utils/renderMobileColumn";

export const masterName = "collection";

const Collections = () => {
	const { status } = useParams();
	const dispatch = useDispatch();
	const collectionsList = useSelector(
		masterSelectors?.getCollectionsMasterList
	);

	const { pageSize, totalElements, currentPage } = useSelector(
		masterSelectors?.getCollectionsPagination
	);
	const meta = useSelector(getFormMeta(masterName));
	const formValues = useSelector(getFormValues(masterName));
	//console.log(meta);

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);

	const { getColumnSearchProps, tableFilters } = useSearchFilter();

	const getCollectionsList = useCallback(
		(pageNum = 1, itemsPerPage = 10) => {
			dispatch(
				getMasterList({
					masterName,
					query: {
						page: pageNum,
						pageSize: itemsPerPage,
						is_active: 1,
						status,
						...tableFilters,
					},
				})
			);
		},
		[status, tableFilters, dispatch]
	);

	const { handlePageChange, handleEditMaster, handleAddMaster } =
		useMasterLogic(getCollectionsList, masterName);

	const expandedRowRender = record => {
		const columns = [
			{
				title: "Main Item",
				//dataIndex: "itemList",
				key: "itemList",
				responsive: ["sm"],
				render: value => value || "-",
			},
		];
		return (
			<Table
				columns={columns}
				dataSource={record?.itemList?.map(item => item)}
				//rowKey={seasonList => seasonList?.id}
				pagination={false}
				bordered
			/>
		);
	};

	const columns = [
		{
			title: "Collection",
			responsive: ["xs"],
			render: data => {
				const primaryText = data?.collection || "-";
				const secondaryText = data?.mainItemList || "-";
				return renderMobileColumn(primaryText, secondaryText);
			},
			...getColumnSearchProps("Collection", "collection_name"),
		},
		{
			title: "Collection Name",
			dataIndex: "collection",
			key: "collection",
			responsive: ["sm"],
			width: "45%",
			render: value => value || "-",
			...getColumnSearchProps("Collection", "collection_name"),
		},
		// {
		// 	title: "Main Item",
		// 	dataIndex: "mainItemList",
		// 	responsive: ["sm"],
		// 	key: "mainItemList",
		// 	render: value => value || "-",
		// },
		{
			title: "Actions",
			key: "Actions",
			dataIndex: "action",
			width: "45%",
			render: (_, data) => {
				const items = [
					{
						label: "Edit",
						onClick: () => handleEditMaster(data?.id),
					},
				];

				return renderActions(items);
			},
		},
	];

	return (
		<>
			<Helmet>
				<title>Collections</title>
			</Helmet>
			<TableOptions masterName={masterName} handleAddMaster={handleAddMaster} />
			<Table
				dataSource={collectionsList}
				columns={columns}
				expandable={{
					expandedRowRender,
					rowExpandable: record => !!record.id,
				}}
				rowKey={collectionsList => collectionsList?.id}
				pagination={false}
				loading={isFetchingMasterList}
				bordered
			/>
			<div className="flex justify-center mt-5">
				{collectionsList?.length ? (
					<Pagination
						pageSize={pageSize}
						total={totalElements}
						current={currentPage}
						onChange={handlePageChange}
					/>
				) : null}
			</div>
		</>
	);
};

export default Collections;
