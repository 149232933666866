import { put, takeLatest } from "redux-saga/effects";

import { addNotification } from "slices/notificationSlices";
import { clearUserDetails } from "slices/userSlice";

function* errorHandler({ payload }) {
	if (payload?.response?.status === 400) {
		yield put(
			addNotification({
				message: payload?.response?.data?.message || "Bad Request",
				type: "error",
			})
		);
	} else if (payload?.response?.status === 401) {
		yield put(clearUserDetails());
		yield put(
			addNotification({
				message: "Unauthorized",
				type: "error",
			})
		);
	} else {
		yield put(
			addNotification({
				message:
					payload?.response?.data?.message || "Please try again after sometime",
				type: "error",
			})
		);
	}
}

export function* watchApiError() {
	yield takeLatest(function (action) {
		if (action?.type?.includes("rejected")) return action;
	}, errorHandler);
}

function* successHandler({ payload }) {
	yield put(
		addNotification({
			message: payload?.data?.message || "successfully added",
			type: "success",
		})
	);
}

export function* watchApiSuccess() {
	yield takeLatest(function (action) {
		if (
			action?.type?.includes("add/fulfilled") ||
			action?.type?.includes("edit/fulfilled") ||
			action?.type?.includes("sync/fulfilled") ||
			action?.type?.includes("fabricSync/fulfilled") ||
			action?.type?.includes("chalanSync/fulfilled") ||
			action?.type?.includes("lotSetSync/fulfilled") ||
			action?.type?.includes("readyStockSync/fulfilled") ||
			action?.type?.includes("/editmain/fulfilled") ||
			action?.type?.includes("/excelfile/fulfilled") ||
			action?.type?.includes("/addReadyStockEntry/fulfilled") ||
			action?.type?.includes("dispatchOutwardStockEntry/fulfilled") ||
			action?.type?.includes("dispatchStockEntry/fulfilled") ||
			action?.type?.includes("dispatchStockRevert/fulfilled") ||
			action?.type?.includes("editOutwardStockEntry/fulfilled") ||
			action?.type?.includes("ReadyStock/fulfilled") ||
			action?.type?.includes("addUserRole/fulfilled")
		)
			return action;
	}, successHandler);
}
