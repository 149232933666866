import React, {useEffect, useState} from 'react'
import { Button } from 'antd'
import { Form, Field  } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { getMasterList, masterSelectors, editMasterList, addMasterList, getMasterLogsList, getMasterDropDownList } from 'slices/masterSlice'
import Activity from '../common/Activity';
import { useHotkeys } from 'react-hotkeys-hook'
import { ReduxFormSelectField2 } from 'utils/ReduxFormSelectField';



const masterName = "customergroup"
const customerDropdownMaster = "customer_dropdown"
const CustomerGroupDetails = ({data, setData, type, setType}) => {

  const [isEnabled, setIsEnabled] = useState(true)
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const customerDropdown = useSelector(masterSelectors?.getCustomerDropdown);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);


  const logs = useSelector(masterSelectors?.getCustomerGroupLogs);

  useEffect(()=>{
    if(data?.id){
      dispatch(getMasterLogsList({masterName:"CustomerGroup", id: data?.id}))
      dispatch(getMasterDropDownList({masterName:customerDropdownMaster}))
      setIsEnabled(true)
    }
  },[data])

  const onSubmit = async values => {
    
    let customerId = [];
      values?.customerId?.map((item)=>{
          customerId.push(item?.value)
      })
      let formData= {
        customerId,
        groupName: values?.groupName
      }
      if(data?.id){
        formData.id = data?.id
      }


    let res = null;
    if(type === 'new'){
      res = await dispatch(
        addMasterList({
          masterName,
          formData,
        })
      );
    }else{
      res = await dispatch(
        editMasterList({
          masterName,
          formData,
        })
      );
    }
      if(res?.type === "master/edit/fulfilled" || res?.type === "master/add/fulfilled"){
        setData({})
        dispatch(getMasterList({
          masterName
        }))
      }
    }

    const handleClear = () =>{
      setData({})
      setType("update")
    }

      const required = value => (value ? undefined : 'Required')
    useHotkeys('alt+e', () => setIsEnabled(false))
    useHotkeys('alt+l', () => handleClear())
    useHotkeys('alt+s', (event) => {
      event.preventDefault();
      document.getElementById('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    });

  return (
    <>
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id='form'>
    <>
   <div className='flex justify-between items-center mt-5'>

   <p className='font-bold mb-3'>Record Details:</p>
   <div className='flex gap-4'>
   <Button
   disabled={isEnabled}
   type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
   onClick={handleClear}
   >{type === "new" ? "Cancel" : "Clear"}</Button>
   
   <Button
   disabled={isEnabled || loading}
   type="primary" size="small" className='bg-redBase' htmlType='submit'
   loading={loading}
   >{type === "new" ? "Add As New" : "Update"}</Button>
   
   </div>
   </div>
    <div className='flex gap-5'>
        <div className=''>
            <Field name="groupName" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Group name"/>
            )}
          </Field>
        </div>
        <div>
        <div>
    <Field
    component={ReduxFormSelectField2}
    validate={required}
    label="Customers"
    name="customerId"
    placeholder="Select customers"
    options={customerDropdown}
    isLoading={loadingDropdown}
    isMulti
  />
</div>
        </div>
        
    </div>

    </>
    </form>
      )}/>
      {logs?.length ?
      <Activity logs={logs}/>
    :null  
    }
    </>
  )
}

export default CustomerGroupDetails