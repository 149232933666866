import { notification } from "antd";

export function notificationsManager() {
	return next => action => {
		if (action?.type === "addNotification") {
			const { message, type } = action?.payload;
			notification.open({
				message: message,
				className: `!w-auto !text-md ${
					type === "error" ? "!bg-redBase" : "!bg-greenBase"
				}`,
			});
		}
		return next(action);
	};
}
