import { Button, Checkbox, Modal, Pagination, Segmented, Table } from "antd";
import useMasterLogic from "customHooks/useMasterLogic";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import {
  dispatchOutwardStockEntry,
  dispatchStockEntry,
  dispatchStockRevert,
  getMasterList,
  getOutwardEntryDetails,
  masterSelectors,
} from "slices/masterSlice";
import { renderActions } from "utils/renderActions";
import OutwardFilters from "./OutwardFilters";
import OutwardPrint from "./OutwardPrint";
import DispatchPagination from "./DispatchPagination";
import DispatchStock from "./DispatchStock";
import OutwardPrintV2 from "./OutwardPrintV2";

export const masterName = "outwardStock";
const OutwardStockEntry = () => {
  const dispatch = useDispatch();

  const outwardStockListMain = useSelector(
    masterSelectors?.getOutwardStockEntryList
  );

  const dispatchStockListMain = useSelector(
    masterSelectors?.getDispatchStockList
  );

  const { pageSize, totalElements, currentPage } = useSelector(
    masterSelectors?.getOutwardStockEntryPagination
  );

  const isFetchingMasterList = useSelector(
    masterSelectors?.getIsFetchingMasterList
  );

  const isUpdatingMasterList = useSelector(
    masterSelectors?.getIsUpdatingMasterList
  );

  const [outwardStockList, setOutwardStockList] = useState(
    useSelector(masterSelectors?.getOutwardStockEntryList)
  );

  useEffect(() => {
    setOutwardStockList(outwardStockListMain);
  }, [outwardStockListMain]);

  const [dispatchStockList, setDispatchStockList] = useState(
    useSelector(masterSelectors?.getDispatchStockList)
  );

  useEffect(() => {
    setDispatchStockList(dispatchStockListMain);
  }, [dispatchStockListMain]);

  const [val, setVal] = useState("Outward");

  const status = outwardStockList?.length
    ? Array.from(new Set(outwardStockList.map((item) => item.recordStatus)))
    : [];

  const statusFilters = status.map((status) => ({
    text: status,
    value: status,
  }));

  const party = dispatchStockList?.length
    ? Array.from(new Set(dispatchStockList.map((item) => item.partyName)))
    : [];

  const partyFilters = party.map((party) => ({
    text: party,
    value: party,
  }));

  const getOutwardStockList = useCallback(
    (pageNum = 1, itemsPerPage = 50) => {
      const val = {
        startDate: "",
        endDate: "",
        mainItem: [],
        color: [],
        outwardno: [],
        party: [],
      };
      dispatch(
        getOutwardEntryDetails({
          masterName,
          formdata: val,
          query: {
            page: pageNum,
            pageSize: itemsPerPage,
          },
        })
      );
    },
    [dispatch]
  );

  const { handleEditMasterOutward, handlePageChange } = useMasterLogic(
    getOutwardStockList,
    masterName,
    50
  );

  const stock = outwardStockList?.map((item) => item?.stock);

  const [selectAllMain, setSelectAllMain] = useState(false);
  const [expandedRowSelections, setExpandedRowSelections] = useState({});
  const [mainRowSelections, setMainRowSelections] = useState({});

  const handleSelectAllMainChange = useCallback(
    (e) => {
      const checked = e.target.checked;

      setSelectAllMain(checked);

      const updatedMainSelections = {};
      const updatedExpandedSelections = { ...expandedRowSelections };

      outwardStockList.forEach((item) => {
        updatedMainSelections[item.id] = checked;

        if (checked) {
          item.stock.forEach((stockItem) => {
            updatedExpandedSelections[stockItem.id] = stockItem;
          });
        } else {
          item.stock.forEach((stockItem) => {
            delete updatedExpandedSelections[stockItem.id];
          });
        }
      });

      setMainRowSelections(updatedMainSelections);
      setExpandedRowSelections(updatedExpandedSelections);
    },
    [outwardStockList, expandedRowSelections]
  );

  const handleExpandedRowSelectChange = useCallback(
    (record, e) => {
      const { id } = record;
      const checked = e.target.checked;

      const updatedExpandedSelections = { ...expandedRowSelections };
      const updatedMainSelections = { ...mainRowSelections };

      if (checked) {
        updatedExpandedSelections[id] = record;

        const mainRowId1 = outwardStockList?.length
          ? outwardStockList.find((item) =>
              item.stock.some((stockItem) => stockItem.id === id)
            ).id
          : null;
        const allExpandedSelected = outwardStockList?.length
          ? outwardStockList
              .find((item) => item.id === mainRowId1)
              .stock.every(
                (expandedItem) => updatedExpandedSelections[expandedItem.id]
              )
          : null;

        updatedMainSelections[mainRowId1] = allExpandedSelected;
      } else {
        delete updatedExpandedSelections[id];

        const mainRowId = outwardStockList.find((item) =>
          item.stock.some((stockItem) => stockItem.id === id)
        ).id;
        updatedMainSelections[mainRowId] = false;
        setSelectAllMain(false);
      }

      setExpandedRowSelections(updatedExpandedSelections);
      setMainRowSelections(updatedMainSelections);
    },
    [expandedRowSelections, mainRowSelections, outwardStockList]
  );

  const handleMainRowSelectChange = useCallback(
    (record, e) => {
      const { id } = record;
      const checked = e.target.checked;

      const updatedMainSelections = { ...mainRowSelections };
      const updatedExpandedSelections = { ...expandedRowSelections };

      updatedMainSelections[id] = checked;

      if (checked) {
        record.stock.forEach((item) => {
          updatedExpandedSelections[item.id] = item;
        });
      } else {
        record.stock.forEach((item) => {
          delete updatedExpandedSelections[item.id];
        });

        setSelectAllMain(false);
      }

      setMainRowSelections(updatedMainSelections);
      setExpandedRowSelections(updatedExpandedSelections);
    },
    [mainRowSelections, expandedRowSelections]
  );

  const arr = Object.values(expandedRowSelections);

  let dispatchArray = arr.filter((obj) => obj.recordStatus !== "Dispatched");

  const [selectedRowKeys, setSelectedRowKeys] = useState({});
  const [selectedRowDispatch, setSelectedRowDispatch] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [ids, setIds] = useState();
  const [actionValue, setActionVaue] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);

  // const handleConfirm = () => {
  //   setIsLoading(true);
  //   setIsOpen(false);

  //   dispatch(
  //     dispatchOutwardStockEntry({
  //       formData: { dispatch: dispatchArray },
  //     })
  //   );

  //   if (!isUpdatingMasterList) {
  //     setTimeout(() => {
  //       setIsLoading(false);
  //       window.location.reload();
  //     }, 2000);
  //   }
  // };

  // const handleSubmit = () => {
  //   setIsLoading(true);
  //   dispatch(
  //     dispatchOutwardStockEntry({
  //       formData: { dispatch: dispatchArray },
  //     })
  //   );

  //   if (isUpdatingMasterList === false) {
  //     //navigate("/working/outward/outwardStockEntry");
  //     setTimeout(() => {
  //       setIsLoading(false);
  //       window.location.reload();
  //     }, 2000);
  //   }
  // };

  const handleSubmit = () => {
    setIsLoading(true);

    dispatch(
      dispatchOutwardStockEntry({
        formData: { dispatch: dispatchArray },
      })
    );

    if (!isUpdatingMasterList) {
      setTimeout(() => {
        setIsLoading(false);
        window.location.reload();
      }, 2000);
    }
  };

  const handleInitialClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    handleSubmit();
    setShowConfirmation(false);
  };

  const handleCancelDispatch = () => {
    setShowConfirmation(false);
  };
  // const [packingStatus, setPackingStatus] = useState();
  // const [dispatchStatus, setDispatchStatus] = useState();

  // const onSelectChange = useCallback(
  // 	(record, e) => {
  // 		const { id } = record;
  // 		const checked = e.target.checked;

  // 		const updatedMainSelections = { ...selectedRowKeys };

  // 		updatedMainSelections[id] = checked;

  // 		if (checked === true) {
  // 			setPackingStatus(2);
  // 			setDispatchStatus(record?.dispatch);
  // 		} else {
  // 			setPackingStatus(1);
  // 			setDispatchStatus(record?.dispatch);
  // 		}

  // 		setActionVaue("packing");
  // 		setSelectedRowKeys(updatedMainSelections);
  // 		setIds(id);
  // 		setIsModalVisible(true);
  // 	},
  // 	[selectedRowKeys]
  // );

  // const onSelectChangeDispatch = useCallback(
  // 	(record, e) => {
  // 		const { id } = record;
  // 		const checked = e.target.checked;

  // 		const updatedMainSelections = { ...selectedRowDispatch };

  // 		updatedMainSelections[id] = checked;

  // 		if (checked === true) {
  // 			setDispatchStatus(2);
  // 			setPackingStatus(record?.packing);
  // 		} else {
  // 			setDispatchStatus(1);
  // 			setPackingStatus(record?.packing);
  // 		}

  // 		setActionVaue("dispatch");
  // 		setSelectedRowDispatch(updatedMainSelections);
  // 		setIds(id);
  // 		setIsModalVisible(true);
  // 	},
  // 	[selectedRowDispatch]
  // );

  const handleOk = () => {
    setConfirmLoading(true);

    dispatch(
      dispatchStockEntry({
        formData: {
          action: actionValue,
          data: [{ id: ids, status: 2 }],
        },
      })
    );

    setTimeout(() => {
      setIsModalVisible(false);
      setConfirmLoading(false);
      dispatch(
        getMasterList({
          masterName: "DispatchStock",
          //query: { status: 2 },
        })
      );
    }, 2000);
    setSelectedRowKeys({});
    setSelectedRowDispatch({});
    setIds();
    setActionVaue();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRowKeys({});
    setSelectedRowDispatch({});
    setIds();
    setActionVaue();
  };

  const [selectAllDispatch, setSelectAllDispatch] = useState(false);
  const [expandedDispatchRowSelections, setExpandedDispatchRowSelections] =
    useState({});
  const [dispatchRowSelections, setDispatchRowSelections] = useState({});
  const [modal1Visible, setModal1Visible] = useState(false);
  const [confirmLoading1, setConfirmLoading1] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [confirmLoading2, setConfirmLoading2] = useState(false);
  const [statusPacking, setStatusPacking] = useState([]);
  const [statusDispatch, setStatusDispatch] = useState([]);
  const [revertSelections, setRevertSelections] = useState({});
  const [modalPrint, setModalPrint] = useState(false);

  const [revertLoading, setRevertLoading] = useState(false);

  const handleSelectAllDispatchChange = useCallback(
    (e) => {
      const checked = e.target.checked;
      setSelectAllDispatch(checked);

      const updatedMainSelections = {};
      const updatedExpandedSelections = { ...expandedDispatchRowSelections };
      const updatedPackingStatus = [...statusPacking];
      const updatedDispatchStatus = [...statusDispatch];
      const updatedRevertSelections = { ...revertSelections };

      dispatchStockList.forEach((item) => {
        updatedMainSelections[item.id] = checked;

        if (checked) {
          item.dispatchStock.forEach((stockItem) => {
            updatedExpandedSelections[stockItem.id] = stockItem?.id;
            updatedRevertSelections[stockItem.id] = stockItem;
          });
          updatedPackingStatus.push(item?.packing);
          updatedDispatchStatus.push(item?.dispatch);
        } else {
          item.dispatchStock.forEach((stockItem) => {
            delete updatedExpandedSelections[stockItem.id];
            delete updatedRevertSelections[stockItem.id];
          });
          let index = updatedDispatchStatus.indexOf(item?.dispatch);
          if (index !== -1) {
            updatedDispatchStatus.splice(index, 1);
          }
          let index1 = updatedPackingStatus.indexOf(item?.packing);
          if (index1 !== -1) {
            updatedPackingStatus.splice(index1, 1);
          }
        }
      });

      setStatusDispatch(updatedDispatchStatus);
      setStatusPacking(updatedPackingStatus);
      setDispatchRowSelections(updatedMainSelections);
      setExpandedDispatchRowSelections(updatedExpandedSelections);
      setRevertSelections(updatedRevertSelections);
    },
    [
      dispatchStockList,
      expandedDispatchRowSelections,
      statusDispatch,
      statusPacking,
      revertSelections,
    ]
  );

  const handleExpandedDispatchRowSelectChange = useCallback(
    (record, e, record1) => {
      const { id } = record;
      const checked = e.target.checked;

      const updatedExpandedSelections = { ...expandedDispatchRowSelections };
      const updatedMainSelections = { ...dispatchRowSelections };
      const updatedPackingStatus = [...statusPacking];
      const updatedDispatchStatus = [...statusDispatch];
      const updatedRevertSelections = { ...revertSelections };

      if (checked) {
        updatedExpandedSelections[id] = record?.id;
        updatedRevertSelections[id] = record;

        const mainRowId1 = dispatchStockList?.length
          ? dispatchStockList.find((item) =>
              item.dispatchStock.some((stockItem) => stockItem.id === id)
            ).id
          : null;
        const allExpandedSelected = dispatchStockList?.length
          ? dispatchStockList
              .find((item) => item.id === mainRowId1)
              .dispatchStock.every(
                (expandedItem) => updatedExpandedSelections[expandedItem.id]
              )
          : null;

        updatedMainSelections[mainRowId1] = allExpandedSelected;
        updatedPackingStatus.push(record1?.packing);
        updatedDispatchStatus.push(record1?.dispatch);
      } else {
        delete updatedExpandedSelections[id];
        delete updatedRevertSelections[id];

        const mainRowId = dispatchStockList.find((item) =>
          item.dispatchStock.some((stockItem) => stockItem.id === id)
        ).id;
        updatedMainSelections[mainRowId] = false;
        setSelectAllDispatch(false);
        let index = updatedDispatchStatus.indexOf(record1?.dispatch);
        if (index !== -1) {
          updatedDispatchStatus.splice(index, 1);
        }
        let index1 = updatedPackingStatus.indexOf(record1?.packing);
        if (index1 !== -1) {
          updatedPackingStatus.splice(index1, 1);
        }
      }

      setStatusDispatch(updatedDispatchStatus);
      setStatusPacking(updatedPackingStatus);
      setExpandedDispatchRowSelections(updatedExpandedSelections);
      setDispatchRowSelections(updatedMainSelections);
      setRevertSelections(updatedRevertSelections);
    },
    [
      expandedDispatchRowSelections,
      dispatchRowSelections,
      dispatchStockList,
      statusDispatch,
      statusPacking,
      revertSelections,
    ]
  );

  const [selectedId, setSelectedId] = useState([]);

  const handleMainDispatchRowSelectChange = useCallback(
    (record, e) => {
      const { id } = record;
      const checked = e.target.checked;

      const updatedMainSelections = { ...dispatchRowSelections };
      const updatedExpandedSelections = { ...expandedDispatchRowSelections };
      const updatedPackingStatus = [...statusPacking];
      const updatedDispatchStatus = [...statusDispatch];
      const updatedRevertSelections = { ...revertSelections };

      updatedMainSelections[id] = checked;

      if (checked) {
        let temp = selectedId;
        temp.push(id);
        setSelectedId([...temp]);
        record?.dispatchStock.forEach((item) => {
          updatedExpandedSelections[item.id] = item?.id;
          updatedRevertSelections[item.id] = item;
        });
        updatedPackingStatus.push(record?.packing);
        updatedDispatchStatus.push(record?.dispatch);
      } else {
        let temp = selectedId;
        temp = temp.filter((x) => x !== id);
        setSelectedId(temp);
        record?.dispatchStock.forEach((item) => {
          delete updatedExpandedSelections[item.id];
          delete updatedRevertSelections[item.id];
        });
        let index = updatedDispatchStatus.indexOf(record?.dispatch);
        if (index !== -1) {
          updatedDispatchStatus.splice(index, 1);
        }
        let index1 = updatedPackingStatus.indexOf(record?.packing);
        if (index1 !== -1) {
          updatedPackingStatus.splice(index1, 1);
        }

        setSelectAllDispatch(false);
      }
      setStatusDispatch(updatedDispatchStatus);
      setStatusPacking(updatedPackingStatus);
      setDispatchRowSelections(updatedMainSelections);
      setExpandedDispatchRowSelections(updatedExpandedSelections);
      setRevertSelections(updatedRevertSelections);
    },
    [
      dispatchRowSelections,
      expandedDispatchRowSelections,
      statusPacking,
      statusDispatch,
      revertSelections,
    ]
  );

  console.log("selected id", selectedId);

  const packing = statusPacking.find((item1) => item1 !== 1);
  const dispatching = statusDispatch.find((item1) => item1 !== 1);
  const packingDispatch = statusPacking.find((item1) => item1 === 1);

  const handlePacking = () => {
    const obj = Object.values(expandedDispatchRowSelections);
    const newData = obj.map((item) => ({ id: item, status: 2 }));
    setConfirmLoading1(true);
    dispatch(
      dispatchStockEntry({
        formData: {
          action: "packing",
          data: newData,
        },
      })
    );

    setTimeout(() => {
      setConfirmLoading1(false);
      setModal1Visible(false);
      const values = {
        startDate: "",
        endDate: "",
        mainItem: [],
        color: [],
        dispatchno: [],
        party: [],
      };
      dispatch(
        getOutwardEntryDetails({
          masterName: "DispatchStock",
          formdata: values,
          query: {
            page: 1,
            pageSize: 50,
          },
        })
      );
      setExpandedDispatchRowSelections({});
      setDispatchRowSelections({});
    }, 2000);
  };

  const handleDispatch = () => {
    const obj = Object.values(expandedDispatchRowSelections);
    const newData = obj.map((item) => ({ id: item, status: 3 }));
    setConfirmLoading2(true);
    dispatch(
      dispatchStockEntry({
        formData: {
          action: "dispatch",
          data: newData,
        },
      })
    );

    setTimeout(() => {
      setConfirmLoading2(false);
      setModal2Visible(false);
      const values = {
        startDate: "",
        endDate: "",
        mainItem: [],
        color: [],
        dispatchno: [],
        party: [],
      };
      dispatch(
        getOutwardEntryDetails({
          masterName: "DispatchStock",
          formdata: values,
          query: {
            page: 1,
            pageSize: 50,
          },
        })
      );
      setExpandedDispatchRowSelections({});
      setDispatchRowSelections({});
    }, 2000);
  };

  const revertArray = Object.values(revertSelections);

  const handleRevert = () => {
    setRevertLoading(true);
    dispatch(
      dispatchStockRevert({
        formData: { data: revertArray },
      })
    );

    setTimeout(() => {
      setRevertLoading(false);
      const values = {
        startDate: "",
        endDate: "",
        mainItem: [],
        color: [],
        dispatchno: [],
        party: [],
      };
      dispatch(
        getOutwardEntryDetails({
          masterName: "DispatchStock",
          formdata: values,
          query: {
            page: 1,
            pageSize: 50,
          },
        })
      );
      setExpandedDispatchRowSelections({});
      setDispatchRowSelections({});
      setStatusDispatch([]);
      setStatusPacking([]);
      setRevertSelections({});
    }, 2000);
  };

  const expandedOutwardRowRender = (record) => {
    var size34 = 0;
    var size36 = 0;
    var size38 = 0;
    var size40 = 0;
    var size42 = 0;
    var size44 = 0;
    var size46 = 0;
    var size48 = 0;
    var total = 0;

    record?.stock?.forEach((se) => {
      size34 += +se?.size34 || 0;
      size36 += +se?.size36 || 0;
      size38 += +se?.size38 || 0;
      size40 += +se?.size40 || 0;
      size42 += +se?.size42 || 0;
      size44 += +se?.size44 || 0;
      size46 += +se?.size46 || 0;
      size48 += +se?.size48 || 0;
    });
    const expandedOutwardColumns = [
      {
        title: "Select",
        dataIndex: "selected",
        key: "selected",
        render: (_, record) =>
          record.recordStatus !== "Dispatched" ? (
            <Checkbox
              checked={expandedRowSelections[record.id]}
              onChange={(e) => handleExpandedRowSelectChange(record, e)}
            />
          ) : null,
      },

      {
        title: "Party Name",
        dataIndex: "partyName",
        key: "partyName",
        responsive: ["sm"],
        render: (value) => value || "-",
        className: "custom-font-size",
      },

      {
        title: "Item",
        dataIndex: "mainItem",
        key: "mainitem",
        responsive: ["sm"],
        render: (value) => value || "-",
      },
      {
        title: "Color Name",
        dataIndex: "color",
        key: "color",
        responsive: ["sm"],
        render: (value) => value || "-",
      },
      {
        title: `34 (Total-${size34}pcs)`,
        dataIndex: "size34",
        key: "size34",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `36 (Total-${size36}pcs)`,
        dataIndex: "size36",
        key: "size36",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `38 (Total-${size38}pcs)`,
        dataIndex: "size38",
        key: "size38",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `40 (Total-${size40}pcs)`,
        dataIndex: "size40",
        key: "size40",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `42 (Total-${size42}pcs)`,
        dataIndex: "size42",
        key: "size42",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `44 (Total-${size44}pcs)`,
        dataIndex: "size44",
        key: "size44",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `46 (Total-${size46}pcs)`,
        dataIndex: "size46",
        key: "size46",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `48 (Total-${size48}pcs)`,
        dataIndex: "size48",
        key: "size48",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: "Total",
        key: "Total",
        dataIndex: "total",
        responsive: ["sm"],
        render: (text, data, index) => {
          total =
            data?.size34 +
            data?.size36 +
            data?.size38 +
            data?.size40 +
            data?.size42 +
            data?.size44 +
            data?.size46 +
            data?.size48;
          return <p>{total}</p>;
        },
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
        responsive: ["sm"],
        render: (value) => value || "-",
      },
      {
        title: "Status",
        dataIndex: "recordStatus",
        key: "recordStatus",
        render: (value) => value || "-",
      },
    ];
    return (
      <Table
        columns={expandedOutwardColumns}
        dataSource={record?.stock}
        rowKey={(stock) => stock?.id}
        pagination={false}
        bordered
        size="small"
      />
    );
  };
  const expandedDispatchRowRender = (record1) => {
    var size34 = 0;
    var size36 = 0;
    var size38 = 0;
    var size40 = 0;
    var size42 = 0;
    var size44 = 0;
    var size46 = 0;
    var size48 = 0;
    var total = 0;

    record1?.dispatchStock?.forEach((se) => {
      size34 += +se?.size34 || 0;
      size36 += +se?.size36 || 0;
      size38 += +se?.size38 || 0;
      size40 += +se?.size40 || 0;
      size42 += +se?.size42 || 0;
      size44 += +se?.size44 || 0;
      size46 += +se?.size46 || 0;
      size48 += +se?.size48 || 0;
    });
    const expandedDispatchColumns = [
      {
        title: "Select",
        dataIndex: "selected",
        key: "selected",
        render: (_, record) => (
          <Checkbox
            checked={expandedDispatchRowSelections[record.id]}
            onChange={(e) =>
              handleExpandedDispatchRowSelectChange(record, e, record1)
            }
          />
        ),
      },

      {
        title: "Party Name",
        dataIndex: "partyName",
        key: "partyName",
        responsive: ["sm"],
        render: (value) => value || "-",
        className: "custom-font-size",
      },

      {
        title: "Item",
        dataIndex: "mainItem",
        key: "mainItem",
        responsive: ["sm"],
        render: (value) => value || "-",
      },
      {
        title: "Color Name",
        dataIndex: "color",
        key: "color",
        responsive: ["sm"],
        render: (value) => value || "-",
      },
      {
        title: `34 (Total-${size34}pcs)`,
        dataIndex: "size34",
        key: "size34",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `36 (Total-${size36}pcs)`,
        dataIndex: "size36",
        key: "size36",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `38 (Total-${size38}pcs)`,
        dataIndex: "size38",
        key: "size38",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `40 (Total-${size40}pcs)`,
        dataIndex: "size40",
        key: "size40",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `42 (Total-${size42}pcs)`,
        dataIndex: "size42",
        key: "size42",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `44 (Total-${size44}pcs)`,
        dataIndex: "size44",
        key: "size44",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `46 (Total-${size46}pcs)`,
        dataIndex: "size46",
        key: "size46",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: `48 (Total-${size48}pcs)`,
        dataIndex: "size48",
        key: "size48",
        responsive: ["sm"],
        render: (value) => value || "0",
      },
      {
        title: "Total",
        key: "Total",
        dataIndex: "total",
        responsive: ["sm"],
        render: (text, data, index) => {
          total =
            data?.size34 +
            data?.size36 +
            data?.size38 +
            data?.size40 +
            data?.size42 +
            data?.size44 +
            data?.size46 +
            data?.size48;
          return <p>{total}</p>;
        },
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
        responsive: ["sm"],
        render: (value) => value || "-",
      },
    ];
    return (
      <Table
        columns={expandedDispatchColumns}
        dataSource={record1?.dispatchStock}
        rowKey={(dispatchStock) => dispatchStock?.id}
        pagination={false}
        bordered
        size="small"
      />
    );
  };
  const outwardColumns = [
    {
      title: "Select",
      dataIndex: "selected",
      key: "selected",
      render: (_, record) =>
        record.recordStatus !== "Dispatched" ? (
          <Checkbox
            checked={mainRowSelections[record.id]}
            onChange={(e) => handleMainRowSelectChange(record, e)}
          />
        ) : null,
    },
    {
      title: "Date",
      dataIndex: "OutwardDate",
      key: "OutwardDate",
      render: (value) => value || "-",
    },
    {
      title: "Outward No",
      dataIndex: "OutwardNo",
      key: "OutwardNo",
      render: (value) => value || "-",
    },
    {
      title: "Status",
      dataIndex: "recordStatus",
      key: "recordStatus",
      render: (value) => value || "-",
      filters: statusFilters,
      onFilter: (value, record) => {
        return record.recordStatus.startsWith(value);
      },

      filterSearch: true,
    },

    {
      title: "Actions",
      key: "Actions",
      dataIndex: "action",
      width: "20%",
      render: (_, data) => {
        const items = [
          {
            label: "Edit",
            onClick: () => handleEditMasterOutward(data?.id),
          },
        ];

        return renderActions(items);
      },
    },
  ];

  const dispatchColumns = [
    {
      title: "Select",
      dataIndex: "selected",
      key: "selected",
      render: (_, record) => (
        <Checkbox
          checked={dispatchRowSelections[record.id]}
          onChange={(e) => handleMainDispatchRowSelectChange(record, e)}
        />
      ),
    },
    {
      title: "Date",
      dataIndex: "dispatchDate",
      key: "dispatchDate",
      render: (value) => value || "-",
    },
    {
      title: "Dispatch No",
      dataIndex: "dispatchNo",
      key: "dispatchNo",
      render: (value) => value || "-",
    },
    {
      title: "Party Name",
      dataIndex: "partyName",
      key: "patyName",
      render: (value) => value || "-",
      filters: partyFilters,
      onFilter: (value, record) => {
        return record.partyName.startsWith(value);
      },

      filterSearch: true,
      className: "custom-font-size",
    },
    {
      title: "Sent to Packing",
      dataIndex: "packing",
      key: "packing",
      render: (_, record) => (
        <Checkbox
          checked={record.packing === 2}
          //	onChange={e => onSelectChange(record, e)}
        />
      ),
    },
    {
      title: "Dispatch",
      dataIndex: "dispatch",
      key: "dispatch",
      render: (_, record) => (
        <Checkbox
          checked={record.dispatch === 3}
          //onChange={e => onSelectChangeDispatch(record, e)}
        />
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Outward Entry</title>
      </Helmet>
      <div className="flex justify-between mb-1">
        <h1 className="text-2xl font-bold self-center captialize ">
          Outward Entry
        </h1>
        <div className="flex justify-end md:mr-10 ">
          <Link
            to="/working/outward"
            className=" text-black border-black border p-2 rounded-md font-semibold hover:text-primaryDark hover:border-primaryDark"
            type="link"
            target="_blank"
          >
            Open Form
          </Link>
        </div>
      </div>
      <OutwardFilters
        outwardStockList={outwardStockList}
        setOutwardStockList={setOutwardStockList}
        val={val}
        dispatchStockList={dispatchStockList}
        setDispatchStockList={setDispatchStockList}
      />

      {val === "Outward" && dispatchArray.length ? (
        <>
          {/* <div className="flex justify-end md:mr-10 mb-1">
            <Button
              className="mt-2 text-primaryBase border-primaryDark font-semibold"
              onClick={handleSubmit}
              loading={isLoading}
            >
              Dispatch Stock
            </Button>
          </div> */}
          <div className="flex flex-col items-end md:mr-10 mb-1">
            {showConfirmation && (
              <>
                <Modal
                  title="Confirm Dispatch"
                  open={showConfirmation}
                  onOk={handleConfirm}
                  onCancel={handleCancelDispatch}
                  confirmLoading={isLoading}
                  okText={isLoading ? "Dispatching..." : "Confirm Dispatch"}
                  cancelText="Cancel"
                >
                  <p>
                    Are you sure you want to dispatch the stock? Click confirm
                    to proceed.
                  </p>
                </Modal>
              </>
            )}
            <Button
              className="mt-2 text-primaryBase border-primaryDark font-semibold"
              onClick={handleInitialClick}
              disabled={isLoading}
            >
              Dispatch Stock
            </Button>
          </div>
        </>
      ) : (
        <div className="flex justify-end md:mr-10 mb-1">
          {Object.keys(revertSelections).length ? (
            <>
              <Button
                className="mt-2 mr-3  text-redBase border-redBase font-semibold"
                onClick={() => handleRevert()}
                loading={revertLoading}
              >
                Revert
              </Button>
              <Button
                className="mt-2 mr-3  text-primaryBase border-primaryDark font-semibold"
                onClick={() => setModalPrint(true)}
              >
                Print
              </Button>
            </>
          ) : null}

          {packing === undefined && statusPacking?.length ? (
            <Button
              className="mt-2 mr-3  text-primaryBase border-primaryDark font-semibold"
              onClick={() => setModal1Visible(true)}
            >
              Packing
            </Button>
          ) : null}
          {dispatching === undefined &&
          statusDispatch?.length &&
          packing !== 1 &&
          packing !== undefined &&
          statusDispatch?.length >= 1 &&
          packingDispatch === undefined ? (
            <Button
              className="mt-2 text-primaryBase border-primaryDark font-semibold"
              onClick={() => setModal2Visible(true)}
            >
              Dispatch
            </Button>
          ) : null}
        </div>
      )}
      <div className="flex justify-center mb-2">
        <Segmented
          options={["Outward", "Dispatch"]}
          onChange={(value) => {
            if (value === "Outward") {
              const values = {
                startDate: "",
                endDate: "",
                mainItem: [],
                color: [],
                outwardno: [],
                party: [],
              };
              dispatch(
                getOutwardEntryDetails({
                  masterName,
                  //query: { status: 1 },
                  formdata: values,
                  query: {
                    page: 1,
                    pageSize: 50,
                  },
                })
              );

              setVal(value);
            } else {
              // const values = {
              // 	startDate: "",
              // 	endDate: "",
              // 	mainItem: [],
              // 	color: [],
              // 	dispatchno: [],
              // 	party: [],
              // };
              // dispatch(
              // 	getOutwardEntryDetails({
              // 		masterName: "DispatchStock",
              // 		//query: { status: 2 },
              // 		formdata: values,
              // 		query:{
              // 			page: 1,
              // 		pageSize: 50
              // 		}
              // 	})
              // );
              setVal("Dispatch");
            }
          }}
        />
      </div>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Do you want send for {actionValue} ?</p>
      </Modal>
      <Modal
        title="Packing"
        open={modal1Visible}
        onOk={handlePacking}
        confirmLoading={confirmLoading1}
        onCancel={() => setModal1Visible(false)}
      >
        <p>Do you want send for Packing ?</p>
      </Modal>
      <Modal
        title="Dispatch"
        open={modal2Visible}
        onOk={handleDispatch}
        confirmLoading={confirmLoading2}
        onCancel={() => setModal2Visible(false)}
      >
        <p>Do you want send for Dispatch ?</p>
      </Modal>

      {/* <OutwardPrint
				modalPrint={modalPrint}
				setModalPrint={setModalPrint}
				revertSelections={revertSelections}
				setExpandedDispatchRowSelections={setExpandedDispatchRowSelections}
				setDispatchRowSelections={setDispatchRowSelections}
				setStatusDispatch={setStatusDispatch}
				setStatusPacking={setStatusPacking}
				setRevertSelections={setRevertSelections}
			/> */}
      <OutwardPrintV2
        selectedId={selectedId}
        modalPrint={modalPrint}
        setModalPrint={setModalPrint}
        revertSelections={revertSelections}
        setExpandedDispatchRowSelections={setExpandedDispatchRowSelections}
        setDispatchRowSelections={setDispatchRowSelections}
        setStatusDispatch={setStatusDispatch}
        setStatusPacking={setStatusPacking}
        setRevertSelections={setRevertSelections}
      />
      {val === "Outward" ? (
        <>
          <Table
            columns={outwardColumns}
            expandable={{
              expandedRowRender: expandedOutwardRowRender,
              rowExpandable: (record) => !!record.id,
            }}
            rowKey={(outwardStockList) => outwardStockList?.id}
            dataSource={outwardStockList}
            bordered
            loading={isFetchingMasterList}
            pagination={false}
            title={() => (
              <Checkbox
                checked={selectAllMain}
                onChange={handleSelectAllMainChange}
              >
                Select All
              </Checkbox>
            )}
            scroll={{ y: 600 }}
            size="small"
          />
          {outwardStockList?.length ? (
            <div className="flex justify-end">
              <Pagination
                showSizeChanger={false}
                pageSize={pageSize}
                total={totalElements}
                current={currentPage}
                onChange={handlePageChange}
              />
            </div>
          ) : null}
        </>
      ) : (
        <DispatchStock
          dispatchColumns={dispatchColumns}
          dispatchStockList={dispatchStockList}
          isFetchingMasterList={isFetchingMasterList}
          selectAllDispatch={selectAllDispatch}
          handleSelectAllDispatchChange={handleSelectAllDispatchChange}
          expandedDispatchRowRender={expandedDispatchRowRender}
        />
      )}
    </>
  );
};

export default reduxForm({
  form: "OutwardStockEntry",
  enableReinitialize: true,
})(OutwardStockEntry);
