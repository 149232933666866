import { Field, reduxForm } from "redux-form";

import { ReduxFormTextField } from "utils/ReduxFormTextField";
import { isRequired } from "utils/validations";

const LoginForm = ({ handleSubmit, disabled }) => {
	return (
		<form className="flex flex-col p-4" onSubmit={handleSubmit}>
			<div className="m-auto mb-5">
				<Field
					component={ReduxFormTextField}
					label="User Name"
					name="username"
					type="text"
					placeholder="Enter username"
					className="mb-5"
				/>
				<Field
					component={ReduxFormTextField}
					label="Password"
					name="password"
					type="password"
					placeholder="Enter Password"
				/>
			</div>

			<button
				className="bg-transparent hover:bg-primaryBase text-primaryDark hover:text-white font-semibold py-2 mt-2 border border-primaryBase hover:border-transparent rounded"
				type="submit"
				disabled={disabled}
			>
				Submit
			</button>
		</form>
	);
};

export default reduxForm({
	form: "login",
	validate: values => {
		const errors = {};
		isRequired(values?.username) && (errors.username = "Required");
		isRequired(values?.password) && (errors.password = "Required");
		return errors;
	},
})(LoginForm);
