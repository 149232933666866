import Categories from "components/category/Categories";
import Collections from "components/collection/Collections";
import CollectionsWrapper from "components/collection/CollectionsWrapper";
import Dashboard from "components/dashboard/Dashboard";
import MainItems from "components/main-items/MainItems";
import MainItemsWrapper from "components/main-items/MainItemsWrapper";
import PdfGeneratorWrapper from "components/pdfgenerator/PdfGeneratorWrapper";
import ChalanReports from "components/reports/ChalanReport";
import FabricReport from "components/reports/FabricReport";
import ImageUploadReport from "components/reports/ImageUploadReport";
import KatkutReports from "components/reports/KatkutReport";
import ReadyStockReports from "components/reports/ReadyStockReport";
import Seasons from "components/season/Seasons";
import SeasonsWrapper from "components/season/SeasonsWrapper";
import Subcategories from "components/subcategory/Subcategories";
import SubcategoriesWrapper from "components/subcategory/SubcategoriesWrapper";
// import Outward from "components/working/Outward";
import LedgerReport from "components/working/LedgerReport";
import LedgerReportv2 from "components/working/Ledger Report/LedgerReportv2";

import OutwardV2 from "components/working/OutwardV2";
import ReadyStockEntry from "components/working/ReadyStockEntry";
import ReadyStockEntryWrapper from "components/working/ReadyStockEntryWrapper";
import ReadyStockWorkingReport from "components/working/ReadyStockWorkingReport";
import ReadyStockWrapper from "components/working/ReadyStockWrapper";
import SalesOrderReport from "components/working/SalesOrderReport";
import SalesOrderReportV2 from "components/working/saleOrderV2/SaleOrderReportV2";
import OutwardStockEntry from "components/working/outward/OutwardStockEntry";
import OutwardStockWrapper from "components/working/outward/OutwardStockWrapper";
//masters
import Charges from "components/masters/charges/Charges";
import AddCharges from "components/masters/charges/AddCharges";
import Hsn from "components/masters/hsn/Hsn";
import AddHsn from "components/masters/hsn/AddHsn";
import City from "components/masters/city/City";
import AddCity from "components/masters/city/AddCity";
import Category from "components/masters/category/Category";
import AddCategory from "components/masters/category/AddCategory";
import SubCategory from "components/masters/subCategory/SubCategory";
import AddSubCategory from "components/masters/subCategory/AddSubCategory";
import CustomerGroup from "components/masters/customerGroup/CustomerGroup";
import AddCusomerGroup from "components/masters/customerGroup/AddCusomerGroup";
import Agent from "components/masters/agent/Agent";
import AddAgent from "components/masters/agent/AddAgent";
import Transport from "components/masters/transport/Transport";
import AddTransport from "components/masters/transport/AddTransport";
import Customer from "components/masters/customer/Customer";
import AddCustomer from "components/masters/customer/AddCustomer";
import MainItem from "components/masters/mainItem/MainItem";
import AddMainItem from "components/masters/mainItem/AddMainItem";
//
import { useSelector } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import { userSelectors } from "slices/userSlice";
import UserRoles from "components/settings/UserRoles";
import UserRoleList from "components/settings/UserRoleList";
import AddSaleOrderForm from "components/working/saleOrderV2/AddSaleOrderForm";
import NewSaleOrderReport from "components/working/saleOrderV2/NewSaleOrderReport";
import SaleOrderSheet from "components/working/saleOrderV3/SaleOrderSheet";

const AuthorizedRoutes = () => {
  const user = useSelector(userSelectors?.getUserDetails);

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} exact />

      {/* masters routes */}
      <Route path="/charges" element={<Outlet />}>
        <Route index element={<Charges />} />
        <Route path="add" element={<AddCharges />} />
      </Route>
      <Route path="/hsn" element={<Outlet />}>
        <Route index element={<Hsn />} />
        <Route path="add" element={<AddHsn />} />
      </Route>
      <Route path="/city" element={<Outlet />}>
        <Route index element={<City />} />
        <Route path="add" element={<AddCity />} />
      </Route>
      <Route path="/customer-group" element={<Outlet />}>
        <Route index element={<CustomerGroup />} />
        <Route path="add" element={<AddCusomerGroup />} />
      </Route>
      <Route path="/agent" element={<Outlet />}>
        <Route index element={<Agent />} />
        <Route path="add" element={<AddAgent />} />
      </Route>
      <Route path="/transport" element={<Outlet />}>
        <Route index element={<Transport />} />
        <Route path="add" element={<AddTransport />} />
      </Route>
      <Route path="/customer" element={<Outlet />}>
        <Route index element={<Customer />} />
        <Route path="add" element={<AddCustomer />} />
      </Route>
      {user?.accessRights?.categories === "1" ? (
        <Route path="/category/:status" element={<Category />} exact /> //Categories
      ) : null}
      {user?.accessRights?.categories === "1" ? (
        <Route path="/category/add/new" element={<AddCategory />} exact />
      ) : null}
      {/* <Route path="/subcategory/:status" element={<Subcategories />} exact /> */}
      {user?.accessRights?.subcategories === "1" ? (
        <Route path="/old-subcategory/" element={<Outlet />}>
          <Route index element={<Subcategories />} />
          <Route path=":mode/:id" element={<SubcategoriesWrapper />} />
          <Route path=":status" element={<Subcategories />} />
        </Route>
      ) : null}
      {user?.accessRights?.subcategories === "1" ? (
        <Route path="/subcategory" element={<Outlet />}>
          <Route index element={<SubCategory />} />
          <Route path="add" element={<AddSubCategory />} />
          <Route path=":status" element={<SubCategory />} />
        </Route>
      ) : null}

      <Route path="/season/" element={<Outlet />}>
        <Route index element={<Seasons />} />
        <Route path="add" element={<SeasonsWrapper />} />
        <Route path=":mode/:id" element={<SeasonsWrapper />} />
        <Route path=":status" element={<Seasons />} />
      </Route>

      <Route path="/collection/" element={<Outlet />}>
        <Route index element={<Collections />} />
        <Route path="add" element={<CollectionsWrapper />} />
        <Route path=":mode/:id" element={<CollectionsWrapper />} />
        <Route path=":status" element={<Collections />} />
      </Route>

      <Route path="/mainitem/" element={<Outlet />}>
        <Route index element={<MainItems />} />
        <Route path=":mode/:id" element={<MainItemsWrapper />} />
        <Route path=":status" element={<MainItems />} />
      </Route>
      <Route path="/mainitems/" element={<Outlet />}>
        <Route index element={<MainItem />} />
        <Route path="add" element={<AddMainItem />} />
        <Route path=":status" element={<MainItem />} />
      </Route>

      {user?.accessRights?.fabricReport === "1" ? (
        <Route path="/reports/fabric" element={<Outlet />}>
          <Route index element={<FabricReport />} />
        </Route>
      ) : null}

      <Route path="/reports/image-upload" element={<Outlet />}>
        <Route index element={<ImageUploadReport />} />
      </Route>

      <Route path="/reports/chalan" element={<Outlet />}>
        <Route index element={<ChalanReports />} />
      </Route>

      <Route path="/reports/ready-stock" element={<Outlet />}>
        <Route index element={<ReadyStockReports />} />
      </Route>

      <Route path="/reports/katkut" element={<Outlet />}>
        <Route index element={<KatkutReports />} />
      </Route>

      <Route path="/working/sales-order" element={<Outlet />}>
        <Route index element={<SalesOrderReport />} />
        <Route path="add" element={<AddSaleOrderForm />} />
      </Route>
      <Route path="/working/sale-orders" element={<Outlet />}>
        <Route index element={<NewSaleOrderReport />} />
      </Route>

      <Route path="/working/outward" element={<Outlet />}>
        <Route index element={<OutwardV2 />} />
        <Route path=":id" element={<OutwardStockWrapper />} />
      </Route>

      <Route path="/working/ready-stock-form/" element={<Outlet />}>
        <Route index element={<ReadyStockWrapper />} />
        <Route path=":id" element={<ReadyStockEntryWrapper />} />
      </Route>

      {/* <Route path="/working/ready-stock-form/:id" element={<Outlet />}>
				<Route index element={<ReadyStockWrapper />} />
			</Route> */}

      <Route path="/working/readyStockEntry" element={<Outlet />}>
        <Route index element={<ReadyStockEntry />} />
      </Route>

      <Route path="/working/outward/outwardStockEntry" element={<Outlet />}>
        <Route index element={<OutwardStockEntry />} />
      </Route>
      <Route path="/working/readyStockWorkingReport" element={<Outlet />}>
        <Route index element={<ReadyStockWorkingReport />} />
      </Route>
      {/* <Route path="/working/ledgerReport" element={<Outlet />}>
        <Route index element={<LedgerReport />} />
      </Route> */}

      <Route path="/working/ledgerReport" element={<Outlet />}>
        <Route index element={<LedgerReportv2 />} />
      </Route>
      <Route path="/working/saleOrderSheetv3" element={<Outlet />}>
        <Route index element={<SaleOrderSheet />} />
      </Route>
      <Route path="/pdf" element={<PdfGeneratorWrapper />} exact />
      <Route path="/user-roles" element={<UserRoles />} exact />
      <Route path="/role-list" element={<UserRoleList />} exact />
    </Routes>
  );
};

export default AuthorizedRoutes;
