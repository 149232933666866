const apiConfig = {
	baseURL: "http://165.232.177.246:8080/EthosERP-v1.1",
	headers: {
		"Content-Type": "application/json",
	},
	getHeaders: getState => {
		const token = getState()?.userDetails?.token || null;
		if (token)
			return {
				...apiConfig.headers,
				Authorization: `Bearer ${token}`,
			};

		return { ...apiConfig.headers };
	},
};

export default apiConfig;
