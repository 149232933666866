import { Table } from "antd";
import TableOptions from "components/TableOptions";
import useMasterLogic from "customHooks/useMasterLogic";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
	getReadyStockWorkingReport,
	masterSelectors,
} from "slices/masterSlice";
import ClosingStockExport from "./outwardV2/ClosingStockExport";

const masterName = "readyStockFinal";

const ReadyStockWorkingReport = () => {
	const dispatch = useDispatch();

	const mainReadyStockListFinal = useSelector(
		masterSelectors?.getReadyStockWorkingList
	);

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);

	const [readyStockListFinal, setReadyStockListFinal] = useState(
		useSelector(masterSelectors?.getReadyStockWorkingList)
	);

	const [sizeTotals, setSizeTotals] = useState({
		size85: 0,
		size90: 0,
		size95: 0,
		size100: 0,
		size105: 0,
		size110: 0,
		size115: 0,
		size120: 0,
		totalOfTotal: 0,
	});

	useEffect(() => {
		setReadyStockListFinal(mainReadyStockListFinal);
		const totals = calculateTotals(mainReadyStockListFinal);
		setSizeTotals(totals);
	}, [mainReadyStockListFinal]);

	const calculateTotals = data => {
		let size85 = 0;
		let size90 = 0;
		let size95 = 0;
		let size100 = 0;
		let size105 = 0;
		let size110 = 0;
		let size115 = 0;
		let size120 = 0;
		let totalOfTotal = 0;

		data.forEach(se => {
			if (se?.color !== "Total") {
				size85 += +se?.size85 || 0;
				size90 += +se?.size90 || 0;
				size95 += +se?.size95 || 0;
				size100 += +se?.size100 || 0;
				size105 += +se?.size105 || 0;
				size110 += +se?.size110 || 0;
				size115 += +se?.size115 || 0;
				size120 += +se?.size120 || 0;

				const totalItem =
					se.size85 +
					se.size90 +
					se.size95 +
					se.size100 +
					se.size105 +
					se.size110 +
					se.size115 +
					se.size120;
				totalOfTotal += totalItem;
			}
		});

		return {
			size85,
			size90,
			size95,
			size100,
			size105,
			size110,
			size115,
			size120,
			totalOfTotal,
		};
	};
	const calculateColorTotal = data => {
		let size85 = 0;
		let size90 = 0;
		let size95 = 0;
		let size100 = 0;
		let size105 = 0;
		let size110 = 0;
		let size115 = 0;
		let size120 = 0;
		let totalOfTotal = 0;

		data.forEach(se => {
			size85 += +se?.size85 || 0;
			size90 += +se?.size90 || 0;
			size95 += +se?.size95 || 0;
			size100 += +se?.size100 || 0;
			size105 += +se?.size105 || 0;
			size110 += +se?.size110 || 0;
			size115 += +se?.size115 || 0;
			size120 += +se?.size120 || 0;

			const totalItem =
				se.size85 +
				se.size90 +
				se.size95 +
				se.size100 +
				se.size105 +
				se.size110 +
				se.size115 +
				se.size120;
			totalOfTotal += totalItem;
		});

		return {
			size85,
			size90,
			size95,
			size100,
			size105,
			size110,
			size115,
			size120,
			totalOfTotal,
		};
	};

	const getReadyStockWorkingData = useCallback(() => {
		dispatch(
			getReadyStockWorkingReport({
				masterName,
			})
		);
	}, [dispatch]);

	useMasterLogic(getReadyStockWorkingData, masterName);

	const [filters, setFilters] = useState({});

	const handleFilterChange = (pagination, filters, sorter) => {
		setFilters(filters);
	};

	useEffect(() => {
		let filteredData = mainReadyStockListFinal;

		let mainItem = filters?.mainItem || [];
		let color = filters?.color || [];
		let subCategory = filters?.subCategory || [];

		if (mainItem?.length)
			filteredData = filteredData.filter(item =>
				mainItem.includes(item?.mainItem)
			);
		if (color?.length)
			filteredData = filteredData.filter(item => color.includes(item?.color));
		if (subCategory?.length)
			filteredData = filteredData.filter(item =>
				subCategory.includes(item?.subCategory)
			);

		setReadyStockListFinal(filteredData);

		if (color.includes("Total")) {
			const totals = calculateColorTotal(filteredData);
			setSizeTotals(totals);
		} else {
			const totals = calculateTotals(filteredData);
			setSizeTotals(totals);
		}
	}, [filters, mainReadyStockListFinal]);

	const subCategory = Array.from(
		new Set(readyStockListFinal.map(item => item.subCategory))
	);

	const subCategoryFilters = subCategory.map(subCategory => ({
		text: subCategory,
		value: subCategory,
	}));

	const mainItems = Array.from(
		new Set(readyStockListFinal.map(item => item.mainItem))
	);

	const mainItemFilters = mainItems.map(mainItems => ({
		text: mainItems,
		value: mainItems,
	}));

	const colors = Array.from(
		new Set(readyStockListFinal.map(item => item.color))
	);

	const colorsFilters = colors.map(colors => ({
		text: colors,
		value: colors,
	}));

	const columns = [
		{
			title: "Sub Category",
			dataIndex: "subCategory",
			key: "subCategory",
			responsive: ["sm"],
			render: value => value || "-",
			filters: subCategoryFilters,
			onFilter: (value, record) => {
				return record?.subCategory?.length
					? record.subCategory.startsWith(value)
					: [];
			},
			filterSearch: true,
		},
		{
			title: "Main Item",
			dataIndex: "mainItem",
			key: "mainItem",
			responsive: ["sm"],
			render: value => value || "-",
			filters: mainItemFilters,
			onFilter: (value, record) => {
				return record?.mainItem?.length
					? record.mainItem.startsWith(value)
					: [];
			},
			filterSearch: true,
		},
		{
			title: "Color",
			dataIndex: "color",
			key: "color",
			responsive: ["sm"],
			render: value => value || "-",
			filters: colorsFilters,
			onFilter: (value, record) => {
				return record.color.startsWith(value);
			},
			filterSearch: true,
		},
		{
			title: `85 (Total-${sizeTotals.size85}pcs)`,
			dataIndex: "size85",
			key: "size85",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size85 - b.size85,
		},
		{
			title: `90 (Total-${sizeTotals.size90}pcs)`,
			dataIndex: "size90",
			key: "size90",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size90 - b.size90,
		},
		{
			title: `95 (Total-${sizeTotals.size95}pcs)`,
			dataIndex: "size95",
			key: "size95",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size95 - b.size95,
		},
		{
			title: `100 (Total-${sizeTotals.size100}pcs)`,
			dataIndex: "size100",
			key: "size100",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size100 - b.size100,
		},
		{
			title: `105 (Total-${sizeTotals.size105}pcs)`,
			dataIndex: "size105",
			key: "size105",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size105 - b.size105,
		},
		{
			title: `110 (Total-${sizeTotals.size110}pcs)`,
			dataIndex: "size110",
			key: "size110",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size110 - b.size110,
		},
		{
			title: `115 (Total-${sizeTotals.size115}pcs)`,
			dataIndex: "size115",
			key: "size115",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size115 - b.size115,
		},
		{
			title: `120 (Total-${sizeTotals.size120}pcs)`,
			dataIndex: "size120",
			key: "size120",
			responsive: ["sm"],
			render: value => value || 0,
			sorter: (a, b) => a.size120 - b.size120,
		},
		{
			title: `Total (${sizeTotals.totalOfTotal})`,
			key: "Total",
			dataIndex: "total",
			responsive: ["sm"],
			render: (text, data, index) => {
				const total =
					data?.size85 +
					data?.size90 +
					data?.size95 +
					data?.size100 +
					data?.size105 +
					data?.size110 +
					data?.size115 +
					data?.size120;
				return <p>{total}</p>;
			},
			sorter: (a, b) => {
				const totalA =
					a.size85 +
					a.size90 +
					a.size95 +
					a.size100 +
					a.size105 +
					a.size110 +
					a.size115 +
					a.size120;
				const totalB =
					b.size85 +
					b.size90 +
					b.size95 +
					b.size100 +
					b.size105 +
					b.size110 +
					b.size115 +
					b.size120;
				return totalA - totalB;
			},
		},
	];

	const rowClassName = record => {
		return record.color === "Total" ? "highlight-row" : "";
	};

	return (
		<>
			<Helmet>
				<title>Closing Stock Report</title>
			</Helmet>
			<div className="flex justify-between flex-wrap">
			<TableOptions masterName={"Closing Stock"} showAdd={false} />
			<ClosingStockExport/>
			</div>
			
			<Table
				dataSource={readyStockListFinal}
				columns={columns}
				rowKey={record => record?.id}
				pagination={{
					pageSize: 100,
				}}
				loading={isFetchingMasterList}
				bordered
				onChange={handleFilterChange}
				rowClassName={rowClassName}
			/>
		</>
	);
};

export default ReadyStockWorkingReport;
