import React from 'react'
import { Button } from 'antd'
import { Form, Field } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { addMasterList, getMasterList, masterSelectors, } from 'slices/masterSlice'
import { Helmet } from 'react-helmet';
import { useHotkeys } from 'react-hotkeys-hook'

const masterName = "hsn"
const AddCharges = () => {

  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);

  const onSubmit = async (values, form) => {
    let res = await dispatch(
        addMasterList({
          masterName,
          formData:values,
        })
      );
      if(res?.type === "master/add/fulfilled"){
        form.restart();
        dispatch(
          getMasterList({
            masterName
          })
        )
      }
    }

      const required = value => (value ? undefined : 'Required')
      useHotkeys('alt+l', () => window.location.reload())
      useHotkeys('alt+s', (event) => {
        event.preventDefault();
        document.getElementById('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      });

  return (
    <>
    <Helmet>
	<title>HSN Code | Add</title>
	</Helmet>
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} id='form'>
    <>
    <h1 className='text-2xl mb-5'>Add HSN Code</h1>
    <div className='flex gap-5'>
        <div className=''>
            <Field name="hsncode" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="HSN Code"/>
            )}
          </Field>
        </div>
    </div>
<div className='flex gap-5 justify-end mt-10'>
<Button type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
onClick={form.reset}
>Clear</Button>
<Button type="primary" size="small" className='bg-redBase w-[80px]' htmlType='submit'
loading={loading}
disabled={loading}
>Add</Button>

</div>
    </>
    </form>
      )}/>
    </>
  )
}

export default AddCharges