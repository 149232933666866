import { Button, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { Field, change, formValueSelector, reduxForm } from "redux-form";
import {
	getDropdownList,
	getMasterList,
	masterSelectors,
} from "slices/masterSlice";
import { ReduxFormSelectField } from "utils/ReduxFormSelectField";
import {
	ReduxFormTextField,
	ReduxFormTextField1,
} from "utils/ReduxFormTextField";
import { onWheelHandler } from "utils/onWheelHandler";
import { renderModalButtons } from "utils/renderModalButtons";
import { isRequired } from "utils/validations";
import {
	SizesOptions,
	chalanOptions,
	colorPerPageOptions,
	dateOptions,
	mainItemStatusesOptions,
	previewOptions,
	sizesSelect,
	specificationOptions,
	statusOptions,
} from "./pdfGeneratorConstants";

const formName = "pdfGenerator";
const formSelector = formValueSelector(formName);

const PdfGeneratorForm = ({ title, onCancel, handleSubmit, isViewOnly }) => {
	const dispatch = useDispatch();

	const isFetchingDropdownList = useSelector(
		masterSelectors?.getIsFetchingDropdownList
	);
	const categoryOptions = useSelector(
		masterSelectors?.getCategoriesDropdownList
	);
	const subCategoryOptions = useSelector(
		masterSelectors?.getSubCategoriesDropdownList
	);
	const mainItemsOptions = useSelector(
		masterSelectors?.getMainItemsDropdownList
	);

	const generatedMainItemsOptions = useSelector(
		masterSelectors?.getGeneratedMainItemsDropdownList
	);
	const rawMaterialColorsOptions = useSelector(
		masterSelectors?.getRawMaterialColorsDropdownList
	);

	const generatedRawMaterialColorsOptions = useSelector(
		masterSelectors?.getGeneratedRawMaterialColorsDropdownList
	);
	const lot_setOptions = useSelector(masterSelectors?.getLotSetDropdownList);
	const seasonOptions = useSelector(masterSelectors?.getSeasonsDropdownList);
	const collectionsOptions = useSelector(
		masterSelectors?.getCollectionsDropdownList
	);
	const isGeneratingPdf = useSelector(masterSelectors?.getIsGeneratingPdf);

	const collection_list = useSelector(masterSelectors?.getCollectionList);

	const category = useSelector(state => formSelector(state, "category"));
	const subCategory = useSelector(state => formSelector(state, "subCategory"));
	const season = useSelector(state => formSelector(state, "season"));
	const collection = useSelector(state => formSelector(state, "collection"));
	const mainItem = useSelector(state => formSelector(state, "mainItem"));
	const lotList = useSelector(state => formSelector(state, "lots"));
	const colors = useSelector(state => formSelector(state, "colors"));
	const status = useSelector(state => formSelector(state, "status"));
	const chalanStatus = useSelector(state =>
		formSelector(state, "chalanStatus")
	);
	const dateType = useSelector(state => formSelector(state, "dateType"));

	const dateType_Select = dateType?.value || null;
	const sizes = useSelector(state => formSelector(state, "sizes"));

	const sizes_Select = sizes?.value || 0;

	const generatedColors = useSelector(state =>
		formSelector(state, "generatedColors")
	);
	const setMin = useSelector(state => formSelector(state, "setMin"));

	const qtyMin = useSelector(state => formSelector(state, "qtyMin"));

	const mrpMin = useSelector(state => formSelector(state, "mrpMin"));

	const setMax = useSelector(state => formSelector(state, "setMax"));

	const qtyMax = useSelector(state => formSelector(state, "qtyMax"));

	const mrpMax = useSelector(state => formSelector(state, "mrpMax"));
	const size = useSelector(state => formSelector(state, "sizeMin"));

	const sizeMin = size?.value;

	const date = useSelector(state => formSelector(state, "date"));

	const noOfDays = useSelector(state => formSelector(state, "noOfDays"));

	const [tableShow, setTableShow] = useState(false);

	const wip = chalanStatus?.value === "WIP" || false;

	const lotSets = chalanStatus?.value === "Set" || false;

	const ready =
		(status && status?.findIndex(item => item?.value === "Ready") !== -1) ||
		false;

	const color = generatedRawMaterialColorsOptions.map(item => item?.label);

	const getList = () => {
		setTableShow(true);
	};
	useEffect(() => {
		dispatch(getDropdownList({ masterName: "category" }));
		dispatch(getDropdownList({ masterName: "season" }));
		dispatch(getDropdownList({ masterName: "collection" }));
	}, [dispatch]);

	useEffect(() => {
		if (!wip) dispatch(change(formName, "dateType", null));
		if (!ready) dispatch(change(formName, "generatedColors", null));
		if (!wip) dispatch(change(formName, "generatedColors", null));
		if (!lotSets) dispatch(change(formName, "generatedColors", null));
		// if (!mrpMin?.length) dispatch(change(formName, "generatedColors", null));
		// if (!qtyMin?.length) dispatch(change(formName, "generatedColors", null));
		// if (!setMin?.length) dispatch(change(formName, "generatedColors", null));
		//if (!sizeMin?.length) dispatch(change(formName, "generatedColors", null));
		//if (sizes_Select === 0) dispatch(change(formName, "generatedColors", null));
		if (sizes_Select === 0) dispatch(change(formName, "sizeMin", null));
		if (sizes_Select === 0) dispatch(change(formName, "generatedColors", null));
		//if (!size?.length) dispatch(change(formName, "generatedColors", null));
		// if (!mrpMax?.length) dispatch(change(formName, "generatedColors", null));
		// if (!qtyMax?.length) dispatch(change(formName, "generatedColors", null));
		// if (!setMax?.length) dispatch(change(formName, "generatedColors", null));
		//if (!sets) dispatch(change(formName, "generatedColors", null));
		if (dateType_Select === null)
			dispatch(change(formName, "generatedColors", null));
		if (dateType_Select === null) dispatch(change(formName, "date", undefined));
		//if (!sizes_select) dispatch(change(formName, "generatedColors", null));
	}, [
		wip,
		dispatch,
		ready,
		lotSets,
		mrpMin,
		qtyMin,
		setMin,
		sizeMin,
		setMax,
		qtyMax,
		mrpMax,
		noOfDays,
		size,
		sizes_Select,
		noOfDays,
		dateType,
		dateType_Select,
		date,
	]);

	useEffect(() => {
		let categoryList = category?.map(cat => cat?.value);
		if (categoryList?.[0] === "*") categoryList = categoryList?.splice(1);

		dispatch(
			getDropdownList({
				masterName: "subcategory",
				query: { categoryList: categoryList },
			})
		);
	}, [category, dispatch]);

	useEffect(() => {
		let setSelected = lotSets;

		if (setSelected === true) {
			dispatch(
				getDropdownList({
					masterName: "lot_set",
				})
			);
		}
	}, [lotSets, dispatch]);

	useEffect(() => {
		let subcategoryList = subCategory?.map(subCat => subCat?.value);
		let seasonList = season?.map(s => s?.value);
		let collectionList = collection?.map(col => col?.value);
		if (subcategoryList?.[0] === "*")
			subcategoryList = subcategoryList?.splice(1);

		if (seasonList?.[0] === "*") seasonList = seasonList?.splice(1);

		if (collectionList?.[0] === "*") collectionList = collectionList?.splice(1);

		if (subcategoryList?.length) {
			dispatch(
				getDropdownList({
					masterName: "mainitem",
					query: { subcategoryList, seasonList, collectionList },
				})
			);
		}
	}, [subCategory, season, collection, dispatch]);

	useEffect(() => {
		let mainItemList = mainItem?.map(item => item?.value);
		if (mainItemList?.[0] === "*") mainItemList = mainItemList?.splice(1);

		if (mainItem?.length)
			dispatch(
				getDropdownList({
					masterName: "raw_material_colors",
					query: { mainitemList: mainItemList },
				})
			);
	}, [mainItem, dispatch]);

	useEffect(() => {
		let subcategoryList = subCategory?.map(subCat => subCat?.value);
		let seasonList = season?.map(s => s?.value);
		let collectionList = collection?.map(col => col?.value);
		let lots = lotList?.map(lt => lt?.value);
		if (subcategoryList?.[0] === "*")
			subcategoryList = subcategoryList?.splice(1);

		if (seasonList?.[0] === "*") seasonList = seasonList?.splice(1);

		if (collectionList?.[0] === "*") collectionList = collectionList?.splice(1);

		if (lots?.[0] === "*") lots = lots?.splice(1);

		if (subcategoryList?.length && lotSets === false) {
			if (
				mrpMin?.length ||
				mrpMax?.length ||
				setMin?.length ||
				setMax?.length ||
				qtyMin?.length ||
				qtyMax?.length ||
				noOfDays?.length
			) {
				const timer = setTimeout(() => {
					dispatch(
						getDropdownList({
							masterName: "generatedraw_material_colors",
							query: {
								subcategoryList,
								seasonList,
								collectionList,
								ready,
								wip,
								lotSets,
								lots,
								setMin: setMin || 0,
								qtyMin: qtyMin || 0,
								size: sizeMin || 0,
								mrpMin: mrpMin || 0,
								setMax: setMax || 0,
								qtyMax: qtyMax || 0,
								mrpMax: mrpMax || 0,
								date: date,
								no_of_days: noOfDays || 0,
							},
						})
					);
				}, 2000);

				return () => clearTimeout(timer);
			} else {
				dispatch(
					getDropdownList({
						masterName: "generatedraw_material_colors",
						query: {
							subcategoryList,
							seasonList,
							collectionList,
							ready,
							wip,
							lotSets,
							lots,
							setMin: setMin || 0,
							qtyMin: qtyMin || 0,
							size: sizeMin || 0,
							mrpMin: mrpMin || 0,
							setMax: setMax || 0,
							qtyMax: qtyMax || 0,
							mrpMax: mrpMax || 0,
							date: date,
							no_of_days: noOfDays || 0,
						},
					})
				);
			}
		} else if (lotSets === true && lots?.length) {
			dispatch(
				getDropdownList({
					masterName: "generatedraw_material_colors",
					query: {
						subcategoryList,
						seasonList,
						collectionList,
						ready,
						wip,
						lotSets,
						lots,
						setMin: setMin || 0,
						qtyMin: qtyMin || 0,
						size: sizeMin || 0,
						mrpMin: mrpMin || 0,
						setMax: setMax || 0,
						qtyMax: qtyMax || 0,
						mrpMax: mrpMax || 0,
						date: date,
						no_of_days: noOfDays || 0,
					},
				})
			);
		}

		if (subcategoryList?.length) {
			dispatch(
				getMasterList({
					masterName: "collection_list",
					query: { subcategoryList },
				})
			);
		}
	}, [
		wip,
		ready,
		lotSets,
		lotList,
		subCategory,
		season,
		collection,
		dispatch,
		mrpMin,
		qtyMin,
		setMin,
		sizeMin,
		setMax,
		qtyMax,
		mrpMax,
		date,
		noOfDays,
		sizes,
		dateType,
	]);

	// useEffect(() => {
	// 		dispatch(
	// 			getDropdownList({
	// 				masterName: "collection_list",
	// 				query: { subcategoryList:subcategoryList, },
	// 			})
	// 		);
	// }, [dispatch]);

	const columns = [
		{
			title: "Collection Name",
			//dataIndex: "collection",
			key: "collection",
			responsive: ["sm"],
			width: "30%",
			render: value => value || "-",
		},
		{
			title: "Order",
			dataIndex: "order",
			key: "order",
			width: "10%",
			responsive: ["sm"],
			render: (text, data, index) => {
				return (
					<Field
						className="md:w-24 main-wrp"
						component={ReduxFormTextField1}
						min={0}
						max={999}
						step={1}
						type="number"
						onWheel={e => onWheelHandler(e)}
						//placeholder="Enter MRP"
						name={`[${index}.order]`}
					/>
				);
			},
		},
	];

	const colorcolumn = [
		{
			title: "Color Name",
			//dataIndex: "collection",
			key: "Colors",
			responsive: ["sm"],
			width: "30%",
			render: value => value || "-",
		},
	];

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			<Helmet>
				<title>PDF Generator</title>
			</Helmet>
			<form onSubmit={handleSubmit}>
				<h6 className="text-2xl font-bold md:mb-5 mb-10">{title}</h6>
				<div className="md:flex block md:flex-wrap">
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Category"
							name="category"
							options={categoryOptions}
							placeholder="Select Main Items"
							isLoading={isFetchingDropdownList}
							isMulti
							disabled={subCategory?.length}
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Sub-Category"
							name="subCategory"
							options={subCategoryOptions}
							placeholder="Select Subcategories"
							isLoading={isFetchingDropdownList}
							disabled={
								!category?.length || mainItem?.length || generatedColors?.length
							}
							isMulti
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Season"
							name="season"
							options={seasonOptions}
							placeholder="Select Seasons"
							isLoading={isFetchingDropdownList}
							disabled={mainItem?.length || generatedColors?.length}
							isMulti
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Collection"
							name="collection"
							options={collectionsOptions}
							placeholder="Select Main Items"
							isLoading={isFetchingDropdownList}
							disabled={mainItem?.length || generatedColors?.length}
							isMulti
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Main Item"
							name="mainItem"
							options={mainItemsOptions}
							placeholder="Select Main Items"
							isLoading={isFetchingDropdownList}
							isMulti
							disabled={!subCategory?.length || colors?.length}
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Colours"
							name="colors"
							options={rawMaterialColorsOptions}
							placeholder="Select Colours"
							isLoading={isFetchingDropdownList}
							isMulti
							disabled={!mainItem?.length}
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Specification"
							name="specification"
							options={specificationOptions}
							placeholder="Select Specification"
							isMulti
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Status"
							name="status"
							options={statusOptions}
							placeholder="Select Status"
							isMulti
							disabled={!subCategory?.length}
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Chalan Status"
							name="chalanStatus"
							options={chalanOptions}
							placeholder="Select Status"
							disabled={!subCategory?.length}
						/>
					</div>
					{lotSets && (
						<div className="md:mr-10 mb-5">
							<Field
								component={ReduxFormSelectField}
								label="Sets Lot"
								name="lots"
								options={lot_setOptions}
								placeholder="Select Sets"
								isMulti
								disabled={!subCategory?.length}
							/>
						</div>
					)}

					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Preview"
							name="preview"
							options={previewOptions}
							placeholder="Select Preview"
							isMulti
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Main Item Status"
							name="main_item_statuses"
							options={mainItemStatusesOptions}
							placeholder="Select Main Item Statuses"
							isMulti
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Colours Per Page"
							name="color_per_page"
							options={colorPerPageOptions}
							placeholder="Select Colours Per Page"
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Sizes"
							name="sizes"
							options={SizesOptions}
							placeholder="Select Size"
						/>
					</div>
					{sizes?.value === "Sets" && (
						<div className="md:mr-10 mb-5">
							<Field
								label="Number of Sets"
								name="setMin"
								component={ReduxFormTextField}
								type="number"
								className="md:!w-28 !w-[45%] !me-5"
								min={0}
								max={999}
								step={1}
								onWheel={e => onWheelHandler(e)}
								placeholder="From"
								disabled={isViewOnly}
							/>

							<Field
								className="md:!w-28 !w-[45%]"
								name="setMax"
								component={ReduxFormTextField}
								type="number"
								min={0}
								max={999}
								step={1}
								onWheel={e => onWheelHandler(e)}
								placeholder="To"
								disabled={isViewOnly}
							/>
						</div>
					)}
					{sizes?.value === "Quantities" && (
						<div className="md:mr-10 mb-5">
							<Field
								label="Number of Quantities"
								name="qtyMin"
								component={ReduxFormTextField}
								type="number"
								className="md:!w-28 !w-[45%] !me-5"
								min={0}
								max={999}
								step={1}
								onWheel={e => onWheelHandler(e)}
								placeholder="From"
								disabled={isViewOnly}
							/>
							<Field
								className="md:!w-28 !w-[45%]"
								name="qtyMax"
								component={ReduxFormTextField}
								type="number"
								min={0}
								max={999}
								step={1}
								onWheel={e => onWheelHandler(e)}
								placeholder="To"
								disabled={isViewOnly}
							/>
						</div>
					)}

					<div className="md:mr-10 mb-5">
						<Field
							label="MRP"
							name="mrpMin"
							component={ReduxFormTextField}
							type="number"
							className="md:!w-28 !w-[45%] !me-5"
							min={0}
							max={99999}
							step={1}
							onWheel={e => onWheelHandler(e)}
							placeholder="From"
							disabled={isViewOnly}
						/>
						<Field
							className="md:!w-28 !w-[45%]"
							name="mrpMax"
							component={ReduxFormTextField}
							type="number"
							min={0}
							max={99999}
							step={1}
							onWheel={e => onWheelHandler(e)}
							placeholder="To"
							disabled={isViewOnly}
						/>
					</div>
					{sizes?.value === "Sizes" && (
						<div className="md:mr-10 mb-5">
							<Field
								label="Size"
								name="sizeMin"
								component={ReduxFormSelectField}
								options={sizesSelect}
								type="number"
								className="md:!w-28 !w-[45%] !me-5"
								placeholder="From"
								disabled={isViewOnly}
							/>
						</div>
					)}
					{ready || wip ? (
						<>
							<div className="md:mr-10 mb-5">
								<Field
									component={ReduxFormSelectField}
									label="Generated Colors"
									name="generatedColors"
									options={generatedRawMaterialColorsOptions}
									placeholder="Select Colors"
									isLoading={isFetchingDropdownList}
									isMulti
									disabled={!subCategory?.length}
								/>
							</div>
						</>
					) : lotSets && lotList?.length ? (
						<>
							<div className="md:mr-10 mb-5">
								<Field
									component={ReduxFormSelectField}
									label="Generated Colors"
									name="generatedColors"
									options={generatedRawMaterialColorsOptions}
									placeholder="Select Colors"
									isLoading={isFetchingDropdownList}
									isMulti
									disabled={!subCategory?.length}
								/>
							</div>
						</>
					) : (
						<></>
					)}

					{wip && (
						<div className="md:mr-10 mb-5">
							<Field
								component={ReduxFormSelectField}
								label="Date Type"
								name="dateType"
								options={dateOptions}
								placeholder="Select Date Type"
							/>
						</div>
					)}
					{dateType?.value === "date" && (
						<div className="md:mr-10 mb-5">
							<Field
								label="Date"
								name="date"
								component={ReduxFormTextField}
								type="date"
								placeholder="Enter Date"
							/>
						</div>
					)}
					{dateType?.value === "days" && (
						<div className="md:mr-10 mb-5">
							<Field
								label="Number of days"
								name="noOfDays"
								component={ReduxFormTextField}
								type="number"
								min={0}
								max={999}
								step={1}
								onWheel={e => onWheelHandler(e)}
								placeholder="Enter number of days"
								disabled={isViewOnly}
							/>
						</div>
					)}
				</div>
				{renderModalButtons(onCancel, isViewOnly, isGeneratingPdf)}
				<br></br>
				{subCategory?.length && (
					<Button
						className="flex items-center bg-transparent hover:bg-primaryDark text-primaryBase hover:!text-white font-semibold px-2 py-1 mr-10 border border-primaryBase hover:border-transparent rounded mb-9"
						type="button"
						onClick={getList}
					>
						Get sorted list
					</Button>
				)}
				{tableShow && (
					<Table
						dataSource={collection_list}
						columns={columns}
						rowKey={collection_list => collection_list?.id}
						pagination={false}
						//loading={isFetchingMasterList}
						bordered
					/>
				)}

				{(ready || wip) && (
					<>
						<Button onClick={handlePrint} type="primary" danger>
							{" "}
							Export to PDF{" "}
						</Button>
						<div ref={componentRef} style={{ width: "100%" }}>
							<Table
								dataSource={color}
								columns={colorcolumn}
								rowKey={generatedRawMaterialColorsOptions =>
									generatedRawMaterialColorsOptions?.value
								}
								pagination={false}
								//loading={isFetchingMasterList}
								bordered
							/>
						</div>
					</>
				)}
			</form>
		</>
	);
};

export default reduxForm({
	form: formName,
	validate: values => {
		const errors = {};

		isRequired(values?.category) && (errors.category = "Required");
		isRequired(values?.subCategory) && (errors.subCategory = "Required");
		// isRequired(values?.season) && (errors.season = "Required");
		// isRequired(values?.collection) && (errors.collection = "Required");
		// isRequired(values?.mrpMin) && (errors.mrpMin = "Required");
		// isRequired(values?.mrpMax) && (errors.mrpMax = "Required");

		return errors;
	},
})(PdfGeneratorForm);
