import React, {useState, useEffect} from 'react'
import { Button } from 'antd'
import { Form, Field  } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { getMasterList, masterSelectors, editMasterList, addMasterList, getMasterLogsList } from 'slices/masterSlice'
import Activity from '../common/Activity';
import { useHotkeys } from 'react-hotkeys-hook'

const masterName = "city"
const CityDetails = ({data, setData, type, setType}) => {

  const [isEnabled, setIsEnabled] = useState(true)
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  // const logLoading = useSelector(masterSelectors?.getIsFetchingLogs);
  const logs = useSelector(masterSelectors?.getCityLogs);

  useEffect(()=>{
    if(data?.id){
      dispatch(getMasterLogsList({masterName:"City", id: data?.id}))
      setIsEnabled(true)
    }
  },[data])

  const onSubmit = async values => {
    let res = null;
    if(type === 'new'){
      res = await dispatch(
        addMasterList({
          masterName,
          formData:values,
        })
      );
    }else{
      res = await dispatch(
        editMasterList({
          masterName,
          formData:values,
        })
      );
    }
      if(res?.type === "master/edit/fulfilled" || res?.type === "master/add/fulfilled"){
        setData({})
        dispatch(getMasterList({
          masterName
        }))
      }
    }

    const handleClear = () =>{
      setData({})
      setType("update")
    }

      const required = value => (value ? undefined : 'Required')
      useHotkeys('alt+e', () => setIsEnabled(false))
    useHotkeys('alt+l', () => handleClear())
    useHotkeys('alt+s', (event) => {
      event.preventDefault();
      document.getElementById('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    });


  return (
    <>
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id='form'>
    <>
   <div className='flex justify-between items-center mt-5'>

   <p className='font-bold mb-3'>Record Details:</p>
   <div className='flex gap-4'>
   <Button
   disabled={isEnabled}
   type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
   onClick={handleClear}
   >{type === "new" ? "Cancel" : "Clear"}</Button>
   
   <Button
   disabled={isEnabled || loading}
   type="primary" size="small" className='bg-redBase' htmlType='submit'
   loading={loading}
   >{type === "new" ? "Add As New" : "Update"}</Button>
   
   </div>
   </div>
    <div className='flex gap-3'>
        <div className=''>
            <Field name="cityName" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="City name"
              disabled={isEnabled}
              />
            )}
          </Field>
        </div>
        <div className=''>
        <Field name="state" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="State"
              disabled={isEnabled}
              />
            )}
          </Field>
        </div>
        
    </div>
    </>
    </form>
      )}/>
      {logs?.length ?
      <Activity logs={logs}/>
    :null  
    }
    </>
  )
}

export default CityDetails