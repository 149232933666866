import React, {useEffect, useState} from 'react'
import { Button, Select, Tooltip, Checkbox, Table } from 'antd'
import { Form, Field  } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { getMasterList, masterSelectors, editMasterList, addMasterList, getMasterDropDownList, getMasterLogsList } from 'slices/masterSlice'
import arrayMutators from 'final-form-arrays'
import Activity from '../common/Activity';
import { useHotkeys } from 'react-hotkeys-hook'
import { ReduxFormSelectField2 } from 'utils/ReduxFormSelectField';

const masterName = "customer"
const cityDropdownMaster = "city_dropdown"
const agentDropdownMaster = "agent_dropdown"
const transportDropdownMaster = "transport_dropdown"

const CustomerDetails = ({data, setData, type, setType}) => {

let [customerContactPerson, setCustomerContactPerson] = useState([])
let [customerDeliveryDetails, setCustomerDeliveryDetails] = useState([])

useEffect(()=>{
  console.log('yes')
  setCustomerContactPerson(data?.customerContactPerson);
  setCustomerDeliveryDetails(data?.customerDeliveryDetails);
},[data])

const handleContactPersonChange = (index, name, value)=>{
  let temp = [...customerContactPerson];
  temp[index] = { ...temp[index], [name]: value };
  setCustomerContactPerson(temp)
}
const handleAddContactPerson = () =>{
  let length = customerContactPerson?.length;
    let newItem = {
    department:"",
    designation:"",
    emailId:"",
    mobileNo:"",
    name:"",
    id:`t-${length+1}`
    }
    setCustomerContactPerson([...customerContactPerson, newItem]);
}

const handleRemoveContactPerson = (id) =>{
  let temp = customerContactPerson;
  temp = temp?.filter((x) => x?.id !== id)
  setCustomerContactPerson([...temp])
}

const handleDeliveryChange = (index, name, value)=>{
  let temp = [...customerDeliveryDetails];
  temp[index] = { ...temp[index], [name]: value };
  setCustomerDeliveryDetails(temp)
}

const handleAddDelivery = () =>{
  let length = customerDeliveryDetails?.length;
    let newItem = {
    addressLine1:"",
    cityId:"",
    country:"",
    state:"",
    pincode:"",
    id:`t-${length+1}`
    }
    setCustomerDeliveryDetails([...customerDeliveryDetails, newItem]);
}

const handleRemoveDelivery = (id) =>{
  let temp = customerDeliveryDetails;
  temp = temp?.filter((x) => x?.id !== id)
  setCustomerDeliveryDetails([...temp])
}

  const [isEnabled, setIsEnabled] = useState(true)
  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const cityDropdown = useSelector(masterSelectors?.getCityDropdown);
  const agentDropdown = useSelector(masterSelectors?.getAgentDropdown);
  const transportDropdown = useSelector(masterSelectors?.getTransportDropdown);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);

  const logs = useSelector(masterSelectors?.getCustomerLogs);

  useEffect(()=>{
    if(data?.id){
      dispatch(getMasterLogsList({masterName:"Customer", id: data?.id}))
      setIsEnabled(true)
    }
  },[data])

  useEffect(()=>{
    dispatch(getMasterDropDownList({masterName:cityDropdownMaster}))
    dispatch(getMasterDropDownList({masterName:agentDropdownMaster}))
    dispatch(getMasterDropDownList({masterName:transportDropdownMaster}))
  },[])

  const [whatsappEnable, setWhatsappEnable] = useState(false)
  const [emailEnable, setEmailEnable] = useState(false)
  const [documentImg, setDocumentImg] = useState(null)
console.log('data', data)
  const onSubmit = async values => {
    let customerCount = 0;
    let deliveryCount = 0;

  customerContactPerson?.map((item)=>{
    if(!item?.department || !item?.designation || !item?.emailId || !item?.mobileNo || !item?.name) customerCount+=1;
  })
  if(customerCount > 0) return alert("Please complete contact person details")

    customerDeliveryDetails?.forEach((item)=>{
      if(!item?.cityId) item.cityId = item?.city?.id
    })

  customerDeliveryDetails?.map((item)=>{
    if(!item?.addressLine1 || !item?.cityId || !item?.country || !item?.state || !item?.pincode) deliveryCount+=1;
  })
  if(deliveryCount > 0) return alert("Please complete delivery details")
  
  customerContactPerson?.forEach((item)=>{
    if(item?.id?.toString()?.includes('t')) delete item.id
  })
  customerDeliveryDetails?.forEach((item)=>{
    if(item?.id?.toString()?.includes('t')) delete item.id
  })

  values.customerContactPerson = customerContactPerson;
  values.customerDeliveryDetails = customerDeliveryDetails;
  
  if(whatsappEnable === true){
    values.whatsappEnable = "1"
  }else{
    values.whatsappEnable = "0"
  }
  if(emailEnable === true){
    values.emailEnable = "1"
  }else{
    values.emailEnable = "0"
  }
  let tempAgent = []
  values?.agentId?.map((item)=> tempAgent.push(item?.value))
  values.agentId = tempAgent;

  const formData = new FormData()
  formData.append("customer", new Blob([JSON.stringify({...values})], 
      {type:"application/json"}))
  formData.append("document", documentImg)
  
    let res = null;
    if(type === 'new'){
      res = await dispatch(
        addMasterList({
          masterName,
          formData,
        })
      );
    }else{
      res = await dispatch(
        editMasterList({
          masterName,
          formData,
          id:data?.id,
          configType: "multipart/form-data",
        })
      );
    }
      if(res?.type === "master/edit/fulfilled" || res?.type === "master/add/fulfilled"){
        setData({})
        dispatch(getMasterList({
          masterName
        }))
      }
    }

    const handleClear = () =>{
      setData({})
      setType("update")
    }

      const required = value => (value ? undefined : 'Required')
      useHotkeys('alt+e', () => setIsEnabled(false))
    useHotkeys('alt+l', () => handleClear())
    useHotkeys('alt+s', (event) => {
      event.preventDefault();
      document.getElementById('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    });

    const nestedColumn = [
    {
      title: "Contact Person",
      dataIndex: "name",
      key: "name",
      width: "auto",
      render:(data, _, index)=> <input type="text" className={`c-input border`} value={data}
      onChange={(e)=>handleContactPersonChange(index, 'name', e.target.value)}/>
    },
    {
      title: "Email",
    dataIndex: "emailId",
    key: "emailId",
    width: "auto",
    render:(data, _, index)=> <input type="text" className={`c-input border`} value={data}
      onChange={(e)=>handleContactPersonChange(index, 'emailId', e.target.value)}/>
    },
    {
      title: "Mobile No.",
    dataIndex: "mobileNo",
    key: "mobileNo",
      width: "auto",
      render:(data, _, index)=> <input type="number" className={`c-input border`} value={data}
      onChange={(e)=>handleContactPersonChange(index, 'mobileNo', e.target.value)}/>
    },
    {
      title: "Department",
    dataIndex: "department",
    key: "department",
      width: "auto",
      render:(data, _, index)=> <input type="text" className={`c-input border`} value={data}
      onChange={(e)=>handleContactPersonChange(index, 'department', e.target.value)}/>
    },
    {
      title: "Designation",
    dataIndex: "designation",
    key: "designation",
      width: "auto",
      render:(data, _, index)=> <input type="text" className={`c-input border`} value={data}
      onChange={(e)=>handleContactPersonChange(index, 'designation', e.target.value)}/>
    },
    {
      title: "Action",
      width: "auto",
      render:(data)=> <Button type='primary' className='bg-redBase' size='small'  disabled={isEnabled}
      onClick={()=>handleRemoveContactPerson(data?.id)}
      >Remove</Button>
    }
    ]

    const nestedColumn2 = [
    {
    title: "Address",
    dataIndex: "addressLine1",
    key: "addressLine1",
    width: "auto",
    render:(data, _, index)=> <input type="text" className={`c-input border`} value={data}
    onChange={(e)=>handleDeliveryChange(index, 'addressLine1', e.target.value)}/>
    },
    {
    title: "City",
    dataIndex: "cityId",
    key: "cityId",
    width: "auto",
    render:(data, _, index)=> <Select
    style={{width:"192px", borderRadius:'6px'}}
    showSearch
    loading={loadingDropdown}
    placeholder="City"
    allowClear
    defaultValue={{label:data?.cityName, value:data?.id}}
    onSearch={e => dispatch(getMasterDropDownList({masterName:cityDropdownMaster, query:{name:e}}))}
    options={cityDropdown}
    onChange={(e)=>handleDeliveryChange(index, 'cityId', e)}/>
    },
    {
    title: "State",
    dataIndex: "state",
    key: "state",
    width: "auto",
    render:(data, _, index)=> <input type="text" className={`c-input border`} value={data}
    onChange={(e)=>handleDeliveryChange(index, 'state', e.target.value)}/>
    },
    {
    title: "Pincode",
    dataIndex: "pincode",
    key: "pincode",
    width: "auto",
    render:(data, _, index)=> <input type="text" className={`c-input border`} value={data}
    onChange={(e)=>handleDeliveryChange(index, 'pincode', e.target.value)}/>
    },
    {
    title: "Country",
    dataIndex: "country",
    key: "country",
    width: "auto",
    render:(data, _, index)=> <input type="text" className={`c-input border`} value={data}
    onChange={(e)=>handleDeliveryChange(index, 'country', e.target.value)}/>
    }, 
    {
    title: "Action",
    width: "auto",
    render:(data)=> <Button type='primary' className='bg-redBase' size='small'  disabled={isEnabled}
    onClick={()=>handleRemoveDelivery(data?.id)}
    >Remove</Button>
    }
    ]



  return (
    <>
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators
      }}
      initialValues={data}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} id='form'>
    <>
   <div className='flex justify-between items-center mt-5'>

   <p className='font-bold mb-3'>Record Details:</p>

   <div className='flex gap-4'>
   <Button
   disabled={isEnabled}
   type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
   onClick={handleClear}
   >{type === "new" ? "Cancel" : "Clear"}</Button>
   
   <Button
   disabled={isEnabled || loading}
   type="primary" size="small" className='bg-redBase' htmlType='submit'
   loading={loading}
   >{type === "new" ? "Add As New" : "Update"}</Button>
   
   </div>
   </div>

   <div className='flex gap-5'>
        <div className=''>
            <Field name="partyName" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='text' label="Name"/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="telephone" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='number' label="Telephone No."/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="emailId" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='text' label="Email"/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="gstin" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='text' label="GSTIN"/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="pan" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='text' label="PAN"/>
            )}
          </Field>
        </div>
        <div className=''>
        <Field name="mobile" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='number' label="Mobile No."/>
            )}
          </Field>
        </div>
        <div className=''>
        <Field
        disabled={isEnabled}
        component={ReduxFormSelectField2}
        validate={required}
        label="Agents"
        name="agentId"
        placeholder="Select agents"
        defaultValue={data?.agent?.map((item)=> {return{label:item?.agentName, value:item?.id}})}
        options={agentDropdown}
        isLoading={loadingDropdown}
        isMulti
      />
        </div>
    </div>
    <div className='flex gap-5 mt-4'>
    <div className=''>
        <Field name="addressLine1" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='text' label="Address" style='w-[385px]'/>
            )}
          </Field>
        </div> 

        <div>
          <label>City</label><br></br>
          <Field name="cityId" validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"192px", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="City"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:cityDropdownMaster, query:{name:e}}))}
					options={cityDropdown}
          {...input}
          disabled={isEnabled}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div className=''>
        <Field name="country" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='text' label="Country"/>
            )}
          </Field>
        </div> 
        <div className=''>
        <Field name="pincode" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='number' label="Pincode"/>
            )}
          </Field>
        </div> 
        <div className=''>
        <Field name="paymentTerms" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='text' label="Payment Terms"/>
            )}
          </Field>
        </div> 
        <div className=''>
        <Field name="creditLimit" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='number' label="Credit limit"/>
            )}
          </Field>
        </div>

    </div>
    <div className='flex my-4 gap-5'>
    <div className=''>
        <Field name="notes_SO">
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='text' label="Notes SO"/>
            )}
        </Field>
    </div>
    <div className=''>
        <Field name="notes_SI">
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='text' label="Notes SI"/>
            )}
        </Field>
    </div>

    <div>
          <label>Transport</label><br></br>
          <Field name={`transportId`} validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"192px", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="Transport"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:transportDropdownMaster, query:{name:e}}))}
					options={transportDropdown}
          {...input}
          disabled={isEnabled}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div className=''>
              <div>
                <label>Whatsapp Enable</label><br></br>
                <Checkbox value={whatsappEnable} onChange={e=>setWhatsappEnable(e.target.checked)} disabled={isEnabled}/>
              </div>
    </div>
    <div className=''>
              <div>
              <label>Email Enable</label><br></br>
              <Checkbox  value={emailEnable} onChange={e=>setEmailEnable(e.target.checked)} disabled={isEnabled}/>
            </div>
    </div>
    <div className=''>
        <Field name="notes" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2
               disabled={isEnabled}
              input={input} meta={meta} type='text' label="Notes" style='w-[385px]'/>
            )}
          </Field>
        </div> 

    </div>

    <div className='flex justify-between items-center my-2'>
    <p className='mb-0 font-bold text-primaryBase'>Contact Persons:</p>
    <div>
      <Button type='primary' size='small' onClick={handleAddContactPerson}  disabled={isEnabled}>Add New</Button>
    </div>
    </div>
    <Table
    dataSource={customerContactPerson}
    columns={nestedColumn}
    pagination={false}
    bordered
    />

    <div className='flex justify-between items-center my-2'>
    <p className='mb-0 font-bold text-primaryBase'>Delivery Details :</p>
    <div>
      <Button type='primary' size='small' onClick={handleAddDelivery}  disabled={isEnabled}>Add New</Button>
    </div>
    </div>
    <Table
    dataSource={customerDeliveryDetails}
    columns={nestedColumn2}
    pagination={false}
    bordered
    />
   
    </>
    </form>
      )}/>
      {logs?.length ?
      <Activity logs={logs}/>
    :null  
    }
    </>
  )
}

export default CustomerDetails