import { components as reactSelectComponent } from "react-select";

import { Tooltip } from "antd";

const ValueContainerComponent = ({ children, ...props }) => {
	const { getValue } = props;
	const valueLength = getValue()?.length;
	let renderChildren = null;
	if (valueLength === 0) {
		renderChildren = children[0];
	} else if (valueLength === 1) {
		const valueText = `${getValue()?.[0]?.label}`;
		renderChildren = (
			<Tooltip title={valueText}>
				<p className="whitespace-nowrap text-ellipsis overflow-hidden">
					{valueText}
				</p>
			</Tooltip>
		);
	} else {
		const valueText =
			getValue()?.[0]?.value === "*"
				? `${getValue()?.[1]?.label} +(${valueLength - 2})`
				: `${getValue()?.[0]?.label} +(${valueLength - 1})`;
		renderChildren = (
			<Tooltip title={valueText}>
				<p className="whitespace-nowrap text-ellipsis overflow-hidden">
					{valueText}
				</p>
			</Tooltip>
		);
	}
	return (
		<reactSelectComponent.ValueContainer {...props} className="!flex-nowrap">
			{renderChildren}
			{children[1]}
		</reactSelectComponent.ValueContainer>
	);
};

export default ValueContainerComponent;
