import { Button } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, change, formValueSelector, getFormValues, reduxForm } from "redux-form";
import { getMasterDropDownList, masterSelectors, ExportExcelFile } from "slices/masterSlice";
import { ReduxFormSelectField2 } from "utils/ReduxFormSelectField";

const formName = "ClosingStockEntry";
const ClosingStockExport = () => {

	const dispatch = useDispatch();
	const formValues = useSelector(getFormValues("ClosingStockEntry"));

	const subCategoryDropdown = useSelector(masterSelectors?.getSubCategoryDropdown);
	const mainItemDropdown = useSelector(masterSelectors?.getMainItemSubCategoryDropdown);
	const colorDropdown = useSelector(masterSelectors?.getColorDropdown);
  	const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);
	const exportLoading = useSelector(masterSelectors?.getIsImportingExcel);

	  useEffect(()=>{
		dispatch(getMasterDropDownList({masterName:"subcategory_dropdown"}))
	  },[])

	  useEffect(()=>{
		if(formValues !== undefined && formValues?.subCategory?.length > 0){
			let asString = ""
			formValues?.subCategory?.map((item)=>{
				asString += `subcategory=${item?.value}&`
			})
			dispatch(getMasterDropDownList({masterName:"mainitem_subcategory",query:{}, asString }))
		}
		
	  },[formValues?.subCategory])

	  useEffect(()=>{
		if(formValues?.mainItem !== undefined && formValues?.mainItem?.length > 0){
			let asString = ""
			formValues?.mainItem?.map((item)=>{
				asString += `subcategory=${item?.value}&`
			})
			dispatch(getMasterDropDownList({masterName:"color_dropdown",query:{}, asString }))
		}
		
	  },[formValues?.mainItem])

	const [show, setShow] = useState(false);

	const handleFilter = () => {
		const val = {
			subcategory: formValues?.subCategory?.map(item => item?.value) || [],
			mainItem: formValues?.mainItem?.map(item => item?.label) || [],
			color: formValues?.color?.map(item => item?.label) || [],
		};

		dispatch(
			ExportExcelFile({
				masterName: "closingstock/export/excel",
				formData: val,
			})
		);
	};

	const handleResetFilter = () => {
		const val = {
			subCategory: [],
			mainItem: [],
			color: [],
		};
		dispatch(change("ClosingStockEntry", "subCategory", null));
		dispatch(change("ClosingStockEntry", "mainItem", null));
		dispatch(change("ClosingStockEntry", "color", null))
	};

	return (
		<>
			{show ? (
				<>
					<div className="md:flex block">
							<>
								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="sub Category"
										name="subCategory"
										options={subCategoryDropdown}
										placeholder="Select sub category"
										isLoading={loadingDropdown}
										isMulti
									/>
								</div>

								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="Main Item"
										name="mainItem"
										options={mainItemDropdown}
										placeholder="Select Main Items"
										isLoading={loadingDropdown}
										isMulti
										disabled={formValues === undefined || formValues?.subCategory?.length === 0}
									/>
								</div>
								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="Color"
										name="color"
										placeholder="Enter color"
										options={colorDropdown}
										isLoading={loadingDropdown}
										isMulti
										disabled={formValues?.mainItem === undefined || formValues?.mainItem?.length === 0}
									/>
								</div>

								<div className="md:mr-5 ">
									<Button
										className="mt-2 text-primaryBase border-primaryDark font-semibold"
										onClick={handleFilter}
										size="small"
										loading={exportLoading}
									>
										Export
									</Button>
								</div>
								<div className="md:mr-5 ">
									<Button
										className="mt-2 text-primaryBase border-primaryDark font-semibold"
										onClick={handleResetFilter}
										size="small"
									>
										Reset
									</Button>
								</div>
							</>

						<div className="mt-2">
						<Button
							size="small"
							className="text-redBase border-redBase"
							onClick={() => setShow(false)}
						>
							Hide Filter
						</Button>
						</div>
					</div>

				</>
			) : (
				<div className="flex justify-end items-center md:mr-5">
					<Button
						className="text-primaryBase border-primaryDark"
						onClick={() => setShow(true)}
						size="small"
					>
						Show Export Filter
					</Button>
				</div>
			)}
		</>
	);
};


export default reduxForm({
	form: formName,
})(ClosingStockExport);