import { components as reactSelectComponent } from "react-select";

import { FixedSizeList as List } from "react-window";

const MenuListComponent = ({ children, ...props }) => {
	return children?.length ? (
		<List
			height={children?.length > 9 ? 300 : children?.length * 35}
			itemCount={children?.length}
			itemSize={35}
		>
			{({ index, style }) => <div style={style}>{children[index]}</div>}
		</List>
	) : (
		<reactSelectComponent.MenuList {...props}>
			{children}
		</reactSelectComponent.MenuList>
	);
};

export default MenuListComponent;
