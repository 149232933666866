import { Button, Modal, Table } from "antd";
import TableOptions from "components/TableOptions";
import useMasterLogic from "customHooks/useMasterLogic";
import useSearchFilter from "customHooks/useSearchFilter";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
	getLadtSyncDate,
	getMasterList,
	masterSelectors,
	uploadExcel,
} from "slices/masterSlice";
import { ReduxFormFileField } from "utils/ReduxFormFileField";
import { renderMobileColumn } from "utils/renderMobileColumn";
import SaleOrderFilterV2 from "./SaleOrderFilterV2";

const masterName = "saleOrder";

const formName = "excel";
const formSelector = formValueSelector(formName);

const SalesOrderReportV2 = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const saleOrderListMain = useSelector(
		masterSelectors?.getSalesOrderReportList
	);

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);

	const SaleOrderLastSyncDate = useSelector(masterSelectors?.getLastSyncDate);

	const { getColumnSearchProps, tableFilters } = useSearchFilter();

	// console.log(saleOrderList);

	const [saleOrderList, setSaleOrderList] = useState(
		useSelector(masterSelectors?.getSalesOrderReportList)
	);

	useEffect(() => {
		setSaleOrderList(saleOrderListMain);
	}, [saleOrderListMain]);

	var size85 = 0;
	var size90 = 0;
	var size95 = 0;
	var size100 = 0;
	var size105 = 0;
	var size110 = 0;
	var size115 = 0;
	var size120 = 0;
	var total = 0;

	let totalOfTotal = 0;

	saleOrderList?.forEach(se => {
		size85 += +se?.size34 || 0;
		size90 += +se?.size36 || 0;
		size95 += +se?.size38 || 0;
		size100 += +se?.size40 || 0;
		size105 += +se?.size42 || 0;
		size110 += +se?.size44 || 0;
		size115 += +se?.size46 || 0;
		size120 += +se?.size48 || 0;

		const totalItem =
			se.size34 +
			se.size36 +
			se.size38 +
			se.size40 +
			se.size42 +
			se.size44 +
			se.size46 +
			se.size48;
		totalOfTotal += totalItem;
	});

	const getSaleOrderData = useCallback(() => {
		dispatch(
			getMasterList({
				masterName,
				query: {
					...tableFilters,
				},
			})
		);
	}, [tableFilters, dispatch]);

	const { handlePageChange } = useMasterLogic(getSaleOrderData, masterName);

	useEffect(() => {
		dispatch(getLadtSyncDate({ type: "Sale Order" }));
	}, [dispatch]);

	const [filters, setFilters] = useState({}); // State to hold filters

	const handleFilterChange = (column, filtersValue) => {
		setFilters({
			...filters,
			[column]: filtersValue,
		});
	};

	useEffect(() => {
		//console.log("filters:", Object.values(filters));
		let obj = Object.values(filters);

		let tempData = saleOrderListMain;

		let mainItem = obj?.[0]?.["mainItem"] || [];
		let subCategory = obj?.[0]?.["subCategory"] || [];
		let party = obj?.[0]?.["party"] || [];
		//console.log(supplier, main_item, color_name, sub_category_name);

		if (mainItem?.length)
			tempData = tempData.filter(item => mainItem.includes(item?.mainItem));
		if (subCategory?.length)
			tempData = tempData.filter(item =>
				subCategory.includes(item?.subCategory)
			);
		if (party?.length)
			tempData = tempData.filter(item => party.includes(item?.party));

		//	console.log("fd", tempData);
		setSaleOrderList(tempData);
	}, [filters]);

	const subcategories = Array.from(
		new Set(saleOrderList.map(item => item.subCategory))
	);

	const subCategoryFilters = subcategories.map(subcategory => ({
		text: subcategory,
		value: subcategory,
	}));

	const mainItems = Array.from(
		new Set(saleOrderList.map(item => item.mainItem))
	);

	const mainItemFilters = mainItems.map(mainItems => ({
		text: mainItems,
		value: mainItems,
	}));

	const party = Array.from(new Set(saleOrderList.map(item => item.party)));

	const partyFilters = party.map(colors => ({
		text: colors,
		value: colors,
	}));

	const excelFile = useSelector(state => formSelector(state, "item_excel"));

	const columns = [
		{
			title: "Sales Order",

			render: data => {
				const primaryText = `${data?.mainItem || "-"}-${data?.color || "-"}-${
					data?.subCategory || "-"
				}`;
				const secondaryText = `${data?.size34 || "0"}-${data?.size36 || "0"}-${
					data?.size38 || "0"
				}-${data?.size40 || "0"}-${data?.size42 || "0"}-${
					data?.size44 || "0"
				}-${data?.size46 || "0"}- ${data?.size48 || "0"}`;
				return renderMobileColumn(primaryText, secondaryText);
			},
			responsive: ["xs"],
			...getColumnSearchProps("Search", "universal_search"),
		},
		{
			title: "Sub Category",
			dataIndex: "subCategory",
			key: "subCategory",
			responsive: ["sm"],
			render: value => value || "-",
			filters: subCategoryFilters,
			onFilter: (value, record) => {
				return record.subCategory.startsWith(value);
			},
			filterSearch: true,
		},
		{
			title: "Main Item",
			dataIndex: "mainItem",
			key: "mainItem",
			responsive: ["sm"],
			width: 90,
			render: value => value || "-",
			filters: mainItemFilters,
			onFilter: (value, record) => {
				return record.mainItem.startsWith(value);
			},
			filterSearch: true,
		},
		{
			title: "Party",
			dataIndex: "party",
			key: "party",
			responsive: ["sm"],
			render: value => value || "-",
			filters: partyFilters,
			onFilter: (value, record) => {
				return record.party.startsWith(value);
			},
			filterSearch: true,
		},
		{
			title: `85 (Total-${size85}pcs)`,
			dataIndex: "size34",
			key: "size34",
			responsive: ["sm"],
			width: 130,
			render: value => value || "0",
		},
		{
			title: `90 (Total-${size90}pcs)`,
			dataIndex: "size36",
			key: "size35",
			responsive: ["sm"],
			width: 130,
			render: value => value || "0",
		},
		{
			title: `95 (Total-${size95}pcs)`,
			dataIndex: "size38",
			key: "size38",
			responsive: ["sm"],
			width: 130,
			render: value => value || "0",
		},
		{
			title: `100 (Total-${size100}pcs)`,
			dataIndex: "size40",
			key: "size40",
			responsive: ["sm"],
			width: 135,
			render: value => value || "0",
		},
		{
			title: `105 (Total-${size105}pcs)`,
			dataIndex: "size42",
			key: "size42",
			responsive: ["sm"],
			width: 130,
			render: value => value || "0",
		},
		{
			title: `110 (Total-${size110}pcs)`,
			dataIndex: "size44",
			key: "size44",
			responsive: ["sm"],
			width: 130,
			render: value => value || "0",
		},
		{
			title: `115 (Total-${size115}pcs)`,
			dataIndex: "size46",
			key: "size46",
			responsive: ["sm"],
			width: 130,
			render: value => value || "0",
		},
		{
			title: `120 (Total-${size120}pcs)`,
			dataIndex: "size48",
			key: "size48",
			responsive: ["sm"],
			width: 130,
			render: value => value || "0",
		},
		{
			title: `Total (${totalOfTotal})`,
			key: "Total",
			dataIndex: "total",
			responsive: ["sm"],
			width: 90,
			render: (text, data, index) => {
				total =
					saleOrderList[index].size34 +
					saleOrderList[index].size36 +
					saleOrderList[index].size38 +
					saleOrderList[index].size40 +
					saleOrderList[index].size42 +
					saleOrderList[index].size44 +
					saleOrderList[index].size46 +
					saleOrderList[index].size48;
				return <p>{total}</p>;
			},
		},
		{
			title: "Instruction",
			dataIndex: "instruction",
			key: "instruction",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Order Date",
			dataIndex: "orderDate",
			key: "orderDate",
			responsive: ["sm"],
			render: value => value || "-",
		},
	];
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
		const form = new FormData();
		form.append("file", excelFile);
		dispatch(
			uploadExcel({
				masterName,
				formData: form,
				configType: "multipart/form-data",
			})
		);

		setTimeout(() => {
			dispatch(
				getMasterList({
					masterName,
					query: {
						...tableFilters,
					},
				})
			);
		}, 5000);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	return (
		<>
			<Helmet>
				<title>Sales-Order Report</title>
			</Helmet>

			<div className="flex justify-between">
            <TableOptions masterName={masterName} showAdd={false} />
            <div className="flex items-center">
			<SaleOrderFilterV2 data={saleOrderList}/>
				<Button size="small" onClick={showModal}>
					Upload Excel File
				</Button>
				<Link to="/working/sales-order/add" target='_blank'>
					<Button type="primary" size="small" className="ml-4">Add New</Button>
				</Link>
                </div>
			</div>

			<Modal
				title="Upload Excel File"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<Field
					name="item_excel"
					component={ReduxFormFileField}
					className="mt-1"
					placeholder="Item Image"
					itemname="excel file"
				/>
				<p className="mt-2 text-lg font-bold">{excelFile?.name}</p>
			</Modal>
			
			<Table
				dataSource={saleOrderList}
				columns={columns}
				rowKey={saleOrderList => saleOrderList?.id}
				pagination={false}
				loading={isFetchingMasterList}
				bordered
				onChange={handleFilterChange}
			/>
		</>
	);
};

export default reduxForm({
	form: formName,
})(SalesOrderReportV2);
