import { Button } from "antd";

import { useDebugValue, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  change,
  Field,
  formValueSelector,
  getFormValues,
  reduxForm,
  reset,
} from "redux-form";
import {
  getDropdownList,
  getMasterDropDownList,
  masterSelectors,
} from "slices/masterSlice";
import { ReduxFormSelectField } from "utils/ReduxFormSelectField";

const customerDropdownMaster = "customer_dropdown";
const subcategoryDropdownMaster = "subcategory_dropdown";
const formName = "saleorder-filter";
const formSelector = formValueSelector(formName);

function SaleOrderFilter({
  setColumnDefs,
  columnDefs,
  onAddSubCategory,
  onAddCustomer,
  setRowData,
  setShowTable,
  setTotalItems,
}) {
  const dispatch = useDispatch();

  const isFetchingDropdownList = useSelector(
    masterSelectors?.getIsFetchingDropdownList
  );

  const subCategoryOptions = useSelector(
    masterSelectors?.getSubCategoriesDropdownList
  );

  const generatedRawMaterialColorsOptions = useSelector(
    masterSelectors?.getGeneratedRawMaterialColorsDropdownList
  );

  const category = useSelector((state) => formSelector(state, "category"));
  const orderType = useSelector((state) => formSelector(state, "orderType"));
  const subCategory = useSelector((state) =>
    formSelector(state, "subCategory")
  );

  const generatedColors = useSelector((state) =>
    formSelector(state, "generatedColors")
  );

  const formValues = useSelector(getFormValues("saleorder-filter"));

  const customerDropDown = useSelector(masterSelectors?.getCustomerDropdown);

  useEffect(() => {
    dispatch(getMasterDropDownList({ masterName: customerDropdownMaster }));
    dispatch(getMasterDropDownList({ masterName: subcategoryDropdownMaster }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDropdownList({ masterName: "category" }));
  }, [dispatch]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    let categoryList = category?.map((cat) => cat?.value);
    if (categoryList?.[0] === "*") categoryList = categoryList?.splice(1);

    dispatch(
      getDropdownList({
        masterName: "subcategory",
        query: { categoryList: categoryList },
      })
    );
  }, [category, dispatch]);

  useEffect(() => {
    let subcategoryList = subCategory?.map((subCat) => subCat?.value);
    if (subcategoryList?.[0] === "*")
      subcategoryList = subcategoryList?.splice(1);

    if (subcategoryList?.length) {
      dispatch(
        getDropdownList({
          masterName: "generatedraw_material_colors",
          query: {
            subcategoryList,
          },
        })
      );
    }
  }, [subCategory]);
  useEffect(() => {
    dispatch(change(formName, "generatedColors", null));
  }, [dispatch]);

  function handleAddCustomer() {
    const selectedCustomers = formValues?.customer || [];
    if (selectedCustomers.length) {
      onAddCustomer(selectedCustomers);
      dispatch(change(formName, "customer", []));
    }
    setShowTable(true);
  }
  function handleAddSubCategory() {
    const selectedSubcategories = formValues?.subCategory || [];
    const selectedColors = formValues?.generatedColors || [];
    const totalColor = selectedColors.length;
    setTotalItems(totalColor);


    const orderType = formValues?.orderType?.value;
    console.log(orderType);

    const newRows = [];

    if (
      selectedSubcategories?.length &&
      selectedColors?.length &&
      orderType === "categorywise"
    ) {
      selectedSubcategories.forEach((subCat) => {
        const matchingColors = selectedColors.filter(
          (color) => color.subcategory === subCat.label
        );

        const ratioRow = {
          row: " Ratio",
          row2: "-",
        };

        columnDefs.forEach((columnDef) => {
          if (columnDef.children) {
            columnDef.children.forEach((child) => {
              const customerSizeField = child.field;
              ratioRow[customerSizeField] = 0;
            });
          }
        });
        newRows.push(ratioRow);
        matchingColors.forEach((color) => {
          const newRow = {
            row: subCat.label,
            row2: color.label,
          };

          columnDefs.forEach((columnDef) => {
            if (columnDef.children) {
              columnDef.children.forEach((child) => {
                const customerSizeField = child.field;

                newRow[customerSizeField] = 0;
              });
            }
          });

          newRows.push(newRow);
        });

        dispatch(change(formName, "generatedColors", []));
      });

      setRowData((prevRowData) => [...prevRowData, ...newRows]);
    }
    if (
      selectedSubcategories?.length &&
      selectedColors?.length &&
      orderType === "itemwise"
    ) {
      const mainItemGroups = selectedColors.reduce((acc, color) => {
        const { mainitem } = color;
        if (!acc[mainitem]) {
          acc[mainitem] = [];
        }
        acc[mainitem].push(color);
        return acc;
      }, {});

      Object.keys(mainItemGroups).forEach((mainitem) => {
        const ratioRow = {
          row: "Ratio",
          row2: "-",
        };

        columnDefs.forEach((columnDef) => {
          if (columnDef.children) {
            columnDef.children.forEach((child) => {
              const customerSizeField = child.field;
              ratioRow[customerSizeField] = 0;
            });
          }
        });

        newRows.push(ratioRow);

        mainItemGroups[mainitem].forEach((color) => {
          const newRow = {
            row: color.subcategory,
            row2: color.label,
          };

          columnDefs.forEach((columnDef) => {
            if (columnDef.children) {
              columnDef.children.forEach((child) => {
                const customerSizeField = child.field;
                newRow[customerSizeField] = 0;
              });
            }
          });

          newRows.push(newRow);
        });
      });

      setRowData((prevRowData) => [...prevRowData, ...newRows]);
    }
    setShowTable(true);
  }

  const orderTypeOptions = [
    { label: "Category wise", value: "categorywise" },
    { label: "Item wise", value: "itemwise" },
  ];

  return (
    <div>
      {show ? (
        <div className="flex">
          <div>
            <Field
              component={ReduxFormSelectField}
              label="Order Type"
              name="orderType"
              options={orderTypeOptions}
              placeholder="Select Order type"
              isLoading={isFetchingDropdownList}
              disabled={subCategory?.length || generatedColors?.length}
            />
          </div>

          <div className="md:mr-10 mb-5 ">
            <Field
              component={ReduxFormSelectField}
              label="Customer List"
              name="customer"
              options={customerDropDown}
              placeholder="Select Customer list"
              disabled={!orderType}
              isMulti
            />
            <Button onClick={handleAddCustomer}>Add Customer</Button>
          </div>

          <div className="md:mr-10 mb-5">
            <Field
              component={ReduxFormSelectField}
              label="Sub-Category"
              name="subCategory"
              options={subCategoryOptions}
              placeholder="Select Subcategories"
              isLoading={isFetchingDropdownList}
              disabled={!orderType || generatedColors?.length}
              isMulti
            />
          </div>
          <div>
            <Field
              component={ReduxFormSelectField}
              label="Generated Colors "
              name="generatedColors"
              options={generatedRawMaterialColorsOptions}
              placeholder="Select Colors"
              isLoading={isFetchingDropdownList}
              isMulti
              disabled={!subCategory?.length}
            />
            <Button className="mt-2" onClick={handleAddSubCategory}>
              Add Sub-Category
            </Button>
          </div>
          <div className="flex justify-end items-center md:mr-10 ml-10 ">
            <Button
              className="text-redBase border-redBase"
              onClick={() => setShow(false)}
            >
              Hide Filter
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex justify-end items-center md:mr-10 mb-10">
          <Button
            className="text-primaryBase border-primaryDark"
            onClick={() => setShow(true)}
          >
            Show Filter
          </Button>
        </div>
      )}
    </div>
  );
}

export default reduxForm({
  form: "saleorder-filter",
  enableReinitialize: true,
})(SaleOrderFilter);
