import { useDispatch, useSelector } from "react-redux";

import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	editMasterList,
	getMasterDetails,
	masterSelectors,
	resetMasterDetails,
} from "slices/masterSlice";
import { masterName } from "./Subcategories";
import SubcategoriesForm from "./SubcategoriesForm";

const SubcategoriesWrapper = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { mode, id } = useParams();

	const [loading, setLoading] = useState(id ? true : false);

	const isViewOnly = mode === "view";
	const subCategoryList = useSelector(masterSelectors?.getSubcategoryDetails);
	const isFetchingMasterDetails = useSelector(
		masterSelectors?.getIsFetchingMasterDetails
	);

	useEffect(() => {
		if (id) {
			dispatch(getMasterDetails({ masterName, id }));
			setLoading(false);
		}
		return () => dispatch(resetMasterDetails(masterName));
	}, [dispatch, id]);

	const handleSubmit = formData => {
		const form = new FormData();
		form.append("id", formData?.id);
		form.append(
			"subCategory",
			new Blob(
				[
					JSON.stringify({
						...formData,
					}),
				],
				{
					type: "application/json",
				}
			)
		);
		form.append("item_images", formData?.item_images);
		dispatch(
			editMasterList({
				masterName,
				formData: form,
				navigate,
				configType: "multipart/form-data",
			})
		);
	};

	const handleClose = () => {
		navigate(`/${masterName}`);
	};

	const initialValues = {
		id: subCategoryList?.id || null,
		category: subCategoryList?.category?.[0]?.name || null,
		name: subCategoryList?.name || null,
		hsn_code: subCategoryList?.hsn_code || null,
		comment: subCategoryList?.comment || null,
		item_images:subCategoryList?.item_images || null,
	};

	if (loading || isFetchingMasterDetails)
		return (
			<Spin
				className="h-100 d-flex justify-content-center align-items-center"
				size="large"
			/>
		);

	const title = isViewOnly ? `View Main-Item` : `Edit Main-Item`;

	return (
		<SubcategoriesForm
			title={title}
			initialValues={initialValues}
			onSubmit={handleSubmit}
			onCancel={handleClose}
			isViewOnly={isViewOnly}
		/>
	);
};

export default SubcategoriesWrapper;
