import React,{useEffect, useState} from 'react'
import { Button, Tooltip, Select } from 'antd'
import { Form, Field  } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import TextareaFieldV2 from 'utils/v2/TextareFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { getMasterList, masterSelectors, editMasterList, addMasterList, getMasterDropDownList, getMasterLogsList } from 'slices/masterSlice'
import Activity from '../common/Activity'

const masterName = "subcategory"
const hsnDropdownMaster = "hsn_dropdown"
const categoryDropdownMaster = "categorydropdown"
const SubCategoryDetails = ({data, setData, type, setType}) => {

  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);
  const hsnDropdown = useSelector(masterSelectors?.getHsnDropdown);
  const categoryDropdown = useSelector(masterSelectors?.getCategoryDropdown);
  const [item_images, setImage] = useState(null)

  const logs = useSelector(masterSelectors?.getSubCategoryLogs);

  useEffect(()=>{
    if(data?.id){
      dispatch(getMasterLogsList({masterName:"SubCategory", id: data?.id}))
    }
  },[data])

  // console.log('data', data)

  useEffect(()=>{
    dispatch(getMasterDropDownList({masterName:categoryDropdownMaster}))
    dispatch(getMasterDropDownList({masterName:hsnDropdownMaster}))
  },[])

  const onSubmit = async (values, form) => {
    values.comment = values?.comment || null;
    const formData = new FormData();
    if(item_images !== null || item_images !== undefined){
      formData.append('item_image', item_images)
    }
    formData.append('subcategory', values)
    
    let res = null;
    if(type === 'new'){
      res = await dispatch(
        addMasterList({
          masterName,
          formData,
          configType: "multipart/form-data",
        })
      );
    }else{
      res = await dispatch(
        editMasterList({
          masterName,
          formData,
          configType: "multipart/form-data",
        })
      );
    }
      if(res?.type === "master/edit/fulfilled" || res?.type === "master/add/fulfilled"){
        setData({})
        dispatch(getMasterList({
          masterName
        }))
      }
    
    }

    const handleClear = () =>{
      setData({})
      setType("update")
    }

      const required = value => (value ? undefined : 'Required')

  return (
    <>
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
    <>
   <div className='flex justify-between items-center mt-5'>

   <p className='font-bold mb-3'>Record Details:</p>
   <div className='flex gap-4'>
   <Button type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
   onClick={handleClear}
   >{type === "new" ? "Cancel" : "Clear"}</Button>
   
   <Button type="primary" size="small" className='bg-redBase' htmlType='submit'
   loading={loading} disabled={loading}
   >{type === "new" ? "Add As New" : "Update"}</Button>
   
   </div>
   </div>
   <div className='flex gap-5'>
        <div className=''>
            <Field name="name" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Sub Category Name"/>
            )}
          </Field>
        </div>
        <div>
          <label>Category</label>
          <Field name="categoryId" validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"100%", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="Category"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:categoryDropdownMaster, query:{name:e}}))}
					options={categoryDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div className=''>
          <label>HSN Code</label>
          <Field name="hsnId" validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"100%", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
          className={`${meta.error && meta.touched && "border border-redBase"}`}
					showSearch
          loading={loadingDropdown}
					placeholder="HSN code"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:hsnDropdownMaster, query:{name:e}}))}
					options={hsnDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>
        <div>
          <label>Image</label><br></br>
          <input type='file' className='' accept='image/*' onChange={(e)=>setImage(e.target.files[0])}/>
        </div>
        
    </div>
    <div className='flex gap-5 my-3'>
    <div className='w-full'>
        <Field name="comment">
            {({ input, meta }) => (
              <TextareaFieldV2 input={input} meta={meta} label="Comment" style='w-1/2'/>
            )}
          </Field>
        </div>
    </div>
    </>
    </form>
      )}/>
       {logs?.length ?
      <Activity logs={logs}/>
    :null  
    }
    </>
  )
}

export default SubCategoryDetails