import { useEffect } from "react";

const useMasterLogic = (getMasterData, masterName, num=10) => {
	
	useEffect(() => {
			getMasterData(1, num, {});
	}, [getMasterData]);

	const handleAddMaster = () =>
		window.open(
			`${window.location.origin}/${masterName}/add`,
			"_blank",
			"noopener,noreferrer"
		);

	const handleEditMaster = id =>
		window.open(
			`${window.location.origin}/${masterName}/edit/${id}`,
			"_blank",
			"noopener,noreferrer"
		);

	const handleEditMasterReady = id =>
		window.open(
			`${window.location.origin}/working/ready-stock-form/${id}`,
			"_blank",
			"noopener,noreferrer"
		);

	const handleEditMasterOutward = id =>
		window.open(
			`${window.location.origin}/working/outward/${id}`,
			"_blank",
			"noopener,noreferrer"
		);
	const handlePageChange = (pageNum, itemsPerPage) => {
		getMasterData(pageNum, itemsPerPage);
	};
	const handlePageChange2 = (pageNum, itemsPerPage) => {
		getMasterData(pageNum, itemsPerPage);
	};

	return {
		handleAddMaster,
		handleEditMaster,
		handlePageChange,
		handleEditMasterReady,
		handleEditMasterOutward,
		handlePageChange2
	};
};

export default useMasterLogic;
