import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formValueSelector } from "redux-form";
import { masterSelectors } from "slices/masterSlice";
import ReadyStockForm from "./ReadyStockForm";

const masterName = "readyStockEntry";
const readyStockEntryName = "readyStockEntry";
const formName = "readyStockForm";
const formSelector = formValueSelector(formName);

const ReadyStockWrapper = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { mode, id } = useParams();

	const [loading, setLoading] = useState(id ? true : false);

	const readyStockList = useSelector(masterSelectors?.getReadyStockList);
	const isViewOnly = mode === "view";
	const [readyStockContainer, setReadySockContainer] = useState([]);

	const subCategory = useSelector(state => formSelector(state, "subCategory"));

	const generatedColors = useSelector(state =>
		formSelector(state, "generated_Colors")
	);
	const lotNo = useSelector(state => formSelector(state, "lot_No"));

	useEffect(() => {
		setReadySockContainer([...readyStockContainer, ...readyStockList]);
	}, [readyStockList]);

	const handleItemChange = (index, value, key) => {
		let tp = [...readyStockContainer];
		const newValue = Number(value.target.value);

		// if (id) {
		// 	values.forEach(entry => {
		// 		const selectedColor = entry?.color;

		// 		const stockDetailsForColor = readyStockData.find(
		// 			item => item.color === selectedColor
		// 		);

		// 		const index = readyStockData.findIndex(
		// 			item => item.color === selectedColor
		// 		);
		// 		if (key === "size85") {
		// 			let prevVal = readyStock[index].size85;

		// 			tp[index] = {
		// 				...tp[index],
		// 				size85: prevVal - newValue,
		// 			};
		// 		}
		// 		if (key === "size90") {
		// 			let prevVal = readyStock[index].size90;

		// 			tp[index] = {
		// 				...tp[index],
		// 				size90: prevVal - newValue,
		// 			};
		// 		}
		// 		if (key === "size95") {
		// 			let prevVal = readyStock[index].size95;

		// 			tp[index] = {
		// 				...tp[index],
		// 				size95: prevVal - newValue,
		// 			};
		// 		}
		// 		if (key === "size100") {
		// 			let prevVal = readyStock[index].size100;

		// 			tp[index] = {
		// 				...tp[index],
		// 				size100: prevVal - newValue,
		// 			};
		// 		}
		// 		if (key === "size105") {
		// 			let prevVal = readyStock[index].size105;

		// 			tp[index] = {
		// 				...tp[index],
		// 				size105: prevVal - newValue,
		// 			};
		// 		}
		// 		if (key === "size110") {
		// 			let prevVal = readyStock[index].size110;

		// 			tp[index] = {
		// 				...tp[index],
		// 				size110: prevVal - newValue,
		// 			};
		// 		}
		// 		if (key === "size115") {
		// 			let prevVal = readyStock[index].size115;

		// 			tp[index] = {
		// 				...tp[index],
		// 				size115: prevVal - newValue,
		// 			};
		// 		}
		// 		if (key === "size120") {
		// 			let prevVal = readyStock[index].size120;

		// 			tp[index] = {
		// 				...tp[index],
		// 				size120: prevVal - newValue,
		// 			};
		// 		}

		// 		//setReadyStockData([...tp]);
		// 	});
		// }

		if (key === "size34") {
			//let prevVal = readyStockContainer[index].size34;

			tp[index] = {
				...tp[index],
				size34: newValue,
			};
		}
		if (key === "size36") {
			//let prevVal = readyStockContainer[index].size90;

			tp[index] = {
				...tp[index],
				size36: newValue,
			};
		}
		if (key === "size38") {
			//	let prevVal = readyStockContainer[index].size95;

			tp[index] = {
				...tp[index],
				size38: newValue,
			};
		}
		if (key === "size40") {
			//let prevVal = readyStockContainer[index].size100;

			tp[index] = {
				...tp[index],
				size40: newValue,
			};
		}
		if (key === "size42") {
			//let prevVal = readyStockContainer[index].size105;

			tp[index] = {
				...tp[index],
				size42: newValue,
			};
		}
		if (key === "size44") {
			//	let prevVal = readyStockContainer[index].size110;

			tp[index] = {
				...tp[index],
				size44: newValue,
			};
		}
		if (key === "size46") {
			//let prevVal = readyStockContainer[index].size115;

			tp[index] = {
				...tp[index],
				size46: newValue,
			};
		}
		if (key === "size48") {
			//	let prevVal = readyStockContainer[index].size120;

			tp[index] = {
				...tp[index],
				size48: newValue,
			};
		}

		if (key === "comment") {
			//	let prevVal = readyStockContainer[index].size120;

			tp[index] = {
				...tp[index],
				comment: newValue,
			};
		}
		setReadySockContainer([...tp]);
		console.log("readyStockC", readyStockContainer);
	};

	const initialValues = {
		//ready: stock?.[0],

		generated_Colors: generatedColors,
		subCategory: subCategory,
		lot_No: lotNo,

		ready: readyStockContainer?.map(item => ({
			id: item?.id || null,
			mainitem: item?.mainitem || null,
			color: item?.color || null,
			size34: item?.size85 || 0,
			size36: item?.size90 || 0,
			size38: item?.size95 || 0,
			size40: item?.size100 || 0,
			size42: item?.size105 || 0,
			size44: item?.size110 || 0,
			size46: item?.size115 || 0,
			size48: item?.size120 || 0,
			comment: item?.comment || "-",
			active: item?.active || 1,
			//	delete: item?.delete || 0,
			total: 0,
		})),

		//commentOverview: list?.[0]?.commentOverview || null,
	};

	//console.log("initial", initialValues);
	return (
		<ReadyStockForm
			initialValues={initialValues}
			// submit={handleSubmit}
			// onCancel={handleClose}
			isViewOnly={isViewOnly}
			handleItemChange={handleItemChange}
		/>
	);
};

export default ReadyStockWrapper;
