import { Button } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, change, getFormValues } from "redux-form";
import { getSaleOrderExcelList, masterSelectors } from "slices/masterSlice";
import { ReduxFormSelectField2 } from "utils/ReduxFormSelectField";

const SaleOrderFilter = ({data}) => {
	const dispatch = useDispatch();
	const formValues = useSelector(getFormValues("excel"));

    const isLoading = useSelector(
		masterSelectors?.getIsFetchingSaleOrderExcel
	);
    const excelData = useSelector(
        masterSelectors?.getSaleOrderExcel
    )

	let partyNames = [];


    data?.forEach((item)=>{
        partyNames.push(item?.party)
    })


	const party = partyNames?.length
		? Array.from(new Set(partyNames.map(item => item)))
		: [];

	const partyFilters = party?.map(party => ({
		label: party || "",
		value: party,
	}));

	const [show, setShow] = useState(false);

	const handleFilter = () => {
		const val = {
			party: formValues?.party?.map(item => item?.value) || [],
		};

		// console.log("val", val);
        if(val?.party?.length){
        dispatch(
			getSaleOrderExcelList({
				masterName:'report/order',
				query: {
					partyName: val?.party?.toString(),
				},
			})
		);
    }
	};

	const handleResetFilter = () => {
		const val = {
			party: [],
		};
		dispatch(change("excel", "party", null));
	};



	return (
		<>
			{show ? (
					<div className="md:flex block md:flex-wrap">
								<div className="md:mr-4">
									<Field
										component={ReduxFormSelectField2}
										label="Party"
										name="party"
										placeholder="Enter party"
										options={partyFilters}
										//isLoading={isFetchingDropdownList}
										isMulti
									/>
								</div>

								<div className="md:mr-4 ">
									<Button
                                    disabled={isLoading}
										className=" text-primaryBase border-primaryDark font-semibold"
										onClick={handleFilter}
									>
										Generate
									</Button>
								</div>
								<div className="md:mr-4 ">
									<Button
										className=" text-primaryBase border-primaryDark font-semibold"
										onClick={handleResetFilter}
									>
										Reset
									</Button>
								</div>
                                <div className="md:mr-4">
                                <Button
                                    className="text-redBase border-redBase"
                                    onClick={() => setShow(false)}
                                >
                                    Hide
                                </Button>
                            </div>
                    </div>

						) : (
				<div className="flex justify-end items-center md:mr-4">
					<Button
						className="text-primaryBase border-primaryDark"
						onClick={() => setShow(true)}
					>
						Generate Report
					</Button>
				</div>
			)}
		</>
	);
};

export default SaleOrderFilter;
