export const renderMobileColumn = (
	primaryText = null,
	secondaryText = null,
	ternaryText = null
) => (
	<>
		<p className="text-md font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
			{primaryText}
		</p>
		<p className="text-sm text-secondaryBase font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
			{secondaryText}
		</p>
		<p className="text-xs text-secondaryBase font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
			{ternaryText}
		</p>
	</>
);
