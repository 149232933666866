import React,{useEffect, useState} from 'react'
import { Button, Checkbox, Select, Tooltip } from 'antd'
import { Form, Field } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { addMasterList, getMasterDropDownList, getMasterList, masterSelectors, } from 'slices/masterSlice'
import { Helmet } from 'react-helmet';
import TextareaFieldV2 from 'utils/v2/TextareFieldV2';
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { useHotkeys } from 'react-hotkeys-hook'
import { ReduxFormSelectField2 } from 'utils/ReduxFormSelectField';

const masterName = "customer"
const cityDropdownMaster = "city_dropdown"
const agentDropdownMaster = "agent_dropdown"
const transportDropdownMaster = "transport_dropdown"
const AddCustomer = () => {

  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const cityDropdown = useSelector(masterSelectors?.getCityDropdown);
  const agentDropdown = useSelector(masterSelectors?.getAgentDropdown);
  const transportDropdown = useSelector(masterSelectors?.getTransportDropdown);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);

  useEffect(()=>{
    dispatch(getMasterDropDownList({masterName:cityDropdownMaster}))
    dispatch(getMasterDropDownList({masterName:agentDropdownMaster}))
    dispatch(getMasterDropDownList({masterName:transportDropdownMaster}))
  },[])

  const [whatsappEnable, setWhatsappEnable] = useState(false)
  const [emailEnable, setEmailEnable] = useState(false)
  const [documentImg, setDocumentImg] = useState(null)

    const onSubmit = async (values, form) => {
        if(values?.customerContactPerson === undefined || values?.customerContactPerson?.length === 0){
            return alert("Please add Contact Persons.")
        }
        if(values?.customerDeliveryDetails === undefined || values?.customerDeliveryDetails?.length === 0){
            return alert("Please add Delivery Details.")
        }
        if(whatsappEnable === true){
          values.whatsappEnable = "1"
        }else{
          values.whatsappEnable = "0"
        }
        if(emailEnable === true){
          values.emailEnable = "1"
        }else{
          values.emailEnable = "0"
        }
        let tempAgent = []
        values?.agentId?.map((item)=> tempAgent.push(item?.value))
        values.agentId = tempAgent;

       console.log('form Data', values)
       const formData = new FormData()
       formData.append("customer", new Blob([JSON.stringify({...values})], 
      {type:"application/json"}))
       formData.append("document", documentImg)

      let res = await dispatch(
          addMasterList({
            masterName,
            formData:formData,
            configType: "multipart/form-data",
          })
        );
        if(res?.type === "master/add/fulfilled"){
          form.restart();
          dispatch(
            getMasterList({
              masterName
            })
          )
        }
      }

      const required = value => (value ? undefined : 'Required')

      useHotkeys('alt+l', () => window.location.reload())
    useHotkeys('alt+s', (event) => {
      event.preventDefault();
      document.getElementById('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    });

  return (
    <>
    <Helmet>
	<title>Customer | Add</title>
	</Helmet>
    <Form
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators
      }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} id='form'>
    <>
    <h1 className='text-2xl mb-5'>Add Customer</h1>
    <div className='flex gap-5'>
        <div className=''>
            <Field name="partyName" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Name"/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="telephone" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="Telephone No."/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="emailId" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Email"/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="gstin" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="GSTIN"/>
            )}
          </Field>
        </div>
        <div className=''>
            <Field name="pan" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="PAN"/>
            )}
          </Field>
        </div>
        <div className=''>
        <Field name="mobile" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="Mobile No."/>
            )}
          </Field>
        </div>
        <div className=''>
        <Field
        component={ReduxFormSelectField2}
        validate={required}
        label="Agents"
        name="agentId"
        placeholder="Select agents"
        options={agentDropdown}
        isLoading={loadingDropdown}
        isMulti
      />
        </div>
    </div>
    <div className='flex gap-5 mt-4'>
    <div className=''>
        <Field name="addressLine1" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Address" style='w-[385px]'/>
            )}
          </Field>
        </div> 

        <div>
          <label>City</label><br></br>
          <Field name="cityId" validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"192px", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="City"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:cityDropdownMaster, query:{name:e}}))}
					options={cityDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div className=''>
        <Field name="country" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Country"/>
            )}
          </Field>
        </div> 
        <div className=''>
        <Field name="pincode" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="Pincode"/>
            )}
          </Field>
        </div> 
        <div className=''>
        <Field name="paymentTerms" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Payment Terms"/>
            )}
          </Field>
        </div> 
        <div className=''>
        <Field name="creditLimit" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="Credit limit"/>
            )}
          </Field>
        </div>

    </div>
    <div className='flex my-4 gap-5'>
    <div className=''>
        <Field name="notes_SO">
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Notes SO"/>
            )}
        </Field>
    </div>
    <div className=''>
        <Field name="notes_SI">
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Notes SI"/>
            )}
        </Field>
    </div>

    <div>
          <label>Transport</label><br></br>
          <Field name={`transportId`} validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"192px", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="Transport"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:transportDropdownMaster, query:{name:e}}))}
					options={transportDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div className=''>
              <div>
                <label>Whatsapp Enable</label><br></br>
                <Checkbox value={whatsappEnable} onChange={e=>setWhatsappEnable(e.target.checked)}/>
              </div>
    </div>
    <div className=''>
              <div>
              <label>Email Enable</label><br></br>
              <Checkbox  value={emailEnable} onChange={e=>setEmailEnable(e.target.checked)}/>
            </div>
    </div>
    <div className=''>
        <Field name="notes" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Notes" style='w-[385px]'/>
            )}
          </Field>
        </div> 

    </div>
    <div className='w-full my-4'>
    <FieldArray name="customerContactPerson">
          {({ fields }) => (
            <div>
                <div className='flex justify-between'>
                    <p className='font-bold'>Contact Persons : </p>
                <Button
                type="primary"
                htmlType='button'
                className='bg-primaryBase px-5'
                size='small'
                onClick={() => fields.push({})}
              >
                Add Contact Person
              </Button>
                </div>
              {fields.map((name, index) => (
                <div key={name} className='flex gap-5 mb-3 items-center'>
                  <div>
                  <Field name={`${name}.name`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2 input={input} meta={meta} type='text' label="Name"/>
                    )}
                </Field>
                  </div>
                  <div>
                  <Field name={`${name}.mobileNo`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2 input={input} meta={meta} type='number' label="Mobile No."/>
                    )}
                </Field>
                  </div>
                  <div>
                  <Field name={`${name}.emailId`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2 input={input} meta={meta} type='text' label="Email"/>
                    )}
                </Field>
                  </div>
                  <div>
                  <Field name={`${name}.department`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2 input={input} meta={meta} type='text' label="Department"/>
                    )}
                </Field>
                  </div>
                  <div>
                  <Field name={`${name}.designation`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2 input={input} meta={meta} type='text' label="Designation"/>
                    )}
                </Field>
                  </div>
                  <Button type="primary" htmlType='button' size='small' className='bg-redBase mt-5' onClick={() => fields.remove(index)}>
                    Remove
                  </Button>
                </div>
              ))}
              
            </div>
          )}
        </FieldArray>
    </div>
    <div className='w-full my-4'>
    <FieldArray name="customerDeliveryDetails">
          {({ fields }) => (
            <div>
                <div className='flex justify-between'>
                    <p className='font-bold'>Delivery Details : </p>
                <Button
                type="primary"
                htmlType='button'
                className='bg-primaryBase px-5'
                size='small'
                onClick={() => fields.push({})}
              >
                Add Delivery Details
              </Button>
                </div>
              {fields.map((name, index) => (
                <div key={name} className='flex gap-5 mb-3 items-center'>
                  <div>
                  <Field name={`${name}.addressLine1`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2 input={input} meta={meta} type='text' label="Address"/>
                    )}
                </Field>
                  </div>
                  <div>
                  <Field name={`${name}.country`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2 input={input} meta={meta} type='text' label="Country"/>
                    )}
                </Field>
                  </div>
                  <div>
          <label>City</label><br></br>
          <Field name={`${name}.cityId`} validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
          style={{width:"192px", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
					showSearch
          loading={loadingDropdown}
					placeholder="City"
					allowClear
          onSearch={e => dispatch(getMasterDropDownList({masterName:cityDropdownMaster, query:{name:e}}))}
					options={cityDropdown}
          {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>
                  <div>
                  <Field name={`${name}.pincode`} validate={required}>
                    {({ input, meta }) => (
                    <InputFieldV2 input={input} meta={meta} type='number' label="Pincode"/>
                    )}
                </Field>
                  </div>
                  <Button type="primary" htmlType='button' size='small' className='bg-redBase mt-5' onClick={() => fields.remove(index)}>
                    Remove
                  </Button>
                </div>
              ))}
              
            </div>
          )}
        </FieldArray>
    </div>
    <div className='mt-4'>
      {documentImg ? 
      <div>
    <img src={URL.createObjectURL(documentImg)} className='w-[200px] h-[200px] object-cover rounded-md mb-1 block'/>
    <button className='w-[200px] bg-black text-white rounded-md'
    onClick={()=>setDocumentImg(null)}
    >Remove Image</button>
    </div>
    :  
    <div>
      <p className='font-bold mb-2'>Upload Image Document</p>
      <input type='file' className='' value={documentImg} onChange={(e)=>setDocumentImg(e.target.files[0])}/>
    </div>
    }
      

    </div>
<div className='flex gap-5 justify-end mt-10'>
<Button type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
onClick={form.reset}
>Clear</Button>
<Button type="primary" size="small" className='bg-redBase w-[80px]' htmlType='submit'
loading={loading}
disabled={loading}
>Add</Button>

</div>
    </>
    </form>
      )}/>
    </>
  )
}

export default AddCustomer