import { useDispatch, useSelector } from "react-redux";

import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	addMasterList,
	editMasterList,
	getMasterDetails,
	masterSelectors,
	resetMasterDetails,
} from "slices/masterSlice";
import { masterName } from "./Collections";
import CollectionsForm from "./CollectionsForm";

const CollectionsWrapper = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { mode, id } = useParams();

	const [loading, setLoading] = useState(id ? true : false);

	const isViewOnly = mode === "view";
	const collectionDetails = useSelector(masterSelectors?.getCollectionDetails);
	const isFetchingMasterDetails = useSelector(
		masterSelectors?.getIsFetchingMasterDetails
	);

	useEffect(() => {
		if (id) {
			dispatch(getMasterDetails({ masterName, id }));
			setLoading(false);
		}
		return () => dispatch(resetMasterDetails(masterName));
	}, [dispatch, id]);

	const handleSubmit = formValues => {
		const form = new FormData();
		if (formValues?.mainItemList?.[0]?.value === "*")
			formValues.mainItemList = formValues.mainItemList?.splice(1);

		if (collectionDetails?.id) {
			form.append("id", formValues?.id);
			form.append(
				"collection",
				new Blob(
					[
						JSON.stringify({
							...formValues,
							mainItemList: formValues?.mainItemList?.map(
								mainItem => mainItem?.value
							),
						}),
					],
					{
						type: "application/json",
					}
				)
			);
			form.append("item_images", formValues?.item_images);
			dispatch(
				editMasterList({
					masterName,
					formData: form,
					navigate,
					configType: "multipart/form-data",
				})
			);
		} else {
			form.append(
				"collection",
				new Blob(
					[
						JSON.stringify({
							...formValues,
							mainItemList: formValues?.mainItemList?.map(
								mainItem => mainItem?.value
							),
						}),
					],
					{
						type: "application/json",
					}
				)
			);
			form.append("item_images", formValues?.item_images);
			dispatch(
				addMasterList({
					masterName,
					formData: form,
					navigate,
					configType: "multipart/json",
				})
			);
		}
	};
	const handleClose = () => {
		navigate(`/${masterName}`);
	};

	const initialValues = {
		id: collectionDetails?.id || null,
		collection: collectionDetails?.collection || null,
		mainItemList: collectionDetails?.mainItemList || [],
		item_images: collectionDetails?.item_images || null,
	};

	if (loading || isFetchingMasterDetails)
		return (
			<Spin
				className="h-100 d-flex justify-content-center align-items-center"
				size="large"
			/>
		);

	const title = isViewOnly
		? `View Season`
		: `${collectionDetails?.id ? `Edit Season` : "Add Season"}`;

	return (
		<CollectionsForm
			title={title}
			initialValues={initialValues}
			onSubmit={handleSubmit}
			onCancel={handleClose}
			isViewOnly={isViewOnly}
		/>
	);
};

export default CollectionsWrapper;
