import { Pagination, Table } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import TableOptions from "components/TableOptions";

import useMasterLogic from "customHooks/useMasterLogic";
import useSearchFilter from "customHooks/useSearchFilter";
import { Helmet } from "react-helmet";
import { getMasterList, masterSelectors } from "slices/masterSlice";
import { renderActions } from "utils/renderActions";
import { renderMobileColumn } from "utils/renderMobileColumn";

export const masterName = "season";

const Seasons = () => {
	const dispatch = useDispatch();
	const seasonList = useSelector(masterSelectors?.getSeasonsMasterList);

	const { pageSize, totalElements, currentPage } = useSelector(
		masterSelectors?.getSeasonsPagination
	);

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);

	const { getColumnSearchProps, tableFilters } = useSearchFilter();

	const getSeasonsList = useCallback(
		(pageNum = 1, itemsPerPage = 10) => {
			dispatch(
				getMasterList({
					masterName,
					query: {
						page: pageNum,
						pageSize: itemsPerPage,
						is_active: 1,
						...tableFilters,
					},
				})
			);
		},
		[dispatch, tableFilters]
	);

	const { handlePageChange, handleEditMaster, handleAddMaster } =
		useMasterLogic(getSeasonsList, masterName);

	//const mainitem = seasonList?.map(item => item?.mainItemList);

	//console.log("main", mainitem);

	const expandedRowRender = record => {
		const columns = [
			{
				title: "Main Item",
				//dataIndex: "itemList",
				key: "itemList",
				responsive: ["sm"],
				render: value => value || "-",
			},
		];
		return (
			<Table
				columns={columns}
				dataSource={record?.itemList?.map(item => item)}
				//rowKey={seasonList => seasonList?.id}
				pagination={false}
				bordered
			/>
		);
	};

	const columns = [
		{
			title: "Season",
			render: data => {
				const primaryText = data?.season || "-";
				const secondaryText = data?.mainItemList || "-";
				return renderMobileColumn(primaryText, secondaryText);
			},
			responsive: ["xs"],
			...getColumnSearchProps("Season", "season_name"),
		},
		{
			title: "Season",
			dataIndex: "season",
			key: "season",
			responsive: ["sm"],
			width: "45%",
			render: value => value || "-",
			...getColumnSearchProps("Season", "season_name"),
		},
		// {
		// 	title: "Main Item",
		// 	dataIndex: "mainItemList",
		// 	responsive: ["sm"],
		// 	key: "mainItemList",
		// 	render: value => value || "-",
		// },

		{
			title: "Actions",
			key: "Actions",
			dataIndex: "action",
			width: "45%",
			render: (_, data) => {
				const items = [
					{
						label: "Edit",
						onClick: () => handleEditMaster(data?.id),
					},
				];

				return renderActions(items);
			},
		},
	];

	return (
		<>
			<Helmet>
				<title>Seasons</title>
			</Helmet>
			<TableOptions masterName={masterName} handleAddMaster={handleAddMaster} />
			<Table
				dataSource={seasonList}
				columns={columns}
				expandable={{
					expandedRowRender,
					rowExpandable: record => !!record.id,
				}}
				rowKey={seasonList => seasonList?.id}
				pagination={false}
				loading={isFetchingMasterList}
				bordered
			/>
			<div className="flex justify-center mt-5">
				{seasonList?.length ? (
					<Pagination
						pageSize={pageSize}
						total={totalElements}
						current={currentPage}
						onChange={handlePageChange}
					/>
				) : null}
			</div>
		</>
	);
};

export default Seasons;
