import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState } from "react";

function OpenStock({ openStock, openStockTotal }) {
  const rowData = openStock;

  const totals = openStockTotal;
  // console.log(totals.total);

  const pinnedBottomRow = [
    {
      mainItem: "Total",
      color: "-",
      size34: totals?.totalSize34 || 0,
      size36: totals?.totalSize36 || 0,
      size38: totals?.totalSize38 || 0,
      size40: totals?.totalSize40 || 0,
      size42: totals?.totalSize42 || 0,
      size44: totals?.totalSize44 || 0,
      size46: totals?.totalSize46 || 0,
      size48: totals?.totalSize48 || 0,
      total: totals?.total || 0,
    },
  ];

  // console.log(pinnedBottomRow);

  const colDefs = [
    { field: "mainItem", width: 200 },
    { field: "color", width: 200 },
    { field: "size34", headerName: "34", width: 100 },
    { field: "size36", headerName: "36", width: 100 },
    { field: "size38", headerName: "38", width: 100 },
    { field: "size40", headerName: "40", width: 100 },
    { field: "size42", headerName: "42", width: 100 },
    { field: "size44", headerName: "44", width: 100 },
    { field: "size46", headerName: "46", width: 100 },
    { field: "size48", headerName: "48", width: 100 },
    { field: "total", width: 100 },
  ];
  console.log(openStock);
  return (
    <div className="ag-theme-quartz" style={{ height: 300 }}>
      <h1 className="text-xl mb-2 font-semibold">Opening Stock Table</h1>
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        pinnedBottomRowData={pinnedBottomRow}
      />
    </div>
  );
}

export default OpenStock;
