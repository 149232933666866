import React from 'react'
import { useSelector } from 'react-redux';
import { Pagination } from 'antd';
import { masterSelectors } from 'slices/masterSlice';

const DispatchPagination = ({handlePageChange2}) => {

    const { pageSize, totalElements, currentPage } = useSelector(
		masterSelectors?.getDispatchStockPagination
	);
    
  return (
    <div className='flex justify-end'>
    <Pagination
    showSizeChanger={false}
        pageSize={pageSize}
        total={totalElements}
        current={currentPage}
        onChange={handlePageChange2}
    />
    </div>
  )
}

export default DispatchPagination