import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Field, formValueSelector, getFormValues, reduxForm } from "redux-form";
import {
	addReadyStockEntry,
	editReadyStock,
	getDropdownList,
	getMasterDetails,
	getReadyStockFormDetails,
	masterSelectors,
	resetMasterDetails,
} from "slices/masterSlice";
import { ReduxFormSelectField } from "utils/ReduxFormSelectField";
import {
	ReduxFormTextField,
	ReduxFormTextField1,
} from "utils/ReduxFormTextField";
import { onWheelHandler } from "utils/onWheelHandler";
import { renderModalButtons } from "utils/renderModalButtons";

const formName = "readyStockForm";
const formSelector = formValueSelector(formName);
const masterName = "readyStock";
const readyStockEntryName = "readyStockEntry";

const ReadyStockForm = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id, mode } = useParams();
	//const isViewOnly = mode === "view";
	const formValues = useSelector(getFormValues(formName));
	const allSizesGreaterThanZero = formValues?.ready?.every(
		item =>
			item?.size34 > 0 ||
			item?.size36 > 0 ||
			item?.size38 > 0 ||
			item?.size40 > 0 ||
			item?.size42 > 0 ||
			item?.size44 > 0 ||
			item?.size46 > 0 ||
			item?.size48 > 0
	);
	const isViewOnly =
		formValues?.ready?.length && allSizesGreaterThanZero === true
			? false
			: true;
	const readyStockList = useSelector(masterSelectors?.getReadyStockList);

	const [readyStockContainer, setReadySockContainer] = useState([]);

	// useEffect(() => {
	// 	setReadySockContainer([...readyStockContainer, ...readyStockList]);
	// }, [readyStockList]);

	const readyStockDetails = useSelector(
		masterSelectors?.getReadyStockEntryDetails
	);

	const stock = readyStockDetails?.data?.map(item => item?.stock);

	const [prevStock, setPrevStock] = useState([]);
	useEffect(() => {
		if (stock?.[0]?.length && !readyStockList?.length) setPrevStock(stock?.[0]);
	}, [stock]);

	useEffect(() => {
		setReadySockContainer([...readyStockContainer, ...readyStockList]);
		setPrevStock([...prevStock, ...readyStockList]);
	}, [readyStockList]);

	const values = formValues?.ready;
	//console.log("fromvalues", values);

	const isFetchingDropdownList = useSelector(
		masterSelectors?.getIsFetchingDropdownList
	);

	const isUpdatingMaster = useSelector(
		masterSelectors?.getIsUpdatingMasterList
	);

	const subCategoryOptions = useSelector(
		masterSelectors?.getSubCategoriesDropdownList
	);

	const rawMaterialColorsOptions = useSelector(
		masterSelectors?.getRawMaterialColorsDropdownList
	);

	const generatedRawMaterialColorsOptions = useSelector(
		masterSelectors?.getGeneratedRawMaterialColorsDropdownList
	);

	const mainItemsOptions = useSelector(
		masterSelectors?.getMainItemsDropdownList
	);

	const lotNoOptions = useSelector(masterSelectors?.getLotNoDropdownList);

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);

	const subCategory = useSelector(state => formSelector(state, "subCategory"));
	//const mainItem = useSelector(state => formSelector(state, "mainItem"));
	const generatedColors = useSelector(state =>
		formSelector(state, "generated_Colors")
	);
	const lotNo = useSelector(state => formSelector(state, "lot_No"));
	//const status = useSelector(state => formSelector(state, "status"));

	//const [active, setActive] = useState(0);

	const [loading, setLoading] = useState(id ? true : false);

	useEffect(() => {
		if (id) {
			dispatch(getMasterDetails({ masterName: readyStockEntryName, id }));
			setLoading(false);
		}
		return () =>
			dispatch(resetMasterDetails({ masterName: readyStockEntryName }));
	}, [dispatch, id]);

	useEffect(() => {
		dispatch(
			getDropdownList({
				masterName: "subcategory",
			})
		);
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			getDropdownList({
				masterName: "lotNo",
			})
		);
	}, [dispatch]);

	useEffect(() => {
		let subcategoryList = subCategory?.map(subCat => subCat?.value);
		if (subcategoryList?.[0] === "*")
			subcategoryList = subcategoryList?.splice(1);

		if (subcategoryList?.length) {
			dispatch(
				getDropdownList({
					masterName: "generatedraw_material_colors",
					query: { subcategoryList },
				})
			);
		}
	}, [subCategory, dispatch]);

	// useEffect(() => {
	// 	let generatedColorsList = generatedColors?.map(subCat => subCat?.value);
	// 	if (generatedColorsList?.[0] === "*")
	// 		generatedColorsList = generatedColorsList?.splice(1);

	// 	if (generatedColorsList?.length) {
	// 		dispatch(
	// 			getDropdownList({
	// 				masterName: "mainitem",
	// 				query: { generatedColorsList },
	// 			})
	// 		);
	// 	}
	// }, [generatedColors, dispatch]);

	// useEffect(() => {
	// 	let mainItemList = mainItem?.map(item => item?.value);
	// 	if (mainItemList?.[0] === "*") mainItemList = mainItemList?.splice(1);
	// 	if (mainItem?.length)
	// 		dispatch(
	// 			getDropdownList({
	// 				masterName: "raw_material_colors",
	// 				query: { mainitemList: mainItemList },
	// 			})
	// 		);
	// }, [mainItem, dispatch]);

	const getDetails = () => {
		const formValues = {
			mainItem: generatedColors?.map(item => item?.value) || [],
			lotNo: lotNo?.map(item => item?.value) || [],
		};

		dispatch(
			getReadyStockFormDetails({
				masterName,
				formData: formValues,
			})
		);
	};

	const handleSubmit = async(e) => {
		e.preventDefault();
		//const values = formValues?.ready;
		// const commentOverview = formValues?.commentOverview;
		//console.log("fromvalues", values);
		// console.log(commentOverview);
		// const formValues1 = {
		// 	commentOverview: formValues?.commentOverview,
		// 	stock: formValues?.ready,
		// };
		// const formValues2 = {
		// 	commentOverview: formValues?.commentOverview,
		// 	stock: formValues?.ready,
		// 	id: id,
		// };

		//console.log("val", formValues1);
		let res = undefined;
		if (id) {
			let temp = {
				ready: formValues?.ready?.map(item => ({
					active: item?.active || 0,
					delete: item?.delete || 0,
					id: item?.id || null,
					mainitem: item?.mainitem || null,
					color: item?.color || null,
					size34: item?.size34 || 0,
					size36: item?.size36 || 0,
					size38: item?.size38 || 0,
					size40: item?.size40 || 0,
					size42: item?.size42 || 0,
					size44: item?.size44 || 0,
					size46: item?.size46 || 0,
					size48: item?.size48 || 0,
					comment: item?.comment || "-",
					total:
						Number(item?.size34) +
							Number(item?.size36) +
							Number(item?.size38) +
							Number(item?.size40) +
							Number(item?.size42) +
							Number(item?.size44) +
							Number(item?.size46) +
							Number(item?.size48) || 0,
				})),
			};
			const formValues2 = {
				commentOverview: formValues?.commentOverview,
				stock: temp?.ready,
				id: id,
			};
			let r = await dispatch(
				editReadyStock({
					masterName: readyStockEntryName,
					formData: formValues2,
				})
			);
			res = r;
		} else {
			let temp = {
				ready: formValues?.ready?.map(item => ({
					id: item?.id || null,
					mainitem: item?.mainitem || null,
					color: item?.color || null,
					size34: item?.size34 || 0,
					size36: item?.size36 || 0,
					size38: item?.size38 || 0,
					size40: item?.size40 || 0,
					size42: item?.size42 || 0,
					size44: item?.size44 || 0,
					size46: item?.size46 || 0,
					size48: item?.size48 || 0,
					comment: item?.comment || "-",
					active: item?.active || 0,
					//	delete: item?.delete || 0,
					total:
						Number(item?.size34) +
							Number(item?.size36) +
							Number(item?.size38) +
							Number(item?.size40) +
							Number(item?.size42) +
							Number(item?.size44) +
							Number(item?.size46) +
							Number(item?.size48) || 0,
				})),
			};
			const formValues1 = {
				commentOverview: formValues?.commentOverview,
				stock: temp?.ready,
			};
			let r = await dispatch(
				addReadyStockEntry({
					masterName: readyStockEntryName,
					formData: formValues1,
				})
			);
			res = r;
		}
		
		if(res?.type === "master/addReadyStockEntry/fulfilled" || res?.type === "master/editReadyStock/fulfilled"){
			navigate("/working/readyStockEntry/");
		}
		
	};

	//console.log("ready", readyStockList);

	const handleClose = () => {
		navigate("/working/readyStockEntry/");
	};

	const columns = [
		// {
		// 	title: "Ready Stock",

		// 	render: data => {
		// 		const primaryText = `${data?.main_item || "-"}-${
		// 			data?.color_name || "-"
		// 		}-${data?.sub_category_name || "-"}`;
		// 		const secondaryText = `${data?.size85 || "0"}-${data?.size90 || "0"}-${
		// 			data?.size95 || "0"
		// 		}-${data?.size100 || "0"}-${data?.size105 || "0"}-${
		// 			data?.size110 || "0"
		// 		}-${data?.size115 || "0"}- ${data?.size120 || "0"}`;
		// 		return renderMobileColumn(primaryText, secondaryText);
		// 	},
		// 	responsive: ["xs"],
		// 	...getColumnSearchProps("Search", "universal_search"),
		// },

		{
			title: "Check",
			dataIndex: "active",
			key: "active",
			responsive: ["sm"],
			//render: value => value || "0",
			render: (text, data, index) => {
				return (
					<div className="flex justify-center">
						<Field
							className=" bg-grey ant-checkbox "
							component={ReduxFormTextField1}
							type="checkbox"
							onWheel={e => onWheelHandler(e)}
							//placeholder="Enter MRP"
							name={`ready[${index}].active`}
							normalize={value => (value ? 1 : 0)}
						/>
					</div>
				);
			},
		},

		{
			title: "Item",
			dataIndex: "mainitem",
			key: "mainitem",
			responsive: ["sm"],
			render: value => value || "-",
			// ...getColumnSearchProps("Search Item", "main_item"),
		},
		{
			title: "Color Name",
			dataIndex: "color",
			key: "color",
			responsive: ["sm"],
			render: value => value || "-",
			// ...getColumnSearchProps("Search Color", "color_name"),
		},
		{
			title: "34",
			dataIndex: "size34",
			key: "size34",
			responsive: ["sm"],
			//render: value => value || "0",
			render: (text, data, index) => {
				return (
					<Field
						className="md:w-24 main-wrp"
						component={ReduxFormTextField1}
						min={0}
						type="number"
						onWheel={e => onWheelHandler(e)}
						//placeholder="Enter MRP"
						name={`ready[${index}].size34`}
						onChange={value => props.handleItemChange(index, value, "size34")}
					/>
				);
			},
		},
		{
			title: "36",
			dataIndex: "size36",
			key: "size36",
			responsive: ["sm"],
			//render: value => value || "0",
			render: (text, data, index) => {
				return (
					<Field
						className="md:w-24 main-wrp"
						component={ReduxFormTextField1}
						min={0}
						type="number"
						onWheel={e => onWheelHandler(e)}
						//placeholder="Enter MRP"
						name={`ready[${index}].size36`}
						onChange={value => props.handleItemChange(index, value, "size36")}
					/>
				);
			},
		},
		{
			title: "38",
			dataIndex: "size38",
			key: "size38",
			responsive: ["sm"],
			//render: value => value || "0",
			render: (text, data, index) => {
				return (
					<Field
						className="md:w-24 main-wrp"
						component={ReduxFormTextField1}
						min={0}
						type="number"
						onWheel={e => onWheelHandler(e)}
						//placeholder="Enter MRP"
						name={`ready[${index}].size38`}
						onChange={value => props.handleItemChange(index, value, "size38")}
					/>
				);
			},
		},
		{
			title: "40",
			dataIndex: "size40",
			key: "size40",
			responsive: ["sm"],
			//render: value => value || "0",
			render: (text, data, index) => {
				return (
					<Field
						className="md:w-24 main-wrp"
						component={ReduxFormTextField1}
						min={0}
						type="number"
						onWheel={e => onWheelHandler(e)}
						//placeholder="Enter MRP"
						name={`ready[${index}].size40`}
						onChange={value => props.handleItemChange(index, value, "size40")}
					/>
				);
			},
		},
		{
			title: "42",
			dataIndex: "size42",
			key: "size42",
			responsive: ["sm"],
			//render: value => value || "0",
			render: (text, data, index) => {
				return (
					<Field
						className="md:w-24 main-wrp"
						component={ReduxFormTextField1}
						min={0}
						type="number"
						onWheel={e => onWheelHandler(e)}
						//placeholder="Enter MRP"
						name={`ready[${index}].size42`}
						onChange={value => props.handleItemChange(index, value, "size42")}
					/>
				);
			},
		},
		{
			title: "44",
			dataIndex: "size44",
			key: "size44",
			responsive: ["sm"],
			//render: value => value || "0",
			render: (text, data, index) => {
				return (
					<Field
						className="md:w-24 main-wrp"
						component={ReduxFormTextField1}
						min={0}
						type="number"
						onWheel={e => onWheelHandler(e)}
						//placeholder="Enter MRP"
						name={`ready[${index}].size44`}
						onChange={value => props.handleItemChange(index, value, "size44")}
					/>
				);
			},
		},
		{
			title: "46",
			dataIndex: "size46",
			key: "size46",
			responsive: ["sm"],
			//render: value => value || "0",
			render: (text, data, index) => {
				return (
					<Field
						className="md:w-24 main-wrp"
						component={ReduxFormTextField1}
						min={0}
						type="number"
						onWheel={e => onWheelHandler(e)}
						//placeholder="Enter MRP"
						name={`ready[${index}].size46`}
						onChange={value => props.handleItemChange(index, value, "size46")}
					/>
				);
			},
		},
		{
			title: "48",
			dataIndex: "size48",
			key: "size48",
			responsive: ["sm"],
			//render: value => value || "0",
			render: (text, data, index) => {
				return (
					<Field
						className="md:w-24 main-wrp"
						component={ReduxFormTextField1}
						min={0}
						type="number"
						onWheel={e => onWheelHandler(e)}
						//placeholder="Enter MRP"
						name={`ready[${index}].size48`}
						onChange={value => props.handleItemChange(index, value, "size48")}
					/>
				);
			},
		},
		{
			title: "Comment",
			dataIndex: "comment",
			key: "comment",
			responsive: ["sm"],
			//render: value => value || "-",
			render: (text, data, index) => {
				return (
					<Field
						className="md:w-24 main-wrp"
						component={ReduxFormTextField1}
						type="text"
						onWheel={e => onWheelHandler(e)}
						//placeholder="Enter MRP"
						name={`ready[${index}].comment`}
						onChange={value => props.handleItemChange(index, value, "comment")}
					/>
				);
			},
		},

		{
			title: "Delete",
			dataIndex: "delete",
			key: "delete",
			responsive: ["sm"],
			//render: value => value || "0",
			render: (text, data, index) => {
				if (id) {
					return (
						<div className="flex justify-center">
							<Field
								className=" bg-grey  ant-checkbox"
								component={ReduxFormTextField1}
								type="checkbox"
								onWheel={e => onWheelHandler(e)}
								//placeholder="Enter MRP"
								name={`ready[${index}].delete`}
								normalize={value => (value ? 1 : 0)}
							/>
						</div>
					);
				} else {
					return null;
				}
			},
		},

		// {
		// 	title: "Actions",
		// 	key: "Actions",
		// 	dataIndex: "action",
		// 	width: "20%",
		// 	render: (_, data) => {
		// 		const items = [
		// 			{
		// 				label: "Delete",
		// 				onClick: () => handleDeleteRow(data?.key),
		// 			},
		// 		];

		// 		return renderActions(items);
		// 	},
		// },
	];

	return (
		<>
			<Helmet>
				<title>Inward Stock Form</title>
			</Helmet>
			<form onSubmit={handleSubmit}>
				<h6 className="text-2xl font-bold md:mb-5 mb-10">Inward Stock Form</h6>
				<div className="md:flex block md:flex-wrap">
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Sub-Category"
							name="subCategory"
							options={subCategoryOptions}
							placeholder="Select Subcategories"
							isLoading={isFetchingDropdownList}
							isMulti
							disabled={lotNo?.length}
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Generated Colours"
							name="generated_Colors"
							options={generatedRawMaterialColorsOptions}
							placeholder="Select Colours"
							isLoading={isFetchingDropdownList}
							isMulti
							disabled={lotNo?.length}
						/>
					</div>
					<div className="md:mr-10 mb-5">
						<Field
							component={ReduxFormSelectField}
							label="Lot No"
							name="lot_No"
							options={lotNoOptions}
							placeholder="Select LotNo"
							isLoading={isFetchingDropdownList}
							isMulti
							disabled={subCategory?.length}
						/>
					</div>

					<div className="md:mr-10 mb-5">
						<Button
							className="mt-7 text-primaryBase border-primaryDark font-semibold"
							onClick={getDetails}
						>
							Get Data
						</Button>
					</div>
				</div>
				<div className="md:mr-10 mb-5">
					<Field
						component={ReduxFormTextField}
						label="comment"
						name="commentOverview"
						type="text"
						onWheel={e => onWheelHandler(e)}
						placeholder="Type Comment"
					/>
				</div>

				{/* </form> */}

				<Table
					//dataSource={readyStockList}
					dataSource={id ? prevStock : readyStockContainer}
					columns={columns}
					rowKey={readyStockContainer => readyStockContainer?.id}
					pagination={false}
					loading={isFetchingMasterList}
					bordered
					scroll={{ y: 450 }}
				/>

				{/* <div className="md:mr-10 mb-5">
					<button
						className="mt-7 text-white bg-redBase p-2 rounded-md cursor-pointer hover:text-white font-semibold"
						//onClick={() => handleSubmit}
						//loading={loading}
					>
						Submit
					</button>
				</div> */}
				{renderModalButtons(handleClose, isViewOnly, isUpdatingMaster)}
			</form>
			{/* {renderModalButtons(onCancel, isViewOnly, isUpdatingMaster)}
			</form> */}
			{/* // <div className="md:mr-10 mb-5">
			// 	<Button
			// 		className="mt-7 text-white bg-redBase hover:text-white font-semibold"
			// 		onClick={handleSubmit}
			// 	>
			// 		Submit
			// 	</Button>
			// </div> */}
		</>
	);
};

export default reduxForm({
	form: formName,
	enableReinitialize: true,
})(ReadyStockForm);
