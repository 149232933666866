import React, {useEffect} from 'react'
import { Button } from 'antd'
import { Form, Field  } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import TextareaFieldV2 from 'utils/v2/TextareFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { getMasterList, masterSelectors, editMasterList, addMasterList, getMasterLogsList } from 'slices/masterSlice'
import Activity from '../common/Activity'

const masterName = "category"
const CategoryDetails = ({data, setData, type, setType}) => {

  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);

  const logs = useSelector(masterSelectors?.getCategoryLogs);

	useEffect(()=>{
	  if(data?.id){
		dispatch(getMasterLogsList({masterName:"Category", id: data?.id}))
	  }
	},[data])

  const onSubmit = async values => {
    let res = null;
    if(type === 'new'){
      res = await dispatch(
        addMasterList({
          masterName,
          formData:values,
        })
      );
    }else{
      res = await dispatch(
        editMasterList({
          masterName,
          formData:values,
        })
      );
    }
      if(res?.type === "master/edit/fulfilled" || res?.type === "master/add/fulfilled"){
        setData({})
        dispatch(getMasterList({
          masterName
        }))
      }
    }

    const handleClear = () =>{
      setData({})
      setType("update")
    }

      const required = value => (value ? undefined : 'Required')

  return (
    <>
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
    <>
   <div className='flex justify-between items-center mt-5'>

   <p className='font-bold mb-3'>Record Details:</p>
   <div className='flex gap-4'>
   <Button type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
   onClick={handleClear}
   >{type === "new" ? "Cancel" : "Clear"}</Button>
   
   <Button type="primary" size="small" className='bg-redBase' htmlType='submit'
   loading={loading} disabled={loading}
   >{type === "new" ? "Add As New" : "Update"}</Button>
   
   </div>
   </div>
   <div className='flex gap-3'>
        <div className=''>
            <Field name="ctg_no" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="Number"/>
            )}
          </Field>
        </div>
        <div className=''>
        <Field name="name" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Name"/>
            )}
          </Field>
        </div>
        <div className=''>
        <Field name="comment">
            {({ input, meta }) => (
              <TextareaFieldV2 input={input} meta={meta} label="Comment" style='w-[320px]'/>
            )}
          </Field>
        </div>
        
    </div>
    </>
    </form>
      )}/>
      {logs?.length ?
      <Activity logs={logs}/>
    :null  
    }
    </>
  )
}

export default CategoryDetails