import { Button, Table, message } from "antd";
import TableOptions from "components/TableOptions";
import useMasterLogic from "customHooks/useMasterLogic";
import useSearchFilter from "customHooks/useSearchFilter";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
	exportReadyStock,
	generateFabricReport,
	getLadtSyncDate,
	masterSelectors,
} from "slices/masterSlice";
import { renderMobileColumn } from "utils/renderMobileColumn";

const masterName = "readyStock";

const ReadyStockReports = () => {
	const dispatch = useDispatch();

	const mainReadyStockList = useSelector(
		masterSelectors?.getReadyStockReportList
	);
	const isExportingReadyStock = useSelector(masterSelectors?.getIsExportReady);
	const readyStockLastSyncDate = useSelector(masterSelectors?.getLastSyncDate);

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);

	const { getColumnSearchProps, tableFilters } = useSearchFilter();

	const [readyStockList, setReadyStockList] = useState(
		useSelector(masterSelectors?.getFabricReportList)
	);

	useEffect(() => {
		setReadyStockList(mainReadyStockList);
	}, [mainReadyStockList]);

	var size85 = 0;
	var size90 = 0;
	var size95 = 0;
	var size100 = 0;
	var size105 = 0;
	var size110 = 0;
	var size115 = 0;
	var size120 = 0;
	var total = 0;

	let totalOfTotal = 0;

	readyStockList?.forEach(se => {
		size85 += +se?.size85 || 0;
		size90 += +se?.size90 || 0;
		size95 += +se?.size95 || 0;
		size100 += +se?.size100 || 0;
		size105 += +se?.size105 || 0;
		size110 += +se?.size110 || 0;
		size115 += +se?.size115 || 0;
		size120 += +se?.size120 || 0;

		const totalItem =
			se.size85 +
			se.size90 +
			se.size95 +
			se.size100 +
			se.size105 +
			se.size110 +
			se.size115 +
			se.size120;
		totalOfTotal += totalItem;
	});

	const getReadyStockData = useCallback(() => {
		dispatch(
			generateFabricReport({
				masterName,
				query: {
					...tableFilters,
				},
			})
		);
	}, [tableFilters, dispatch]);

	useEffect(() => {
		dispatch(getLadtSyncDate({ type: "ReadyStock" }));
	}, [dispatch]);

	useMasterLogic(getReadyStockData, masterName);

	const [filters, setFilters] = useState({}); // State to hold filters

	const handleFilterChange = (column, filtersValue) => {
		setFilters({
			...filters,
			[column]: filtersValue,
		});
		setExportArray(filteredArray);
	};

	useEffect(() => {
		//console.log("filters:", Object.values(filters));
		let obj = Object.values(filters);

		let tempData = mainReadyStockList;

		let main_item = obj?.[0]?.["main_item"] || [];
		let color_name = obj?.[0]?.["color_name"] || [];
		let sub_category_name = obj?.[0]?.["sub_category_name"] || [];
		//console.log(supplier, main_item, color_name, sub_category_name);

		if (main_item?.length)
			tempData = tempData.filter(item => main_item.includes(item?.main_item));
		if (color_name?.length)
			tempData = tempData.filter(item => color_name.includes(item?.color_name));
		if (sub_category_name?.length)
			tempData = tempData.filter(item =>
				sub_category_name.includes(item?.sub_category_name)
			);

		//	console.log("fd", tempData);
		setReadyStockList(tempData);
	}, [filters]);

	const subcategories = Array.from(
		new Set(readyStockList.map(item => item.sub_category_name))
	);

	const subCategoryFilters = subcategories.map(subcategory => ({
		text: subcategory,
		value: subcategory,
	}));

	const mainItems = Array.from(
		new Set(readyStockList.map(item => item.main_item))
	);

	const mainItemFilters = mainItems.map(mainItems => ({
		text: mainItems,
		value: mainItems,
	}));

	const colors = Array.from(
		new Set(readyStockList.map(item => item.color_name))
	);

	const colorsFilters = colors.map(colors => ({
		text: colors,
		value: colors,
	}));

	let filteredArray = useMemo(() => []);

	const [exportArray, setExportArray] = useState([]);

	useEffect(() => {
		// Simulating data fetching delay with setTimeout
		setTimeout(() => {
			// Fetch data or perform any necessary actions
			setExportArray(readyStockList);
		}, 2000); // Simulated 2 seconds delay for data fetching
	}, [readyStockList]);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const headers = [
		{ label: "Sub Category", key: "sub_category_name" },
		{ label: "Main Item", key: "main_item" },
		{ label: "Color", key: "color_name" },
		{ label: "85", key: "size85" },
		{ label: "90", key: "size90" },
		{ label: "95", key: "size95" },
		{ label: "100", key: "size100" },
		{ label: "105", key: "size105" },
		{ label: "110", key: "size110" },
		{ label: "115", key: "size115" },
		{ label: "120", key: "size120" },
	];

	const columns = [
		{
			title: "Ready Stock",

			render: data => {
				const primaryText = `${data?.main_item || "-"}-${
					data?.color_name || "-"
				}-${data?.sub_category_name || "-"}`;
				const secondaryText = `${data?.size85 || "0"}-${data?.size90 || "0"}-${
					data?.size95 || "0"
				}-${data?.size100 || "0"}-${data?.size105 || "0"}-${
					data?.size110 || "0"
				}-${data?.size115 || "0"}- ${data?.size120 || "0"}`;
				return renderMobileColumn(primaryText, secondaryText);
			},
			responsive: ["xs"],
			...getColumnSearchProps("Search", "universal_search"),
		},
		{
			title: "Sub Category",
			dataIndex: "sub_category_name",
			key: "sub_category_name",
			responsive: ["sm"],
			render: value => value || "-",
			filters: subCategoryFilters,
			onFilter: (value, record) => {
				if (record.sub_category_name.startsWith(value)) {
					filteredArray.push(record);
				}
				return record.sub_category_name.startsWith(value);
			},
			filterSearch: true,
		},
		{
			title: "Main Item",
			dataIndex: "main_item",
			key: "main_item",
			responsive: ["sm"],
			render: value => value || "-",
			filters: mainItemFilters,
			onFilter: (value, record) => {
				if (record.main_item.startsWith(value)) {
					filteredArray.push(record);
				}
				return record.main_item.startsWith(value);
			},
			filterSearch: true,
		},
		{
			title: "Color Name",
			dataIndex: "color_name",
			key: "color_name",
			responsive: ["sm"],
			render: value => value || "-",
			filters: colorsFilters,
			onFilter: (value, record) => {
				if (record.color_name.startsWith(value)) {
					filteredArray.push(record);
				}
				return record.color_name.startsWith(value);
			},
			filterSearch: true,
		},
		{
			title: `85 (Total-${size85}pcs)`,
			dataIndex: "size85",
			key: "size85",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `90 (Total-${size90}pcs)`,
			dataIndex: "size90",
			key: "size90",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `95 (Total-${size95}pcs)`,
			dataIndex: "size95",
			key: "size95",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `100 (Total-${size100}pcs)`,
			dataIndex: "size100",
			key: "size100",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `105 (Total-${size105}pcs)`,
			dataIndex: "size105",
			key: "size105",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `110 (Total-${size110}pcs)`,
			dataIndex: "size110",
			key: "size110",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `115 (Total-${size115}pcs)`,
			dataIndex: "size115",
			key: "size115",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `120 (Total-${size120}pcs)`,
			dataIndex: "size120",
			key: "size120",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `Total (${totalOfTotal})`,
			key: "Total",
			dataIndex: "total",
			responsive: ["sm"],
			render: (text, data, index) => {
				total =
					readyStockList[index].size85 +
					readyStockList[index].size90 +
					readyStockList[index].size95 +
					readyStockList[index].size100 +
					readyStockList[index].size105 +
					readyStockList[index].size110 +
					readyStockList[index].size115 +
					readyStockList[index].size120;
				return <p>{total}</p>;
			},
		},
	];

	return (
		<>
			<Helmet>
				<title>Opening-Stock Report</title>
			</Helmet>
			<h1 className=" font-bold text-xl mb-2 flex justify-end ">
				{readyStockLastSyncDate}
			</h1>
			<div className=" flex justify-end ">
				<Button
					onClick={() => dispatch(exportReadyStock({}))}
					loading={isExportingReadyStock}
					className="rounded-lg  bg-primaryBase hover:bg-primaryDark text-white font-semibold ml-1"
				>
					Export Ready
				</Button>
			</div>
			<TableOptions masterName={"Opening Stock"} showAdd={false} />
			<div style={{ float: "right", marginBottom: "10px" }}>
				<Button onClick={handlePrint} type="primary" danger>
					{" "}
					Export to PDF{" "}
				</Button>
				<CSVLink
					filename={"Ready Stock Report.csv"}
					data={exportArray}
					headers={headers}
					style={{ marginLeft: "10px" }}
					onClick={() => {
						message.success("The file is downloading");
					}}
				>
					<Button type="primary" danger>
						Export to CSV
					</Button>
				</CSVLink>
			</div>
			<div ref={componentRef} style={{ width: "100%" }}>
				<Table
					dataSource={readyStockList}
					columns={columns}
					rowKey={readyStockList => readyStockList?.id}
					pagination={false}
					loading={isFetchingMasterList}
					bordered
					onChange={handleFilterChange}
				/>
			</div>
			{/* <div className="flex justify-center mt-5">
				{readyStockList?.length ? (
					<Pagination
						pageSize={pageSize}
						total={totalElements}
						current={currentPage}
						onChange={handlePageChange}
					/>
				) : null}
			</div> */}
		</>
	);
};

export default ReadyStockReports;
