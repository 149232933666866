import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
	getMasterDetails,
	masterSelectors,
	resetMasterDetails,
} from "slices/masterSlice";
// import Outward from "../Outward";
import OutwardV2 from "../OutwardV2";

const masterName = "outwardStock";
const OutwardStockWrapper = () => {
	const dispatch = useDispatch();
	const { mode, id } = useParams();

	const [loading, setLoading] = useState(id ? true : false);
	const outwardStockDetails = useSelector(
		masterSelectors?.getOutwardStockDetails
	);
	// const readyStockList = useSelector(masterSelectors?.getReadyStockList);
	const isViewOnly = mode === "view";

	// const list = outwardStockDetails?.data;

	const stock = outwardStockDetails?.data?.map(item => item?.Entrydetails);

	// const apiResponseObject = stock?.[0]?.reduce((acc, currentItem) => {
	// 	// Use the 'id' as the key and the item as the value
	// 	acc[currentItem.id] = currentItem;
	// 	return acc;
	// }, {});

	//console.log("deatils", apiResponseObject);
	// console.log("list", stock);
	// console.log("id", id);

	//const abc = apiResponseObject?.stock;
	//console.log("abc", abc?.stock);

	useEffect(() => {
		if (id) {
			dispatch(getMasterDetails({ masterName, id }));
			setLoading(false);
		}
		return () => dispatch(resetMasterDetails(masterName));
	}, [dispatch, id]);

	const initialValues = {
		entry: stock?.[0]?.map(item => ({
			id: item?.id || null,
			mainitem: item?.mainItem || "",
			color: item?.color || "",
			size85: item?.size34 || 0,
			size90: item?.size36 || 0,
			size95: item?.size38 || 0,
			size100: item?.size40 || 0,
			size105: item?.size42 || 0,
			size110: item?.size44 || 0,
			size115: item?.size46 || 0,
			size120: item?.size48 || 0,
			comment: item?.comment || "-",
			delete: item?.delete || 0,
			partyName: item?.partyName, //**
		})),
	};

	console.log("initialValue", initialValues);
	return (
		<OutwardV2
			initialData={initialValues}
			// submit={handleSubmit}
			// onCancel={handleClose}
			isViewOnly={isViewOnly}
		/>
	);
};

export default OutwardStockWrapper;
