import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

function Outward({ outward, outwardTotal }) {
  const rowData = outward;
  // console.log("outward", outward);

  const colDefs = [
    { field: "mainItem", width: 100 },
    { field: "color", width: 100 },
    { field: "partyName", width: 100 },
    { field: "size34", headerName: "34", width: 80 },
    { field: "size36", headerName: "36", width: 80 },
    { field: "size38", headerName: "38", width: 80 },
    { field: "size40", headerName: "40", width: 80 },
    { field: "size42", headerName: "42", width: 80 },
    { field: "size44", headerName: "44", width: 80 },
    { field: "size46", headerName: "46", width: 80 },
    { field: "size48", headerName: "48", width: 80 },
    { field: "total", width: 100 },
    { field: "OutwardNo", width: 150 },
    { field: "OutwardDate", headerName: "Date", width: 100 },
    { field: "recordStatus", headerName: "Status", width: 90 },
  ];
  // const autoSizeStrategy = {
  //   type: "fitGridWidth",
  //   defaultMinWidth: 100,
  // };

  const totals = outwardTotal;

  const pinnedBottomRow = [
    {
      size34: totals?.totalSize34 || 0,
      size36: totals?.totalSize36 || 0,
      size38: totals?.totalSize38 || 0,
      size40: totals?.totalSize40 || 0,
      size42: totals?.totalSize42 || 0,
      size44: totals?.totalSize44 || 0,
      size46: totals?.totalSize46 || 0,
      size48: totals?.totalSize48 || 0,
      total: totals?.total || 0,
      mainItem: "Total",
      color: "-",
    },
  ];
  return (
    <>
      <h1 className="text-xl mb-2 font-semibold">Outward Details Table</h1>
      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          pinnedBottomRowData={pinnedBottomRow}
        />
      </div>
    </>
  );
}

export default Outward;
