import { Button, Checkbox, Select } from "antd";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFilteredColorList, masterSelectors } from "slices/masterSlice";

const EntryTableV2 = ({
	addRow,
	data,
	setData,
	mainItemsOptions1,
	colorOptions,
	readyStockData,
	setReadyStockData,
	tempStockData,
	isFetchingDropdownList,
	isUpdatingMaster,
	handleFormSubmission,
	isUpdated,
	setTempStockData,
}) => {
	const { id } = useParams();
	const filteredColorList = useSelector(
		masterSelectors?.getFilteredColorDropdownList
	);

	const dispatch = useDispatch();

	const sizes = [85, 90, 95, 100, 105, 110, 115, 120];

	const handleDelete = index => {
		let tempReady = [...readyStockData];
		let tempStock = [...tempStockData];

		let tempData = data;

		const readyIndex = tempStock?.findIndex(
			item =>
				item.color === tempData[index]?.color &&
				item.mainitem === tempData[index]?.mainitem
		);

		const mainitem = tempData[index]?.mainitem;
		const color = tempData[index]?.color;

		const itemCount = tempData.reduce((count, item) => {
			if (item?.mainitem === mainitem && item?.color === color) {
				count++;
			}
			return count;
		}, 0);

		if (tempReady[readyIndex] !== undefined) {
			tempReady[readyIndex] = {
				...tempReady[readyIndex],
				size85:
					Number(tempReady[readyIndex]?.size85) +
					Number(tempData[index].size85),
				size90:
					Number(tempReady[readyIndex]?.size90) +
					Number(tempData[index].size90),
				size95:
					Number(tempReady[readyIndex]?.size95) +
					Number(tempData[index].size95),
				size100:
					Number(tempReady[readyIndex]?.size100) +
					Number(tempData[index].size100),
				size105:
					Number(tempReady[readyIndex]?.size105) +
					Number(tempData[index].size105),
				size110:
					Number(tempReady[readyIndex]?.size110) +
					Number(tempData[index].size110),
				size115:
					Number(tempReady[readyIndex]?.size115) +
					Number(tempData[index].size115),
				size120:
					Number(tempReady[readyIndex]?.size120) +
					Number(tempData[index].size120),
				backgroundColor: itemCount >= 2 ? "yellow" : "",
			};
			setReadyStockData([...tempReady]);
		}
		tempData.splice(index, 1);
		setData([...tempData]);
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const onMainItemChange = (value, index) => {
		let tempData = data;
		tempData[index].mainitem = value;
		setData([...tempData]);
		dispatch(
			getFilteredColorList({
				masterName: "outward/color_dropdown",
				mainItem: value,
			})
		);
	};

	const onChangeDefaultColor = index => {
		let tempData = data;
		console.log(tempData[index]);
		let tempReady1 = [...readyStockData];
		const readyIndex1 = readyStockData?.findIndex(
			item =>
				item.color === tempData[index]?.color &&
				item.mainitem === tempData[index]?.mainitem
		);

		if (tempReady1[readyIndex1] !== undefined) {
			tempReady1[readyIndex1] = {
				...tempReady1[readyIndex1],
				size85:
					Number(tempReady1[readyIndex1]?.size85) +
					Number(tempData[index].size85),
				size90:
					Number(tempReady1[readyIndex1]?.size90) +
					Number(tempData[index].size90),
				size95:
					Number(tempReady1[readyIndex1]?.size95) +
					Number(tempData[index].size95),
				size100:
					Number(tempReady1[readyIndex1]?.size100) +
					Number(tempData[index].size100),
				size105:
					Number(tempReady1[readyIndex1]?.size105) +
					Number(tempData[index].size105),
				size110:
					Number(tempReady1[readyIndex1]?.size110) +
					Number(tempData[index].size110),
				size115:
					Number(tempReady1[readyIndex1]?.size115) +
					Number(tempData[index].size115),
				size120:
					Number(tempReady1[readyIndex1]?.size120) +
					Number(tempData[index].size120),
				backgroundColor: "",
			};
			setReadyStockData([...tempReady1]);
		}
	};
	const onColorChange = (value, index) => {
		let tempData = data;
		//onChangeDefaultColor(index);
		let tempReady = [...readyStockData];
		const readyIndex1 = readyStockData?.findIndex(
			item =>
				item.color === tempData[index]?.color &&
				item.mainitem === tempData[index]?.mainitem
		);

		const mainitem = tempData[index]?.mainitem;
		const color = tempData[index]?.color;

		const itemCount = tempData.reduce((count, item) => {
			if (item?.mainitem === mainitem && item?.color === color) {
				count++;
			}
			return count;
		}, 0);

		if (tempReady[readyIndex1] !== undefined) {
			tempReady[readyIndex1] = {
				...tempReady[readyIndex1],
				size85:
					Number(tempReady[readyIndex1]?.size85) +
					Number(tempData[index].size85),
				size90:
					Number(tempReady[readyIndex1]?.size90) +
					Number(tempData[index].size90),
				size95:
					Number(tempReady[readyIndex1]?.size95) +
					Number(tempData[index].size95),
				size100:
					Number(tempReady[readyIndex1]?.size100) +
					Number(tempData[index].size100),
				size105:
					Number(tempReady[readyIndex1]?.size105) +
					Number(tempData[index].size105),
				size110:
					Number(tempReady[readyIndex1]?.size110) +
					Number(tempData[index].size110),
				size115:
					Number(tempReady[readyIndex1]?.size115) +
					Number(tempData[index].size115),
				size120:
					Number(tempReady[readyIndex1]?.size120) +
					Number(tempData[index].size120),
				backgroundColor: itemCount >= 2 ? "yellow" : "",
			};
			setReadyStockData([...tempReady]);
		}
		tempData[index].color = value;
		setData([...tempData]);

		//	let tempReady = [...readyStockData];
		const readyIndex = readyStockData?.findIndex(
			item => item.color === value && item.mainitem === tempData[index].mainitem
		);
		// if (tempReady[readyIndex] !== undefined) {
		// 	tempReady[readyIndex] = {
		// 		...tempReady[readyIndex],
		// 		size85:
		// 			Number(tempReady[readyIndex]?.size85) -
		// 			Number(tempData[index].size85),
		// 		size90:
		// 			Number(tempReady[readyIndex]?.size90) -
		// 			Number(tempData[index].size90),
		// 		size95:
		// 			Number(tempReady[readyIndex]?.size95) -
		// 			Number(tempData[index].size95),
		// 		size100:
		// 			Number(tempReady[readyIndex]?.size100) -
		// 			Number(tempData[index].size100),
		// 		size105:
		// 			Number(tempReady[readyIndex]?.size105) -
		// 			Number(tempData[index].size105),
		// 		size110:
		// 			Number(tempReady[readyIndex]?.size110) -
		// 			Number(tempData[index].size110),
		// 		size115:
		// 			Number(tempReady[readyIndex]?.size115) -
		// 			Number(tempData[index].size115),
		// 		size120:
		// 			Number(tempReady[readyIndex]?.size120) -
		// 			Number(tempData[index].size120),
		// 		backgroundColor: tempReady[readyIndex] ? "yellow" : "",
		// 	};
		// 	setReadyStockData([...tempReady]);
		// }
		if (tempReady[readyIndex] !== undefined) {
			const updatedItem = {
				...tempReady[readyIndex],
				size85:
					Number(tempReady[readyIndex]?.size85) -
					Number(tempData[index].size85),
				size90:
					Number(tempReady[readyIndex]?.size90) -
					Number(tempData[index].size90),
				size95:
					Number(tempReady[readyIndex]?.size95) -
					Number(tempData[index].size95),
				size100:
					Number(tempReady[readyIndex]?.size100) -
					Number(tempData[index].size100),
				size105:
					Number(tempReady[readyIndex]?.size105) -
					Number(tempData[index].size105),
				size110:
					Number(tempReady[readyIndex]?.size110) -
					Number(tempData[index].size110),
				size115:
					Number(tempReady[readyIndex]?.size115) -
					Number(tempData[index].size115),
				size120:
					Number(tempReady[readyIndex]?.size120) -
					Number(tempData[index].size120),
				backgroundColor: "yellow",
			};

			const updatedTempReady = tempReady.map((item, idx) =>
				idx === readyIndex
					? updatedItem
					: {
							...item,
							backgroundColor:
								item.backgroundColor === "yellow" ? "" : item.backgroundColor,
					  }
			);

			setReadyStockData(updatedTempReady);
		}
	};

	const [idx, setIdx] = useState();

	const handleChange = (value, index, type, obj) => {
		let tempReady = [...readyStockData];
		let tempStock = [...tempStockData];

		const readyIndex = tempStock?.findIndex(
			item => item.color === obj?.color && item.mainitem === obj?.mainitem
		);
		setIdx(readyIndex);
		let tempData = data;
		if (type === "partyName") tempData[index].partyName = value;

		if (type === "size85") {
			if (readyStockData[readyIndex]?.size85 >= value && Number(value) > 0) {
				let val = tempData[index].size85;
				tempData[index].size85 = value;
				if (tempReady[readyIndex] !== undefined && Number(value) > 0) {
					const updatedItem = {
						...tempReady[readyIndex],
						size85:
							Number(readyStockData[readyIndex]?.size85) -
							Number(value) +
							Number(val),
						backgroundColor: "yellow",
					};
					//	setReadyStockData([...tempReady]);
					const updatedTempReady = tempReady.map((item, idx) =>
						idx === readyIndex
							? updatedItem
							: {
									...item,
									backgroundColor:
										item.backgroundColor === "yellow"
											? ""
											: item.backgroundColor,
							  }
					);
					setReadyStockData(updatedTempReady);
				}
			}

			if (tempReady[readyIndex] !== undefined && Number(value) === 0) {
				tempReady[readyIndex] = {
					...tempReady[readyIndex],
					size85:
						Number(readyStockData[readyIndex]?.size85) +
						Number(tempData[index].size85),
				};
				tempData[index].size85 = value;
				setReadyStockData([...tempReady]);
			}
		}

		if (type === "size90") {
			if (readyStockData[readyIndex]?.size90 >= value && Number(value) > 0) {
				let val = tempData[index].size90;
				tempData[index].size90 = value;
				if (tempReady[readyIndex] !== undefined && Number(value) > 0) {
					// tempReady[readyIndex] = {
					// 	...tempReady[readyIndex],
					// 	size90:
					// 		Number(readyStockData[readyIndex]?.size90) -
					// 		Number(value) +
					// 		Number(val),
					// };
					const updatedItem = {
						...tempReady[readyIndex],
						size90:
							Number(readyStockData[readyIndex]?.size90) -
							Number(value) +
							Number(val),
						backgroundColor: "yellow",
					};
					//	setReadyStockData([...tempReady]);
					const updatedTempReady = tempReady.map((item, idx) =>
						idx === readyIndex
							? updatedItem
							: {
									...item,
									backgroundColor:
										item.backgroundColor === "yellow"
											? ""
											: item.backgroundColor,
							  }
					);
					setReadyStockData(updatedTempReady);
				}
			}

			if (tempReady[readyIndex] !== undefined && Number(value) === 0) {
				tempReady[readyIndex] = {
					...tempReady[readyIndex],
					size90:
						Number(readyStockData[readyIndex]?.size90) +
						Number(tempData[index].size90),
				};
				tempData[index].size90 = value;
				setReadyStockData([...tempReady]);
			}
		}

		if (type === "size95") {
			if (readyStockData[readyIndex]?.size95 >= value && Number(value) > 0) {
				let val = tempData[index].size95;
				tempData[index].size95 = value;
				if (tempReady[readyIndex] !== undefined && Number(value) > 0) {
					// tempReady[readyIndex] = {
					// 	...tempReady[readyIndex],
					// 	size95:
					// 		Number(readyStockData[readyIndex]?.size95) -
					// 		Number(value) +
					// 		Number(val),
					// };
					const updatedItem = {
						...tempReady[readyIndex],
						size95:
							Number(readyStockData[readyIndex]?.size95) -
							Number(value) +
							Number(val),
						backgroundColor: "yellow",
					};
					//	setReadyStockData([...tempReady]);
					const updatedTempReady = tempReady.map((item, idx) =>
						idx === readyIndex
							? updatedItem
							: {
									...item,
									backgroundColor:
										item.backgroundColor === "yellow"
											? ""
											: item.backgroundColor,
							  }
					);
					setReadyStockData(updatedTempReady);
				}
			}

			if (tempReady[readyIndex] !== undefined && Number(value) === 0) {
				tempReady[readyIndex] = {
					...tempReady[readyIndex],
					size95:
						Number(readyStockData[readyIndex]?.size95) +
						Number(tempData[index].size95),
				};
				tempData[index].size95 = value;
				setReadyStockData([...tempReady]);
			}
		}

		if (type === "size100") {
			if (readyStockData[readyIndex]?.size100 >= value && Number(value) > 0) {
				let val = tempData[index].size100;
				tempData[index].size100 = value;
				if (tempReady[readyIndex] !== undefined && Number(value) > 0) {
					// tempReady[readyIndex] = {
					// 	...tempReady[readyIndex],
					// 	size100:
					// 		Number(readyStockData[readyIndex]?.size100) -
					// 		Number(value) +
					// 		Number(val),
					// };
					const updatedItem = {
						...tempReady[readyIndex],
						size100:
							Number(readyStockData[readyIndex]?.size100) -
							Number(value) +
							Number(val),
						backgroundColor: "yellow",
					};
					//	setReadyStockData([...tempReady]);
					const updatedTempReady = tempReady.map((item, idx) =>
						idx === readyIndex
							? updatedItem
							: {
									...item,
									backgroundColor:
										item.backgroundColor === "yellow"
											? ""
											: item.backgroundColor,
							  }
					);
					setReadyStockData(updatedTempReady);
				}
			}

			if (tempReady[readyIndex] !== undefined && Number(value) === 0) {
				tempReady[readyIndex] = {
					...tempReady[readyIndex],
					size100:
						Number(readyStockData[readyIndex]?.size100) +
						Number(tempData[index].size100),
				};
				tempData[index].size100 = value;
				setReadyStockData([...tempReady]);
			}
		}

		if (type === "size105") {
			if (readyStockData[readyIndex]?.size105 >= value && Number(value) > 0) {
				let val = tempData[index].size105;
				tempData[index].size105 = value;
				if (tempReady[readyIndex] !== undefined && Number(value) > 0) {
					// tempReady[readyIndex] = {
					// 	...tempReady[readyIndex],
					// 	size105:
					// 		Number(readyStockData[readyIndex]?.size105) -
					// 		Number(value) +
					// 		Number(val),
					// };
					const updatedItem = {
						...tempReady[readyIndex],
						size105:
							Number(readyStockData[readyIndex]?.size105) -
							Number(value) +
							Number(val),
						backgroundColor: "yellow",
					};
					//	setReadyStockData([...tempReady]);
					const updatedTempReady = tempReady.map((item, idx) =>
						idx === readyIndex
							? updatedItem
							: {
									...item,
									backgroundColor:
										item.backgroundColor === "yellow"
											? ""
											: item.backgroundColor,
							  }
					);
					setReadyStockData(updatedTempReady);
				}
			}

			if (tempReady[readyIndex] !== undefined && Number(value) === 0) {
				tempReady[readyIndex] = {
					...tempReady[readyIndex],
					size105:
						Number(readyStockData[readyIndex]?.size105) +
						Number(tempData[index].size105),
				};
				tempData[index].size105 = value;
				setReadyStockData([...tempReady]);
			}
		}

		if (type === "size110") {
			if (readyStockData[readyIndex]?.size110 >= value && Number(value) > 0) {
				let val = tempData[index].size110;
				tempData[index].size110 = value;
				if (tempReady[readyIndex] !== undefined && Number(value) > 0) {
					// tempReady[readyIndex] = {
					// 	...tempReady[readyIndex],
					// 	size110:
					// 		Number(readyStockData[readyIndex]?.size110) -
					// 		Number(value) +
					// 		Number(val),
					// };
					const updatedItem = {
						...tempReady[readyIndex],
						size110:
							Number(readyStockData[readyIndex]?.size110) -
							Number(value) +
							Number(val),
						backgroundColor: "yellow",
					};
					//	setReadyStockData([...tempReady]);
					const updatedTempReady = tempReady.map((item, idx) =>
						idx === readyIndex
							? updatedItem
							: {
									...item,
									backgroundColor:
										item.backgroundColor === "yellow"
											? ""
											: item.backgroundColor,
							  }
					);
					setReadyStockData(updatedTempReady);
				}
			}

			if (tempReady[readyIndex] !== undefined && Number(value) === 0) {
				tempReady[readyIndex] = {
					...tempReady[readyIndex],
					size110:
						Number(readyStockData[readyIndex]?.size110) +
						Number(tempData[index].size110),
				};
				tempData[index].size110 = value;
				setReadyStockData([...tempReady]);
			}
		}

		if (type === "size115") {
			if (readyStockData[readyIndex]?.size115 >= value && Number(value) > 0) {
				let val = tempData[index].size115;
				tempData[index].size115 = value;
				if (tempReady[readyIndex] !== undefined && Number(value) > 0) {
					// tempReady[readyIndex] = {
					// 	...tempReady[readyIndex],
					// 	size115:
					// 		Number(readyStockData[readyIndex]?.size115) -
					// 		Number(value) +
					// 		Number(val),
					// };
					const updatedItem = {
						...tempReady[readyIndex],
						size115:
							Number(readyStockData[readyIndex]?.size115) -
							Number(value) +
							Number(val),
						backgroundColor: "yellow",
					};
					//	setReadyStockData([...tempReady]);
					const updatedTempReady = tempReady.map((item, idx) =>
						idx === readyIndex
							? updatedItem
							: {
									...item,
									backgroundColor:
										item.backgroundColor === "yellow"
											? ""
											: item.backgroundColor,
							  }
					);
					setReadyStockData(updatedTempReady);
				}
			}

			if (tempReady[readyIndex] !== undefined && Number(value) === 0) {
				tempReady[readyIndex] = {
					...tempReady[readyIndex],
					size115:
						Number(readyStockData[readyIndex]?.size115) +
						Number(tempData[index].size115),
				};
				tempData[index].size115 = value;
				setReadyStockData([...tempReady]);
			}
		}

		if (type === "size120") {
			if (readyStockData[readyIndex]?.size120 >= value && Number(value) > 0) {
				let val = tempData[index].size120;
				tempData[index].size120 = value;
				if (tempReady[readyIndex] !== undefined && Number(value) > 0) {
					// tempReady[readyIndex] = {
					// 	...tempReady[readyIndex],
					// 	size120:
					// 		Number(readyStockData[readyIndex]?.size120) -
					// 		Number(value) +
					// 		Number(val),
					// };
					const updatedItem = {
						...tempReady[readyIndex],
						size120:
							Number(readyStockData[readyIndex]?.size120) -
							Number(value) +
							Number(val),
						backgroundColor: "yellow",
					};
					//	setReadyStockData([...tempReady]);
					const updatedTempReady = tempReady.map((item, idx) =>
						idx === readyIndex
							? updatedItem
							: {
									...item,
									backgroundColor:
										item.backgroundColor === "yellow"
											? ""
											: item.backgroundColor,
							  }
					);
					setReadyStockData(updatedTempReady);
				}
			}

			if (tempReady[readyIndex] !== undefined && Number(value) === 0) {
				tempReady[readyIndex] = {
					...tempReady[readyIndex],
					size120:
						Number(readyStockData[readyIndex]?.size120) +
						Number(tempData[index].size120),
				};
				tempData[index].size120 = value;
				setReadyStockData([...tempReady]);
			}
		}

		if (type === "comment") tempData[index].comment = value;
		setData([...tempData]);

		//setReadyStockData([...tempReady]);
	};

	// const handleChange = (value, index, type, obj) => {
	// 	let tempReady = [...readyStockData];
	// 	let tempStock = [...tempStockData];

	// 	const readyIndex = tempStock?.findIndex(
	// 		item => item.color === obj?.color && item.mainitem === obj?.mainitem
	// 	);
	// 	setIdx(readyIndex);
	// 	let tempData = data;
	// 	if (type === "partyName") tempData[index].partyName = value;

	// 	if (type === "comment") tempData[index].comment = value;
	// 	setData([...tempData]);

	// 	const newValue = Number(value);

	// 	data.forEach(entry => {
	// 		const readyIndex = tempStock?.findIndex(
	// 			item => item.color === entry?.color && item.mainitem === entry?.mainitem
	// 		);

	// 		if (type === "size85") {
	// 			tempData[index].size85 = value;
	// 			if (tempReady[readyIndex] !== undefined) {
	// 				tempReady[readyIndex] = {
	// 					...tempReady[readyIndex],
	// 					size85: Number(tempStock[readyIndex]?.size85) - Number(newValue),
	// 				};
	// 				//	setReadyStockData([...tempReady]);
	// 			}
	// 		}

	// 		if (type === "size90") {
	// 			tempData[index].size90 = value;
	// 			if (tempReady[readyIndex] !== undefined) {
	// 				tempReady[readyIndex] = {
	// 					...tempReady[readyIndex],
	// 					size90: Number(tempStock[readyIndex]?.size90) - Number(newValue),
	// 				};
	// 				//	setReadyStockData([...tempReady]);
	// 			}
	// 		}

	// 		if (type === "size95") {
	// 			tempData[index].size95 = value;
	// 			if (tempReady[readyIndex] !== undefined) {
	// 				tempReady[readyIndex] = {
	// 					...tempReady[readyIndex],
	// 					size95: Number(tempStock[readyIndex]?.size95) - Number(value),
	// 				};
	// 				//	setReadyStockData([...tempReady]);
	// 			}
	// 		}

	// 		if (type === "size100") {
	// 			tempData[index].size100 = value;
	// 			if (tempReady[readyIndex] !== undefined) {
	// 				tempReady[readyIndex] = {
	// 					...tempReady[readyIndex],
	// 					size100: Number(tempStock[readyIndex]?.size100) - Number(value),
	// 				};
	// 				//	setReadyStockData([...tempReady]);
	// 			}
	// 		}

	// 		if (type === "size105") {
	// 			tempData[index].size105 = value;
	// 			if (tempReady[readyIndex] !== undefined) {
	// 				tempReady[readyIndex] = {
	// 					...tempReady[readyIndex],
	// 					size105: Number(tempStock[readyIndex]?.size105) - Number(value),
	// 				};
	// 				//	setReadyStockData([...tempReady]);
	// 			}
	// 		}

	// 		if (type === "size110") {
	// 			tempData[index].size110 = value;
	// 			if (tempReady[readyIndex] !== undefined) {
	// 				tempReady[readyIndex] = {
	// 					...tempReady[readyIndex],
	// 					size110: Number(tempStock[readyIndex]?.size110) - Number(value),
	// 				};
	// 				//		setReadyStockData([...tempReady]);
	// 			}
	// 		}
	// 		if (type === "size115") {
	// 			tempData[index].size115 = value;
	// 			if (tempReady[readyIndex] !== undefined) {
	// 				tempReady[readyIndex] = {
	// 					...tempReady[readyIndex],
	// 					size115: Number(tempStock[readyIndex]?.size115) - Number(value),
	// 				};
	// 				//	setReadyStockData([...tempReady]);
	// 			}
	// 		}
	// 		if (type === "size120") {
	// 			tempData[index].size120 = value;
	// 			if (tempReady[readyIndex] !== undefined) {
	// 				tempReady[readyIndex] = {
	// 					...tempReady[readyIndex],
	// 					size120: Number(tempStock[readyIndex]?.size120) - Number(value),
	// 				};
	// 				//	setReadyStockData([...tempReady]);
	// 			}
	// 		}

	// 		//setReadyStockData([...tp]);
	// 	});
	// 	setReadyStockData([...tempReady]);
	// };

	const totalValue = index => {
		let tempData = data;
		let row = tempData[index];
		let total =
			Number(row?.size85) +
			Number(row?.size90) +
			Number(row?.size95) +
			Number(row?.size100) +
			Number(row?.size105) +
			Number(row?.size110) +
			Number(row?.size115) +
			Number(row?.size120);
		return total;
	};

	const handleCheck = (value, index) => {
		let tempData = data;
		tempData[index].delete = value === true ? 1 : 0;
		setData([...tempData]);
	};

	return (
		<div className="entry-table-v2 ">
			<h6 className="text-xl mb-2">
				Entry Form <Button onClick={addRow}>Add Row</Button>{" "}
				<Button
					className=" text-white bg-redBase font-semibold"
					onClick={handleFormSubmission}
					loading={isUpdated}
				>
					Submit
				</Button>
			</h6>

			<div className="flex items-center">
				<div className="custom-w-lg">
					<h6>Party</h6>
				</div>
				<div className="custom-w-lg">
					<h6>Main Item</h6>
				</div>
				<div className="custom-w-lg">
					<h6>Color</h6>
				</div>
				{sizes?.map((item, idx) => (
					<div className="custom-w-sm" key={idx}>
						<h6>{item}</h6>
					</div>
				))}
				<div className="custom-w-md">
					<h6>Total</h6>
				</div>
				<div className="custom-w-lg">
					<h6>Comments</h6>
				</div>
				<div className="custom-w-md">
					<h6>Delete</h6>
				</div>
			</div>
			<div className="scroll-v2">
				{data?.map((item, index) => (
					<div className="flex items-center " key={item?.id}>
						<div className="custom-w-lg">
							<textarea
								type="text"
								className="custom-inp h-11  max-w-full custom-text "
								wrap="soft"
								spellCheck="false"
								value={item?.partyName}
								onChange={e => handleChange(e.target.value, index, "partyName")}
							/>
						</div>
						<div className="custom-w-lg">
							<Select
								value={item?.mainitem}
								style={{ width: "148px" }}
								showSearch
								placeholder="Select main item"
								optionFilterProp="children"
								onChange={e => onMainItemChange(e, index)}
								filterOption={filterOption}
								options={mainItemsOptions1}
								allowClear
							/>
						</div>
						<div className="custom-w-lg">
							<Select
								value={item?.color}
								style={{ width: "148px" }}
								loading={isFetchingDropdownList}
								showSearch
								placeholder="Select Color"
								optionFilterProp="children"
								onChange={e => {
									//onChangeDefaultColor(index);

									onColorChange(e, index);
								}}
								filterOption={filterOption}
								options={filteredColorList || colorOptions || []}
								allowClear
							/>
						</div>
						<div className="custom-w-sm">
							<input
								type="number"
								className={`custom-inp ${
									Number(readyStockData[idx]?.size85) < 0
										? "negative-size"
										: "positive-size"
								}`}
								value={item?.size85}
								onChange={e =>
									handleChange(e.target.value, index, "size85", item)
								}
							/>
						</div>
						<div className="custom-w-sm">
							<input
								type="number"
								className={`custom-inp ${
									Number(readyStockData[idx]?.size90) < 0
										? "negative-size"
										: "positive-size"
								}`}
								value={item?.size90}
								onChange={e =>
									handleChange(e.target.value, index, "size90", item)
								}
							/>
						</div>
						<div className="custom-w-sm">
							<input
								type="number"
								className={`custom-inp ${
									Number(readyStockData[idx]?.size95) < 0
										? "negative-size"
										: "positive-size"
								}`}
								value={item?.size95}
								onChange={e =>
									handleChange(e.target.value, index, "size95", item)
								}
							/>
						</div>
						<div className="custom-w-sm">
							<input
								type="number"
								className={`custom-inp ${
									Number(readyStockData[idx]?.size100) < 0
										? "negative-size"
										: "positive-size"
								}`}
								value={item?.size100}
								onChange={e =>
									handleChange(e.target.value, index, "size100", item)
								}
							/>
						</div>
						<div className="custom-w-sm">
							<input
								type="number"
								className={`custom-inp ${
									Number(readyStockData[idx]?.size105) < 0
										? "negative-size"
										: "positive-size"
								}`}
								value={item?.size105}
								onChange={e =>
									handleChange(e.target.value, index, "size105", item)
								}
							/>
						</div>
						<div className="custom-w-sm">
							<input
								type="number"
								className={`custom-inp ${
									Number(readyStockData[idx]?.size110) < 0
										? "negative-size"
										: "positive-size"
								}`}
								value={item?.size110}
								onChange={e =>
									handleChange(e.target.value, index, "size110", item)
								}
							/>
						</div>
						<div className="custom-w-sm">
							<input
								type="number"
								className={`custom-inp ${
									Number(readyStockData[idx]?.size115) < 0
										? "negative-size"
										: "positive-size"
								}`}
								value={item?.size115}
								onChange={e =>
									handleChange(e.target.value, index, "size115", item)
								}
							/>
						</div>
						<div className="custom-w-sm">
							<input
								type="number"
								className={`custom-inp ${
									Number(readyStockData[idx]?.size120) < 0
										? "negative-size"
										: "positive-size"
								}`}
								value={item?.size120}
								onChange={e =>
									handleChange(e.target.value, index, "size120", item)
								}
							/>
						</div>
						<div className="custom-w-md">
							<input
								type="number"
								className="custom-inp"
								disabled
								placeholder={totalValue(index)}
							/>
						</div>

						<div className="custom-w-lg">
							<input
								type="text"
								className="custom-inp"
								value={item?.comment}
								onChange={e => handleChange(e.target.value, index, "comment")}
							/>
						</div>
						<div className="custom-w-xsm">
							{id ? (
								<Checkbox
									onChange={e => handleCheck(e.target.checked, index)}
								/>
							) : (
								<Button danger onClick={() => handleDelete(index)}>
									<MdDelete size={22} />
								</Button>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default EntryTableV2;
