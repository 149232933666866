import { Button, Dropdown } from "antd";
import { MdArrowDropDown } from "react-icons/md";

export const renderActions = items => {
	if (items?.length > 1) {
		return (
			<Dropdown
				menu={{
					items: items.map(item => ({
						label: (
							<Button
								className={`flex justify-center items-center p-0 w-full ${
									item?.type === "danger" ? "text-redBase" : "text-primaryDark"
								}`}
								type="link"
								onClick={() => item.onClick()}
							>
								{item.label}
							</Button>
						),
					})),
				}}
				placement="bottom"
				trigger="click"
			>
				<Button className="flex items-center m-0 p-0" type="link">
					Actions
					<MdArrowDropDown className="mb-1" />
				</Button>
			</Dropdown>
		);
	} else if (items?.length === 1) {
		return (
			<Button
				type={`link ${
					items?.[0]?.type === "danger" ? "text-redBase" : "text-primaryDark"
				}`}
				onClick={() => items?.[0]?.onClick()}
			>
				{items?.[0]?.label}
			</Button>
		);
	} else {
		return null;
	}
};
