import { Table } from "antd";
import useMasterLogic from "customHooks/useMasterLogic";
import { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { reduxForm } from "redux-form";
import { getMasterList, masterSelectors } from "slices/masterSlice";
import { renderActions } from "utils/renderActions";

export const masterName = "readyStockEntry";
const ReadyStockEntry = () => {
	const dispatch = useDispatch();

	const readyStockList = useSelector(masterSelectors?.getReadyStockEntryList);

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);

	const getReadyStockList = useCallback(() => {
		dispatch(
			getMasterList({
				masterName,
			})
		);
	}, [dispatch]);

	const { handleEditMasterReady } = useMasterLogic(
		getReadyStockList,
		masterName
	);

	const stock = readyStockList?.map(item => item?.stock);

	//console.log("val", stock);

	const expandedRowRender = record => {
		var size34 = 0;
		var size36 = 0;
		var size38 = 0;
		var size40 = 0;
		var size42 = 0;
		var size44 = 0;
		var size46 = 0;
		var size48 = 0;
		var total = 0;

		record?.stock?.forEach(se => {
			size34 += +se?.size34 || 0;
			size36 += +se?.size36 || 0;
			size38 += +se?.size38 || 0;
			size40 += +se?.size40 || 0;
			size42 += +se?.size42 || 0;
			size44 += +se?.size44 || 0;
			size46 += +se?.size46 || 0;
			size48 += +se?.size48 || 0;
		});
		const columns = [
			{
				title: "Item",
				dataIndex: "mainitem",
				key: "mainitem",
				responsive: ["sm"],
				render: value => value || "-",
			},
			{
				title: "Color Name",
				dataIndex: "color",
				key: "color",
				responsive: ["sm"],
				render: value => value || "-",
			},
			{
				title: `34 (Total-${size34} pcs)`,
				dataIndex: "size34",
				key: "size34",
				responsive: ["sm"],
				render: value => value || "0",
			},
			{
				title: `36 (Total-${size36} pcs)`,
				dataIndex: "size36",
				key: "size36",
				responsive: ["sm"],
				render: value => value || "0",
			},
			{
				title: `38 (Total-${size38} pcs)`,
				dataIndex: "size38",
				key: "size38",
				responsive: ["sm"],
				render: value => value || "0",
			},
			{
				title: `40 (Total-${size40} pcs)`,
				dataIndex: "size40",
				key: "size40",
				responsive: ["sm"],
				render: value => value || "0",
			},
			{
				title: `42 (Total-${size42} pcs)`,
				dataIndex: "size42",
				key: "size42",
				responsive: ["sm"],
				render: value => value || "0",
			},
			{
				title: `44 (Total-${size44} pcs)`,
				dataIndex: "size44",
				key: "size44",
				responsive: ["sm"],
				render: value => value || "0",
			},
			{
				title: `46 (Total-${size46} pcs)`,
				dataIndex: "size46",
				key: "size46",
				responsive: ["sm"],
				render: value => value || "0",
			},
			{
				title: `48 (Total-${size48} pcs)`,
				dataIndex: "size48",
				key: "size48",
				responsive: ["sm"],
				render: value => value || "0",
			},
			{
				title: "Total",
				key: "Total",
				dataIndex: "total",
				responsive: ["sm"],
				render: (text, data, index) => {
					total =
						record?.stock?.[index].size34 +
						record?.stock?.[index].size36 +
						record?.stock?.[index].size38 +
						record?.stock?.[index].size40 +
						record?.stock?.[index].size42 +
						record?.stock?.[index].size44 +
						record?.stock?.[index].size46 +
						record?.stock?.[index].size48;
					return <p>{total}</p>;
				},
			},
			{
				title: "Comment",
				dataIndex: "comment",
				key: "comment",
				responsive: ["sm"],
				render: value => value || "-",
			},
		];
		return (
			<Table
				columns={columns}
				dataSource={record?.stock}
				rowKey={stock => stock?.id}
				pagination={false}
				bordered
			/>
		);
	};

	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: value => value || "-",
		},
		{
			title: "Entry No",
			dataIndex: "entry_no",
			key: "entry_no",
			render: value => value || "-",
		},
		{
			title: "Comment Overview",
			dataIndex: "commentOverview",
			key: "commentOverview",
			render: value => value || "-",
		},

		{
			title: "Actions",
			key: "Actions",
			dataIndex: "action",
			width: "20%",
			render: (_, data) => {
				const items = [
					{
						label: "Edit",
						onClick: () => handleEditMasterReady(data?.id),
					},
				];

				return renderActions(items);
			},
		},
	];

	return (
		<>
			<Helmet>
				<title>Inward Stock Entry</title>
			</Helmet>
			<div className="flex justify-end md:mr-10 mb-5">
				<Link
					to="/working/ready-stock-form"
					className="mt-1 text-black border-black border p-2 rounded-md font-semibold hover:text-primaryDark hover:border-primaryDark"
					type="link"
					target="_blank"
				>
					Open Form
				</Link>
			</div>
			<h1 className="text-2xl font-bold self-center captialize ">
				Inward Stock Entry
			</h1>
			<Table
				columns={columns}
				expandable={{
					expandedRowRender,
					rowExpandable: record => !!record.id,
				}}
				rowKey={readyStockList => readyStockList?.id}
				dataSource={readyStockList}
				bordered
				loading={isFetchingMasterList}
				pagination={false}
				scroll={{ y: 700 }}
			/>
		</>
	);
};

export default reduxForm({
	form: "readyStockEntry",
	enableReinitialize: true,
})(ReadyStockEntry);
