import { Button, Dropdown, Menu, Modal, Table } from "antd";
import StatusSegments from "components/StatusSegments";
import TableOptions from "components/TableOptions";
import { masterStatuses } from "components/category/Categories";
import useMasterLogic from "customHooks/useMasterLogic";
import useSearchFilter from "customHooks/useSearchFilter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { MdArrowDropDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	Field,
	change,
	formValueSelector,
	getFormValues,
	reduxForm,
} from "redux-form";
import {
	editMasterListMain,
	exportChalan,
	exportExcel,
	exportReadyStock,
	getLadtSyncDate,
	getMasterList,
	masterSelectors,
	uploadNewItemExcel,
} from "slices/masterSlice";
import { ReduxFormFileField } from "utils/ReduxFormFileField";
import { ReduxFormTextField1 } from "utils/ReduxFormTextField";
import { onWheelHandler } from "utils/onWheelHandler";
import { renderActions } from "utils/renderActions";
import { renderMobileColumn } from "utils/renderMobileColumn";

export const masterName = "mainitem";
const formName = "mainitem";

const formSelector = formValueSelector(formName);
const MainItems = initialValues => {
	const { status } = useParams();
	const dispatch = useDispatch();
	const mainItemsListMain = useSelector(
		masterSelectors?.getMainItemsMasterList
	);
	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);
	const isExportExcel = useSelector(masterSelectors?.getIsExportExcel);
	const isExportChalan = useSelector(masterSelectors?.getIsExportChalan);
	const isExportingReadyStock = useSelector(masterSelectors?.getIsExportReady);
	const mainLastSyncDate = useSelector(masterSelectors?.getLastSyncDate);
	const formValues = useSelector(getFormValues(formName));
	const navigate = useNavigate();

	const { getColumnSearchProps, tableFilters } = useSearchFilter();

	const [mainItemsList, setMainItemsList] = useState(
		useSelector(masterSelectors?.getMainItemsMasterList)
	);

	useEffect(() => {
		setMainItemsList(mainItemsListMain);
	}, [mainItemsListMain]);

	const getMainItemsList = useCallback(() => {
		dispatch(
			getMasterList({
				masterName,
				query: {
					is_active: status,
					...tableFilters,
				},
			})
		);
	}, [status, tableFilters, dispatch]);

	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const excelFile = useSelector(state => formSelector(state, "item_excel"));

	const handleOk = () => {
		setIsModalOpen(false);
		const form = new FormData();
		form.append("file", excelFile);
		dispatch(
			uploadNewItemExcel({
				masterName,
				formData: form,
				configType: "multipart/form-data",
			})
		);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		dispatch(getLadtSyncDate({ type: "All Masters" }));
	}, [dispatch]);

	const [filters, setFilters] = useState({}); // State to hold filters

	const handleFilterChange = (column, filtersValue) => {
		setFilters({
			...filters,
			[column]: filtersValue,
		});
	};

	useEffect(() => {
		//console.log("filters:", Object.values(filters));
		let obj = Object.values(filters);

		let tempData = mainItemsListMain;

		let name = obj?.[0]?.["name"] || [];
		let category_id = obj?.[0]?.["category_id"] || [];
		let subcategory_id = obj?.[0]?.["subcategory_id"] || [];
		let season = obj?.[0]?.["season"] || [];
		let collection = obj?.[0]?.["collection"] || [];
		let type_of_item = obj?.[0]?.["type_of_item"] || [];
		//console.log(supplier, main_item, color_name, sub_category_name);

		if (name?.length)
			tempData = tempData.filter(item => name.includes(item?.name));
		if (category_id?.length)
			tempData = tempData.filter(item =>
				category_id.includes(item?.category_id)
			);
		if (subcategory_id?.length)
			tempData = tempData.filter(item =>
				subcategory_id.includes(item?.subcategory_id)
			);
		if (season?.length)
			tempData = tempData.filter(item => season.includes(item?.season));
		if (collection?.length)
			tempData = tempData.filter(item => collection.includes(item?.collection));
		if (type_of_item?.length)
			tempData = tempData.filter(item =>
				type_of_item.includes(item?.type_of_item)
			);

		//	console.log("fd", tempData);
		setMainItemsList(tempData);
	}, [filters]);

	const subcategories = Array.from(
		new Set(mainItemsList.map(item => item.subcategory_id))
	);

	const subCategoryFilters = subcategories.map(subcategory => ({
		text: subcategory,
		value: subcategory,
	}));

	const categories = Array.from(
		new Set(mainItemsList.map(item => item.category_id))
	);

	const categoryFilters = categories.map(categories => ({
		text: categories,
		value: categories,
	}));

	const mainItems = Array.from(new Set(mainItemsList.map(item => item.name)));

	const mainItemFilters = mainItems.map(mainItems => ({
		text: mainItems,
		value: mainItems,
	}));

	const seasons = Array.from(new Set(mainItemsList.map(item => item.season)));

	const seasonFilters = seasons.map(seasons => ({
		text: seasons,
		value: seasons,
	}));

	const collections = Array.from(
		new Set(mainItemsList.map(item => item.collection))
	);

	const collectionFilters = collections.map(collections => ({
		text: collections,
		value: collections,
	}));

	const uploadTypes = Array.from(
		new Set(mainItemsList.map(item => item.type_of_item))
	);

	const uploadTypeFilters = uploadTypes.map(uploadTypes => ({
		text: uploadTypes,
		value: uploadTypes,
	}));

	const mrp = Array.from(new Set(mainItemsList.map(item => item.mrp)));

	const mrpFilters = mrp.map(mrp => ({
		text: String(mrp),
		value: String(mrp),
	}));

	const { handleEditMaster } = useMasterLogic(getMainItemsList, masterName);

	const columns = useMemo(
		() => [
			{
				title: "Main Item",
				render: data => {
					const primaryText = data?.name || "-";
					const secondaryText = `${data?.category_id?.[0]?.name || "-"}, ${
						data?.subcategory_id?.[0]?.name || "-"
					}`;
					const ternaryText = `${data?.season || "-"}, ${
						data?.collection || "-"
					}`;
					return renderMobileColumn(primaryText, secondaryText, ternaryText);
				},
				responsive: ["xs"],
				...getColumnSearchProps("Search", "universal_search"),
			},
			{
				title: "Item Name",
				dataIndex: "name",
				key: "name",
				responsive: ["sm"],
				render: value => value || "-",
				filters: mainItemFilters,
				onFilter: (value, record) => record.name.startsWith(value),
				sorter: (a, b) => a.name.localeCompare(b.name),
				filterSearch: true,
			},
			{
				title: "Category Name",
				dataIndex: "category_id",
				key: "category_id",
				responsive: ["sm"],
				render: value => value || "-",
				filters: categoryFilters,
				onFilter: (value, record) => record.category_id.startsWith(value),
				filterSearch: true,
			},
			{
				title: "Subcategory Name",
				dataIndex: "subcategory_id",
				key: "subcategory_id",
				responsive: ["sm"],
				render: value => value || "-",
				filters: subCategoryFilters,
				onFilter: (value, record) => record.subcategory_id.startsWith(value),
				sorter: (a, b) => a.subcategory_id.localeCompare(b.subcategory_id),
				filterSearch: true,
			},
			{
				title: "Season",
				dataIndex: "season",
				key: "season",
				responsive: ["sm"],
				render: value => value || "-",
				filters: seasonFilters,
				onFilter: (value, record) => record.season.startsWith(value),
				filterSearch: true,
			},
			{
				title: "Collection",
				dataIndex: "collection",
				key: "collection",
				responsive: ["sm"],
				render: value => value || "-",
				filters: collectionFilters,
				onFilter: (value, record) => record.collection.startsWith(value),
				filterSearch: true,
			},
			{
				title: "Upload Type",
				dataIndex: "type_of_item",
				key: "type_of_item",
				responsive: ["sm"],
				render: value => value || "-",
				filters: uploadTypeFilters,
				onFilter: (value, record) => record.type_of_item.startsWith(value),
				filterSearch: true,
			},
			{
				title: "MRP",
				dataIndex: "mrp",
				key: "mrp",
				responsive: ["sm"],
				render: value => value || "-",
				filters: mrpFilters,
				onFilter: (value, record) =>
					String(record.mrp).startsWith(String(value)),
				sorter: (a, b) => a.mrp - b.mrp,
				filterSearch: true,
			},
			{
				title: "WSP",
				dataIndex: "wsp",
				key: "wsp",
				responsive: ["sm"],
				render: value => value || "-",
			},
			{
				title: "MRP",
				dataIndex: "mrp_e",
				key: "mrp_e",
				width: "10%",
				responsive: ["sm"],
				render: (text, data, index) => {
					return (
						<Field
							name={`main[${index}].mrp`}
							className="md:w-24 main-wrp"
							component={ReduxFormTextField1}
							min={0}
							max={9999999999999.99}
							step={0.01}
							type="number"
							onWheel={e => onWheelHandler(e)}
							placeholder="Enter MRP"
						/>
					);
				},
			},
			{
				title: "WSP",
				dataIndex: "wsp_e",
				key: "wsp_e",
				width: "10%",
				responsive: ["sm"],
				render: (text, data, index) => {
					return (
						<Field
							name={`main[${index}].wsp`}
							className="md:w-24 main-wrp"
							component={ReduxFormTextField1}
							type="number"
							min={0}
							max={9999999999999.99}
							step={0.01}
							onWheel={e => onWheelHandler(e)}
							placeholder="Enter WSP"
						/>
					);
				},
			},

			{
				title: "Actions",
				key: "Actions",
				dataIndex: "action",
				width: "20%",
				render: (_, data, index) => {
					const items = [
						{
							label: "Save",
							onClick: () => {
								const values = formValues?.main?.[index];
								const formBody = {
									...values,
									id: data?.id,
									mrp: values?.mrp || null,
									wsp: values?.wsp || null,
								};
								dispatch(
									editMasterListMain({
										masterName,
										formData: formBody,
										navigate,
										//configType: "multipart/form-data",
									})
								);
								dispatch(change(formName, `main[${index}].mrp`, null));
								dispatch(change(formName, `main[${index}].wsp`, null));
							},
						},
						{
							label: "Image Upload",
							onClick: () => handleEditMaster(data?.id),
						},
					];

					return renderActions(items);
				},
			},
		],
		[getColumnSearchProps, handleEditMaster]
	);

	return (
		<>
			<Helmet>
				<title>Main Items</title>
			</Helmet>
			<h1 className=" font-bold text-xl flex justify-end ">
				{mainLastSyncDate}
			</h1>
			<TableOptions masterName={"main-item"} showAdd={false} />

			<div className=" hidden lg:flex lg:justify-end ">
				<Button
					onClick={() => dispatch(exportExcel({}))}
					loading={isExportExcel}
					className="rounded-lg  bg-primaryBase hover:bg-primaryDark text-white font-semibold ml-1 "
				>
					Export To Excel
				</Button>
				<Button
					onClick={() => dispatch(exportChalan({}))}
					loading={isExportChalan}
					className="rounded-lg  bg-primaryBase hover:bg-primaryDark text-white font-semibold ml-1"
				>
					Export Chalan
				</Button>
				<Button
					onClick={() => dispatch(exportReadyStock({}))}
					loading={isExportingReadyStock}
					className="rounded-lg  bg-primaryBase hover:bg-primaryDark text-white font-semibold ml-1 mr-1"
				>
					Export Ready
				</Button>
				<Button
					className="rounded-lg  bg-primaryBase hover:bg-primaryDark text-white font-semibold ml-1 mr-1"
					onClick={showModal}
				>
					Upload New Item
				</Button>
			</div>

			<div className=" flex justify-end  lg:hidden">
				<Dropdown
					overlay={
						<Menu>
							<Menu.Item key="0">
								<Link
									onClick={() => dispatch(exportExcel({}))}
									loading={isExportExcel}
									className=" hover:bg-primaryBase text-white font-semibold ml-1"
								>
									Export To Excel
								</Link>
							</Menu.Item>
							<Menu.Item key="1">
								<Link
									onClick={() => dispatch(exportChalan({}))}
									loading={isExportChalan}
									className=" hover:bg-primaryBase text-white font-semibold ml-1"
								>
									Export Chalan
								</Link>
							</Menu.Item>
							<Menu.Item key="2">
								<Link
									onClick={() => dispatch(exportReadyStock({}))}
									loading={isExportingReadyStock}
									className=" hover:bg-primaryBase text-white font-semibold ml-1 mr-1"
								>
									Export Ready
								</Link>
							</Menu.Item>
							<Menu.Item key="3">
								<Link
									onClick={showModal}
									className=" hover:bg-primaryBase text-white font-semibold ml-1 mr-1"
								>
									Upload New Item
								</Link>
							</Menu.Item>
						</Menu>
					}
					trigger={["click"]}
				>
					<button
						className="ant-dropdown-link inline-flex font-bold text-lg"
						onClick={e => e.preventDefault()}
					>
						Export To
						<MdArrowDropDown className="mt-2" />
					</button>
				</Dropdown>
			</div>
			<>
				<Modal
					title="Upload Excel File"
					open={isModalOpen}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<Field
						name="item_excel"
						component={ReduxFormFileField}
						className="mt-1"
						placeholder="Item Image"
						itemname="excel file"
					/>
					<p className="mt-2 text-lg font-bold">{excelFile?.name}</p>
				</Modal>
			</>
			<StatusSegments masterName={masterName} options={masterStatuses} />

			<Table
				dataSource={mainItemsList}
				columns={columns}
				rowKey={mainItemsList => mainItemsList?.id}
				//pagination={false}
				pagination={{
					pageSize: 100,
				}}
				scroll={{
					y: 500,
				}}
				loading={isFetchingMasterList}
				bordered
				onChange={handleFilterChange}
			/>
		</>
	);
};

export default reduxForm({
	form: formName,
	enableReinitialize: true,
})(MainItems);
