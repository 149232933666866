import { Pagination, Table } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import TableOptions from "components/TableOptions";

import StatusSegments from "components/StatusSegments";
import useMasterLogic from "customHooks/useMasterLogic";
import { getMasterList, masterSelectors } from "slices/masterSlice";
import { renderMobileColumn } from "utils/renderMobileColumn";
import { Helmet } from "react-helmet";

const masterName = "category";
export const masterStatuses = [
	{ label: "Active", value: 1 },
	{ label: "Inactive", value: 0 },
];

const Categories = () => {
	const { status } = useParams();

	const dispatch = useDispatch();
	const categoryList = useSelector(masterSelectors?.getCategoryMasterList);

	const { pageSize, totalElements, currentPage } = useSelector(
		masterSelectors?.getCategoryPagination
	);

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);

	const getCategoriesData = useCallback(
		(pageNum = 1, itemsPerPage = 10) => {
			dispatch(
				getMasterList({
					masterName,
					query: {
						page: pageNum,
						pageSize: itemsPerPage,
						is_active: status,
					},
				})
			);
		},
		[status, dispatch]
	);

	const { handlePageChange } = useMasterLogic(getCategoriesData, masterName);

	const columns = [
		{
			title: "Category",
			render: data => {
				const primaryText = data?.name || "-";
				const secondaryText = `${data?.ctg_no || "-"}, ${data?.comment || "-"}`;
				return renderMobileColumn(primaryText, secondaryText);
			},
			responsive: ["xs"],
		},
		{
			title: "Category Number",
			dataIndex: "ctg_no",
			key: "ctg_no",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Category Name",
			dataIndex: "name",
			key: "name",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Comment",
			dataIndex: "comment",
			key: "comment",
			responsive: ["sm"],
			render: value => value || "-",
		},
	];

	return (
		<>
			<Helmet>
				<title>Categories</title>
			</Helmet>
			<TableOptions masterName={masterName} showAdd={false} />
			<StatusSegments masterName={masterName} options={masterStatuses} />
			<Table
				dataSource={categoryList}
				columns={columns}
				rowKey={categoryList => categoryList?.id}
				pagination={false}
				loading={isFetchingMasterList}
				bordered
			/>
			<div className="flex justify-center mt-5">
				{categoryList?.length ? (
					<Pagination
						pageSize={pageSize}
						total={totalElements}
						current={currentPage}
						onChange={handlePageChange}
					/>
				) : null}
			</div>
		</>
	);
};

export default Categories;
