import { Helmet } from "react-helmet";

const Dashboard = () => {
	return (
		<>
		<Helmet>
				<title>Dashboard</title>
		</Helmet>
		<div
			className="flex justify-center items-center"
			style={{ height: "60vh" }}
		>
			<h1 className="font-bold text-5xl">
				Welcome to <span className="text-redBase text-5xl">Ethos</span>
			</h1>
		</div>
		</>
		
	);
};

export default Dashboard;
