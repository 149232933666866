import React, {useCallback, useEffect, useState} from 'react'
import { Button, Pagination, Table, Select } from 'antd'
import { useDispatch, useSelector } from "react-redux";
import { editMasterList, getMasterDropDownList, getMasterList, masterSelectors } from 'slices/masterSlice';
import { Helmet } from 'react-helmet';
import useMasterLogic from 'customHooks/useMasterLogic';
import { renderMobileColumn } from 'utils/renderMobileColumn';


const masterName = "userrole"
const userRoleDropdown = "role_dropdown"

const UserRoleList = () => {

    const dispatch = useDispatch();
    const updateLoading = useSelector(masterSelectors?.getAddingUserRoleLoading);
    const userRoles = useSelector(masterSelectors?.getRoleDropdown);
    const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);
    const loading = useSelector(masterSelectors?.getIsFetchingMasterList);
    const list = useSelector(masterSelectors?.getRoleMasterList);
	const { pageSize, totalElements, currentPage } = useSelector(
		masterSelectors?.getRolePagination
	);
    useEffect(()=>{
        dispatch(getMasterDropDownList({masterName:userRoleDropdown}))
    },[])

    const [data, setData] = useState([])
    useEffect(()=>{
        setData(list)
    },[list])

    const getListData = useCallback(
		(pageNum = 1, itemsPerPage = 10) => {
			dispatch(
				getMasterList({
					masterName,
					query: {
						page: pageNum,
						pageSize: itemsPerPage
					},
				})
			);
		},
		[dispatch]
	);

	const { handlePageChange } = useMasterLogic(getListData, masterName);

    const handleChange = (index, value) =>{
        let temp = [...data];
        temp[index] = { ...temp[index], ['roleId']: value };
        setData(temp);
    }

    const handleEdit = (data) =>{
        const formData  = {
        id: data?.id,
        roleid: data?.roleId,
        loginid: data?.loginId
        }
        dispatch(
            editMasterList({
              masterName,
              formData,
              id:data?.id
            })
          );
    }

    const columns = [
		{
			title: "Roles",
			render: data => {
				const primaryText = data?.login || "-";
                const secondaryText = `${data?.role || "-"}`;
				return renderMobileColumn(primaryText, secondaryText);
			},
			responsive: ["xs"],
		},
        {
            title: "User",
            dataIndex: "login",
            key: "login",
            width:"auto",
        },
        {
            title: "Role",
            window:"auto",
            render:(data,_,index)=><Select
            style={{width:"192px", borderRadius:'6px'}}
            showSearch
            loading={loadingDropdown}
            placeholder="Roles"
            allowClear
            defaultValue={{label:data?.role, value:data?.roleId}}
            options={userRoles}
            onChange={(e)=>handleChange(index, e)}/>
        },
        {
            title: "Action",
            width:"auto",
            render:(_,data)=><Button type='primary' size='small'
            onClick={()=>handleEdit(data)}
            >Update</Button>
        },
    ]

  return (
    <>
     <Helmet>
	<title>User Permissions</title>
	</Helmet>
    <div className='flex justify-between items-center mb-2'>
    <h1 className='text-2xl'>User Permissions</h1>
    </div>
    <Table
    dataSource={data}
    columns={columns}
    rowKey={item => item?.id}
    pagination={false}
    loading={loading}
    scroll={{y:600}}
    bordered
	style={{minHeight:"600px"}}
    />
	{list?.length ? (
		<div className='flex justify-end'>
		<Pagination
			pageSize={pageSize}
			total={totalElements}
			current={currentPage}
			onChange={handlePageChange}
		/>
		</div>
	) : null}
    </>
  )
}

export default UserRoleList