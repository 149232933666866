import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Field, change, getFormValues, reduxForm } from "redux-form";
import {
	getDropdownList,
	getLedger,
	ledgerExcelList,
	masterSelectors,
} from "slices/masterSlice";
import { ReduxFormSelectField2 } from "utils/ReduxFormSelectField";
import { ReduxFormTextField } from "utils/ReduxFormTextField";

const masterName = "ledger";
const LedgerReport = () => {
	const dispatch = useDispatch();
	const formValues = useSelector(getFormValues("Ledger"));

	const mainItemsOptions = useSelector(
		masterSelectors?.getMainItemsDropdownList
	);

	const colorsOptions = useSelector(masterSelectors?.getColorsDropdownList);

	const ledgerMain = useSelector(masterSelectors?.getLedgerList);

	const [ledger, setLedger] = useState(
		useSelector(masterSelectors?.getLedgerList)
	);

	useEffect(() => {
		setLedger(ledgerMain);
	}, [ledgerMain]);

	const inward = ledger?.Inward;
	const outward = ledger?.Outward;
	const openStock = ledger?.openingStock;

	const isFetchingMasterList = useSelector(
		masterSelectors?.getIsFetchingMasterList
	);

	useEffect(() => {
		dispatch(getDropdownList({ masterName: "mainitem" }));
		dispatch(getDropdownList({ masterName: "colors" }));
	}, [dispatch]);

	const [show, setShow] = useState(false);

	var total = 0;

	useEffect(() => {
		const values = {
			startDate: "",
			endDate: "",
			mainItem: [],
			color: [],
		};
		dispatch(
			getLedger({
				masterName,
				formdata: values,
			})
		);
	}, [dispatch]);

	const inwardData = inward?.flatMap(entry =>
		entry.stock.map(stockItem => ({
			...entry,
			...stockItem,
		}))
	);

	const outwardData = outward?.flatMap(entry =>
		entry.stock.map(stockItem => ({
			...entry,
			...stockItem,
		}))
	);

	var size34 = 0;
	var size36 = 0;
	var size38 = 0;
	var size40 = 0;
	var size42 = 0;
	var size44 = 0;
	var size46 = 0;
	var size48 = 0;

	inwardData?.forEach(se => {
		size34 += +se?.size34 || 0;
		size36 += +se?.size36 || 0;
		size38 += +se?.size38 || 0;
		size40 += +se?.size40 || 0;
		size42 += +se?.size42 || 0;
		size44 += +se?.size44 || 0;
		size46 += +se?.size46 || 0;
		size48 += +se?.size48 || 0;
	});

	var s34 = 0;
	var s36 = 0;
	var s38 = 0;
	var s40 = 0;
	var s42 = 0;
	var s44 = 0;
	var s46 = 0;
	var s48 = 0;

	outwardData?.forEach(se => {
		s34 += +se?.size34 || 0;
		s36 += +se?.size36 || 0;
		s38 += +se?.size38 || 0;
		s40 += +se?.size40 || 0;
		s42 += +se?.size42 || 0;
		s44 += +se?.size44 || 0;
		s46 += +se?.size46 || 0;
		s48 += +se?.size48 || 0;
	});

	const inwardColumns = [
		{
			title: "Narration",
			dataIndex: "Narration",
			key: "Narration",
			responsive: ["sm"],
		},
		{
			title: "Entry No",
			dataIndex: "EntryNo",
			key: "EntryNo",
			responsive: ["sm"],
		},
		{
			title: "Ready Stock Date",
			dataIndex: "ReadyStockDate",
			key: "ReadyStockDate",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Main Item",
			dataIndex: "mainItem",
			key: "mainItem",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Color",
			dataIndex: "color",
			key: "color",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: `34 (Total-${size34}pcs)`,
			dataIndex: "size34",
			key: "size34",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `36 (Total-${size36}pcs)`,
			dataIndex: "size36",
			key: "size36",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `38 (Total-${size38}pcs)`,
			dataIndex: "size38",
			key: "size38",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `40 (Total-${size40}pcs)`,
			dataIndex: "size40",
			key: "size40",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `42 (Total-${size42}pcs)`,
			dataIndex: "size42",
			key: "size42",
			responsive: ["sm"],
			render: value => value || 0,
		},

		{
			title: `44 (Total-${size44}pcs)`,
			dataIndex: "size44",
			key: "size44",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `46 (Total-${size46}pcs)`,
			dataIndex: "size46",
			key: "size46",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `48 (Total-${size48}pcs)`,
			dataIndex: "size48",
			key: "size48",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: "Total",
			key: "Total",
			dataIndex: "total",
			responsive: ["sm"],
			render: (text, data, index) => {
				total =
					inwardData[index].size34 +
					inwardData[index].size36 +
					inwardData[index].size38 +
					inwardData[index].size40 +
					inwardData[index].size42 +
					inwardData[index].size44 +
					inwardData[index].size46 +
					inwardData[index].size48;
				return <p>{total}</p>;
			},
		},
		{
			title: "Comment",
			dataIndex: "comment",
			key: "comment",
			responsive: ["sm"],
			render: value => value || "-",
		},
	];

	const outwardColumns = [
		{
			title: "Entry No",
			dataIndex: "OutwardNo",
			key: "OutwardNo",
			responsive: ["sm"],
		},
		{
			title: "Date",
			dataIndex: "OutwardDate",
			key: "OutwardDate",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Main Item",
			dataIndex: "mainItem",
			key: "mainItem",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Color",
			dataIndex: "color",
			key: "color",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Party Name",
			dataIndex: "partyName",
			key: "partyName",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: `34 (T-${s34}pcs)`,
			dataIndex: "size34",
			key: "size34",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `36 (T-${s36}pcs)`,
			dataIndex: "size36",
			key: "size36",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `38 (T-${s38}pcs)`,
			dataIndex: "size38",
			key: "size38",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `40 (T-${s40}pcs)`,
			dataIndex: "size40",
			key: "size40",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `42 (T-${s42}pcs)`,
			dataIndex: "size42",
			key: "size42",
			responsive: ["sm"],
			render: value => value || 0,
		},

		{
			title: `44 (T-${s44}pcs)`,
			dataIndex: "size44",
			key: "size44",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `46 (T-${s46}pcs)`,
			dataIndex: "size46",
			key: "size46",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: `48 (T-${s48}pcs)`,
			dataIndex: "size48",
			key: "size48",
			responsive: ["sm"],
			render: value => value || 0,
		},
		{
			title: "Comment",
			dataIndex: "comment",
			key: "comment",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Total",
			dataIndex: "total",
			key: "total",
			responsive: ["sm"],
			render: value => value || "-",
		},
		{
			title: "Status",
			dataIndex: "recordStatus",
			key: "recordStatus",
			responsive: ["sm"],
			render: value => value || "-",
		},
	];
	const handleFilter = () => {
		const val = {
			startDate: formValues?.startDate || "",
			endDate: formValues?.endDate || "",
			mainItem: formValues?.mainItem?.map(item => item?.label) || [],
			color: formValues?.color?.map(item => item?.label) || [],
		};

		dispatch(
			getLedger({
				masterName: "ledger",
				formdata: val,
			})
		);

		setLedger(ledgerMain);
	};

	const handleReport = () => {
		const val = {
			startDate: formValues?.startDate || "",
			endDate: formValues?.endDate || "",
			mainItem: formValues?.mainItem?.map(item => item?.label) || [],
			color: formValues?.color?.map(item => item?.label) || [],
		};

		dispatch(
			ledgerExcelList({
				masterName:"export/ledger",
				formData: val,
			})
		);
	};

	const handleResetFilter = () => {
		const val = {
			startDate: "",
			endDate: "",
			mainItem: [],
			color: [],
			dispatchno: [],
			party: [],
		};
		dispatch(
			getLedger({
				masterName: "ledger",
				formdata: val,
			})
		);

		setLedger(ledgerMain);

		dispatch(change("Ledger", "startDate", null));
		dispatch(change("Ledger", "endDate", null));
		dispatch(change("Ledger", "mainItem", null));
		dispatch(change("Ledger", "color", null));
	};

	const getColumns = () => [
		{ columnId: "narration", width: 70 },
		{ columnId: "EntryNo", width: 130 },
		{ columnId: "ReadyStockDate", width: 70 },
		{ columnId: "mainItem", width: 90 },
		{ columnId: "color", width: 100 },
		{ columnId: "size34", width: 20 },
		{ columnId: "size36", width: 20 },
		{ columnId: "size38", width: 20 },
		{ columnId: "size40", width: 20 },
		{ columnId: "size42", width: 20 },
		{ columnId: "size44", width: 20 },
		{ columnId: "size46", width: 20 },
		{ columnId: "size48", width: 20 },
		{ columnId: "total", width: 20 },
		{ columnId: "comment", width: 60 },
	];

	const headerRow = {
		rowId: "header",
		cells: [
			{ type: "header", text: "Narration" },
			{ type: "header", text: "Entry No" },
			{ type: "header", text: " Date" },
			{ type: "header", text: "Mainitem" },
			{ type: "header", text: "Color" },
			{ type: "header", text: "34" },
			{ type: "header", text: "36" },
			{ type: "header", text: "38" },
			{ type: "header", text: "40" },
			{ type: "header", text: "42" },
			{ type: "header", text: "44" },
			{ type: "header", text: "46" },
			{ type: "header", text: "48" },
			{ type: "header", text: "Total" },
			{ type: "header", text: "Comment" },
		],
	};

	const sizeTotals = {
		size34: 0,
		size36: 0,
		size38: 0,
		size40: 0,
		size42: 0,
		size44: 0,
		size46: 0,
		size48: 0,
	};

	const sizeTotalsOut = {
		size34: 0,
		size36: 0,
		size38: 0,
		size40: 0,
		size42: 0,
		size44: 0,
		size46: 0,
		size48: 0,
	};

	const sizeTotalsOpen = {
		size34: 0,
		size36: 0,
		size38: 0,
		size40: 0,
		size42: 0,
		size44: 0,
		size46: 0,
		size48: 0,
	};

	const getRows = people => {
		people?.forEach(person => {
			sizeTotals.size34 += person.size34 || 0;
			sizeTotals.size36 += person.size36 || 0;
			sizeTotals.size38 += person.size38 || 0;
			sizeTotals.size40 += person.size40 || 0;
			sizeTotals.size42 += person.size42 || 0;
			sizeTotals.size44 += person.size44 || 0;
			sizeTotals.size46 += person.size46 || 0;
			sizeTotals.size48 += person.size48 || 0;
		});
		return [
			headerRow,
			...people?.map((person, idx) => ({
				rowId: idx,
				cells: [
					{ type: "text", text: person?.narration || "" },
					{ type: "text", text: person?.EntryNo || "" },
					{ type: "text", text: person?.ReadyStockDate || "" },
					{ type: "text", text: person?.mainItem || "" },
					{ type: "text", text: person?.color || "" },
					{ type: "text", text: person?.size34.toString() || "0" },
					{ type: "text", text: person?.size36.toString() || "0" },
					{ type: "text", text: person?.size38.toString() || "0" },
					{ type: "text", text: person?.size40.toString() || "0" },
					{ type: "text", text: person?.size42.toString() || "0" },
					{ type: "text", text: person?.size44.toString() || "0" },
					{ type: "text", text: person?.size46.toString() || "0" },
					{ type: "text", text: person?.size48.toString() || "0" },
					{
						type: "text",
						text:
							(
								person?.size34 +
								person?.size36 +
								person?.size38 +
								person?.size40 +
								person?.size42 +
								person?.size44 +
								person?.size46 +
								person?.size48
							).toString() || "0",
					},
					{ type: "text", text: person?.comment || "" },
				],
			})),
		];
	};
	// const getRows = people => {
	// 	// Calculate totals for each size
	// 	const sizeTotals = {
	// 		size34: 0,
	// 		size36: 0,
	// 		size38: 0,
	// 		size40: 0,
	// 		size42: 0,
	// 		size44: 0,
	// 		size46: 0,
	// 		size48: 0,
	// 	};

	// 	people?.forEach(person => {
	// 		sizeTotals.size34 += person.size34 || 0;
	// 		sizeTotals.size36 += person.size36 || 0;
	// 		sizeTotals.size38 += person.size38 || 0;
	// 		sizeTotals.size40 += person.size40 || 0;
	// 		sizeTotals.size42 += person.size42 || 0;
	// 		sizeTotals.size44 += person.size44 || 0;
	// 		sizeTotals.size46 += person.size46 || 0;
	// 		sizeTotals.size48 += person.size48 || 0;
	// 	});

	// 	// Generate rows including totals
	// 	return [
	// 		headerRow,
	// 		...people?.map((person, idx) => ({
	// 			rowId: idx,
	// 			cells: [
	// 				{ type: "text", text: person?.narration || "" }, // Adding null check and default value
	// 				{ type: "text", text: person?.EntryNo || "" }, // Adding null check and default value
	// 				{ type: "text", text: person?.ReadyStockDate || "" }, // Adding null check and default value
	// 				{ type: "text", text: person?.mainItem || "" }, // Adding null check and default value
	// 				{ type: "text", text: person?.color || "" }, // Adding null check and default value
	// 				{ type: "text", text: person?.size34.toString() || "0" }, // Adding null check and default value
	// 				{ type: "text", text: person?.size36.toString() || "0" }, // Adding null check and default value
	// 				{ type: "text", text: person?.size38.toString() || "0" }, // Adding null check and default value
	// 				{ type: "text", text: person?.size40.toString() || "0" }, // Adding null check and default value
	// 				{ type: "text", text: person?.size42.toString() || "0" }, // Adding null check and default value
	// 				{ type: "text", text: person?.size44.toString() || "0" }, // Adding null check and default value
	// 				{ type: "text", text: person?.size46.toString() || "0" }, // Adding null check and default value
	// 				{ type: "text", text: person?.size48.toString() || "0" }, // Adding null check and default value
	// 				{
	// 					type: "text",
	// 					text:
	// 						(
	// 							person?.size34 +
	// 							person?.size36 +
	// 							person?.size38 +
	// 							person?.size40 +
	// 							person?.size42 +
	// 							person?.size44 +
	// 							person?.size46 +
	// 							person?.size48
	// 						).toString() || "0",
	// 				},
	// 				{ type: "text", text: person?.comment || "" },
	// 			],
	// 		})),
	// 		{
	// 			rowId: "totals",
	// 			cells: [
	// 				{ type: "header", text: "Total" },
	// 				{ type: "empty" }, // Leave empty cells for non-numeric columns
	// 				{ type: "empty" },
	// 				{ type: "empty" },
	// 				{ type: "empty" },
	// 				{ type: "empty" },
	// 				{ type: "number", number: sizeTotals.size34 || 0 },
	// 				{ type: "number", number: sizeTotals.size36 || 0 },
	// 				{ type: "number", number: sizeTotals.size38 || 0 },
	// 				{ type: "number", number: sizeTotals.size40 || 0 },
	// 				{ type: "number", number: sizeTotals.size42 || 0 },
	// 				{ type: "number", number: sizeTotals.size44 || 0 },
	// 				{ type: "number", number: sizeTotals.size46 || 0 },
	// 				{ type: "number", number: sizeTotals.size48 || 0 },
	// 				{ type: "empty" },
	// 				{ type: "empty" },
	// 			],
	// 		},
	// 	];
	// };
	const people = inwardData;
	const columns = getColumns();
	const rows = inwardData?.length ? getRows(people) : [];

	const getOutwardColumns = () => [
		{ columnId: "outwardNo", width: 130 },
		{ columnId: "outwardDate", width: 70 },
		{ columnId: "mainItem", width: 70 },
		{ columnId: "color", width: 100 },
		{ columnId: "party", width: 100 },
		{ columnId: "size34", width: 10 },
		{ columnId: "size36", width: 10 },
		{ columnId: "size38", width: 10 },
		{ columnId: "size40", width: 10 },
		{ columnId: "size42", width: 10 },
		{ columnId: "size44", width: 10 },
		{ columnId: "size46", width: 10 },
		{ columnId: "size48", width: 10 },
		{ columnId: "total", width: 10 },
		{ columnId: "recordStatus", width: 60 },
	];

	const headerOutwardRow = {
		rowId: "header",
		cells: [
			{ type: "header", text: "Outward No" },
			{ type: "header", text: " Date" },
			{ type: "header", text: "Mainitem" },
			{ type: "header", text: "Color" },
			{ type: "header", text: "Party" },
			{ type: "header", text: "34" },
			{ type: "header", text: "36" },
			{ type: "header", text: "38" },
			{ type: "header", text: "40" },
			{ type: "header", text: "42" },
			{ type: "header", text: "44" },
			{ type: "header", text: "46" },
			{ type: "header", text: "48" },
			{ type: "header", text: "Total" },
			{ type: "header", text: "Status" },
		],
	};

	const getOutwardRows = people => {
		people?.forEach(person => {
			sizeTotalsOut.size34 += person.size34 || 0;
			sizeTotalsOut.size36 += person.size36 || 0;
			sizeTotalsOut.size38 += person.size38 || 0;
			sizeTotalsOut.size40 += person.size40 || 0;
			sizeTotalsOut.size42 += person.size42 || 0;
			sizeTotalsOut.size44 += person.size44 || 0;
			sizeTotalsOut.size46 += person.size46 || 0;
			sizeTotalsOut.size48 += person.size48 || 0;
		});
		return [
			headerOutwardRow,
			...people?.map((person, idx) => ({
				rowId: idx,
				cells: [
					{ type: "text", text: person?.OutwardNo || "" },
					{ type: "text", text: person?.OutwardDate || "" },
					{ type: "text", text: person?.mainItem || "" },
					{ type: "text", text: person?.color || "" },
					{ type: "text", text: person?.partyName || "" },
					{ type: "text", text: person?.size34.toString() || "0" },
					{ type: "text", text: person?.size36.toString() || "0" },
					{ type: "text", text: person?.size38.toString() || "0" },
					{ type: "text", text: person?.size40.toString() || "0" },
					{ type: "text", text: person?.size42.toString() || "0" },
					{ type: "text", text: person?.size44.toString() || "0" },
					{ type: "text", text: person?.size46.toString() || "0" },
					{ type: "text", text: person?.size48.toString() || "0" },
					{ type: "text", text: person?.total.toString() || "0" },
					{ type: "text", text: person?.recordStatus || "" },
				],
			})),
		];
	};
	const out = outwardData;
	const columnsOutward = getOutwardColumns();
	const rowsOutward = outwardData?.length ? getOutwardRows(out) : [];

	const getOpeningStockColumns = () => [
		{ columnId: "mainItem", width: 200 },
		{ columnId: "color", width: 200 },
		{ columnId: "size34", width: 100 },
		{ columnId: "size36", width: 100 },
		{ columnId: "size38", width: 100 },
		{ columnId: "size40", width: 100 },
		{ columnId: "size42", width: 100 },
		{ columnId: "size44", width: 100 },
		{ columnId: "size46", width: 100 },
		{ columnId: "size48", width: 100 },
		{ columnId: "total", width: 100 },
	];

	const headerOpeningStockRow = {
		rowId: "header",
		cells: [
			{ type: "header", text: "Mainitem" },
			{ type: "header", text: "Color" },
			{ type: "header", text: "34" },
			{ type: "header", text: "36" },
			{ type: "header", text: "38" },
			{ type: "header", text: "40" },
			{ type: "header", text: "42" },
			{ type: "header", text: "44" },
			{ type: "header", text: "46" },
			{ type: "header", text: "48" },
			{ type: "header", text: "Total" },
		],
	};

	const getOpeningStockRows = people => {
		people?.forEach(person => {
			sizeTotalsOpen.size34 += person.size34 || 0;
			sizeTotalsOpen.size36 += person.size36 || 0;
			sizeTotalsOpen.size38 += person.size38 || 0;
			sizeTotalsOpen.size40 += person.size40 || 0;
			sizeTotalsOpen.size42 += person.size42 || 0;
			sizeTotalsOpen.size44 += person.size44 || 0;
			sizeTotalsOpen.size46 += person.size46 || 0;
			sizeTotalsOpen.size48 += person.size48 || 0;
		});
		return [
			headerOpeningStockRow,
			...people?.map((person, idx) => ({
				rowId: idx,
				cells: [
					{ type: "text", text: person?.mainItem || "" },
					{ type: "text", text: person?.color || "" },
					{ type: "text", text: person?.size34.toString() || "0" },
					{ type: "text", text: person?.size36.toString() || "0" },
					{ type: "text", text: person?.size38.toString() || "0" },
					{ type: "text", text: person?.size40.toString() || "0" },
					{ type: "text", text: person?.size42.toString() || "0" },
					{ type: "text", text: person?.size44.toString() || "0" },
					{ type: "text", text: person?.size46.toString() || "0" },
					{ type: "text", text: person?.size48.toString() || "0" },
					{
						type: "text",
						text:
							(
								person?.size34 +
								person?.size36 +
								person?.size38 +
								person?.size40 +
								person?.size42 +
								person?.size44 +
								person?.size46 +
								person?.size48
							).toString() || "0",
					},
				],
			})),
		];
	};
	const opening = openStock;
	const columnsOpeningStock = getOpeningStockColumns();
	const rowsOpeningStock = openStock?.length
		? getOpeningStockRows(opening)
		: [];

	return (
		<>
			<Helmet>
				<title>Ledger Report</title>
			</Helmet>
			<h6 className="text-2xl my-3">Ledger Report</h6>
			{show ? (
				<>
					<div className="flex justify-end items-center md:mr-10">
						<Button
							className="text-redBase border-redBase"
							onClick={() => setShow(false)}
						>
							Hide Filter
						</Button>
					</div>
					<div className="md:flex block md:flex-wrap">
						<div className="md:mr-10 mb-5">
							<Field
								label="Start Date"
								name="startDate"
								component={ReduxFormTextField}
								type="date"
								placeholder="Enter Date"
							/>
						</div>
						<div>
							<Field
								label="End Date"
								name="endDate"
								component={ReduxFormTextField}
								type="date"
								placeholder="Enter Date"
							/>
						</div>
						<div className="md:mr-10 mb-5">
							<Field
								component={ReduxFormSelectField2}
								label="Main Item"
								name="mainItem"
								options={mainItemsOptions}
								placeholder="Select Main Items"
								//isLoading={isFetchingDropdownList}
								isMulti
							/>
						</div>
						<div className="md:mr-10 mb-5">
							<Field
								component={ReduxFormSelectField2}
								label="Color"
								name="color"
								placeholder="Enter color"
								options={colorsOptions}
								//isLoading={isFetchingDropdownList}
								isMulti
							/>
						</div>

						<div className="md:mr-10 ">
							<Button
								className="mt-2 text-primaryBase border-primaryDark font-semibold"
								onClick={handleFilter}
							>
								Apply Filters
							</Button>
						</div>
						<div className="md:mr-10 ">
							<Button
								className="mt-2 text-primaryBase border-primaryDark font-semibold"
								onClick={handleResetFilter}
							>
								Reset Filters
							</Button>
						</div>
						<div className="md:mr-10 ">
							<Button
								className="mt-2 text-primaryBase border-primaryDark font-semibold"
								onClick={handleReport}
							>
								Generate Report
							</Button>
						</div>
					</div>
				</>
			) : (
				<div className="flex justify-end items-center md:mr-10">
					<Button
						className="text-primaryBase border-primaryDark"
						onClick={() => setShow(true)}
					>
						Show Filter
					</Button>
				</div>
			)}
			<div style={{ width: "1310px" }}>
				<h6 className="text-xl my-3 ">Opening Stock Table</h6>

				<div style={{ height: "300px", overflowY: "auto", width: "1310px" }}>
					<ReactGrid
						rows={rowsOpeningStock}
						columns={columnsOpeningStock}
						virtualization
					/>
				</div>
				<Row className="mb-3 mt-2">
					<Col className="font-semibold " span={7}>
						Total
					</Col>
					<Col
						className="ml-8 text-center font-semibold border border-solid "
						span={1}
					>
						{sizeTotalsOpen?.size34}
					</Col>
					<Col
						className=" ml-12 mr-2 text-center font-semibold border border-solid"
						span={1}
					>
						{sizeTotalsOpen?.size36}
					</Col>
					<Col
						className="ml-12 mr-3 text-center font-semibold border border-solid"
						span={1}
					>
						{sizeTotalsOpen?.size38}
					</Col>
					<Col
						className="ml-12 mr-3 text-center font-semibold border border-solid"
						span={1}
					>
						{sizeTotalsOpen?.size40}
					</Col>
					<Col
						className="ml-12 mr-3  text-center font-semibold border border-solid"
						span={1}
					>
						{sizeTotalsOpen?.size42}
					</Col>
					<Col
						className="ml-10 text-center font-semibold border border-solid"
						span={1}
					>
						{sizeTotalsOpen?.size44}
					</Col>
					<Col
						className="ml-10 mr-5 text-center font-semibold border border-solid"
						span={1}
					>
						{sizeTotalsOpen?.size46}
					</Col>

					<Col
						className="ml-10 mr-4 text-center font-semibold border border-solid"
						span={1}
					>
						{sizeTotalsOpen?.size48}
					</Col>
					<Col
						className=" ml-9 text-center font-semibold border border-solid"
						span={1}
					>
						{sizeTotalsOpen?.size34 +
							sizeTotalsOpen?.size36 +
							sizeTotalsOpen?.size38 +
							sizeTotalsOpen?.size40 +
							sizeTotalsOpen?.size42 +
							sizeTotalsOpen?.size44 +
							sizeTotalsOpen?.size46 +
							sizeTotalsOpen?.size48}
					</Col>
				</Row>
			</div>
			<div className="flex mt-4">
				<div>
					<h6 className="text-xl my-3">Inward Details Table</h6>
					{/* <Table
				dataSource={inwardData}
				columns={inwardColumns}
				loading={isFetchingMasterList}
				pagination={false}
				rowKey={inwardData => inwardData?.id}
				bordered
				scroll={{ y: 170 }}
				size="small"
			/> */}
					<div style={{ height: "300px", overflowY: "auto" }}>
						<ReactGrid rows={rows} columns={columns} virtualization />
					</div>
					<Row className="mb-3 mt-2">
						<Col className="font-semibold " span={4}>
							Total
						</Col>
						<Col span={4}></Col>
						<Col span={4}></Col>
						<Col
							className="ml-4 text-center font-semibold border border-solid "
							span={1}
						>
							{sizeTotals?.size34}
						</Col>
						<Col
							className=" ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotals?.size36}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotals?.size38}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotals?.size40}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotals?.size42}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotals?.size44}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotals?.size46}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotals?.size48}
						</Col>
						<Col
							className=" ml-1 text-center font-semibold border border-solid"
							span={1.5}
						>
							{sizeTotals?.size34 +
								sizeTotals?.size36 +
								sizeTotals?.size38 +
								sizeTotals?.size40 +
								sizeTotals?.size42 +
								sizeTotals?.size44 +
								sizeTotals?.size46 +
								sizeTotals?.size48}
						</Col>
					</Row>
				</div>
				<div>
					<h6 className="text-xl my-3 ">Outward Details Table</h6>
					{/* <Table
				dataSource={outwardData}
				columns={outwardColumns}
				loading={isFetchingMasterList}
				pagination={false}
				rowKey={outwardData => outwardData?.id}
				bordered
				scroll={{ y: 250 }}
				size="small"
			/> */}
					<div style={{ height: "300px", overflowY: "auto" }}>
						<ReactGrid
							rows={rowsOutward}
							columns={columnsOutward}
							virtualization
						/>
					</div>
					<Row className="mb-3 mt-2">
						<Col className="font-semibold " span={4}>
							Total
						</Col>
						<Col span={4}></Col>
						<Col span={4}></Col>
						<Col
							className="ml-5 text-center font-semibold border border-solid "
							span={1}
						>
							{sizeTotalsOut?.size34}
						</Col>
						<Col
							className=" ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotalsOut?.size36}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotalsOut?.size38}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotalsOut?.size40}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotalsOut?.size42}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotalsOut?.size44}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotalsOut?.size46}
						</Col>
						<Col
							className="ml-1 text-center font-semibold border border-solid"
							span={1}
						>
							{sizeTotalsOut?.size48}
						</Col>
						<Col
							className=" ml-1 text-center font-semibold border border-solid"
							span={1.5}
						>
							{sizeTotalsOut?.size34 +
								sizeTotalsOut?.size36 +
								sizeTotalsOut?.size38 +
								sizeTotalsOut?.size40 +
								sizeTotalsOut?.size42 +
								sizeTotalsOut?.size44 +
								sizeTotalsOut?.size46 +
								sizeTotalsOut?.size48}
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default reduxForm({
	form: "Ledger",
	enableReinitialize: true,
})(LedgerReport);
