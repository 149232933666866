import React from 'react'
import { Checkbox } from 'antd'

const pdfRoleForm = ({pdfRole, setPdfRole}) => {

    const handleChange = (section, key, value) => {
        setPdfRole({
          ...pdfRole,
          [section]: {
            ...pdfRole[section],
            [key]: value,
          },
        });
      };

  return (
    <>
    <p className='font-bold mb-4 mt-10'>PDF Roles :</p>
    <div className='w-full'>
    {Object.keys(pdfRole).map((section) => (
        <div key={section} className='mb-3 flex gap-5 flex-wrap'>
          <div className='w-[180px]'>
            <Checkbox
              className="mr-2"
              value={pdfRole[section].main}
              onChange={(e) => handleChange(section, 'main', e.target.checked)}
            />
            <label className='capitalize'>{section}</label>
          </div>
          <div>
            <Checkbox
              className="mr-2"
              value={pdfRole[section].createAccess}
              onChange={(e) => handleChange(section, 'createAccess', e.target.checked)}
              disabled={!pdfRole[section].main}
            />
            <label>Create Access</label>
          </div>
          <div>
            <Checkbox
              className="mr-2"
              value={pdfRole[section].viewAccess}
              onChange={(e) => handleChange(section, 'viewAccess', e.target.checked)}
              disabled={!pdfRole[section].main}
            />
            <label>View Access</label>
          </div>
          <div>
            <Checkbox
              className="mr-2"
              value={pdfRole[section].editAccess}
              onChange={(e) => handleChange(section, 'editAccess', e.target.checked)}
              disabled={!pdfRole[section].main}
            />
            <label>Update Access</label>
          </div>
          <div>
            <Checkbox
              className="mr-2"
              value={pdfRole[section].deleteAccess}
              onChange={(e) => handleChange(section, 'deleteAccess', e.target.checked)}
              disabled={!pdfRole[section].main}
            />
            <label>Delete Access</label>
          </div>
        </div>
      ))}
    </div>
    </>
  )
}

export default pdfRoleForm