import { Segmented } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const StatusSegments = ({ masterName, options }) => {
	const { status } = useParams();
	const navigate = useNavigate();

	return (
		<div className="flex justify-center">
			<Segmented
				className=""
				size="small"
				options={options}
				onChange={val => navigate(`/${masterName}/${val}`)}
				value={+status}
			/>
		</div>
	);
};

export default StatusSegments;
