import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDropdownList,
  getLedger,
  masterSelectors,
} from "slices/masterSlice";
import OpenStock from "./OpenStock";
import Inward from "./Inward";
import Outward from "./Outward";
import Filter from "./Filter";
import Loading from "utils/Loading";

const masterName = "ledger";

function LedgerReportv2() {
  const dispatch = useDispatch();
  const ledgerMain = useSelector(masterSelectors?.getLedgerList);
  const [isLoading, setIsLoading] = useState(true);
  const [ledger, setLedger] = useState([]);

  useEffect(() => {
    setLedger(ledgerMain);
    setIsLoading(false);
  }, [ledgerMain]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getDropdownList({ masterName: "mainitem" }));
    dispatch(getDropdownList({ masterName: "colors" }));
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    const values = {
      startDate: "",
      endDate: "",
      mainItem: [],
      color: [],
    };
    dispatch(
      getLedger({
        masterName,
        formdata: values,
      })
    );
  }, [dispatch]);

  const inward = ledger?.Inward?.flatMap((entry) =>
    entry.stock.map((stockItem) => ({
      ...entry,
      ...stockItem,
    }))
  );

  const outward = ledger?.Outward?.flatMap((entry) =>
    entry.stock.map((stockItem) => ({
      ...entry,
      ...stockItem,
    }))
  );
  const openStock = ledger?.openingStock;

  const inwardTotal = ledger?.inwardStockTotals;
  const outwardTotal = ledger?.outwardStockTotals;
  const openStockTotal = ledger?.openingStockTotals;

  return (
    <div>
      <h1 className="text-2xl font-bold py-4">Ledger Report</h1>
      <Filter
        setLedger={setLedger}
        ledgerMain={ledgerMain}
        masterName={masterName}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="mb-20 w-[1310px] ">
            <OpenStock openStock={openStock} openStockTotal={openStockTotal} />
          </div>
          <div className="flex ">
            <div className="grow">
              <Inward inward={inward} inwardTotal={inwardTotal} />
            </div>
            <div className="grow">
              <Outward outward={outward} outwardTotal={outwardTotal} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default LedgerReportv2;
