import React, {useEffect} from 'react'
import { Select, Tooltip } from 'antd';
import { Field } from "react-final-form";
import InputFieldV2 from 'utils/v2/InputFieldV2'
import TextareaFieldV2 from 'utils/v2/TextareFieldV2';

const TopForm = ({form, loadingDropdown, partyDropdown, edit}) => {
    const required = value => (value ? undefined : 'Required')

    useEffect(() => {
		const state = form.getFieldState(`saleOrderSubForm`)?.value;
        let total = 0;
        state?.map((item)=>{
            total += item?.totalPcs; 
        })
        console.log('state', state)
		form.change(`totalPcs`, total);
	}, [form]);

  return (
    <>
    <div className='flex gap-5'>
      {edit ? null :
        <div className=''>
            <Field name="date" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='date' label="Date"/>
            )}
          </Field>
        </div>
        }
        <div>
          <label>Party</label><br></br>
          <Field name="partyId" validate={required}>
          {({ input, meta }) => (
            <Tooltip title={meta.touched && meta.error ? meta.error : null}>
        <Select
        style={{width:"192px", border:`${meta.touched && meta.error ? "1px solid red" : ""}`, borderRadius:'6px'}}
        showSearch
        loading={loadingDropdown}
        placeholder="Party"
        allowClear
        // onSearch={e => dispatch(getMasterDropDownList({masterName:partyNameDropdownMaster, query:{name:e}}))}
        options={partyDropdown}
        {...input}
				/>
        </Tooltip>
          )}
        </Field>
        </div>

        <div className=''>
        <Field name="totalPcs">
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='number' label="Total Pieces." disabled={true}/>
            )}
          </Field>
        </div>
    </div>
    <div className='flex gap-5 my-3'>
    <div className='w-full'>
        <Field name="remarks">
            {({ input, meta }) => (
              <TextareaFieldV2 input={input} meta={meta} label="Remarks" style='w-1/2'/>
            )}
          </Field>
        </div>
    </div>
    </>
  )
}

export default TopForm