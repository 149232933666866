import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formValueSelector } from "redux-form";
import {
	getMasterDetails,
	masterSelectors,
	resetMasterDetails,
} from "slices/masterSlice";
import ReadyStockForm from "./ReadyStockForm";

const masterName = "readyStockEntry";
const readyStockEntryName = "readyStockEntry";
const formName = "readyStockForm";
const formSelector = formValueSelector(formName);

const ReadyStockEntryWrapper = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { mode, id } = useParams();

	const [loading, setLoading] = useState(id ? true : false);
	const readyStockDetails = useSelector(
		masterSelectors?.getReadyStockEntryDetails
	);
	const readyStockList = useSelector(masterSelectors?.getReadyStockList);
	const isViewOnly = mode === "view";

	const list = readyStockDetails?.data;

	const stock = readyStockDetails?.data?.map(item => item?.stock);

	const subCategory = useSelector(state => formSelector(state, "subCategory"));

	const generatedColors = useSelector(state =>
		formSelector(state, "generated_Colors")
	);
	const lotNo = useSelector(state => formSelector(state, "lot_No"));

	const [prevStock, setPrevStock] = useState([]);
	useEffect(() => {
		if (stock?.[0]?.length && !readyStockList?.length) setPrevStock(stock?.[0]);
	}, [stock]);

	useEffect(() => {
		//	setReadySockContainer([...readyStockContainer, ...readyStockList]);
		setPrevStock([...prevStock, ...readyStockList]);
	}, [readyStockList]);

	useEffect(() => {
		if (id) {
			dispatch(getMasterDetails({ masterName, id }));
			setLoading(false);
		}
		return () => dispatch(resetMasterDetails(masterName));
	}, [dispatch, id]);

	const handleItemChange = (index, value, key) => {
		let tp = [...prevStock];
		const newValue = Number(value.target.value);

		if (id) {
			if (key === "size34") {
				tp[index] = {
					...tp[index],
					size34: newValue,
				};
			}
			if (key === "size36") {
				tp[index] = {
					...tp[index],
					size36: newValue,
				};
			}
			if (key === "size38") {
				tp[index] = {
					...tp[index],
					size38: newValue,
				};
			}
			if (key === "size40") {
				tp[index] = {
					...tp[index],
					size40: newValue,
				};
			}
			if (key === "size42") {
				tp[index] = {
					...tp[index],
					size42: newValue,
				};
			}
			if (key === "size44") {
				tp[index] = {
					...tp[index],
					size44: newValue,
				};
			}
			if (key === "size46") {
				tp[index] = {
					...tp[index],
					size46: newValue,
				};
			}
			if (key === "size48") {
				tp[index] = {
					...tp[index],
					size48: newValue,
				};
			}

			if (key === "comment") {
				tp[index] = {
					...tp[index],
					comment: newValue,
				};
			}
			//setPrevStock([...tp]);
		}
		//	setPrevStock([...tp]);
		//console.log("readyStockC", readyStockContainer);
	};

	const initialValues = {
		//ready: stock?.[0],
		generated_Colors: generatedColors,
		subCategory: subCategory,
		lot_No: lotNo,

		ready: prevStock?.map(item => ({
			active: item?.active || 1,
			delete: item?.delete || 0,
			id: item?.id || null,
			mainitem: item?.mainitem || null,
			color: item?.color || null,
			size34: item?.size85 || 0,
			size36: item?.size90 || 0,
			size38: item?.size95 || 0,
			size40: item?.size100 || 0,
			size42: item?.size105 || 0,
			size44: item?.size110 || 0,
			size46: item?.size115 || 0,
			size48: item?.size120 || 0,
			comment: item?.comment || "-",
			total: 0,
		})),

		commentOverview: list?.[0]?.commentOverview || null,
	};

	//console.log("initialValue", initialValues);
	return (
		<ReadyStockForm
			initialValues={initialValues}
			handleItemChange={handleItemChange}
			// submit={handleSubmit}
			// onCancel={handleClose}
			isViewOnly={isViewOnly}
		/>
	);
};

export default ReadyStockEntryWrapper;
