import React, {useEffect} from 'react'
import { Button } from 'antd'
import { Form, Field } from 'react-final-form'
import InputFieldV2 from 'utils/v2/InputFieldV2'
import { useDispatch, useSelector } from "react-redux";
import { addMasterList, getMasterDropDownList, getMasterList, masterSelectors, } from 'slices/masterSlice'
import { Helmet } from 'react-helmet';
import { useHotkeys } from 'react-hotkeys-hook'
import { ReduxFormSelectField2 } from 'utils/ReduxFormSelectField';

const masterName = "customergroup"
const customerDropdownMaster = "customer_dropdown"
const AddCustomerGroup = () => {

  const dispatch = useDispatch();
  const loading = useSelector(masterSelectors?.getIsUpdatingMasterList);
  const customerDropdown = useSelector(masterSelectors?.getCustomerDropdown);
  const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);

  useEffect(()=>{
    dispatch(getMasterDropDownList({masterName:customerDropdownMaster}))
  },[])

    const onSubmit = async (values, form) => {
      if(values?.customerId === undefined || values?.customerId?.length === 0){
        return alert("Please add customer.")
    }

      let customerId = [];
      values?.customerId?.map((item)=>{
          customerId.push(item?.value)
      })
      let formData= {
        customerId,
        groupName: values?.groupName
      }

      let res = await dispatch(
          addMasterList({
            masterName,
            formData,
          })
        );
        if(res?.type === "master/add/fulfilled"){
          form.restart();
          dispatch(
            getMasterList({
              masterName
            })
          )
        }
    }

    const required = value => (value ? undefined : 'Required')
    useHotkeys('alt+l', () => window.location.reload())
    useHotkeys('alt+s', (event) => {
      event.preventDefault();
      document.getElementById('form').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    });

  return (
    <>
    <Helmet>
	<title>Customer Group | Add</title>
	</Helmet>
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} id='form'>
    <>
    <h1 className='text-2xl mb-5'>Add Customer Group</h1>
    <div className='flex gap-5'>
        <div className=''>
            <Field name="groupName" validate={required}>
            {({ input, meta }) => (
              <InputFieldV2 input={input} meta={meta} type='text' label="Group name"/>
            )}
          </Field>
        </div>

<div>
    <Field
    component={ReduxFormSelectField2}
    validate={required}
    label="Customers"
    name="customerId"
    placeholder="Select customers"
    options={customerDropdown}
    isLoading={loadingDropdown}
    isMulti
  />
</div>
        
    </div>

<div className='flex gap-5 justify-end mt-10'>
<Button type="primary" size="small" className='bg-secondaryBase w-[80px]' htmlType='button'
onClick={form.reset}
>Clear</Button>
<Button type="primary" size="small" className='bg-redBase w-[80px]' htmlType='submit'
loading={loading}
disabled={loading}
>Add</Button>

</div>
    </>
    </form>
      )}/>
    </>
  )
}

export default AddCustomerGroup