import { Button } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, change, formValueSelector, getFormValues } from "redux-form";
import { getMasterDropDownList, getOutwardEntryDetails, masterSelectors } from "slices/masterSlice";
import { ReduxFormSelectField2 } from "utils/ReduxFormSelectField";
import { ReduxFormTextField3 } from "utils/ReduxFormTextField";
import { masterName } from "./OutwardStockEntry";

const formSelector = formValueSelector("OutwardStockEntry");

const OutwardFilters = ({
	outwardStockList,
	setOutwardStockList,
	val,
	dispatchStockList,
	setDispatchStockList,
}) => {
	const dispatch = useDispatch();
	const formValues = useSelector(getFormValues("OutwardStockEntry"));

	const mainItemDropdown = useSelector(masterSelectors?.getMainItemDropdown);
	const colorDropdown = useSelector(masterSelectors?.getColorDropdown);
	const partyNameDropdown = useSelector(masterSelectors?.getPartyNameDropdown);
	const outwardDropdown = useSelector(masterSelectors?.getOutwardDropdown);
	const dispatchDropdown = useSelector(masterSelectors?.getDispatchDropdown);
  	const loadingDropdown = useSelector(masterSelectors?.getIsFetchingDropdown);

	  useEffect(()=>{
		dispatch(getMasterDropDownList({masterName:"item"}))
		dispatch(getMasterDropDownList({masterName:"outward_dropdown"}))
		dispatch(getMasterDropDownList({masterName:"partyName_dropdown"}))
		dispatch(getMasterDropDownList({masterName:"color_dropdown"}))
		dispatch(getMasterDropDownList({masterName:"dispatch_dropdown"}))
	  },[])

	const outwardStockListMain = useSelector(
		masterSelectors?.getOutwardStockEntryList
	);

	const dispatchStockListMain = useSelector(
		masterSelectors?.getDispatchStockList
	);

	let mainItems = [];
	let colors = [];
	let partyNames = [];

	outwardStockList.forEach(record => {
		record.stock.forEach(item => {
			mainItems.push(item?.mainItem);
			colors.push(item?.color);
			partyNames.push(item?.partyName);
		});
	});



	let dispatchMainItems = [];
	let dispatchColors = [];
	let dispatchPartyNames = [];

	dispatchStockList?.forEach(record => {
		record?.dispatchStock.forEach(item => {
			dispatchMainItems.push(item?.mainItem);
			dispatchColors.push(item?.color);
			dispatchPartyNames.push(item?.partyName);
		});
	});


	const [show, setShow] = useState(false);

	const handleFilter = () => {
		const val = {
			startDate: formValues?.startDate || "",
			endDate: formValues?.endDate || "",
			mainItem: formValues?.mainItem?.map(item => item?.label) || [],
			color: formValues?.color?.map(item => item?.label) || [],
			outwardno: formValues?.outwardno?.map(item => item?.label) || [],
			party: formValues?.party?.map(item => item?.label) || [],
		};

		dispatch(
			getOutwardEntryDetails({
				masterName,
				formdata: val,
			})
		);

		setOutwardStockList(outwardStockListMain);
		console.log("val", val);
	};

	const handleResetFilter = () => {
		const val = {
			startDate: "",
			endDate: "",
			mainItem: [],
			color: [],
			outwardno: [],
			party: [],
		};
		dispatch(
			getOutwardEntryDetails({
				masterName,
				formdata: val,
			})
		);

		setOutwardStockList(outwardStockListMain);

		dispatch(change("OutwardStockEntry", "startDate", null));
		dispatch(change("OutwardStockEntry", "endDate", null));
		dispatch(change("OutwardStockEntry", "mainItem", null));
		dispatch(change("OutwardStockEntry", "color", null));
		dispatch(change("OutwardStockEntry", "party", null));
		dispatch(change("OutwardStockEntry", "outwardno", null));
	};

	const handleDispatchFilter = () => {
		const val = {
			startDate: formValues?.startDateDispatch || "",
			endDate: formValues?.endDateDispatch || "",
			mainItem: formValues?.mainItemDispatch?.map(item => item?.label) || [],
			color: formValues?.colorDispatch?.map(item => item?.label) || [],
			dispatchno: formValues?.dispatchNo?.map(item => item?.label) || [],
			party: formValues?.partyDispatch?.map(item => item?.label) || [],
		};

		dispatch(
			getOutwardEntryDetails({
				masterName: "DispatchStock",
				formdata: val,
			})
		);

		setDispatchStockList(dispatchStockListMain);
		console.log("val", val);
	};

	const handleResetDispatchFilter = () => {
		const val = {
			startDate: "",
			endDate: "",
			mainItem: [],
			color: [],
			dispatchno: [],
			party: [],
		};
		dispatch(
			getOutwardEntryDetails({
				masterName: "DispatchStock",
				formdata: val,
			})
		);

		setDispatchStockList(dispatchStockListMain);

		dispatch(change("OutwardStockEntry", "startDateDispatch", null));
		dispatch(change("OutwardStockEntry", "endDateDispatch", null));
		dispatch(change("OutwardStockEntry", "mainItemDispatch", null));
		dispatch(change("OutwardStockEntry", "colorDispatch", null));
		dispatch(change("OutwardStockEntry", "partyDispatch", null));
		dispatch(change("OutwardStockEntry", "dispatchNo", null));
	};

	return (
		<>
			{show ? (
				<>
					{/* <div className="flex justify-end items-center md:mr-10">
						<Button
							size="small"
							className="text-redBase border-redBase"
							onClick={() => setShow(false)}
						>
							Hide Filter
						</Button>
					</div> */}
					<div className="md:flex block">
						{val === "Outward" ? (
							<>
								<div className="md:mr-5 mb-5">
									<Field
										label="Start Date"
										name="startDate"
										component={ReduxFormTextField3}
										type="date"
										placeholder="Enter Date"
									/>
								</div>
								<div className="md:mr-5 mb-5">
									<Field
										label="End Date"
										name="endDate"
										component={ReduxFormTextField3}
										type="date"
										placeholder="Enter Date"
									/>
								</div>
								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="Main Item"
										name="mainItem"
										options={mainItemDropdown}
										placeholder="Select Main Items"
										isLoading={loadingDropdown}
										isMulti
									/>
								</div>
								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="Color"
										name="color"
										placeholder="Enter color"
										options={colorDropdown}
										isLoading={loadingDropdown}
										isMulti
									/>
								</div>
								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="Party"
										name="party"
										placeholder="Enter party"
										options={partyNameDropdown}
										isLoading={loadingDropdown}
										isMulti
									/>
								</div>
								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="OutwardNo"
										name="outwardno"
										placeholder="Enter Outward No"
										options={outwardDropdown}
										isLoading={loadingDropdown}
										isMulti
									/>
								</div>

								<div className="md:mr-5 ">
									<Button
										className="mt-2 text-primaryBase border-primaryDark font-semibold"
										onClick={handleFilter}
										size="small"
									>
										Apply Filters
									</Button>
								</div>
								<div className="md:mr-5 ">
									<Button
										className="mt-2 text-primaryBase border-primaryDark font-semibold"
										onClick={handleResetFilter}
										size="small"
									>
										Reset Filters
									</Button>
								</div>
							</>
						) : (
							<>
								<div className="md:mr-5 mb-5">
									<Field
										label="Start Date"
										name="startDateDispatch"
										component={ReduxFormTextField3}
										type="date"
										placeholder="Enter Date"
									/>
								</div>
								<div>
									<Field
										label="End Date"
										name="endDateDispatch"
										component={ReduxFormTextField3}
										type="date"
										placeholder="Enter Date"
									/>
								</div>
								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="Main Item"
										name="mainItemDispatch"
										options={mainItemDropdown}
										placeholder="Select Main Items"
										isLoading={loadingDropdown}
										isMulti
									/>
								</div>
								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="Color"
										name="colorDispatch"
										placeholder="Enter color"
										options={colorDropdown}
										isLoading={loadingDropdown}
										isMulti
									/>
								</div>
								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="Party"
										name="partyDispatch"
										placeholder="Enter party"
										options={partyNameDropdown}
										isLoading={loadingDropdown}
										isMulti
									/>
								</div>
								<div className="md:mr-5 mb-5">
									<Field
										component={ReduxFormSelectField2}
										label="DispatchNo"
										name="dispatchNo"
										placeholder="Enter Dispatch No"
										options={dispatchDropdown}
										isLoading={loadingDropdown}
										isMulti
									/>
								</div>

								<div className="md:mr-5 ">
									<Button
										className="mt-2 text-primaryBase border-primaryDark font-semibold"
										onClick={handleDispatchFilter}
										size="small"
									>
										Apply Filters
									</Button>
								</div>
								<div className="md:mr-5 ">
									<Button
										className="mt-2 text-primaryBase border-primaryDark font-semibold"
										onClick={handleResetDispatchFilter}
										size="small"
									>
										Reset Filters
									</Button>
								</div>
							</>
						)}
						<div className="mt-2">
						<Button
							size="small"
							className="text-redBase border-redBase"
							onClick={() => setShow(false)}
						>
							Hide Filter
						</Button>
						</div>
					</div>

				</>
			) : (
				<div className="flex justify-end items-center md:mr-5">
					<Button
						className="text-primaryBase border-primaryDark"
						onClick={() => setShow(true)}
						size="small"
					>
						Show Filter
					</Button>
				</div>
			)}
		</>
	);
};

export default OutwardFilters;
